import React, { useContext, useEffect, useState } from "react";
import style from "./filter.module.scss";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import MenuBarCommon from "../../commonComponent/menuBarComponent/MenuBarCommon";
import AllFilterMenu from "../../commonComponent/filterMenuData/AllFilterMenu";
import Divider from "@mui/material/Divider";
import BulbIcon from "../../../assets/searchIcons/BulbIcon";
import FocusIcon from "../../../assets/searchIcons/FocusIcon";
import CalenderIcon from "../../../assets/searchIcons/CalenderIcon";
import TageIcon from "../../../assets/searchIcons/TageIcon";
import ContactIcon from "../../../assets/searchIcons/ContactIcon";
import UserIcon from "../../../assets/searchIcons/UserIcon";
import PluseIcon from "../../../assets/searchIcons/PluseIcon";
import ChevronDown from "../../../assets/searchIcons/ChevronDown";
import AssignedToMenu from "../../commonComponent/filterMenuData/AssignedToMenu";
import TeamMenu from "../../commonComponent/filterMenuData/TeamMenu";
import TagMenu from "../../commonComponent/filterMenuData/TagMenu";
import UserMenu from "../../commonComponent/filterMenuData/UserMenu";
import StartedMenu from "../../commonComponent/filterMenuData/StartedMenu";
import StatusMenu from "../../commonComponent/filterMenuData/StatusMenu";
import TopicMenu from "../../commonComponent/filterMenuData/TopicMenu";
import FilterMenu from "../../commonComponent/filterMenuData/FilterMenu";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import FilterToolbar from "./FilterToolbar";
import UserCard from "../../sidebar/Card";
import { MainContext } from "../../../context/MainProvider";
import { Outlet, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { get } from "lodash";
import OrderMenu from "../../commonComponent/filterMenuData/OrderMenu";
import { arrayToQueryString } from "../../../utils/helpers/helpers";
import MessageBarComponent from "../yourInbox/messageBarComponent/MessageBarComponent";
import { useSocket } from "../../../context/SocketProvider";
import filterStyle from "./filter.module.scss"
import { SidebarContext } from "../../../context/SidebarProvider";
import ExternalIdMenu from "../../commonComponent/filterMenuData/ExternalIdMenu";
const newFilters = [];
const filters = ['all','assignee_id','team_id','tag_id','customer_id','created_to','status','topic_id','sort_by']
const FilterComponent = ({ ToolbarComponent, filterValue }) => {
  const  { filterTickets, handleFetchFilterTickets, filterLoadingData, currentUser } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const [forAll, setForAll] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleFetchTickets, tickets, setAllTickets } = useContext(MainContext)
  const [currentView, setCurrentView] = useState(false);
  const [lastMessage, setLastMessage] = useState("");
  const [cardMessagesState, setCardMessagesState] = useState({})
  const [userTickets,setUserTickets] = useState([])
  const [currentTicket,setCurrentTicket] = useState({});
  const [advanceFilter,setAdvanceFilters] = useState([])
  const params = useParams();
  const navigate = useNavigate()
  const socket = useSocket()
  useEffect(()=>{
    if(forAll){
      if(filterValue === 'unassigned'){
        handleFetchFilterTickets({type:'assignee_id', data:filterValue})
      }else if(filterValue=== 'all'){
        handleFetchFilterTickets()
      }else if(filterValue === 'mentions'){
        handleFetchFilterTickets({type:'mention_id',data:get(currentUser,'id','')})
      }
    }
   
   
  },[filterValue, currentUser, forAll])

  const [filtersKey, setFilterKeys] = useState({})

const handleFetchFilterTicketDetails = (ticketSno, all) => {
 
  if(all){
    setForAll(true)
  }else{
    let filterObj = {};
    if(filterValue === 'unassigned'){
      filterObj = {type:'assignee_id', data:filterValue}
    }else if(filterValue=== 'all'){
      filterObj = {}
    }else if(filterValue === 'mentions'){
      filterObj = {type:'mention_id',data:get(currentUser,'id','')}
    }
    handleFetchFilterTickets({
      type:"sno",
      data:ticketSno
    },filterObj).then(res=>{
      if(Object.keys(res).length===0){
        handleSetNewView(filterValue)
        setForAll(true)
        handleShowSnackPopup({message:"Ticket not found", error:true, time:3000})
      }else{
        setCurrentTicket(res)
      }
    })
  }
}

useEffect(()=>{
  if(Object.keys(currentTicket).length && socket.socket){
  socket.onUserClicked({ticketId:currentTicket.sno})
}
},[currentTicket])

  useEffect(()=>{
    if(!(Object.values(params).filter(Boolean).length)){
      setForAll(true)
      setCurrentTicket({})
    }
  },[filterValue])
  const handleAddNewFilter = ([...arr],add=1) => {
    let allFilterKeys = {...filtersKey}
    let isAll = false;
    arr.forEach(({key,value})=>{
      if(key=='all'){
        isAll = true
      }
      allFilterKeys[key] = value;
    })
    if(isAll){
      setFilterKeys({
        all:'all'
      })
    }else{
      delete allFilterKeys['all'];
      setFilterKeys(allFilterKeys);
    }
  }

  useEffect(()=>{
    if(forAll){
      if(filterValue === "searchFilter"){
        let tempFilters = {...filtersKey}
        let filterPayload = Object.keys(tempFilters).map(ele=>({
          type:ele,
          data:tempFilters[ele]
        }))
        let query = arrayToQueryString(filterPayload)
        setSearchParams(query)
        handleFetchFilterTickets(...filterPayload)
      }
    }   
  },[filtersKey, forAll])

  const handleDeleteFilter = (...deletedItems) => {
    let allFilters = {...filtersKey}
    deletedItems.forEach(key=>{
      delete allFilters[key];
    })
    // navigate('/search')
    setFilterKeys(allFilters)
  }

  useEffect(()=>{
    let obj = {}
    searchParams.forEach((value, key) => {
      obj[key] = value
    });
    setFilterKeys(obj)
  },[])










  const handleSetCurrentView = (cardDetails) => {
    navigate(`conversation/${cardDetails.sno}`)
    setCurrentTicket(cardDetails)
    // socket.onUserClicked({ticketId:cardDetails.sno})
  };

  const handleSetNewView = (filterValue) => {
    navigate(`/inbox/${filterValue}`)
  }

  useEffect(()=>{
  },[filterTickets])

  useEffect(()=>{
    // handleFetchTickets().then(cardDetails=>{
    //     setCurrentTicket(cardDetails);
    //     // navigate(`conversation/${cardDetails.sno}`)
    // })
  },[])

  useEffect(()=>{
    setUserTickets(get(tickets,'docs',[]))
  },[tickets])

  useEffect(()=>{
    let newMessages = {...socket.messages};
    let ticketId  = currentTicket.sno;
    let ticketMessages = get(newMessages,`[${ticketId}]`,[]).map(ele=>({...ele,read:true}))
    newMessages[ticketId] = ticketMessages;
    setCardMessagesState(newMessages)
  },[socket.messages])

  useEffect(()=>{
    let ticketsData = [...userTickets];
    ticketsData.forEach(ticEle=>{
      let sno = ticEle.sno;
      const getChatMessage= get(cardMessagesState,`[${sno}]`,[])
      const getMessage =  get(getChatMessage.slice(-1),`[0].message`,"");
      ticEle['ticketLastMessage'] = getMessage
    })
    setUserTickets(ticketsData);
  }, [cardMessagesState])

  

  const getUnreadMessages = (messages) => {
    let allUnreadsMessages = messages.filter(ele=>ele.read == false)
    let time = get(allUnreadsMessages.slice(-1),`[0].createdAt`,"")
    let response =  {
      unreadMessages:allUnreadsMessages.length,
      lastMessageTime:time
    }
    return response
  }

  const handleAddAdvenceFilter = (value,deleted=false) => {
    let tempAdvanceFilters = [...advanceFilter]
    const isExist = tempAdvanceFilters.some(({key})=>key===value.key)
    if(isExist) return
    tempAdvanceFilters.push(value)
    setAdvanceFilters(tempAdvanceFilters)
  }

  return (
    <>
    
    <MainComponent   className={filterStyle.filterClassComponentMainComponent} >
      <SideToolbar>{ToolbarComponent && <ToolbarComponent filtersKey={filtersKey} setFilterKeys={setFilterKeys} />}</SideToolbar>
      {filterValue === 'searchFilter' && <> 
      <Box className={style.filterMainComponent} sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={10}
            flexDirection={"row"}
            display={"flex"}
            gap={"10px"}
            flexWrap={"wrap"}
          >
            
           
            <MenuBarCommon MenuComponent={AllFilterMenu} 
            isDeleted={filtersKey[filters[0]]}
            selected={filtersKey[filters[0]]}
            handleDelete={()=>handleDeleteFilter(filters[0])}
            onSelectFilter={({value})=>handleAddNewFilter([{key:'all',value:value}]) }
            filKey={filters[0]} 

             label={"All"} />
            <MenuBarCommon
              isDeleted={filtersKey[filters[1]]}
              selected={filtersKey[filters[1]]}
              handleDelete={()=>handleDeleteFilter(filters[1])}
              filKey={filters[1]}
              onSelectFilter={({id})=>handleAddNewFilter([{key:'assignee_id',value:id}]) }
              MenuComponent={AssignedToMenu}
              Icon={()=><UserIcon  color={filtersKey[filters[1]] && "white"}/>}
              label={"Assigned to"}
            />
            <MenuBarCommon
             onSelectFilter={({id})=>{handleAddNewFilter([{key:'team_id',value:id}])}}
             isDeleted={filtersKey[filters[2]]}
             selected={filtersKey[filters[2]]}
             handleDelete={()=>handleDeleteFilter(filters[2])}
             filKey={filters[2]}
              MenuComponent={TeamMenu}
              Icon={()=><ContactIcon color={filtersKey[filters[2]] && "white"} />}
              label={"Team"}
            />
            <MenuBarCommon
              onSelectFilter={({id})=>{handleAddNewFilter([{key:'tag_id',value:id}]) }}
              isDeleted={filtersKey[filters[3]]}
              selected={filtersKey[filters[3]]}
              handleDelete={()=>handleDeleteFilter(filters[3])}
              filKey={filters[3]}
              MenuComponent={TagMenu}
              Icon={()=><TageIcon color={filtersKey[filters[3]] && "white"} />}
              label={"Tag"}
            />
            <MenuBarCommon
             onSelectFilter={({id})=>{handleAddNewFilter([{key:'customer_id',value:id}]) }}
             selected={filtersKey[filters[4]]}
             isDeleted={filtersKey[filters[4]]}
             handleDelete={()=>handleDeleteFilter(filters[4])}
             filKey={filters[4]}
              MenuComponent={UserMenu}
              Icon={()=><UserIcon color={filtersKey[filters[4]] && "white"} />}
              label={"User"}
            />
            <MenuBarCommon
              onSelectFilter={({created_to, created_from})=>{handleAddNewFilter([{key:'created_to',value:created_to},{
                key:'created_from',value:created_from
              }]) }}
              selected={filtersKey[filters[5]]}
              isDeleted={filtersKey[filters[5]]}
              handleDelete={()=>handleDeleteFilter('created_to','created_from')}
              filKey={filters[5]}
              MenuComponent={StartedMenu}
              Icon={()=><CalenderIcon color={filtersKey[filters[5]] && "white"} />}
              label={"Started"}
            />
            <MenuBarCommon
              onSelectFilter={({key})=>{handleAddNewFilter([{key:'status',value:key}]) }}
              selected={filtersKey[filters[6]]}
              isDeleted={filtersKey[filters[6]]}
              handleDelete={()=>handleDeleteFilter(filters[6])}
              filKey={filters[6]}
              MenuComponent={StatusMenu}
              Icon={ ()=><FocusIcon color={filtersKey[filters[6]] && "white"} />}
              label={"Status"}
            />
            <MenuBarCommon
             onSelectFilter={({id})=>{handleAddNewFilter([{key:'topic_id',value:id}]) }}
              selected={filtersKey[filters[7]]}
              isDeleted={filtersKey[filters[7]]}
              handleDelete={()=>handleDeleteFilter(filters[7])}
              filKey={filters[7]}
              MenuComponent={TopicMenu}
              Icon={()=> <BulbIcon color={filtersKey[filters[7]] && "white"} />}
              label={"Topic"}
            />
            {advanceFilter.map(ele=>{
            return <MenuBarCommon
            key={ele.key}
            defaultOpen={true}
            onSelectFilter={({data})=>{ handleAddNewFilter([{key:ele.key,value:data}])}}
            selected={filtersKey[ele.key]}
            isDeleted={filtersKey[ele.key]}
            handleDelete={()=>handleDeleteFilter(ele.key)}
            filKey={ele.key}
            MenuComponent={ExternalIdMenu}
            Icon={()=> <></>}
            label={ele.display}
            />
            })}
            <MenuBarCommon
              onSelectFilter={(value)=>{handleAddAdvenceFilter(value)}}
              MenuComponent={FilterMenu}
              Icon={()=> <PluseIcon color={advanceFilter.length && "white"} />}
              label={"Filters"}
            />
          </Grid>
          <Grid
            item
            xs={2}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <MenuBarCommon
               onSelectFilter={({sort_by,sort_order})=>{handleAddNewFilter([{
                key:'sort_by',
                value:sort_by
               },{
                key:'sort_order',
                value:sort_order
               }]) }}
               selected={filtersKey[filters[8]]}
               isDeleted={filtersKey[filters[8]]}
               handleDelete={()=>handleDeleteFilter('sort_by','sort_order')}
               filKey={filters[7]}
                MenuComponent={OrderMenu}
               Icon={()=> {
                return filtersKey[filters[8]] ? <></> : <ChevronDown />
               }} 
               label={"Relevance"}
                style={{
                background: "#f4f4fb",
                fontSize: "16px",
                flexDirection:filtersKey[filters[8]] ? "" : "row-reverse",
              }}
            />
          </Grid>
        </Grid>
      </Box>
      </> }
      <Box  sx={{ flexGrow: 1, display:'flex', flexDirection:'column', gap:'10px', maxHeight:'82vh', overflowY:'scroll' }} >
       
      {filterLoadingData ? <Stack spacing={1}>
        {[ ...Array(10).keys() ].map(ele=>(
        <Skeleton variant="rectangular" width={'100%'} height={50} />
        ))}
    </Stack> : 
        filterTickets.map(ele=>(
             <UserCard  active={get(currentTicket, "sno", null) === get(ele, "sno", null)}   cardDetails={ele} cardLastMessage={""} handleOpenChatView={handleSetCurrentView}  unreadMessages={{}}/>
        ))}

      </Box>
    </MainComponent>

    <Routes>
        <Route path="/" element={<Outlet />} />
          <Route  path="conversation/:ticketId" element={
            <>
            <MessageBarComponent  currentTicket={currentTicket} handleFetchFilterTicketDetails={handleFetchFilterTicketDetails}  />
            </>
          } />
        </Routes>
    </>
  );
};

export default FilterComponent;
