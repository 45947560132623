import React, { useEffect, useState } from "react";
import { Box, Button, InputBase, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { get } from "lodash";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
const DisplayTicketTypeAttributes = ({
  ticketTypeAttributes,
  handleCreateAttributes
}) => {

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: "10px",
          marginTop:"20px"
        }}
      >
        <Box sx={{ padding: "0px 10px 0px 10px" }}>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none"}}
          >
            <Table
              sx={{ minWidth: 650 }}
              aria-label="simple table" >
              <TableHead >
                <TableRow>
                <TableCell  padding="checkbox" sx={{ fontWeight: "bold" }}></TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  
                    Format{" "}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Visible on teammate create ticket form
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Required for teammates to create ticket
                  </TableCell>

                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Visible to customers
                  </TableCell>

                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Required for customers to create ticket
                  </TableCell>

                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                  Created
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                
                  </TableCell>
                
                </TableRow>
              </TableHead>
              <TableBody>
                {ticketTypeAttributes.map((element, index) => {
                  const modeType = get(element, "type", "view");
                  const name = get(element, "name", "");
                  const createdAt = get(element, "createdAt", "");
                  const isViewMode = modeType === "view";
                  const format = get(element,'fieldType','Text')
                  
                  let momentTime = moment(createdAt);
                  momentTime = momentTime.isValid() ? momentTime.fromNow() : "";
                  return (
                    <TableRow
                     
                      key={get(element, "id", createdAt)}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                         <TableCell  padding="checkbox" sx={{ fontWeight: "bold" }}></TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                          {name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left"  sx={{textTransform:"capitalize"}} >{format}</TableCell>
                      <TableCell align="left">{"NaN"}</TableCell>
                      <TableCell align="left">{"NaN"}</TableCell>
                      <TableCell align="left">{"NaN"}</TableCell>
                      <TableCell align="left">{"NaN"}</TableCell>
                      <TableCell align="left">{"NaN"}</TableCell>
                      <TableCell align="right">
                        {
                          <IconButton
                          onClick={()=>{
                            handleCreateAttributes({
                              type:"view",
                              id:get(element, "id", null)
                            })
                          }}
                          
                            aria-label="delete"
                            size="small"
                            sx={{
                              padding: "3px 10px",
                              background: "#f1f1f1",
                              borderRadius: "5px",
                            }}
                          >
                            <ModeEditIcon
                              sx={{ color: "black", fontSize: "18px" }}
                            />
                          </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default DisplayTicketTypeAttributes