import { Box, Button, InputBase, Paper, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./allUsers.module.scss";
import CursorDownIcon from "../../../../assets/icons/CursorDownIcon";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PlusBoxIcon from "../../../../assets/icons/PlusBoxIcon";
import { MainContext } from "../../../../context/MainProvider";
import CreateCompany from "./createCompany/CreateCompany";
import SearchBarComponent from "../../../setting/searchComponent/SearchBarComponent";
const AllCompanyToolbar = ({ handleGetAllCompines, setCompanyLoadingData }) => {
  const { handleOpenModal } = useContext(MainContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCreateNewCompany = (type) => {
    handleClose();
    handleOpenModal({
      state: true,
      Component: <CreateCompany data={{  handleGetAllCompines }} />,
    });
  };
  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
        <Box className="toolbarContent">
          <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
            All
          </Typography>
          <Box sx={{ display: "flex", gap: "30px" }}>
         <SearchBarComponent  handleCollectValue={(value)=>{
          setCompanyLoadingData(true)
          handleGetAllCompines({type:"name",data:value}).then(res=>{
            setCompanyLoadingData(false)
          }).catch(err=>{
            setCompanyLoadingData(false)
          })
         }}  style={{
          height:"30px"
         }} />
            <Box>
              <Button
                endIcon={<CursorDownIcon sx={{ color: "white !important" }} />}
                className="add-profile"
                sx={{
                  textTransform: "inherit",
                }}
                id="basic-menu"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
              >
               Company
              </Button>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  onClick={() => handleCreateNewCompany()}
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {" "}
                  <PlusBoxIcon />{" "}
                  <Typography sx={{ color: "#222", fontSize: "15px" }}>
                    Create new company
                  </Typography>
                </MenuItem>
                {/* <MenuItem
                  onClick={() => handleCreateNewUser("lead")}
                  sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                >
                  {" "}
                  <PlusBoxIcon />{" "}
                  <Typography sx={{ color: "#222", fontSize: "15px" }}>
                    Create new lead
                  </Typography>
                </MenuItem> */}
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AllCompanyToolbar;
