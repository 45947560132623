import {  Box, CssBaseline, Paper, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { LoginApi, resetPassword } from "../../../services/Apis";
import { decryptBase64, encryptBase64, getCookie, handleSetToken, isEmail, setCookie, setWorkspaceId } from "../../../utils/helpers/helpers";
import { useNavigate, useLocation, Link, useParams } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import { HttpClient } from "../../../services/HttpClient";
import { SidebarContext } from "../../../context/SidebarProvider";
import { get } from "lodash";
import login2Img from "../../../assets/login2.jpg"
import brandIcon from "../../../assets/Logo.png"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.pullse.ai/"   >
      Pullse AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ResetPassword() {
    const { handleShowSnackPopup } = useContext(SidebarContext);
    const [loginLoading,setLoginLoading] = useState(false)
    const [globalError, setGlobalError] = useState(false)
    const [loginData, setLoginData] = useState({
      password: "",
      confirmPassword:""
    });
    const [onFormFocus, setOnFormFocus ] = useState(false) 
    const [passwordReset, setPasswordReset] = useState(false)
    let navigate = useNavigate();
    const params = useParams()
  
    const handleCollectValue = (e) => {
      let name = e.target.name,
        val = e.target.value;
      setLoginData((state) => ({
        ...state,
        [name]: val,
      }));
    };
  

    const validateLoginData = () => {
      if(loginData.password !== loginData.confirmPassword)
      {
        setGlobalError(true)
        return true
      }
      return false
    }
  
    const handleSubmitresult = async () => {
      let isError  = validateLoginData(loginData)
      if(isError) return;
      setLoginLoading(true)
      resetPassword({password:loginData.password,token:params.id}).then(res=>{
        handleShowSnackPopup({ message: "Password updated successfully" });
        setPasswordReset(true)
      }).catch(err=>{
        let errResponse = get(err,'response.data.message',"");
        handleShowSnackPopup({ message:  errResponse, error: true });
        setLoginLoading(false)
      })
    };
  
    const onHandleSubmit = (event) => {
      if(event.keyCode == 13 && onFormFocus){
        handleSubmitresult()
      }
    }
  
    let confirmPassword = globalError &&  loginData.password !== loginData.confirmPassword

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
          
            // background: `rgb(2,0,36)`,
            //     background: `linear-gradient(40deg, rgba(2,0,36,1) 13%, rgba(168,74,203,1) 52%)`
           
          }}
        > 
          <img src={login2Img} style={{
          backgroundClip:'border-box',
          //  backgroundClip:'content-box',
          //  backgroundPosition:'center',
          //  backgroundRepeat:'no-repeat',
           width:'150%'
        }} />

        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={8} square>
          <Box
            sx={{
              my: 12,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={brandIcon} />
            <Typography component="h1" variant="h5"  sx={{marginTop:"10px"}} >
              Reset Password
            </Typography>
            <Box component="div"  sx={{ mt: 1, width:"90%" }}>
                {
                    passwordReset ?
                     <>
                      <Typography
                    sx={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "25px",
                      color: "green",
                    }}
                  >
                    Password reset successfully
                  </Typography>

                  <Typography
                  onClick={()=>{
                    navigate("/")
                  }}
                    sx={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "15px",
                      textDecoration:"underline",
                      color:"blue",
                      cursor:"pointer"
                    }}
                  >
                    Click here to login
                  </Typography>
                     
                     </>
                     :
                     <>
                         <TextField
                error={false}
                value={loginData.password}
                margin="normal"
                required
                fullWidth
                onChange={handleCollectValue}
                id="password"
                label="Password"
                name="password"
                autoComplete="password"
                autoFocus
                onFocus={()=>setOnFormFocus(true)}
                onBlur={()=>setOnFormFocus(false)}
                onKeyUp={onHandleSubmit}
              />
              <TextField
                error={confirmPassword}
                value={loginData.confirmPassword}
                margin="normal"
                required
                onChange={handleCollectValue}
                fullWidth
                name="confirmPassword"
                label="confirmPassword"
                type="password"
                id="confirmPassword"
                autoComplete="confirmPassword"
                onFocus={()=>setOnFormFocus(true)}
                onBlur={()=>setOnFormFocus(false)}
                 onKeyUp={onHandleSubmit}
                  />

                <LoadingButton
                  loading={loginLoading}
                  onClick={handleSubmitresult}
                  sx={{
                    width: "100%",
                    border: "1px solid #B9B9B9",
                    color: "#3E1599",   
                    marginTop:"10px"
                  }}
                  variant="outlined"
                  loadingIndicator="Loading…"
                >
                  Reset Password
                </LoadingButton>
                     
                     </>
                }
          


              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}