import AutomationSidebarIcon from "./AutomationSidebarIcon"
import ContactSideBarIcon from "./ContactSideBarIcon"
import InboxSidebarIcon from "./InboxSidebarIcon"
import OutBoundSidebarIcon from "./OutBoundSidebarIcon"
import PiechatSidebarIcon from "./PiechatSidebarIcon"
import SettingSidebarIcon from "./SettingSidebarIcon"
import BookIcon from "./BookIcon"
import UserIcon from "../searchIcons/UserIcon"


const icons = {
    AutomationIcon:AutomationSidebarIcon,
    ContactIcon:ContactSideBarIcon,
    InboxIcon:InboxSidebarIcon,
    OutBondIcon:OutBoundSidebarIcon,
    PiechartIcon:PiechatSidebarIcon,
    SettingIcon:SettingSidebarIcon,
    BookIcon:BookIcon,
    UserIcon:UserIcon
}

export {
    icons
}