import {
  Box,
  Button,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import style from "./reports.module.scss";
import { get } from "lodash";
import DashboardIcon from '@mui/icons-material/Dashboard';
const ReportsToolbar = ({ reportListData, currentDashboardId, setCurrentDashboardId }) => {
  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box>
          <Typography sx={{fontWeight:"bold", fontSize:"18px"}} >
              Reports
            </Typography>
          </Box>
          <Box sx={{display:"flex", alignItems:'center', gap:"10px"}} >
            <DashboardIcon sx={{ color:'#a84acb'  }} />
            <Select
              defaultValue=""
              placeholder="text"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                width: "200px",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: "1px solid rgba(0,0,0,0.1)",
              }}
              onChange={event=>{
                setCurrentDashboardId(event.target.value)
              }}
              value={currentDashboardId}
              name="companyId"
              input={
                <InputBase
                  placeholder="company"
                  name="companyId"
                  variant="standard"
                  autoFocus={true}
                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                />
              }
            >
              <MenuItem value="" disabled>
                <em>Select</em>
              </MenuItem>
              {
                get(reportListData,'docs',[]).map(ele=>(
                  <MenuItem value={ele.id} >
                  {ele.name}
                </MenuItem>
                ))
              }
            </Select>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ReportsToolbar;
