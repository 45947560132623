import React, { useContext, useEffect, useState } from "react";
import { Avatar, Box, Toolbar, Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import UserCard from "../../sidebar/Card";
import style from "./yourInbox.module.scss";
import MiniCircle from "../../commonComponent/miniCircle.js/MiniCircle";
import TripleDotMenu from "../../../assets/icons/TripleDotMenu";
import StartIcon from "../../../assets/icons/StartIcon";
import MessageBox from "./messageBox/MessageBox";
import MoonIcon from "../../../assets/icons/MoonIcon";
import DownloadIcon from "../../../assets/icons/DownloadIcon";
import SendingDropDown from "./sendingMessageBox/SendingDropDown";
import MessageTextBox from "./textBox/MessageTextBox";
import LightningFilledIcon from "../../../assets/icons/LightningFilledIcon";
import SendingButton from "../../../assets/icons/SendingButton";
import CrossIcon from "../../../assets/icons/CrossIcon";
import ExternalLinkIcon from "../../../assets/icons/ExternalLinkIcon";
import AccordionComponent from "../../commonComponent/accordionComponent/AccordionComponent";
import Button from "@mui/material/Button";
import LayoutLeft from "../../../assets/searchIcons/LayoutLeft";
import { MainContext } from "../../../context/MainProvider";
import { get } from "lodash";
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import MessageBarComponent from "./messageBarComponent/MessageBarComponent";
import { useSocket } from "../../../context/SocketProvider";
import moment from "moment";
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import InboxCardComponent from "./InboxCardComponent";
import { SidebarContext } from "../../../context/SidebarProvider";
// import ConnectToSocket from "../../../context/SocketProvider";

const YourInbox = ({ ToolbarComponent }) => {
const navigate = useNavigate()
const { handleFetchTickets, tickets, setAllTickets } = useContext(MainContext)
const { handleShowSnackPopup } = useContext(SidebarContext)
const [currentView, setCurrentView] = useState(false);
const [lastMessage, setLastMessage] = useState("");
const [cardMessagesState, setCardMessagesState] = useState({})
const [userTickets,setUserTickets] = useState([])
const [currentTicket,setCurrentTicket] = useState({});
const [forAll, setForAll] = useState(false)

const handleFetchFilterTicketDetails = (ticketSno, all) => {
 
  if(all){
    setForAll(true)
  }else{
    handleFetchTickets({
      type:"sno",
      data:ticketSno
    }).then(res=>{
    
      if(Object.keys(res).length===0){
        handleSetNewView()
        setForAll(true)
        handleShowSnackPopup({message:"Ticket not found", error:true, time:3000})
      }else{
        setCurrentTicket(res)
      }
     
     
    })
  }
}

const params = useParams();
useEffect(()=>{
  if(!(Object.values(params).filter(Boolean).length)){
    setForAll(true)
  }
},[])


const location = useLocation();

const socket = useSocket()

  useEffect(()=>{
      if(Object.keys(currentTicket).length && socket.socket){
      socket.onUserClicked({ticketId:currentTicket.sno})
    }
  },[currentTicket])
  const handleSetNewView = () => {
    navigate(`/inbox/your-inbox`)
  }


  useEffect(()=>{
    if(forAll){
      handleFetchTickets()
    }
  },[forAll])
  useEffect(()=>{
    setUserTickets(get(tickets,'docs',[]))
  },[get(tickets,'docs',[])])
  

  useEffect(()=>{
    let newMessages = {...socket.messages};
    let ticketId  = currentTicket.sno;
    let ticketMessages = get(newMessages,`[${ticketId}]`,[]).map(ele=>({...ele,read:true}))
    newMessages[ticketId] = ticketMessages;
    setCardMessagesState(newMessages)
  },[socket.messages])

  useEffect(()=>{
    let ticketsData = [...userTickets];
    ticketsData.forEach(ticEle=>{
      let sno = ticEle.sno;
      const getChatMessage= get(cardMessagesState,`[${sno}]`,[])
      const getMessage =  get(getChatMessage.slice(-1),`[0].message`,"");
      ticEle['ticketLastMessage'] = getMessage
    })
    setUserTickets(ticketsData);
  }, [cardMessagesState])


  


 



  return (
    <>
      <MainComponent className={style.mainInboxComponentClass}>
        <SideToolbar>
          {ToolbarComponent && (
            <ToolbarComponent
              handleSetCurrentView={handleSetNewView}
              currentView={currentView}
            />
          )}
        </SideToolbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            padding:'10px',
            gap: "15px",
            maxHeight:'89vh',
          }}
        >
          <InboxCardComponent userTickets={userTickets} currentTicket={currentTicket}  setCurrentTicket={setCurrentTicket} cardMessagesState={cardMessagesState}/>
        </Box>
      </MainComponent>


      <Routes>
        <Route path="/" element={<Outlet />} />
          <Route  path="conversation/:ticketId" element={
            <>
            <MessageBarComponent setCurrentTicket={setCurrentTicket}  currentTicket={currentTicket}  handleFetchFilterTicketDetails={handleFetchFilterTicketDetails} />
            </>
          } />
        </Routes>
    </>
  );
};

export default YourInbox;
