import React from "react";

const ClockIcon = ({color='#313131', height=24, width=24}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M15.2838 15.3491C15.8077 15.5237 16.374 15.2406 16.5487 14.7166C16.7233 14.1927 16.4402 13.6264 15.9162 13.4517L15.2838 15.3491ZM12 13.2004H11C11 13.6308 11.2754 14.013 11.6838 14.1491L12 13.2004ZM13 8.18265C13 7.63036 12.5523 7.18265 12 7.18265C11.4477 7.18265 11 7.63036 11 8.18265H13ZM15.9162 13.4517L12.3162 12.2517L11.6838 14.1491L15.2838 15.3491L15.9162 13.4517ZM13 13.2004V8.18265H11V13.2004H13ZM20.6 12.0004C20.6 16.75 16.7496 20.6004 12 20.6004V22.6004C17.8542 22.6004 22.6 17.8546 22.6 12.0004H20.6ZM12 20.6004C7.25034 20.6004 3.39999 16.75 3.39999 12.0004H1.39999C1.39999 17.8546 6.14577 22.6004 12 22.6004V20.6004ZM3.39999 12.0004C3.39999 7.25074 7.25034 3.40039 12 3.40039V1.40039C6.14577 1.40039 1.39999 6.14617 1.39999 12.0004H3.39999ZM12 3.40039C16.7496 3.40039 20.6 7.25074 20.6 12.0004H22.6C22.6 6.14617 17.8542 1.40039 12 1.40039V3.40039Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default ClockIcon;
