import React from "react";

const TrackerTicketTypeIcon = ({color="#000", height=16, width=16}) => {
  return (
    <>
      <svg
        class="interface-icon o__standard o__standard__tracker o__by-text"
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path  fill={color} d="M14 14.117a1.053 1.053 0 01-1.054 1.033h-2.09a1.054 1.054 0 01-1.054-1.053v-.795H9.8V12.7h.002v-.393a1.054 1.054 0 011.053-1.054h2.091A1.053 1.053 0 0114 12.286v1.831zm0-6.955v1.827a1.054 1.054 0 01-1.054 1.034h-2.09A1.054 1.054 0 019.8 8.97V7.182a1.054 1.054 0 011.054-1.054h2.091A1.054 1.054 0 0114 7.162zm0-5.129v1.831a1.054 1.054 0 01-1.054 1.033h-2.09a1.054 1.054 0 01-1.054-1.053V3.45H9.8v-.602h.002v-.795A1.054 1.054 0 0110.855 1h2.091A1.054 1.054 0 0114 2.033zM0 6.913v2.324a1.054 1.054 0 001.054 1.054h2.392A1.054 1.054 0 004.5 9.237V6.913A1.054 1.054 0 003.446 5.86H1.054A1.054 1.054 0 000 6.913z"></path>
        <path  fill={color} d="M4.413 8.378v-.602h2.193v-3.42c0-.4.164-.783.456-1.065a1.582 1.582 0 011.1-.441h1.725v.602H8.16a.949.949 0 00-.66.265.889.889 0 00-.273.638v3.42h2.659v.603H7.228v3.42c0 .24.099.47.274.639a.948.948 0 00.66.264h1.725v.602H8.16a1.58 1.58 0 01-1.1-.44 1.482 1.482 0 01-.455-1.065v-3.42H4.413z"></path>
        <path
        fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4 8.778V7.376h2.193v-3.02c0-.506.208-.99.577-1.348.369-.357.87-.558 1.391-.558H10.3v1.402H8.161A.528.528 0 007.794 4l-1.6 4.778m1.034-1.002h2.659v.602H7.228v3.42c0 .24.099.47.274.639a.948.948 0 00.66.264h1.725v.602H8.16a1.58 1.58 0 01-1.1-.44 1.482 1.482 0 01-.455-1.065v-3.42H4.413v-.602h2.193v-3.42c0-.4.164-.783.456-1.065a1.582 1.582 0 011.1-.441h1.725v.602H7.228M6.193 8.778H4zm0 0v3.02c0 .505.208.99.577 1.347s.87.558 1.391.558H10.3v-1.402H8.161a.528.528 0 01-.367-.147.495.495 0 01-.153-.356v-3.02H10.3V7.376H7.641v-3.02c0-.134.055-.262.153-.356"
        ></path>
      </svg>
    </>
  );
};

export default TrackerTicketTypeIcon;
