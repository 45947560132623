import React from 'react';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "./handoverWorkflow.module.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
let name = process.env.REACT_APP_AUTO_NAME;


const HandoverWorkflowHeader = ({open}) => {
  return (
   <>
     <AccordionSummary
        className={style["hanover-workflow-header-main-container"]}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="accordianHeading">
          {`How ${name} should hand over`} {open ? <ExpandMoreIcon /> : <NavigateNextIcon />}{" "}
        </Typography>
      </AccordionSummary>
   </>
  )
}

export default HandoverWorkflowHeader
