import FilterComponent from "../components/inbox/searchComponent/FilterComponent";
import FilterToolbar from "../components/inbox/searchComponent/FilterToolbar";
import YourInbox from "../components/inbox/yourInbox/YourInbox";
import YourInboxToolbar from "../components/inbox/yourInbox/YourInboxToolbar";
import AllUsers from "../components/contact/people/allUsers/AllUsers";
import AllUsersToolbar from "../components/contact/people/allUsers/AllUsersToolbar";
import UserProfile from "../components/contact/people/allUsers/userProfile/UserProfile";
import UserProfileToolbar from "../components/contact/people/allUsers/userProfile/UserProfileToolbar";
import AllCompanyToolbar from "../components/contact/company/allUsers/AllCompanyToolbar";
import AllCompany from "../components/contact/company/allUsers/allCompany";
import CompanyProfileToolbar from "../components/contact/company/allUsers/companyProfile/CompanyProfileToolbar";
import CompanyProfile from "../components/contact/company/allUsers/companyProfile/CompanyProfile";
import TagComponentToolbar from "../components/setting/tags/TagToolbar";
import TagCompnent from "../components/setting/tags/TagComponent";
import TopicComponentToolbar from "../components/setting/topics/TopicComponentToolbar";
import TopicComponent from "../components/setting/topics/TopicComponent";
import OfficeHoursToolbar from "../components/setting/officeHours/OfficeHoursToolbar";
import OfficeHoursComponent from "../components/setting/officeHours/OfficeHoursComponent";
import TeamsComponentToolbar from "../components/setting/teams/TeamsComponentToolbar";
import TeamsComponent from "../components/setting/teams/TeamsComponent";
import TicketTypeComponentToolbar from "../components/setting/ticketType/TicketTypeComponentToolbar";
import TicketTypeComponent from "../components/setting/ticketType/TicketTypeComponent";
import WebWidgetComponentToolbar from "../components/setting/web-widget/WebWidgetComponentToolbar";
import WebWidgetComponent from "../components/setting/web-widget/WebWidgetComponent";
import CompanyDataModellingToolbar from "../components/setting/data-modelling/compnay-data/CompanyDataModellingToolbar";
import CompanyDataModellingComponent from "../components/setting/data-modelling/compnay-data/CompanyDataModellingComponent";
import ActionComponentToolbar from "../components/setting/actions/ActionsComponentToolbar";
import ActionComponent from "../components/setting/actions/ActionsComponent";
import AccountDetailComponentToolbar from "../components/account/details/AccountDetailComponentToolbar";
import AccountDetailComponent from "../components/account/details/AccountDetailComponent";
import NotificationComponentToolbar from "../components/account/notifications/NotificationComponentToolbar";
import NotificationComponent from "../components/account/notifications/NotificationComponent";
import ChangePasswordToolbar from "../components/account/details/change-password/ChangePasswordToolbar";
import ChangePasswordComponent from "../components/account/details/change-password/ChangePasswordComponent";
import SentimentsToolbar from "../components/setting/sentiments/SentimentsToolbar";
import SentimentsComponent from "../components/setting/sentiments/SentimentsComponent";
import CannedResponseToolbar from "../components/setting/canned-response/CannedResponseToolbar";
import CannedResponseComponent from "../components/setting/canned-response/CannedResponseComponent";
import ReportsToolbar from "../components/setting/reports/ReportsToolbar";
import ReportsComponent from "../components/setting/reports/ReportsComponent";
import AiChatBotContentComponent from "../components/automation/ai-chatbot-content/AiChatBotContentComponent";
import AiChatbotContnetToolbar from "../components/automation/ai-chatbot-content/AiChatbotContnetToolbar";
import TeamMatesComponent from "../components/setting/team-mates/TeamMatesComponent";
import TeamMatesToolbar from "../components/setting/team-mates/TeamMatesToolbar";
import ProfileMainComponent from "../components/automation/ProfileMainComponent";
import PerformaceToolbar from "../components/setting/performace/PerformaceToolbar";
import PerformaceComponent from "../components/setting/performace/PerformaceComponent";
import EmailSettingToolbar from "../components/setting/email-setting/EmailSettingToolbar";
import EmailSettingComponent from "../components/setting/email-setting/EmailSettingComponent";
import DomainComponentToolbar from "../components/setting/email-domain/DomainComponentToolbar";
import DomainComponent from "../components/setting/email-domain/DomainComponent";
import SenderEmailToolbar from "../components/setting/sender-email-address/SenderEmailToolbar";
import SenderEmailComponent from "../components/setting/sender-email-address/SenderEmailComponent";
import EmailForwardingToolbar from "../components/setting/email-forwarding/EmailForwardingToolbar";
import EmailForwardingComponent from "../components/setting/email-forwarding/EmailForwardingComponent";
import EmailAutoReplyToolbar from "../components/setting/email-auto-reply/EmailAutoReplyToolbar";
import EmailAutoReplyComponent from "../components/setting/email-auto-reply/EmailAutoReplyComponent";
import TicketTypeDetailToolbar from "../components/setting/ticketType/ticketTypeDetails/TicketTypeDetailToolbar";
import TicketTypeDetailsComponent from "../components/setting/ticketType/ticketTypeDetails/TicketTypeDetailsComponent";
import EmailTempleteToolbar from "../components/setting/email-templete/EmailTempleteToolbar";
import EmailTempleteComponent from "../components/setting/email-templete/EmailTempleteComponent";
import WorkflowComponent from "../components/automation/workflows/WorkflowComponent";
import WorkflowComponentToolbar from "../components/automation/workflows/WorkflowComponentToolbar";
const botName = process.env.REACT_APP_AUTO_NAME
export const nestedRoute = [
    {
        parentPath:'/inbox',
        childRoutes:[
            {
                path:'/search/*',
                ToobarComponent:FilterToolbar,
                Component:FilterComponent,
                filterValue:"searchFilter"
            },
            // {
            //     path:'/search/*',
            //     ToobarComponent:FilterToolbar,
            //     Component:FilterComponent,
            //     filterValue:"searchFilter"
            // },
            {
                path:'/your-inbox/*',
                ToobarComponent:YourInboxToolbar,
                Component:YourInbox,
                filterValue:""
            },
            {
                path:'/mentions/*',
                ToobarComponent:FilterToolbar,
                Component:FilterComponent,
                filterValue:"mentions"
            },
            {
                path:'/all/*',
                ToobarComponent:FilterToolbar,
                Component:FilterComponent,
                filterValue:"all"


            },
            {
                path:'/tickets/*',
                ToobarComponent:FilterToolbar,
                Component:FilterComponent,
                filterValue:"tickets"
            },
            {
                path:'/unassigned/*',
                ToobarComponent:FilterToolbar,
                Component:FilterComponent,
                filterValue:"unassigned"
            }
        ]
    },

    {
        parentPath:'/automation',
        childRoutes:[
            {
                path:'/profile/*',
                ToobarComponent:<></>,
                Component:ProfileMainComponent,
            },
            // {
            //     path:'/create-profile',
            //     ToobarComponent:CreateProfileToolbar,
            //     Component:CreateProfile,
            // },
            // {
            //     path:'/profile-setup/:id',
            //     ToobarComponent:null,
            //     Component:ProfileSetupPage,
            // },
            {
                path:`/${botName}-contents/*`,
                ToobarComponent:AiChatbotContnetToolbar,
                Component:AiChatBotContentComponent,

            },
            {
                path:'/actions/*',
                ToobarComponent:ActionComponentToolbar,
                Component:ActionComponent,
            },
            {
                path:'/workflows/*',
                ToobarComponent:WorkflowComponentToolbar,
                Component:WorkflowComponent,
            },
        ]
    },
    {
        parentPath:'/support',
        childRoutes:[
            {
                path:'/basic',
                ToobarComponent:FilterToolbar,
                Component:FilterComponent,
            },
        ]
    },
    {
        parentPath:'/contacts',
        childRoutes:[
            {
                path:'/customer',
                ToobarComponent:AllUsersToolbar,
                Component:AllUsers,
                filterValue:"customer"
            },
            {
                path:'/customer/:id',
                ToobarComponent:UserProfileToolbar,
                Component:UserProfile,
                filterValue:"customer"
            },
            {
                path:'/visitor',
                ToobarComponent:AllUsersToolbar,
                Component:AllUsers,
                filterValue:"visitor"
            },
            {
                path:'/visitor/:id',
                ToobarComponent:UserProfileToolbar,
                Component:UserProfile,
                filterValue:"visitor"
            },
            {
                path:'/company',
                ToobarComponent:AllCompanyToolbar,
                Component:AllCompany,
                filterValue:"company"
            },
            {
                path:'/company/:id',
                ToobarComponent:CompanyProfileToolbar,
                Component:CompanyProfile,
                filterValue:"company"
            }
        ]
    },
    {
        parentPath:'/reports',
        childRoutes:[
            {
                path:'/basic',
                ToobarComponent:FilterToolbar,
                Component:FilterComponent,
            },
        ]
    },
    {
        parentPath:'/settings',
        childRoutes:[
            {
                path:'/tags',
                ToobarComponent:TagComponentToolbar,
                Component:TagCompnent,
            },
            {
                path:'/topics',
                ToobarComponent:TopicComponentToolbar,
                Component:TopicComponent,
            },
            {
                path:'/offce-hours',
                ToobarComponent:OfficeHoursToolbar,
                Component:OfficeHoursComponent,
            },
            {
                path:'/teams',
                ToobarComponent:TeamsComponentToolbar,
                Component:TeamsComponent,
            },
            {
                path:'/agents',
                ToobarComponent:TeamMatesToolbar,
                Component:TeamMatesComponent,
            },
            {
                path:'/ticket-types',
                ToobarComponent:TicketTypeComponentToolbar,
                Component:TicketTypeComponent,
            },
            {
                path:'/ticket-types/:id',
                ToobarComponent:TicketTypeDetailToolbar,
                Component:TicketTypeDetailsComponent,
            },
            {
                path:'/web-widget',
                ToobarComponent:WebWidgetComponentToolbar,
                Component:WebWidgetComponent,
            },
            {
                path:'/data-modelling',
                ToobarComponent:CompanyDataModellingToolbar,
                Component:CompanyDataModellingComponent,
            },
            {
                path:'/sentiments',
                ToobarComponent:SentimentsToolbar,
                Component:SentimentsComponent,
            },
            {
                path:'/macros/*',
                ToobarComponent:CannedResponseToolbar,
                Component:CannedResponseComponent,
            },
            {
                path:'/reports',
                ToobarComponent:ReportsToolbar,
                Component:ReportsComponent,
            },
            {
                path:'/performace',
                ToobarComponent:PerformaceToolbar,
                Component:PerformaceComponent,
            },
            {
                path:'/email-setting/*',
                ToobarComponent:EmailSettingToolbar,
                Component:EmailSettingComponent,
            },  
            {
                path:'/domain',
                ToobarComponent:DomainComponentToolbar,
                Component:DomainComponent,
            },
            {
                path:'/senders',
                ToobarComponent:SenderEmailToolbar,
                Component:SenderEmailComponent,
            },
            {
                path:'/email-forwarding',
                ToobarComponent:EmailForwardingToolbar,
                Component:EmailForwardingComponent,
            },
            {
                path:'/email-auto-reply',
                ToobarComponent:EmailAutoReplyToolbar,
                Component:EmailAutoReplyComponent,
            },
            {
                path:'/email-templete/*',
                ToobarComponent:EmailTempleteToolbar,
                Component:EmailTempleteComponent,
            }
           
        ]
    },
    {
        parentPath:'/account',
        childRoutes:[
            {
                path:'/details',
                ToobarComponent:AccountDetailComponentToolbar,
                Component:AccountDetailComponent,
            },
            {
                path:'/details/change-password',
                ToobarComponent:ChangePasswordToolbar,
                Component:ChangePasswordComponent,
            },
            {
                path:'/notifications',
                ToobarComponent:NotificationComponentToolbar,
                Component:NotificationComponent,
            },       
        ]
    }
]