import React from "react";
import { Box, Button, Divider, TextareaAutosize, Typography} from "@mui/material";
import style from "./email-auto-reply.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import PullseAntiSwitch from "../../commonComponent/switch/PullseAntiSwitch";
const EmailAutoReplyComponent = ({ ToolbarComponent }) => {
  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
              marginBottom: "10px",
              marginTop: "10px",
              padding: "20px",
            }}
          >
            <Box>
              <Typography  sx={{ fontSize:"14px" }} variant="content" >
              You can set up an email auto reply to automatically notify people that you won't be able to get back to them right away. When people send you an email message, they'll receive a response containing what you've written in your email auto reply.


              </Typography>
            </Box>

            <Box sx={{marginTop:"20px" }}>
              <Typography  sx={{ fontSize:"17px", fontWeight:"bold", }} variant="content" >
              Set your email auto reply
              </Typography>
            </Box>

            <Box sx={{marginTop:"20px", display:"flex", gap:"10px", alignItems:"center" }}>
              <PullseAntiSwitch />
              <Typography  sx={{ fontSize:"14px",  }} variant="content" >
              When turned on, it sends an automated reply to incoming emails.
              </Typography>
            </Box>

            <Box sx={{marginTop:"20px", display:"flex", gap:"10px", alignItems:"center" }}>
              <Typography  sx={{ fontSize:"14px", fontWeight:"bold" }} variant="content" >
               Email body
              </Typography>
            </Box>

            <Box sx={{marginTop:"20px", display:"flex", gap:"10px", alignItems:"center", width:"600px" }}>
       
              <TextareaAutosize
                value={"Thanks for contactc name ing us. Our team will get back to you as soon as we can"}
                name="description"
                maxRows={13}
                placeholder="Thanks for contact name ing us. Our team will get back to you as soon as we can."
                minRows={13}
                style={{ outerHeight: "100%", width: "100%", fontSize: "12px", border:"1px solid rgba(0,0,0,0.2)", resize:"none", borderRadius:"5px", padding:"10px" }}
              />
            </Box>

            <Box sx={{marginTop:"20px", display:"flex", gap:"10px", alignItems:"center" }}>
            <Button
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                  }}
                  //   onClick={handleAddNewDomain}
                >
                  Save English version
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                  }}
                  //   onClick={handleAddNewDomain}
                >
                  Cancel
                </Button>
            </Box>
          </Box>

        
        </Box>
      </MainComponent>
    </>
  );
};

export default EmailAutoReplyComponent;
