import React from 'react'

const UserIcon = ({color="#131A29",height="20",width="20"}) => {
  return (
    <>

     <svg
        width={width}
        height={height}
        viewBox="0 0 35 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3333 8.16667C16.3333 6.87983 15.2868 5.83333 14 5.83333C12.7131 5.83333 11.6666 6.87983 11.6666 8.16667C11.6666 9.4535 12.7131 10.5 14 10.5C15.2868 10.5 16.3333 9.4535 16.3333 8.16667ZM18.6666 8.16667C18.6666 10.7403 16.5736 12.8333 14 12.8333C11.4263 12.8333 9.33331 10.7403 9.33331 8.16667C9.33331 5.593 11.4263 3.5 14 3.5C16.5736 3.5 18.6666 5.593 18.6666 8.16667ZM5.83331 23.3333C5.83331 18.83 9.49781 15.1667 14 15.1667C18.5021 15.1667 22.1666 18.83 22.1666 23.3333C22.1666 23.9773 21.6451 24.5 21 24.5C20.3548 24.5 19.8333 23.9773 19.8333 23.3333C19.8333 20.1168 17.2165 17.5 14 17.5C10.7835 17.5 8.16665 20.1168 8.16665 23.3333C8.16665 23.9773 7.64515 24.5 6.99998 24.5C6.35481 24.5 5.83331 23.9773 5.83331 23.3333Z"
          fill="url(#paint0_linear_183_659)"
        />
        <mask
          id="mask0_183_659"
          style={{"maskType":"luminance"}}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="18"
          height="22"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3333 8.16667C16.3333 6.87983 15.2868 5.83333 14 5.83333C12.7131 5.83333 11.6666 6.87983 11.6666 8.16667C11.6666 9.4535 12.7131 10.5 14 10.5C15.2868 10.5 16.3333 9.4535 16.3333 8.16667ZM18.6666 8.16667C18.6666 10.7403 16.5736 12.8333 14 12.8333C11.4263 12.8333 9.33331 10.7403 9.33331 8.16667C9.33331 5.593 11.4263 3.5 14 3.5C16.5736 3.5 18.6666 5.593 18.6666 8.16667ZM5.83331 23.3333C5.83331 18.83 9.49781 15.1667 14 15.1667C18.5021 15.1667 22.1666 18.83 22.1666 23.3333C22.1666 23.9773 21.6451 24.5 21 24.5C20.3548 24.5 19.8333 23.9773 19.8333 23.3333C19.8333 20.1168 17.2165 17.5 14 17.5C10.7835 17.5 8.16665 20.1168 8.16665 23.3333C8.16665 23.9773 7.64515 24.5 6.99998 24.5C6.35481 24.5 5.83331 23.9773 5.83331 23.3333Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_183_659)">
          <rect width="28" height="28" fill={color} />
        </g>
      </svg>
      </>
  )
}


export default UserIcon
