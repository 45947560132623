import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function AudianceRuleCondition({handleCurrentTab=()=> {}, onClick=()=>{}, value="any"}) {
  const [currentCondition, setCurrentCondition] = React.useState('any');

  const handleChange = (event, newAlignment) => {
    if(!newAlignment) return;
    setCurrentCondition(newAlignment);
    handleCurrentTab(newAlignment)
    onClick()
  };

  React.useEffect(()=>{
    setCurrentCondition(value)
  },[value])

  return (
    <ToggleButtonGroup
      color="secondary"
      value={currentCondition}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{
        height:"25px",
        border:"none"
      }}
    >
      <ToggleButton value="any"  sx={{ textTransform:"inherit"}}>Any </ToggleButton>
      <ToggleButton value="all"  sx={{ textTransform:"inherit"}}> All </ToggleButton>
    </ToggleButtonGroup>
  );
}