import React, { useContext, useEffect, useState } from "react";
// import MainComponent from '../../../../fixedComponent/sideDashboard/MainComponent'
// import SideToolbar from '../../../../fixedComponent/sideToolbar/SideToolbar'
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  InputBase,
  Paper,
} from "@mui/material";
import style from "./tagComponent.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import ListComponent from "./listComponent/ListComponent";
import {
  createTag,
  deleteTag,
  getTags,
  updateTag,
} from "../../../services/Apis";
import { get } from "lodash";
import CreateNewTags from "./createNew/CreateNewTag";
import TagIcon from "../../../assets/icons/TagIcon";
import SearchIcon from "../../../assets/icons/SearchIcon";
import SearchBarComponent from "../searchComponent/SearchBarComponent";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DisplayTagsComponent from "./displayTags/DisplayTagsComponent";
import TageIcon from "../../../assets/searchIcons/TageIcon";
import TopicTab from "../topics/tab/TopicTab";
import { SidebarContext } from "../../../context/SidebarProvider";
let workspaceId = process.env.REACT_APP_WORKSPACE_ID;
const createNewTag = {
  name:"",
  description:"",
  type:"new",
}

const TagCompnent = ({ ToolbarComponent }) => {
  const { handleOpenModal } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const [tags, setAllTags] = useState([]);
  const [resetTagState, setResetTagState] = useState([]);
  const [isArchivedTag, setIsArchivedTag] = useState(false)
  useEffect(() => {
    handleGetTags({type:'archived',data:isArchivedTag});
  }, [isArchivedTag]);

  const handleGetTags = (obj = {}) => {
    getTags(obj).then((res) => {
      let responseArr = get(res, "data.data.docs", []);
      setAllTags(responseArr);
      setResetTagState(responseArr)
    });
  };

  const handleResetData = () => {

    
    setAllTags([...resetTagState]);
  }
  const handleCreateNewTag = (data) => {
    return new Promise((resolve, reject) => {
      createTag(data)
        .then((res) => {
          resolve(true);
          handleShowSnackPopup({message:"Tag created successfully"})
          handleGetTags();
        })
        .catch(() => reject(true));
    });
  };
  const handleUpdateTag = (id, data) => {
    return new Promise((resolve, reject) => {
      updateTag(id, data)
        .then(() => {
          resolve(true);
          handleGetTags();
          handleShowSnackPopup({message:"Tag updated successfully"})
        })
        .catch(() => {
          reject(true);
        });
    });
  };
  const handleCreateTag = (type, data) => {
    handleOpenModal({
      state: true,
      Component: (
        <CreateNewTags
          data={{
            type: type,
            data,
            handleCreateNewTag,
            handleUpdateTag,
          }}
        />
      ),
    });
  };

  const handleAddNewTag = () => {
    let tempTags = [...tags];
    tempTags.unshift({...createNewTag});
    setAllTags(tempTags);
}

  const handleDeleteTag = (id) => {
    deleteTag(id).then((res) => {
      handleGetTags();
      handleShowSnackPopup({message:"Tag archived successfully"})
    });
  };
  const getFilterTags = (value) => {
    handleGetTags({ type: "name", data: value });
  };

  return (
    <>
      <MainComponent className={style['tag-main-component']}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent heading={'Tags'}  />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <SearchBarComponent style={{
              width:"50%",
              height:"35px",
              border:"1px solid rgba(0,0,0,0.1)",
              marginLeft:"30px"

            }} handleCollectValue={getFilterTags} />{" "}
            <Button
              startIcon={<TageIcon height="16px" color="#a84acb" />}
              variant="outlined"
              disabled={isArchivedTag}
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "35px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
              onClick={() =>
                handleAddNewTag()
              }
            >
              Create tag
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "80vh",
            }}
          >
             <Box sx={{padding:"10px 50px"}}>
             <TopicTab  count={tags.length} onClick={()=>setIsArchivedTag(!isArchivedTag)} />
             </Box>   
         <DisplayTagsComponent isArchivedComponent={isArchivedTag} component={"Tag"}  data={JSON.parse(JSON.stringify(tags))} setData={(data)=>setAllTags(data)} handleCreate={handleCreateNewTag} handleUpdate={handleUpdateTag} handleArchive={({id})=>handleDeleteTag(id)} handleBlur={handleResetData} Icon={<TageIcon height="16px" color="#a84acb" />}/>

          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default TagCompnent;
