import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip, Button } from '@mui/material';
import { Box, } from '@mui/system';

let mockTableData = [
    {
        teammates:"suhail",
        emailAddress:"suhail@pullse.ai",
        action:true
    },
]
const DomainContentDisplayTable = () => {
  return (
    <Box  sx={{padding:"10px 5px"}} >
   <TableContainer component={Paper} sx={{boxShadow:"none"}} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
        <TableRow>
            <TableCell  sx={{fontWeight:"bold"}}>Teammates</TableCell>
            <TableCell   sx={{fontWeight:"bold"}}>	Email address	</TableCell>
            {/* <TableCell align="right"  sx={{fontWeight:"bold"}} >Action</TableCell> */}
            <TableCell align="right"  sx={{fontWeight:"bold"}}>		</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mockTableData.map(({teammates,emailAddress,action}) => (
            <TableRow
              key={emailAddress}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {teammates}
              </TableCell>
              <TableCell >{emailAddress}</TableCell>
              <TableCell align="right">
              <Button
                    sx={{
                      background: "#f1f1fb",
                      color: "#707c97",
                      height: "26px",
                      boxShadow: "none",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      padding: "3px 10px",
                    //   fontWeight:"bold",
                      borderColor: "transparent",
                      ":hover": { borderColor: "#a84acb" },
                    }}
                    variant="outlined"
                  >
                    {" "}
                    Remove
                  </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   </Box>
  )
}

export default DomainContentDisplayTable
