import React, { useContext, useEffect, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Chip,
  InputBase,
  ListItemText,
  Paper,
} from "@mui/material";
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Image, Padding } from "@mui/icons-material";
import { get } from "lodash";
import DisplayTable from "./DisplayTable";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const createurl = (fileObj) => {
  return URL.createObjectURL(fileObj)

}

const DisplayTicketTypesTable = ({dataList}) => {
 

  return (
    <>
      <AccordionDetails sx={{
        zIndex:"9",
        position:'relative',
        background:'white',
        transition:'none !important',
        padding:'0px 0px 0px 0px'
      }} >
        <DisplayTable dataList={dataList} />
      </AccordionDetails>
    </>
  );
};

export default DisplayTicketTypesTable;


