import {
  Box,
  Button,
  Paper,
  Typography,
  InputBase,
  TextareaAutosize,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
import { LoadingButton } from "@mui/lab";
import { SidebarContext } from "../../../../context/SidebarProvider";
const AddDomainComponent = ({ handleCreateEmailDomain }) => {
  const { handleCloseModal, } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const [emailDomainState, setEmailDomainData] = useState({
    name:"",
    domain:"",
    description:"",
  })
  const [loading, setLoading] = useState(false);

  const handleCollectDomainData = (event) => {
    const { name, value } = event.target;
    setEmailDomainData((state)=>({
        ...state,
        [name]:value
    }))
  }

  const handleAddNewDomain = () => {
        setLoading(true)
        handleCreateEmailDomain(emailDomainState).then(res=>{
        setLoading(false)
        handleCloseModal()
        handleShowSnackPopup({message:"Doamin created",})
    }).catch(err=>{
        let errorRes = get(err,'response.data.message',"")
        handleShowSnackPopup({message:errorRes,error:true})
        setLoading(false)
    })

  }
  return (
    <>
      <Box sx={{ padding: "20px 40px", minHeight: "350px", borderBottom:"5px solid #a84acb" }}>
        <Box
          sx={{
            width: "400px",
            display: "inline-block",
            minHeight: "300px",
            position: "relative",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
            Add and Verify Domain
            </Typography>
          </Box>

          <Box sx={{marginTop:"10px"}} >
            <Typography sx={{ fontWeight: "normal", fontSize: "13px" }}>
            Enter an address from the domain you want to send emails from, and we'll send you a verification link via email.
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Box  sx={{display:"flex", flexDirection:"column", gap:"5px", }} >
            <Typography sx={{fontSize:"14px", fontWeight:"bold"}}>Display name</Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                onChange={handleCollectDomainData}
                value={emailDomainState.name}
                autoFocus
                name="name"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="John Doe"
              />
              
            </Paper>
            <Typography sx={{fontSize:"14px", fontWeight:"normal", color:'gray'}}>Use a name that matches the email to increase open rates</Typography>
            </Box>
           

            <Box  sx={{display:"flex", flexDirection:"column", gap:"5px", }} >
            <Typography sx={{fontSize:"14px", fontWeight:"bold"}}>Email</Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                onChange={handleCollectDomainData}
                value={emailDomainState.domain}
                autoFocus
                type="email"
                name="domain"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="john.doe@example.com"
              />
              
            </Paper>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "30px",

            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <LoadingButton
                loading={loading}
                onClick={handleAddNewDomain}
                variant="outlined"
                sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                }}
              >
                {"Add"}
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddDomainComponent;
