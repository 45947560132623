import React, { useContext, useEffect, useState } from "react";
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../../fixedComponent/sideToolbar/SideToolbar";
import { Box, Divider, Typography, Button } from "@mui/material";
import style from "./allUsers.module.scss";
import { MainContext } from "../../../../context/MainProvider";
import TelegramIcon from "@mui/icons-material/Telegram";
import TageIcon from "../../../../assets/searchIcons/TageIcon";
import NewMessageIcon from "../../../../assets/icons/NewMessageIcon";
import CaretDownIcon from "../../../../assets/icons/CaretDownIcon";
import MenuSharpIcon from "@mui/icons-material/MenuSharp";
import FormatListBulletedSharpIcon from "@mui/icons-material/FormatListBulletedSharp";
import GlobalIcon from "../../../../assets/icons/GlobalIcon";
import DensityMediumSharpIcon from "@mui/icons-material/DensityMediumSharp";
import UsersTable from "./usersTable/UsersTable";
import { allCustomers, customerBulkActions } from "../../../../services/Apis";
import { get } from "lodash";
import { SidebarContext } from "../../../../context/SidebarProvider";
import CreateNewTags from "../../../setting/tags/createNew/CreateNewTag";
import { DeleteOutlineOutlined } from "@mui/icons-material";
import ContactFilters from "../../filters/ContactFilters";
import MenuBarCommon from "../../../commonComponent/menuBarComponent/MenuBarCommon";
import TagMenu from "../../../commonComponent/filterMenuData/TagMenu";
import TagIcon from "../../../../assets/icons/TagIcon";
import ExcelIcon from "../../../../assets/icons/ExcelIcon";
import CsvIcon from "../../../../assets/icons/CsvIcon";
import UploadContactsModal from "./uploadContact/UploadContactsModal";
import LoadingButton from '@mui/lab/LoadingButton';

const AllUsers = ({ ToolbarComponent, filterValue }) => {
  const { chatbotProfiles, handleOpenModal } = useContext(MainContext);
  const { handleSetCount, handleShowSnackPopup } = useContext(SidebarContext);
  const [bulkActionUsers, setBulkActionUsers] = useState({});
  const [companyUsers, setCompanyUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [bulkDeleteButtonLoading, setBulkDeleteButtonLoading] = useState(false)
  const handleSetBulkActionUser = (id, value) => {
    setBulkActionUsers((state) => ({
      ...state,
      [id]: value,
    }));
  };

  useEffect(() => {
    handleGetAllUsers();
  }, [filterValue]);

  const handleGetAllUsers = (filters = []) => {
    let newFilters = [...filters];
    let key = {};
    key["type"] = "customer_type";
    key["data"] = 'customer';
    if (filterValue === "visitor") {
      key["data"] = 'lead';
    }
    newFilters.push(key)
    setUsersLoading(true);
    allCustomers(...newFilters).then((res) => {
      let data = get(res, "data.data.docs", []);
      handleSetCount("contacts", {
        [`${filterValue}`]: data.length,
      });
      setUsersLoading(false);
      setCompanyUsers(data);
    });
  };

  const getBulkActionKey = (bulkData) => {
    return Object.keys(bulkData)
      .map((ele) => {
        if (bulkActionUsers[ele]) {
          return ele;
        }
      })
      .filter(Boolean);
  };

  const handleAddBulkTags = (data) => {
    let userIds = getUserIds();
    let payload=  {
      "action": "addTags",
      "ids":userIds,
      "tag_ids":[data.id]
    }
    customerBulkActions(payload).then(res=>{
      handleGetAllUsers();
      handleShowSnackPopup({message:"data updated successfully"})
    }).catch(err=>{
      handleShowSnackPopup({message:"error while updating", error:true})
    })
  };

  const handleUploadContacts = () => {
    handleOpenModal({
      Component:<UploadContactsModal />
    })
  }

  const getUserIds = () => {
    return Object.keys(bulkActionUsers).map(key=>bulkActionUsers[key] ? key : null).filter(Boolean)
  }

  const handleDeleteMultipleCustomer = () => {
    let userIds = getUserIds();
    let payload=  {
      "action": "archive",
      "ids":userIds,
    }
    setBulkDeleteButtonLoading(true)
    customerBulkActions(payload).then(res=>{
      handleGetAllUsers();
      handleShowSnackPopup({message:`${filterValue} deleted successfully`})
      setBulkDeleteButtonLoading(false)
    }).catch(err=>{
      handleShowSnackPopup({message:`error while deleting ${filterValue}`, error:true})
      setBulkDeleteButtonLoading(false)
    })
  }


  const isEnableButtons = getUserIds().length

  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>
            {ToolbarComponent && (
              <ToolbarComponent
                className={""}
                handleGetAllUsers={handleGetAllUsers}
                filterValue={filterValue}
              />
            )}
          </SideToolbar>
          {/* <ContactFilters componentType="customer" /> */}
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              // overflowY: "scroll",
              gap: "10px",
              maxHeight: "80vh",
              height:"100%"

            }}
          >
            <Box className="body-headers-tool">
              <Box className="body-headers-1 body-header-container">
                <Typography className="display-total-users">
                  {companyUsers.length} {filterValue}
                </Typography>
                <MenuBarCommon
                  parentStyle={{
                  pointerEvents:!isEnableButtons ?  "none" : "initial"
                  }}
                  style={{
                    height: "27px",
                    background: "#f1f1fb !important",
                    color: "black !important",
                   
                  }}
                  onSelectFilter={handleAddBulkTags}
                  isDeleted={false}
                  selected={false}
                  handleDelete={() => {}}
                  filKey={""}
                  MenuComponent={TagMenu}
                  Icon={() => <TageIcon color={!isEnableButtons ? "gray" : "black"} height="14" />}
                  label={<Typography  sx={{fontSize:"13px",color:!isEnableButtons ? "gray" : "black",}} >Tag</Typography>}
                />
                <Button
                  className="header-first-btn"
                  variant="contained"
                  startIcon={<ExcelIcon />}
                >
                  Export contacts
                </Button>
                <LoadingButton
                  loading={bulkDeleteButtonLoading}
                // loadingIndicator="deleting" 
                  disabled={!isEnableButtons}
                  onClick={handleDeleteMultipleCustomer}
                  className="header-first-btn"
                  variant="contained"
                  startIcon={<DeleteOutlineOutlined sx={{color: isEnableButtons ? "red" : "gray"}} />}
                  
                >
                  Archive contacts
                </LoadingButton>

                <Button
                  onClick={handleUploadContacts}
                  className="header-first-btn"
                  variant="contained"
                  startIcon={<CsvIcon height={16} width={16} />}
                >
                  upload contacts
                </Button>
              </Box>
              <Box className="body-headers-2 body-header-container"></Box>
            </Box>

            <Box className="users-table-container"    sx={{height:"100%",              
            maxHeight: "70vh",
              height:"100%"}}>
              <UsersTable
                bulkActionUsers={bulkActionUsers}
                handleSetBulkActionUser={handleSetBulkActionUser}
                companyUsers={companyUsers}
                loading={usersLoading}
                filterValue={filterValue}
              />
            </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default AllUsers;
