import {
  Box,
  Button,
  Paper,
  Typography,
  InputBase,
  TextareaAutosize,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
const CreateNewTeams = ({ data }) => {
  const { handleCloseModal } = useContext(MainContext);
  const [currentTag, setCurrentTag] = useState({
    
  });
  const [loading,setLoading ] = useState(false)

  useEffect(()=>{
    setCurrentTag(get(data,'data',{}))

  },[get(data,'data',{})])



  const handleCollectData = (event) => {
    let {  name, value } = event.target;
    setCurrentTag((state)=>({
      ...state,
      [name]:value
    }))
  }
  let {type, handleUpdate, handleCreate} = data
  const handleSubmitData = () => {
    setLoading(true)
    if(type === 'create'){
      handleCreate(currentTag).then(res=>{
        setLoading(false);
        handleCloseModal()
      })
    }else{
      let {id,name,description} = currentTag;
      handleUpdate(id,{name,description}).then(res=>{
        setLoading(false)
        handleCloseModal()
      })
    }

  }

  return (
    <>
      <Box sx={{ padding: "20px 40px", minHeight:"350px" }}>
        <Box
          sx={{
            width: "400px",
            display: "inline-block",
            height: "300px",
            position: "relative",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              {type}  Team
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                value={get(currentTag,'name',"")}
                onChange={handleCollectData}
                autoFocus
                name="name"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="name"
              />
            </Paper>

            <Box
              sx={{
                height: "100%",
                width: "100%",
                overflow: "hidden",
              }}
            >
              <TextareaAutosize
                 value={get(currentTag,'description',"")}
                onChange={handleCollectData}
                name="description"
                maxRows={8}
                placeholder="add your description here"
                minRows={8}
                style={{ outerHeight: "100%", width: "100%", fontSize: "12px", border:"1px solid rgba(0,0,0,0.2)" }}
              />
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "30px",
              position: "absolute",
              bottom: "0px",
              right: "0px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <Button
              onClick={handleSubmitData}
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                {loading ?  `loading....` : type}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateNewTeams;
