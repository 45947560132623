import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import Badge from "@mui/material/Badge";
import { SidebarContext } from "../../../../context/SidebarProvider";
import LoadingButton from '@mui/lab/LoadingButton';
import { updateWorkflowDetails } from "../../../../services/Apis";

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

const DisplayTrigger = ({ handleSetActionView, workflow, handleSearchDebounce, currentStatus, handleSetCurrentStatus }) => {
    const [currentAction, setCurrentAction] = useState('all')
    const navigate = useNavigate();
    const [updating, setUpdating] = useState("")

    const handleUpdateStatusOfWorkflow = (id,status) => {
      setUpdating(id)
      updateWorkflowDetails(id,{status:status}).then(res=>{
        handleSearchDebounce({
          target:{
            value:""
          }
        })
        setUpdating("")
      })
    }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: "10px",
          maxHeight: "88vh",
          padding: "30px 30px 0px 30px",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "400", fontSize: "30px" }}>
            Triggers
          </Typography>
          <Button
            variant="outlined"
            sx={{
              textTransform: "inherit",
              height: "35px",
            }}
            onClick={() => {
                navigate('create-workflow')
            }}
          >
            Add trigger
          </Button>
        </Box>
        <Box>
          <Paper
            component="box"
            sx={{
              p: "1px 2px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: 40,
              padding: "10px",
              boxShadow: "none",
              border: `1px solid rgba(0,0,0,0.2)`,
            }}
          >
            <InputBase
              onChange={handleSearchDebounce}
              name="triggerName"
              sx={{ ml: 1, flex: 1, fontSize: "14px" }}
              placeholder="search trigger"
            />
          </Paper>
        </Box>
        <Box sx={{ padding: "10px" }}>
          <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Trigger Name</TableCell>
                  <TableCell align="center">Description</TableCell>
                  <TableCell align="center">Triggered (7d)</TableCell>
                  <TableCell align="right">
                    <Select
                      autoFocus={true}
                      value={currentStatus}
                      onChange={(event) => {
                        handleSetCurrentStatus(event.target.value)
                      }}
                      name="type"
                      sx={{ width: "50%", border: "1px solid rgba(0,0,0,0.1)", borderRadius:"5px" }}
                      input={
                        <InputBase
                          variant="standard"
                          autoFocus={true}
                          sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                        ></InputBase>
                      }
                    >
                          <MenuItem value={"all"}>
                        {"All"}
                      </MenuItem>
                      <MenuItem value="active">Active </MenuItem>
                      <MenuItem value="inactive">Inactive </MenuItem>
                    
                    </Select>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {workflow.map(({id,name,description,affectedTicketsCount,status,ruleIds}) => {
                  let displayCurrentStatus = status === "active" ? "success" : status === "inactive" ? "error" : "primary"
                  let nextStatus = ["draft","inactive"].includes(status) ?  "active" : "inactive"
                 return (
                  <TableRow
                    key={id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      <Badge
                        badgeContent={status}
                        sx={{
                          textTransform:'capitalize',
                            // color:displayCurrentStatus
                            background:displayCurrentStatus
                            // border:"2px solid green"
                        }}
                        color={displayCurrentStatus}
                        // color="primary"
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                      >
                        <Link style={{ padding: "10px", background:"white" }}  to={`workflow/${id}`} >{name}</Link>
                      </Badge>
                    </TableCell>
                    <TableCell align="center">{description}</TableCell>
                    <TableCell align="center">{affectedTicketsCount}</TableCell>
                    <TableCell align="right">
                      <LoadingButton
                      loading={updating == id}
                      onClick={()=>{
                        handleUpdateStatusOfWorkflow(id,nextStatus)

                      }}
                        sx={{
                          textTransform: 'capitalize',
                          color: nextStatus == "active" ? "green" : "red",

                          border: `1px solid ${ nextStatus == "active" ? "green" : "red"}`,
                          ":hover": {
                            border: `1px solid ${ nextStatus == "active" ? "green" : "red"}`,
                          },
                        }}
                        variant="outlined"
                      >
                        {/* Deactivate */}
                        {nextStatus === "active" ? "Live" : "Disable"}
                      </LoadingButton>
                    </TableCell>
                  </TableRow>
                )})}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default DisplayTrigger;
