import React from "react";

const SnoozedIcon = ({color='#313131', height=16, width=16}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M14.2003 9.95966C13.5988 10.1421 12.9606 10.2402 12.2995 10.2402C8.68752 10.2402 5.75942 7.31207 5.75942 3.70007C5.75942 3.03926 5.85742 2.40134 6.03969 1.80005C3.35473 2.6143 1.40039 5.10874 1.40039 8.05964C1.40039 11.6716 4.3285 14.5997 7.9405 14.5997C10.8917 14.5997 13.3863 12.645 14.2003 9.95966Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M13.4231 6.61538V5.17308M13.4231 5.17308V3.73077M13.4231 5.17308L14.8654 5.17308M13.4231 5.17308H11.9808M11.1154 3.73077V2.86538M11.1154 2.86538V2M11.1154 2.86538L11.9808 2.86538M11.1154 2.86538L10.25 2.86538"
          stroke={color}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default SnoozedIcon;
