import style from "../email-templete.module.scss";
import { get } from "lodash";
import { LoadingButton } from "@mui/lab";
import { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import MiniEditor from "../../../commonComponent/mini-editor/MiniEditor";
import PullseInputBox from "../../../commonComponent/pullse-input-box/PullseInputBox";
import { createTemplete, emailTempleteDetails, updateEmailTemplete } from "../../../../services/Apis";
import { SidebarContext } from "../../../../context/SidebarProvider";
import { useNavigate, useParams } from "react-router-dom";
const CreateEmailTemplete = ({ handleGetEmailTempletes, componentType }) => {
    const params = useParams();
  const { handleShowSnackPopup } = useContext(SidebarContext);
  const [emailTempleteData, setEmailTempleteData] = useState({
    name: "",
    event: "",
    subject: "",
    subjectDelta: {},
    body: "",
    bodyDelta: {},
    description: "description",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleCollect = (event) => {
    let { name, value } = event.target;
    setEmailTempleteData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleCollectEditorsValue = (value, delta, source, editor, name) => {
    let payload = {
      [name]: value,
      [`${name}Delta`]: editor.getContents(),
    };
    setEmailTempleteData((state) => ({
      ...state,
      ...payload,
    }));
  };

  useEffect(()=>{
    if(componentType === "update"){
        emailTempleteDetails(get(params,'templete_id','')).then(res=>{
            let response = get(res,'data.data',{});
            let payload = {
                name: get(response,'name',""),
                event: get(response,'event',""),
                subject: get(response,'subject',""),
                subjectDelta: null,
                body: get(response,'body',""),
                bodyDelta: null,
                description:  get(response,'description',""),
            }
            setEmailTempleteData(payload)
        })


    }

  },[])

  const handleCreateTemplete = () => {
    let tempTempleteData = { ...emailTempleteData };
    delete tempTempleteData["bodyDelta"];
    delete tempTempleteData["subjectDelta"];
    setLoading(true);
    createTemplete(tempTempleteData)
      .then((res) => {
        setLoading(false);
        handleShowSnackPopup({ message: "Templeted created successfully" });
        handleGetEmailTempletes()
        navigate("/settings/email-templete")
      })
      .catch((err) => {
        setLoading(false);
        let errResponse = get(err, "response.data.message", "");
        handleShowSnackPopup({ message: errResponse, error: true });
      });
  };
  const isUpdatedComponent = componentType === "update"
  const handleUpdateEmailTemplete = () => {
    setLoading(true);
    updateEmailTemplete(get(params,'templete_id',''),emailTempleteData).then(res=>{
        setLoading(false);
        handleShowSnackPopup({ message: "Templeted updated successfully" });
    }).catch(err=>{
        let errResponse = get(err, "response.data.message", "");
        handleShowSnackPopup({ message: errResponse, error:true });
        setLoading(false);
    })

  }

  const handleSubmitData = () => {
    if(isUpdatedComponent){
        handleUpdateEmailTemplete();
    }else{
        handleCreateTemplete();
    }

  }



  return (
    <>
      <Box
        sx={{
          marginTop: "100px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
            width: "70%",
          }}
        >
          <PullseInputBox
            inputProps={{
                disabled:isUpdatedComponent
            }}
            name="name"
            value={emailTempleteData.name}
            onChange={handleCollect}
            placeholder="Enter templete name"
          />

            <PullseInputBox
             inputProps={{
                disabled:isUpdatedComponent
            }}
            name="event"
            value={emailTempleteData.event}
            onChange={handleCollect}
            placeholder="Enter even name"
          />
          <MiniEditor
            placeholder={"Enter your subject here...."}
            editorClassName={style.emailTempleteEditorSubjectClass}
            onChange={(value, delta, source, editor) =>
              handleCollectEditorsValue(value, delta, source, editor, "subject")
            }
            value={{
              value: get(emailTempleteData, "subject", ""),
              delta: get(emailTempleteData, "subjectDelta", ""),
            }}
          />
          <MiniEditor
            placeholder={"Enter your body here...."}
            editorClassName={style.emailTempleteEditorClass}
            onChange={(value, delta, source, editor) =>
              handleCollectEditorsValue(value, delta, source, editor, "body")
            }
            value={{
              value: get(emailTempleteData, "body", ""),
              delta: get(emailTempleteData, "bodyDelta", ""),
            }}
          />
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              width: "100%",
              marginTop: "20px",
              justifyContent: "flex-end",
            }}
          >
            <LoadingButton
              loading={loading}
              onClick={handleSubmitData}
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
              variant="outlined"
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateEmailTemplete;
