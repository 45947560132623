import React, { useContext } from 'react'
import MainComponent from '../../../fixedComponent/sideDashboard/MainComponent'
import SideToolbar from '../../../fixedComponent/sideToolbar/SideToolbar'
import { Box, Typography } from '@mui/material'
import style from "./profileAutomation.module.scss"
import ReportCard from './reportCards/ReportCard'
import ProfileContents from './profileContents/ProfileContents'
import { Link, Route, Routes } from 'react-router-dom'
import { MainContext } from '../../../context/MainProvider'
const ProfileAutomation = ({ToolbarComponent}) => {
  const { chatbotProfiles } = useContext(MainContext);

  return (
   <>
     <MainComponent className={style.profileAutomationMainContainer} >
       <Box  sx={{padding:'0px 10px',  height:'95vh', background:'white !important'}} >
       <SideToolbar>
          {ToolbarComponent && (
            <ToolbarComponent/>
          )}
        </SideToolbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            gap: "10px",
            maxHeight:'88vh',

          }}
        >

            <ReportCard  />
            <Typography sx={{marginLeft:'20px'}} >
            Only one AI Chatbot profile will be matched per user. Priority order ranges from top to bottom. Drag a profile to rearrange their order.
            </Typography>
           {
            chatbotProfiles.map((ele)=>(
                <Link to={`/automation/profile/setup/${ele.id}`} style={{textDecoration:'none', color:'initial'}} >
                <ProfileContents {...ele} />
                </Link>
            ))
           }
        </Box>
       </Box>
      </MainComponent>
   
   </>
  )
}

export default ProfileAutomation
