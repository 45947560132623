import React from "react";
import {
  Box,
} from "@mui/material";



const StartedMenu = () => {

  return (
    <Box sx={{ width: "100%", padding: "0px" }} className={"box"}>
     
    </Box>
  );
};

export default StartedMenu;
