import React from "react";

const InboxSidebarIcon = ({color}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 26 26"
        fill="none"
      >
        <path
          d="M2.59998 15.5996H7.79997L9.53331 17.4196H16.4666L18.2 15.5996H23.4M5.19997 23.3996C3.76403 23.3996 2.59998 22.2355 2.59998 20.7996V5.19961C2.59998 3.76367 3.76404 2.59961 5.19998 2.59961H20.8C22.2359 2.59961 23.4 3.76367 23.4 5.19961V20.7996C23.4 22.2355 22.2359 23.3996 20.8 23.3996H5.19997Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default InboxSidebarIcon;
