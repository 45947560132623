import React, { useContext, useEffect, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Chip,
  InputBase,
  ListItemText,
  Paper,
} from "@mui/material";
import FileIcon from "../../../../assets/icons/FileIcon";
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import { LightTooltip } from "../../../commonComponent/tooltip/LightTooltip";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Image } from "@mui/icons-material";
import chatbotImagelink from "../../../../assets/chatbot-image-link.png"
import { get } from "lodash";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});
const createurl = (fileObj) => {
  return URL.createObjectURL(fileObj)

}

const ChatBotSetting = ({data, handleUpdateChatbotSetting}) => {
  const [chatbotSettingsState, setChatbotSettingState] = useState({
    file: {},
    imageLink: "",
    name: "",
  }); 

  useEffect(()=>{
    setChatbotSettingState(data)
  },[data])

  const handleCollectFile = (event) => {
    let getFile  = get(event,`target.files[0]`,{});
    if(Object.keys(getFile)){
      let imageLink = createurl(getFile)
      setChatbotSettingState((state)=>({
        ...state,
        file:getFile,
        imageLink:imageLink
      }))
    }    
  }

  const handleCollectData = (event) => {
    setChatbotSettingState((state)=>({
      ...state,
      name:event.target.value
    }))
  }

  const handleResetState = () => {
    setChatbotSettingState(data)
  }

  const handleSaveData = () => {
    handleUpdateChatbotSetting({
      chatbotSettings:{
        imageLink:chatbotSettingsState.imageLink,
        name:chatbotSettingsState.name,

      }
    })
  }


  return (
    <>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            padding: "50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              maxWidth: "40%",
            }}
          >
            <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
              Name
            </Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 30,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid rgba(0,0,0,0.2) `,
              }}
            >
              <InputBase
                onChange={handleCollectData}
                value={get(chatbotSettingsState,'name',"")}
                className="input-box"
                type="email"
                name="first_name"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="Enter First Name"
              />
            </Paper>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "5px",
              maxWidth: "40%",
            }}
          >
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                Profile picture
              </Typography>
              <LightTooltip
                placement="top"
                title="Bot interactions go better when the end
                user knows they're not talking to a human.
                We recommend choosing an image that
                reminds users they're interacting with a
                bot.
                "
              >
                <HelpOutlinedIcon sx={{ fontSize: "14px" }} />
              </LightTooltip>
            </Box>

         <Box sx={{display:'flex', gap:"50px"}} >
          <img  src={get(chatbotSettingsState,'imageLink',chatbotImagelink)}  style={{
             height:"50px",
             width:"50px",
          }}  alt="error while loading image" />

          <Button component="label" variant="outlined" sx={{
               background: "#f1f1fb",
               color: "#707c97",
               height: "26px",
               boxShadow: "none",
               fontSize: "12px",
               textTransform: "capitalize",
               padding: "3px 10px",
               borderColor:'#a84acb',
              color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }}  
             startIcon={<CloudUploadIcon />}>
      Upload file
      <VisuallyHiddenInput type="file" onChange={handleCollectFile} />
    </Button>
 
         </Box>
          </Box>




          <Box
            sx={{
              display: "flex",
              gap: "10px",
              maxWidth: "40%",
            }}
          >
            <Button onClick={handleResetState} sx={{ 
              
              
              
              background: "#f1f1fb",
              color: "#707c97",
              height: "26px",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              border:'none',
              ":hover":{borderColor: "none", }}}  variant="outlined" >   Reset</Button>
            <Button
            onClick={handleSaveData}
            sx={{
              background: "#f1f1fb",
              color: "#707c97",
              height: "26px",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined">Save</Button>
 

          </Box>
        </Box>
      </AccordionDetails>
    </>
  );
};

export default ChatBotSetting;
