import { Avatar, Box, Button, ListItemText, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./comapnyPeoples.module.scss";
import Checkbox from "@mui/material/Checkbox";
import NewMessageIcon from "../../../../../../assets/icons/NewMessageIcon";
import CaretDownIcon from "../../../../../../assets/icons/CaretDownIcon";
import TagIcon from "../../../../../../assets/icons/TagIcon";

const comapnyDetail = [
  {
    header: "Signed up",
    subHeader: "Unknown",
  },
  {
    header: "Web sessions",
    subHeader: "N/A",
  },
  {
    header: "Last seen",
    subHeader: "N/A",
  },
  {
    header: "Last contacted",
    subHeader: "Unknown",
  },
  {
    header: "Last heard from",
    subHeader: "N/A",
  },
];
const CompanyPeoples = ({companyPeoples=[]}) => {
    const [selectedPeoples, setSelectedPeoples] = useState({})


    const handleChangeSelectedUser = (event) => {
        let {name,checked} = event.target;
        let tempSelectedPeoples = {...selectedPeoples};
         tempSelectedPeoples[name] = checked;
        setSelectedPeoples(tempSelectedPeoples)
    }
    const handleSetAllSelected = (event) => {
        let {checked} = event.target;
        if(checked){
            let keysData = {};
            companyPeoples.forEach(({id})=>{
                keysData[id] = true
            })
            setSelectedPeoples(keysData)
        }else{
            setSelectedPeoples({})
        }
    }
    const allSelected = Object.values(selectedPeoples).filter(Boolean).length === companyPeoples.length
  return (
    <>
      <Box
        sx={{ flexGrow: 1 }}
        className={style["company-peoples-main-container"]}
      >
        <Box className="header-container">
          <Box>
            <Checkbox
            checked={allSelected}
              onChange={handleSetAllSelected}
              sx={{
                color: "#A84ACB",
                "&.Mui-checked": {
                  color: "#A84ACB",
                },
              }}
            />
          </Box>
          <Box className="header-contents">
            <Box className="contents">
              <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                {companyPeoples.length} User
              </Typography>
              <Button
                className="header-first-btn"
                variant="contained"
                startIcon={<NewMessageIcon height={13} width={13} />}
              >
                New message
              </Button>
              <Button
                className="header-first-btn"
                variant="contained"
                startIcon={<TagIcon height={13} width={13} />}
              >
                Add Tags
              </Button>
              <Button className="header-first-btn" variant="contained">
                remove
              </Button>
            </Box>
            <Box className="contents">
              <Button
                className="header-first-btn"
                variant="contained"
                endIcon={<CaretDownIcon />}
              >
                Last seen
              </Button>
            </Box>
          </Box>
        </Box>

        <Box className="body-container">
            {
                companyPeoples.map(({name,email,id})=>(
                    <Box className="company-people-card" key={id}>
                    <Box>
                      <Checkbox
                        checked={selectedPeoples[id] ? true : false}
                        onChange={handleChangeSelectedUser}
                        name={id}
                        sx={{
                          color: "#A84ACB",
                          "&.Mui-checked": {
                            color: "#A84ACB",
                          },
                          paddingTop: "0px",
                        }}
                      />
                    </Box>
                    <Box className="body-contents">
                      <Box className="contents">
                        <Box className="comapny-card">
                          <Box className="comapny-header">
                            <Avatar
                              sx={{
                                background: "#E87373",
                                height: "28px",
                                width: "28px",
                              }}
                            >
                              S
                            </Avatar>
                            <ListItemText
                              className="company-name-item"
                              primary={
                                <Typography sx={{ fontSize: "13px" }}>
                                  {name}
                                </Typography>
                              }
                              secondary={
                                <Typography sx={{ fontSize: "12px", color: "gray" }}>
                                 {email}
                                </Typography>
                              }
                            />
                          </Box>
                          <Box className="comapny-details">
                            {comapnyDetail.map(({ header, subHeader }) => (
                              <ListItemText
                                className="company-name-item"
                                primary={
                                  <Typography sx={{ fontSize: "13px", margin: "3px" }}>
                                    {header}
                                  </Typography>
                                }
                                secondary={
                                  <Typography
                                    sx={{
                                      fontSize: "12px",
                                      color: "gray",
                                      margin: "3px",
                                    }}
                                  >
                                    {subHeader}
                                  </Typography>
                                }
                              />
                            ))}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ))
            }

       
        </Box>
      </Box>
    </>
  );
};

export default CompanyPeoples;
