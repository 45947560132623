/* eslint-disable react-hooks/exhaustive-deps */
import ReactQuill, { Quill } from "react-quill";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "quill-mention";
import "./mini-editor.css"
import "react-quill/dist/quill.snow.css";
import editorCss from "./mini-editor.module.scss"
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";
import MagicUrl from 'quill-magic-url'

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji,
  'modules/magicUrl': MagicUrl
}, true);

 const  MiniEditor = ({
  editorClassName,
  onChange,
  placeholder,
  value={},
}) => {
  

  const [mentionData, setMentionData] = useState([])
  const editor = useRef();
  useEffect(() => {
    setMentionData([
      { id: "firstName", value: "{{firstName}}" },
      { id: "{{email}}", value: "{{email}}" },
      { id: "{{ticket.assignee.name}}", value: "{{ticket.assignee.name}}" },
      { id: "{{ticket.assignee.first_name}}", value: "{{ticket.assignee.last_name}}" },
      { id: "{{ticket.name}}", value: "{{ticket.name}}" },
    ]);
  }, []);



  const modules = useMemo(() => ({
      magicUrl: true,
     toolbar: [
        ['emoji'],
      ],
      'emoji-toolbar': true,
      "emoji-shortname": true,
      clipboard: {
        matchVisual: true,
      },
    mention: {
      showDenotationChar:false,
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['{{'],
      source: function (searchTerm, renderList, mentionChar) {
        let values;
        if (mentionChar === '{{') {
          values = mentionData;
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = values.filter(
            (mention) =>
              mention.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );
          renderList(matches, searchTerm);
        }
      },
    },
  }), [mentionData]);


  return (
    <div className={`${editorCss.mentionCssClass} ${editorClassName}`}    >
      <ReactQuill
        ref={editor}
        value={value?.delta || value?.value}
        theme={'snow'}
        modules={{ ...modules }}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}

export default MiniEditor