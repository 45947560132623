import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./userProfile.module.scss";
import CursorDownIcon from "../../../../../assets/icons/CursorDownIcon";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PlusBoxIcon from "../../../../../assets/icons/PlusBoxIcon";
import { MainContext } from "../../../../../context/MainProvider";
import LocationIcon from "../../../../../assets/icons/LocationIcon";
import ClockIcon from "../../../../../assets/icons/ClockIcon";
import CaretDownIcon from "../../../../../assets/icons/CaretDownIcon";
import UserIcon from "../../../../../assets/searchIcons/UserIcon";
import { get } from "lodash";
const UserProfileToolbar = ({userDetails}) => {
    const {handleOpenModal} = useContext(MainContext)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleCreateNewUser = (type) => {
    // handleClose()
    // handleOpenModal({
    //     state:true,
    //      Component:<CreateNewUser  data={{
    //         type:type,
    //         handleGetAllUsers
    //      }} />, 
        
    // })
  }
  return (
    <>
      <Box className={style.userprofileToolbarComponent}>
        <Box className="toolbarContent">
            <Box  className="userDetails" >
            <Avatar sx={{height:"30px", width:"30px",background:'#E87373', }} >{get(userDetails,'name[0]',"").toUpperCase()}</Avatar>
            <Typography  className="user-name" >{get(userDetails,'name',"")}</Typography>
            <Chip label={get(userDetails,'type',"")}  s variant="outlined"  className="user-lable"/>
            <Button startIcon={<LocationIcon />} className="user-location" variant="text">Unknown</Button>
            <Button startIcon={<ClockIcon />} className="user-location" variant="text"> {new Date().toLocaleDateString()} </Button>
            {/* <Button startIcon={<UserIcon />} endIcon={<CaretDownIcon />} className="user-location" variant="text">No owner</Button> */}
            

            </Box>
            <Box>
            {/* <Box>
            <Button
              endIcon={<CursorDownIcon sx={{ color: "white !important" }} />}
              className="add-profile"
              sx={{
                textTransform: "inherit",
              }}
              id="basic-menu"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              New conversation
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={()=>handleCreateNewUser('user')}  sx={{display:'flex', gap:'10px', alignItems:"center"}} >  <PlusBoxIcon />  <Typography  sx={{color:"#222", fontSize:"15px"}} >Create new user</Typography></MenuItem>
              <MenuItem onClick={()=>handleCreateNewUser('lead')}  sx={{display:'flex', gap:'10px', alignItems:"center"}} >  <PlusBoxIcon />  <Typography  sx={{color:"#222", fontSize:"15px"}} >Create new lead</Typography></MenuItem>
            </Menu>
          </Box>
                 */}
                </Box>

          {/* <Button
            className="users-header"
            endIcon={<CursorDownIcon sx={{ color: "gray !important" }} />}
            variant="text"
          >
            Users
          </Button>

          <Button
            className="filter-buttom"
            startIcon={
              <AddIcon
                style={{
                  fontSize: "14px",
                  color: "#8f1eb3",
                  fontWeight: "bold",
                }}
              />
            }
            variant="text"
          >
            Add filters
          </Button>
          <Box>
            <Button
              endIcon={<CursorDownIcon sx={{ color: "white !important" }} />}
              className="add-profile"
              sx={{
                textTransform: "inherit",
              }}
              id="basic-menu"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              New user or leads
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={()=>handleCreateNewUser('user')}  sx={{display:'flex', gap:'10px', alignItems:"center"}} >  <PlusBoxIcon />  <Typography  sx={{color:"#222", fontSize:"15px"}} >Create new user</Typography></MenuItem>
              <MenuItem onClick={()=>handleCreateNewUser('lead')}  sx={{display:'flex', gap:'10px', alignItems:"center"}} >  <PlusBoxIcon />  <Typography  sx={{color:"#222", fontSize:"15px"}} >Create new lead</Typography></MenuItem>
            </Menu>
          </Box> */}
        </Box>
      </Box>
    </>
  );
};

export default UserProfileToolbar;
