import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import style from "./usersTable.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import MainBoxIcon from "../../../../../assets/icons/MainBoxIcon";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";
import BuildingIcon from "../../../../../assets/icons/BuildingIcon";
import OutBoundSidebarIcon from "../../../../../assets/icons/OutBoundSidebarIcon";
import { get } from "lodash";
import { skeletonLoadingData } from "../../../../../utils/constants/chatbotSetting";

const CompnayTable = ({ companies, loading = false }) => {

  return (
    <Box className={style["users-table-main-component"]}>
        <Table
        className="table-main-container"
          sx={{ minWidth: 650 }}
          aria-label="simple table"
        >
          <TableHead className="table-header">
            <TableRow className="table-header-row">
              <TableCell
                padding="checkbox"
                className="header-row-items"
              >

              </TableCell>
              <TableCell className="header-row-items" align="left">
                Name
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Last Seen
              </TableCell>
              <TableCell className="header-row-items" align="left">
                People
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Company web sessions
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Company size
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Company industry
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Company website
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          
            {loading ? (
                <>
                {skeletonLoadingData.map(ele=>(
                  <TableRow
                  key={ele.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                    {/* <TableCell   padding="checkbox">
                           <Skeleton variant="rounded" sx={{width:"100%"}} height={20} />
                          </TableCell> */}
                  {
                      ele.child.map(ele=>(
                          <TableCell  key={ele}  >
                          <Skeleton variant="rounded" sx={{width:"100%"}}  height={20} />
                          {/* hi */}
                          </TableCell>
                      ))
                  }
                </TableRow>
                ))}
             </>       
            ) : (
              <>
                {companies.map(
                  ({
                    name = "N/A",
                    lastSeen="N/A",
                    people="0",
                    companyWebSessions="0",
                    numberOfEmployees="N/A",
                    industry="N/A",
                    websites,
                    id
                    
                    // "description": "description",
                    // "phone": "phone",
                    // "numberOfEmployees": 10,
                    // "annualRevenue": 1000,
                    // "websites": ["https://pullseai.com", "https://www.pullse.ai"],
                    // "notes": "notes",
                    // "accountTier": "accountTier",
                    // "industry": "industry",
                    // "address": "address",
                    // "city": "city",
                    // "state": "state",
                    // "zipcode": "zipcode",
                    // "country": "country"



                    // name="langoor havas",
                    // lastSeen="a day ago",
                    // people="2015",
                    // companyWebSessions="45",
                    // companySize="1245",
                    // companyIndustry="Ad agency",
                    // companyWebsite="langoor.com"
                  }) => (
                    <TableRow
                      key={name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell padding="checkbox">
                        {/* <Checkbox
                          sx={{
                            color: "#a84acb",
                            "&.Mui-checked": {
                              color: "#a84acb",
                            },
                          }}
                        /> */}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent:'space-between'
                        }}
                      >
                        <Box  sx={{  display: "flex",
                          gap: "10px", alignItems: "center",}} >
                        <Avatar
                          sx={{
                            bgcolor: "#222",
                            color: "white",
                            height: 30,
                            width: 30,
                          }}
                        >
                          <BuildingIcon />
                        </Avatar>
                        <Link
                          to={`/contacts/company/${id}`}
                          style={{ textDecoration: "underline" }}
                        >
                          <Typography
                            sx={{
                              fontSize: "14px",
                              cursor: "pointer",
                              color: "gray",
                              ":hover": { color: "blue" },
                            }}
                          >
                            {name}
                          </Typography>
                        </Link>
                        </Box>
                        <Box  sx={{  display: "flex",
                          gap: "10px", alignItems: "center",}} >
                        <OutBoundSidebarIcon color={"#222"}  style={{cursor:"pointer"}} />
                        </Box>
                        <Box>
                        </Box>
                       
                       
                        {/* <MainBoxIcon
                          height={20}
                          width={20}
                          style={{ cursor: "pointer" }}
                          onClick={() => {}}
                        /> */}
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ fontSize: "14px", color: "gray" }}>
                          {lastSeen}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ fontSize: "14px", color: "gray" }}>
                          {people}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ fontSize: "14px", color: "gray" }}>
                          {companyWebSessions}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ fontSize: "14px", color: "gray" }}>
                          {numberOfEmployees}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ fontSize: "14px", color: "gray" }}>
                          {industry}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">
                        <Typography sx={{ fontSize: "14px", color: "gray" }}>
                          {
                            get(websites,'[0]',"N/A") === "N/A" ? 
                            "N/A"
                             :
                             <a href={`${get(websites,'[0]',"#")}`} target="_blank" rel="noopener noreferrer">
                              { get(websites,'[0]',"N/A")}
                             </a>

                          }
                       
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )
                )}{" "}
              </>
            )}
          </TableBody>
        </Table>
    </Box>
  );
};
export default CompnayTable;
