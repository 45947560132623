import { Navigate } from "react-router";
import { HttpClient } from "../../services/HttpClient";
import { get } from "lodash";
import Delta from "quill-delta";
import AlphabetIcon from "../../assets/icons/AlphabetIcon";
import NumberIcon from "../../assets/icons/NumberIcon";
import DecimalIcon from "../../assets/icons/DecimalIcon";
import TrueFalseIcon from "../../assets/icons/TrueFalseIcon";
import { CalendarIcon } from "@mui/x-date-pickers";
import CalenderIcon from "../../assets/icons/CalenderIcon";
import ListIcon from "../../assets/icons/ListIcon";
import mustache from "mustache";
// import NotificationSound from "../../assets/sound/water_droplet.mp3"
export const getCookie = (cname) => {
  const name = `${cname}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (cname, cvalue, exdays = 10) => {
  const domain = window.location.hostname;
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${cname}=${cvalue};${expires};path=/`;
};

export const deleteCookie = (name) => {
  document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const handleLogout = () => {
  deleteCookie("customerToken");
  window.location.href = "/";
};

export const handleSetToken = (token) => {
  setCookie("customerToken", token);
  HttpClient.setAxiosDefaultConfig();
  return true;
};

export const setWorkspaceId = (id) => {
  setCookie("workspcaeId", id);
};

export const getWorkspaceId = () => {
  return getCookie("workspcaeId");
};

export const PrivateRoute = ({ children }) => {
  let user = getCookie("customerToken");
  if (!user) return <Navigate to="/" />;
  return <> {children} </>;
};

export const PublicRoute = ({ children }) => {
  let user = getCookie("customerToken");
  if (user) return <Navigate to="/inbox" />;
  return <> {children} </>;
};

export function debounceWithImmediate(func, immediateFunc, delay) {
  let timeoutId;
  let immediateCall = true;
  return (props) => {
    clearTimeout(timeoutId);
    if (immediateCall) {
      immediateFunc(props);
      immediateCall = false;
    }
    timeoutId = setTimeout(() => {
      immediateCall = true;
      func(props);
    }, delay);
  };
}

export const arrayToQueryString = (objArray) => {
  const queryString = [];

  for (const obj of objArray) {
    const key = obj.type;
    const value = obj.data;
    if (key && value !== undefined) {
      queryString.push(
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
      );
    }
  }

  return queryString.join("&");
};

export const isEmail = (emailAdress) => {
  let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  if (emailAdress.match(regex)) return true;
  else return false;
};

export const isValidHttpUrl = (str) => {
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(str);
};

export const getNameFromUrl = (url) => {
  const regex = /[http,https]:\/\/(.+?)\./;
  const match = url.match(regex);
  return get(match, "[1]", "");
};

export const throttle = (func, delay) => {
  let lastCall = 0;

  return function (...args) {
    const now = new Date().getTime();

    if (now - lastCall >= delay) {
      func(...args);
      lastCall = now;
    }
  };
};

export const encryptBase64 = (text) => {
  return btoa(text);
};

export const decryptBase64 = (encryptText) => {
  try {
    return atob(encryptText);
  } catch {
    return "";
  }
};

export const convertDeltaToText = (delta) => {
  if (!!delta) {
    try {
      return delta.ops
        .map((op) =>
          typeof op.insert === "string" ? op.insert.replace(/\n/g, " ") : ""
        )
        .join("");
    } catch (error) {
      return "";
    }
  }
  return "";
};

export const convertTextToDelta = (text) => {
  return new Delta().insert(text);
};
export const convertDeltaToPlainText = (ops) => {
  return ops.reduce((text, op) => {
    if (typeof op.insert === "string") {
      text += op.insert;
    } else if (op.insert.mention) {
      text += op.insert.mention.value;
    }
    return text;
  }, "");
};

export const convertPalntextToDelta = (plainText, mentionIndex = "0") => {
  const delta = [];
  let currentIndex = 0;

  function addText(text) {
    if (text.length > 0) {
      delta.push({ insert: text });
    }
  }

  while (currentIndex < plainText.length) {
    const mentionStartIndex = plainText.indexOf("{{", currentIndex);
    if (mentionStartIndex !== -1) {
      const mentionEndIndex = plainText.indexOf("}}", mentionStartIndex);
      if (mentionEndIndex !== -1) {
        // Text before mention
        addText(plainText.substring(currentIndex, mentionStartIndex));

        // Mention
        const mentionValue = plainText.substring(
          mentionStartIndex,
          mentionEndIndex + 2
        );
        delta.push({
          insert: {
            mention: {
              index: mentionIndex,
              denotationChar: "",
              id: mentionValue,
              value: mentionValue,
            },
          },
        });

        currentIndex = mentionEndIndex + 2;
      } else {
        // No closing }} found for the opening {{
        addText(plainText.substring(currentIndex));
        currentIndex = plainText.length;
      }
    } else {
      // No more mentions found
      addText(plainText.substring(currentIndex));
      currentIndex = plainText.length;
    }
  }

  return delta;
};

export const variabledText = (plainText = "", data = {}) => {
  let newData = {
    ticket: data,
  };

  try {
    return mustache.render(plainText, newData);
  } catch (error) {
    return "";
  }
};

export const customFieldIcons = {
  text: AlphabetIcon,
  number: NumberIcon,
  Decimal_Number: DecimalIcon,
  radio: TrueFalseIcon,
  date: CalenderIcon,
  select: ListIcon,
};

export const copyToClipboard = (value) => {
  navigator.clipboard.writeText(value);
};

export const capitalizeFirstCharacterOfWords = (inputString) => {
  if (typeof inputString !== "string" || inputString.length === 0) {
    // Return the input if it's not a non-empty string
    return inputString;
  }

  // Split the string into an array of words
  const words = inputString.split(" ");

  // Capitalize the first character of each word
  const capitalizedWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  // Join the capitalized words back into a string
  const capitalizedString = capitalizedWords.join(" ");

  return capitalizedString;
};

const tempKeys = {
  firstName:{
    placeholder:"Enter your first name",
    type:"text",
    name:"firstName",
    required:true
  },
  lastName:{
    placeholder:"Enter your last name",
    type:"text",
    name:"lastName",
    required:true
  },
  email:{
    placeholder:"Enter your email",
    type:"email",
    name:"email",
    required:true
  },
  mobile:{
    placeholder:"Enter your mobile number",
    type:"number",
    name:"mobile",
    required:true
  }
}
export const handleMakeFormForChatWidget = (keyFields = []) => {
  let tempHtmlTemplete = `
  <div style='display: flex;' > 
  <form  class='pullse-data-form' style='display: flex; flex-direction: column; gap: 10px; width: 100%;' > 
  ${
    keyFields.map(keyState=>{
      if(!tempKeys[keyState]) return null
      let {placeholder, type, name, required } = tempKeys[keyState];
      return `<input style='width: 100%; padding: 10px 5px; border: 1px solid #ccc; border-radius:
      4px; box-sizing: border-box; resize: vertical; ' type='${type}' id='fname' name='${name}' placeholder='${placeholder}' required=${required} />` 
    }).filter(Boolean).join(' ')
  }
  <button type='submit' class='pullse-form-submit-button' style='
   background-color: #04aa6d; color: white; padding: 12px 0px; border: none; border-radius: 4px; cursor: pointer; ' value='Submit' >Submit</button> 
  </form> 
  </div>
  `;

  return tempHtmlTemplete;
};


export const extractInputNamesFromHTML = (htmlString=<></>)  => {
  try {
    const regex = /<input[^>]*name=['"]([^'"]*)['"][^>]*>/gi;
    const matches = htmlString.match(regex);
  
    if (!matches) {
        return [];
    }
  
    return matches.map(match => {
        const nameMatch = match.match(/name=['"]([^'"]*)['"]/i);
        return nameMatch ? nameMatch[1] : null;
    }).filter(name => name !== null);
  } catch (error) {
    return []
  }
 
}


export const isArrayString = (array)  => {
  // If array is empty, we cannot determine its type, so return false
  if (array.length === 0) {
      return false;
  }

  // Iterate through each element in the array
  for (let element of array) {
      // Check if the element is not a string
      if (typeof element !== 'string') {
          // If any element is not a string, return false
          return false;
      }
  }

  // If all elements are strings, return true
  return true;
}

