import React, { useRef } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadCommonComponent = ({ children }) => {
    const ref = useRef(null)
  const handleCollectFile = () => {};
  const handleClickRef = () => {
    ref.current.click()
  }

  return (
    <Box  onClick={handleClickRef} sx={{ width:"fit-content"}}  >
      <VisuallyHiddenInput ref={ref} onChange={handleCollectFile} type="file" />
      {children}
    </Box>
  );
};

export default UploadCommonComponent;
