import { InputAdornment, InputBase, Paper } from '@mui/material'
import React, { useCallback, useState } from 'react'
import SearchIcon from '../../../assets/icons/SearchIcon'
import { debounceWithImmediate } from '../../../utils/helpers/helpers';
import useStateCallback from '../../../utils/hooks/useStateCallback';

const SearchBarComponent = ({style,onEnterKeyDown=()=>{},handleCollectValue=()=>{}}) => {
    const [inputData, setInputData] = useState('');
    const collectDataUsingDebounce = (event) => {
        handleCollectValue(event.target.value);
    }

    const onChange = useCallback(debounceWithImmediate(collectDataUsingDebounce,()=>{},700),[]);
    const handleCollectInputValue = (event) => {
        onChange(event);
        setInputData(event.target.value);
    }

  return (
    <>
    
    
    <Paper
          component="div"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "80%",
            height: 40,
            padding: "10px",
            boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
            ...style,
            borderRadius:"5px"
          }}
        >
          <InputBase
            placeholder="Search"
            sx={{ ml: 1, flex: 1 }}
            value={inputData}
            onChange={handleCollectInputValue}
            onKeyDown={(key)=>{
                if(key==='Enter'){
                    onEnterKeyDown(inputData)
                }
            }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon color={"#8f1eb3"} height={14}   />
              </InputAdornment>
            }
          />
        </Paper>

    </>
  )
}

export default SearchBarComponent
