import React from "react";

const NumberIcon = ({height=16,width=16,color="black",...props}) => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.968 7.662c.004 1.119-1.048 1.927-2.518 1.927-1.438 0-2.461-.795-2.483-1.94h1.393c.026.48.483.8 1.093.8.633 0 1.08-.358 1.077-.88.003-.527-.46-.897-1.202-.897h-.61V5.655h.61c.63 0 1.068-.348 1.065-.863.003-.492-.371-.83-.93-.83-.566 0-1.023.322-1.04.82h-1.325c.019-1.13 1.029-1.917 2.371-1.917 1.355 0 2.26.812 2.256 1.844.004.735-.501 1.253-1.233 1.384v.051c.962.122 1.48.703 1.476 1.518zM5.03 11.5h4.667v-1.131H6.948v-.045l.956-.937C9.249 8.16 9.61 7.547 9.61 6.802c0-1.135-.926-1.937-2.33-1.937-1.374 0-2.317.821-2.313 2.106H6.28c-.003-.626.393-1.01.99-1.01.576 0 1.004.358 1.004.934 0 .52-.32.878-.914 1.45l-2.33 2.158v.997zM3.878 6.955h-1.3L.956 7.98v1.228l1.499-.94h.038V13.5h1.384V6.955z"
         fill={color}
        ></path>
      </svg>
    </>
  );
};

export default NumberIcon;
