import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./aiChatbotContent.module.scss"
const AiChatbotContnetToolbar = ({handleGetAllUsers}) => {

  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
        <Box className="toolbarContent">

        </Box>
      </Box>
    </>
  );
};

export default AiChatbotContnetToolbar;
