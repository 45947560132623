export const dynamicChartsData = [
    {
        "type": "matrix_card",
        "title":"test",
        value:10,
        color:'rgb(75, 192, 192)',
        columns:4
    },
    {
        "type": "matrix_card",
        "title":"test",
        value:20,
        color:'rgb(75, 192, 192)',
        columns:4
    },
    {
        "type": "matrix_card",
        "title":"test",
        value:30,
        color:'rgb(75, 192, 192)',
        columns:4
    },
    {
        "type": "matrix_card",
        "title":"test",
        value:40,
        color:'rgb(75, 192, 192)',
        columns:4
    },
    {
        "type": "line",
        "title":"test",
        columns:8,
        options:{},       
        chartData:{
            "labels":['January', 'February', 'March', 'April', 'May', ],
            "datasets":[
                {
                    label: 'Dataset 1',
                    "backgroundColor": 'rgb(75, 192, 192)',
                    data: [100,200,2,65,500],
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    borderWidth: 2,
                    fill: false,                
                },
                {
                    label: 'Dataset 2',
                    "backgroundColor": 'rgb(75, 192, 192)',
                    data: [100,20,30,400,1200],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    borderWidth: 2,
                    fill: false,                
                }
            ]
        }
    },
    {
        "type": "bar",
        "title":"test",
        columns:6,
        options:{},       
        chartData:{
            "labels":['January', 'February', 'March', 'April', 'May', ],
            "datasets":[
                {
                    label: 'Dataset 2',
                    "backgroundColor": 'rgb(75, 192, 192)',
                    data: [100,200,300,400,500],
                    borderColor: 'white',
                    borderColor: 'rgb(153, 162, 235)',
                    borderWidth: 2,
                    fill: true,                
                }
            ]
        }
    },
    {
        "type": "line",
        "title":"test",
        columns:12,
        options:{},       
        chartData:{
            "labels":['January', 'February', 'March', 'April', 'May', ],
            "datasets":[
                {
                    fill: true,
                    label: 'Dataset 2',
                    data:  [100,200,300,2,100],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',             
                },
                {
                    fill: true,
                    label: 'Dataset 2',
                    data:  [10,20,0,10,90],
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',             
                }
            ]
        }
    },
    {
        "type": "pie",
        "title":"test",
        columns:12,
        options:{},       
        chartData:{
            "labels":['January', 'February', 'March', 'April', 'May', ],
            "datasets":[
                {
                    fill: false,
                    label: 'Dataset 2',
                    data:  [100,200,300,2,100],
                    borderColor: 'rgb(53, 162, 235)',
                    "backgroundColor": ['rgb(75, 192, 192)', 'red', 'green', 'blue',"yellow"],           
                },
                {
                    fill: false,
                    label: 'Dataset 2',
                    data:  [10,20,0,10,90],
                    borderColor: 'rgb(53, 162, 235)',
                    "backgroundColor": ['rgb(75, 192, 192)', 'red', 'green', 'blue',"yellow"],          
                }
            ]
        }
    }
]