import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Divider, InputAdornment, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { MainContext } from "../../../context/MainProvider";
import { get } from "lodash";


const ExternalIdMenu = ({handleClose, onSelectFilter, selectedVal}) => {
  const [inputVal, setInputVal] = useState("")


  useEffect(()=>{
    setInputVal(selectedVal)
  },[selectedVal])

  const handleCollectData = (event) => {
    setInputVal(event.target.value)
  }

  const handleSubmitDataCollection = (event) => {
    if(event.keyCode===13){
        onSelectFilter({data:inputVal})
        handleClose()
    }
  }
  return (
    <Box sx={{ width: "300px", padding: "10px" }} className={"box"}>
      <Paper
        component="box"
        className="padding"
        sx={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: 40,
          padding: "10px",
          boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
          border:'1px solid #c166e2'
        }}
      >
        <InputBase
          className=""
          value={inputVal}
          placeholder={"enter your externl id"}
          sx={{ flex: 1, padding: 0 }}
          onChange={handleCollectData}
          onKeyUp={handleSubmitDataCollection}
        />
      </Paper>
      <Divider />
    </Box>
  );
};

export default ExternalIdMenu;
