import { Box, Button } from "@mui/material";
import React from "react";
import style from "./profileAutomation.module.scss";
import CursorDownIcon from "../../../assets/icons/CursorDownIcon";
import ProfileBookIcon from "../../../assets/icons/ProfileBookIcon";
import { Link } from "react-router-dom";
const ProfileToolbar = () => {
  return (
    <>
      <Box className={style.profileToolbarMainClass}>
        <Box className="toolbarContent">
        <Button
        
        startIcon={<ProfileBookIcon />}
        endIcon={<CursorDownIcon />}
        className="displaynote"
            sx={{
              textTransform: "inherit",
            }}
          >
            Learn about Pullse
          </Button>
          <Link to='/automation/profile/create' >
          <Button
          className="add-profile"
            variant="contained"
            sx={{
              textTransform: "inherit",
            }}
          >
            Create new profile
          </Button>
          </Link>
        
        </Box>
      </Box>
    </>
  );
};

export default ProfileToolbar;
