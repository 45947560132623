import React from "react";

const LayoutLeft = ({ onClick }) => {
  return (
    <>
      <svg
        onClick={onClick}
        style={{ cursor: "pointer" }}
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.00002 20.9999L9.00002 2.9999M21.6 17.9999L21.6 5.9999C21.6 4.01168 19.9882 2.3999 18 2.3999L6.00002 2.3999C4.0118 2.3999 2.40003 4.01168 2.40002 5.9999L2.40002 17.9999C2.40002 19.9881 4.0118 21.5999 6.00002 21.5999H18C19.9882 21.5999 21.6 19.9881 21.6 17.9999Z"
          stroke="#313131"
          strokeWidth="2"
        />
      </svg>
    </>
  );
};

export default LayoutLeft;
