import React from "react";

const SwitchIcon = ({color="#313131",height="14", width="14"}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M10.5 5.83398L12.25 4.08398M12.25 4.08398L10.5 2.33398M12.25 4.08398H4.08333M3.5 8.16732L1.75 9.91732M1.75 9.91732L3.5 11.6673M1.75 9.91732H9.91667"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default SwitchIcon;
