import {
  Box,
  Button,
  Paper,
  Typography,
  InputBase,
  TextareaAutosize,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
const AddDomainComponent = ({ data }) => {
  const { handleCloseModal, } = useContext(MainContext);

  return (
    <>
      <Box sx={{ padding: "20px 40px", minHeight: "350px", borderBottom:"5px solid #a84acb" }}>
        <Box
          sx={{
            width: "400px",
            display: "inline-block",
            minHeight: "300px",
            position: "relative",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
            Add a custom address
            </Typography>
          </Box>

          <Box sx={{marginTop:"10px"}} >
            <Typography sx={{ fontWeight: "normal", fontSize: "13px" }}>
            Enter an address from the domain you want to send emails from, and we'll send you a verification link via email
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Box  sx={{display:"flex", flexDirection:"column", gap:"5px", }} >
            <Typography sx={{fontSize:"14px", fontWeight:"bold"}}>Display name</Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                autoFocus
                name="name"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="John Doe"
              />
              
            </Paper>
            <Typography sx={{fontSize:"14px", fontWeight:"normal", color:'gray'}}>Use a name that matches the email to increase open rates</Typography>
            </Box>
           

            <Box  sx={{display:"flex", flexDirection:"column", gap:"5px", }} >
            <Typography sx={{fontSize:"14px", fontWeight:"bold"}}>Email</Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                autoFocus
                name="name"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="john.doe@example.com"
              />
              
            </Paper>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "30px",

            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                {"Add"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddDomainComponent;
