import {
  Box,
  Button,
  Paper,
  Typography,
  InputBase,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../../context/MainProvider";
import {
  createNewCustomer,
  getAllComapany,
} from "../../../../../services/Apis";
import { get } from "lodash";
import { SidebarContext } from "../../../../../context/SidebarProvider";
import { throttle } from "../../../../../utils/helpers/helpers";
const CreateNewUser = ({ data }) => {
  const { handleCloseModal, compinesData } = useContext(MainContext);
  const {handleShowSnackPopup} = useContext(SidebarContext)
  const [loading, setLoading] = useState(false);
  let { type, handleGetAllUsers } = data;
  const [globalError, setGlobalError] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    companyId: null,
    type: type == "customer" ? "customer" : "lead",
  });

  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setUserData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleCreateNewCustomer = (data) => {
    setLoading(true);
    createNewCustomer(data).then((res) => {
      handleGetAllUsers();
      handleCloseModal();
      setLoading(false);
    }).catch(err=>{
      let errorResponse = get(err,'response.data.message',"error while creating user")
      handleShowSnackPopup({message:errorResponse, error:true});
      setLoading(false);
    })
  };

  // useEffect(() => {
  //   handleGetAllCompines();
  // }, []);

  // const handleGetAllCompines = (obj = {}) => {
  //   getAllComapany(obj).then((res) => {
  //     let data = get(res, "data.data.docs", []);
  //     setCompines(data);
  //   })
  // };
  const handleCreateUser = useCallback(throttle(handleCreateNewCustomer,2500),[])
  return (
    <>
      <Box sx={{ padding: "20px 40px" }}>
        <Box
          sx={{
            width: "400px",
            display: "inline-block",
            minHeight: "280px",
            position: "relative",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              Create {type}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                name="name"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="Name"
                value={userData.name}
                onChange={handleCollectData}
              />
            </Paper>

            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                value={userData.email}
                name="email"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="Work email"
                onChange={handleCollectData}
              />
            </Paper>

            <Select
              defaultValue=""
              placeholder="text"
              sx={{
                
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                borderRadius:"5px",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: "1px solid rgba(0,0,0,0.2)",
              }}
              onChange={handleCollectData}
              value={userData.companyId}
              name="companyId"
              input={
                <InputBase
                  placeholder="company"
                  variant="standard"
                  autoFocus={true}
                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                />
              }
            >
              <MenuItem value="" disabled>
                <em>Company</em>
              </MenuItem>
              {compinesData.map((ele) => (
                <MenuItem value={ele.id}>{ele.name}</MenuItem>
              ))}
            </Select>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "30px",
              position: "absolute",
              bottom: "0px",
              right: "0px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={()=>{
                  handleCreateUser(userData)
                }}
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                {loading ? "Loading..." : "Create"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateNewUser;
