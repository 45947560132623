import React from "react";

const OpenIcon = ({color='#313131', height=21, width=20}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 21"
        fill="none"
      >
        <path
          d="M4.81055 13.6155C5.0731 13.8802 5.42919 14.029 5.8005 14.029H14.2005C14.5718 14.029 14.9279 13.8802 15.1904 13.6155"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.1902 10V2M10.1902 2L13.3902 5.05677M10.1902 2L6.99023 5.05677"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1 18C1.4553 18.6403 2.07281 19 2.71669 19H17.2833C17.9272 19 18.5447 18.6403 19 18"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default OpenIcon;
