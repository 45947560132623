import React from "react";

const TageIcon = ({color="#313131",height="20", width="20"}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M13.6283 7.37707L13.623 7.37704M16.2688 3.37616L11.4351 3.00434C11.0066 2.97138 10.585 3.12732 10.2811 3.43124L2.43123 11.2811C1.85626 11.8561 1.85626 12.7883 2.43123 13.3633L7.63669 18.5688C8.21166 19.1437 9.14389 19.1437 9.71887 18.5688L17.5688 10.7189C17.8727 10.415 18.0286 9.9934 17.9957 9.56486L17.6238 4.73123C17.5681 4.00717 16.9928 3.43186 16.2688 3.37616Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default TageIcon;
