import React from 'react'
import { Box, Chip, Typography } from '@mui/material'
import DesktopComputerIcon from '../../../../assets/icons/DesktopComputerIcon'
import ContactSideBarIcon from '../../../../assets/icons/ContactSideBarIcon'
const botName = process.env.REACT_APP_AUTO_NAME

const ProfileContents = (props) => {
  let {audience,channels,clientId, status,name} = props
  let active = status === 'active';
  return (
  <>
  <Box sx={{border:'1px solid rgba(0,0,0,0.2)'  , borderRadius:'9px', display:'flex', flexDirection:'column', padding:'20px 20px', gap:'15px', cursor:'pointer'}} >
    <Box sx={{display:'flex', gap:'10px', alignItems:'center'}} >  
    <Typography sx={{fontSize:'24px'}} >{name}</Typography>
    <Chip variant='span' label={active ? 'Live' : 'Draft'} sx={{borderRadius:'5px', background:active ? '#D7EFDC' :'#E8E8E8', fontWeight:'600'}} />
    </Box>
    <Box sx={{display:'flex', gap:'10px', alignItems:'center'}} >  
    <Typography sx={{fontSize:'18px'}} >How {botName} should answer</Typography>
    <Chip label='Disabled' sx={{borderRadius:'5px', background:'#E8E8E8', fontWeight:'600'}} />
    </Box>
    <Box sx={{display:'flex', gap:'10px', alignItems:'center'}} >  
    <Typography sx={{fontSize:'16px'}} >Audience</Typography>
    <Chip icon={ <DesktopComputerIcon /> } label={channels.join(' , ')} sx={{borderRadius:'5px', background:'#E8E8E8', fontWeight:'600',paddingLeft:'10px'}} />
    <Chip  icon={<ContactSideBarIcon color={'black'}  />} label={audience.join(" , ")} sx={{borderRadius:'5px', background:'#E8E8E8', fontWeight:'600', paddingLeft:'10px'}} />
    </Box>
  </Box>
  </>  )
}

export default ProfileContents
