import React from "react";
import {
  Box,
  Divider,
  Typography,
} from "@mui/material";
import style from "./notification.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
const checkBoxData = [
  {
    type: "checkbox",
    label: "Desktop",
    key: "desktop",
  },
  {
    type: "checkbox",
    label: "Mobile",
    key: "mobile",
  },
  {
    type: "checkbox",
    label: "Email",
    key: "email",
  },
];


const browserNotificationsCheckBox = [
  {
    type: "checkbox",
    label: "Assigned to you",
    key: "assigned_to_you",
  },
  {
    type: "checkbox",
    label: "Unassigned",
    key: "unassigned",
  },
  {
    type: "checkbox",
    label: "Assigned to any of your team",
    key: "assigned_to_any_of_your_eam",
  },
]

const NotificationComponent = ({ ToolbarComponent }) => {
  return (
    <>
      <MainComponent className={style["details-main-component"]}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>
            {ToolbarComponent && (
              <ToolbarComponent heading={"Your notification preferences"} />
            )}
          </SideToolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "80vh",
            }}
          >
            <Box className="notification-devices">
              <Box  sx={{display:"flex", alignItems:'center', justifyContent:"space-between"}} >
                <Typography className="header">
                  Activity on conversations{" "}
                  <strong>started from messages you sent</strong>
                </Typography>
                <Box className="divices-checkbox">
                  {checkBoxData.map((ele) => (
                    <FormControlLabel
                      className="form-box-for-checkbox"
                      value="end"
                      control={
                        <Checkbox
                          name={ele.key}
                          size="small"
                          sx={{
                            color: "#8F1EB3",
                            "&.Mui-checked": {
                              color: "#8F1EB3",
                            },
                          }}
                        />
                      }
                      label={ele.label}
                      labelPlacement="end"
                    />
                  ))}
                </Box>
              </Box>
              <Box  sx={{display:"flex", alignItems:'center', justifyContent:"space-between"}} >
                <Typography className="header">
                New conversations with 
                  <strong>leads and users you own</strong>
                </Typography>
                <Box className="divices-checkbox">
                  {checkBoxData.map((ele) => (
                    <FormControlLabel
                      className="form-box-for-checkbox"
                      value="end"
                      control={
                        <Checkbox
                          name={ele.key}
                          size="small"
                          sx={{
                            color: "#8F1EB3",
                            "&.Mui-checked": {
                              color: "#8F1EB3",
                            },
                          }}
                        />
                      }
                      label={ele.label}
                      labelPlacement="end"
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Divider />
            <Box  className="browser-notifications" >
              <Typography  className="heading" >
              Browser notifications
              </Typography>
              <Typography  className="sub-heading" >
              Blink your tab when there’s new activity in these conversations. You'll see these changes when you refresh the page.
              </Typography>

              <Box className="notifications-checkbox">
                  {browserNotificationsCheckBox.map((ele) => (
                    <FormControlLabel
                      className="form-box-for-checkbox"
                      value="end"
                      control={
                        <Checkbox
                          name={ele.key}
                          size="small"
                          sx={{
                            color: "#8F1EB3",
                            "&.Mui-checked": {
                              color: "#8F1EB3",
                            },
                          }}
                        />
                      }
                      label={ele.label}
                      labelPlacement="end"
                    />
                  ))}
                </Box>

                <Typography  sx={{
                  fontSize:"13px",
                  color:"gray"
                }} >
                Play sound when your tab blinks
                </Typography>


            </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default NotificationComponent;
