import React, { useContext, useEffect, useState } from "react";
// import MainComponent from '../../../../fixedComponent/sideDashboard/MainComponent'
// import SideToolbar from '../../../../fixedComponent/sideToolbar/SideToolbar'
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  InputBase,
  Paper,
} from "@mui/material";
import style from "./topicComponent.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import ListComponent from "./listComponent/ListComponent";
import {
  createTag,
  createTicketTopic,
  deleteTag,
  deleteTicketTopic,
  getTags,
  getTopics,
  updateTag,
  updateTicketTopicData,
} from "../../../services/Apis";
import { get } from "lodash";
import CreateNewTeams from "./createNew/CreateNewTeams";
import TagIcon from "../../../assets/icons/TagIcon";
import SearchIcon from "../../../assets/icons/SearchIcon";
import SearchBarComponent from "../searchComponent/SearchBarComponent";
import TopicIcon from "../../../assets/icons/TopicIcon";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
import DisplayTagsComponent from "../tags/displayTags/DisplayTagsComponent";
import TopicTab from "./tab/TopicTab";
import { SidebarContext } from "../../../context/SidebarProvider";
let workspaceId = process.env.REACT_APP_WORKSPACE_ID;

const createNewTopic = {
  name:"",
  description:"",
  type:"new",
}

const TopicComponent = ({ ToolbarComponent }) => {
  const {  handleOpenModal } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext);
  const [topics, setTopics] = useState([]);
  const [resetTopicsState, setResetTopicsState] = useState([])
  const [isArchivedTopic, setIsArchivedTopic] = useState(false)

  useEffect(() => {
    handleGetTopics({type:'archived', data:isArchivedTopic});
  }, [isArchivedTopic]);


  const handleResetData = () => {

    setTopics([...resetTopicsState]);
  }

  const handleGetTopics = (obj = {}) => {
    getTopics(obj).then((res) => {
      let responseArr = get(res, "data.data.docs", []);
      setTopics(responseArr);
      setResetTopicsState(responseArr)
    });
  };
  const handleCreateNewTopic = (data) => {
    return new Promise((resolve, reject) => {
      createTicketTopic(data)
        .then((res) => {
          resolve(true);
          handleShowSnackPopup({message:"Topic created successfully"})
          handleGetTopics();
        })
        .catch((err) => {
          let errRes = get(err,'response.data.message',"")
          handleShowSnackPopup({message:errRes,error:true})
          reject(true)
        });
    });
  };
  const handleUpdateTopic = (id, data) => {
    return new Promise((resolve, reject) => {
      updateTicketTopicData(id, data)
        .then(() => {
          resolve(true);
          handleShowSnackPopup({message:"Topic updated successfully"})
          handleGetTopics();
        })
        .catch(() => {
          reject(true);
        });
    });
  };
  const handleCreateTopic = (type, data) => {
    handleOpenModal({
      state: true,
      Component: (
        <CreateNewTeams
          data={{
            type: type,
            data,
            handleCreate:handleCreateNewTopic,
            handleUpdate:handleUpdateTopic,
          }}
        />
      ),
    });
  };

  const handleDeleteTopic = (id) => {
    deleteTicketTopic(id).then((res) => {
      handleShowSnackPopup({message:"Topic archived successfully"})
      handleGetTopics();
    });
  };
  const getFilterTopics = (value) => {
    handleGetTopics({ type: "name", data: value });
  };

  const handleAddNewTopic = () => {
    let tempTopics = [...topics];
    tempTopics.unshift({...createNewTopic});
    setTopics(tempTopics);
}

  return (
    <>
      <MainComponent className={style['topic-component-main-container']}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent heading={'Topics'} />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <SearchBarComponent style={{
              width:"50%",
              height:"35px",
              border:"1px solid rgba(0,0,0,0.1)",
              marginLeft:"30px"
            }} handleCollectValue={getFilterTopics} />{" "}
            <Button
              disabled={isArchivedTopic}
              startIcon={<TopicIcon color="#a84acb" />}
              variant="outlined"
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "35px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
              onClick={handleAddNewTopic}
            >
              Create topic
            </Button>
          </Box>
        
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "0px",
              maxHeight: "80vh",
            }}
          >
             <Box sx={{padding:"10px 50px"}}>
             <TopicTab  count={topics.length} onClick={()=>setIsArchivedTopic(!isArchivedTopic)} />
             </Box>

          <DisplayTagsComponent data={JSON.parse(JSON.stringify(topics))}  setData={(data)=>setTopics(data)} handleCreate={handleCreateNewTopic} handleUpdate={handleUpdateTopic} handleArchive={({id})=>handleDeleteTopic(id)} handleBlur={handleResetData} Icon={<TopicIcon color="#a84acb" height={'18px'}  />} component={"Topic"} isArchivedComponent={isArchivedTopic} />
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default TopicComponent;
