import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import style from "./usersTable.module.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import MainBoxIcon from "../../../../../assets/icons/MainBoxIcon";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import CircularProgress from '@mui/material/CircularProgress';
import Skeleton from '@mui/material/Skeleton';
import { Link } from "react-router-dom";
import { skeletonLoadingData } from "../../../../../utils/constants/chatbotSetting";
// import TablePagination from '@mui/material/TablePagination';

const UsersTable = ({companyUsers, loading=false, filterValue, handleSetBulkActionUser, bulkActionUsers}) => {
  return (
    <Box className={style["users-table-main-component"]}>
      <TableContainer className="table-main-container"    >
        <Table
          sx={{ minWidth: 650 }}
          aria-label="simple table"
          
        >
          <TableHead className="table-header">
            <TableRow className="table-header-row">
              <TableCell
              padding="checkbox"
                className="header-row-items"
              ></TableCell>
              <TableCell className="header-row-items" align="left">
                Name
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Last Seen
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Type
              </TableCell>
              <TableCell className="header-row-items" align="left">
                First Seen
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Signed up
              </TableCell>
              <TableCell className="header-row-items" align="left">
                Web Sessions
              </TableCell>
              <TableCell className="header-row-items" align="left">
                City
              </TableCell>
            </TableRow>
           
          </TableHead>
          <TableBody >
            {loading  ?
             <>
               {skeletonLoadingData.map(ele=>(
                 <TableRow
                 key={ele.id}
                 sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
               >
                   <TableCell   padding="checkbox">
                          <Skeleton variant="rounded" sx={{width:"100%"}} height={20} />
                          {/* hi */}
                         </TableCell>
                 {
                     ele.child.map(ele=>(
                         <TableCell  key={ele}  >
                         <Skeleton variant="rounded" sx={{width:"100%"}}  height={20} />
                         {/* hi */}
                         </TableCell>
                     ))
                 }
               </TableRow>
               ))}
            </>           
              :
               <>
            {companyUsers.map(({    
            email,
            lastSeen="N/A",
            type,
            firstSeen="N/A",
            signupUp="N/A",
            webSessions="N/A",
            city="N/A",
            id,
            name
               }) => (
              <TableRow
                key={email}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell padding="checkbox">
                  <Checkbox
                  checked={bulkActionUsers[id]}
                  onClick={(event)=>{
                    handleSetBulkActionUser(id, event.target.checked)
                  }}
                    sx={{
                      color: "#a84acb",
                      "&.Mui-checked": {
                        color: "#a84acb",
                      },
                    }}
                  />
                </TableCell>
                <TableCell  sx={{display:'flex', gap:"10px", alignItems:"center"}}   >
                <Avatar  sx={{bgcolor:"#E87373",color:"white", height:30, width:30}} >{name[0] ? name[0] : email}</Avatar>
                <Link to={`/contacts/${filterValue}/${id}`} style={{textDecoration:"none"}} >
                <Typography  sx={{fontSize:"14px",cursor:"pointer", color:'gray' ,":hover":{color:"blue", }}} >
                    {            name ? name : email}
                    </Typography>
                </Link>
                {/* <MainBoxIcon height={20} width={20} style={{cursor:"pointer"}} onClick={()=>{}}  /> */}
                </TableCell>
                <TableCell  align="left">
                    <Typography  sx={{fontSize:"14px", color:'gray'}} >
                    {lastSeen}
                    </Typography>
                </TableCell>
                <TableCell  align="left">
                    <Typography  sx={{fontSize:"14px", color:'gray'}} >
                    {type}
                    </Typography>
                </TableCell>
                <TableCell  align="left">
                    <Typography  sx={{fontSize:"14px", color:'gray'}} >
                    {firstSeen}
                    </Typography>
                </TableCell>
                <TableCell  align="left">
                    <Typography  sx={{fontSize:"14px", color:'gray'}} >
                    {signupUp}
                    </Typography>
                </TableCell>
                <TableCell  align="left">
                    <Typography  sx={{fontSize:"14px", color:'gray'}} >
                    {webSessions}
                    </Typography>
                </TableCell>
                <TableCell  align="left">
                    <Typography  sx={{fontSize:"14px", color:'gray'}} >
                    {city}
                    </Typography>
                </TableCell>
              </TableRow>
            ))} </>}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default UsersTable;
