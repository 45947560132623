import React from "react";

const SwitchIcon = ({height=20, width=20}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M15 8.33325L17.5 5.83325M17.5 5.83325L15 3.33325M17.5 5.83325H5.83333M5 11.6666L2.5 14.1666M2.5 14.1666L5 16.6666M2.5 14.1666H14.1667"
          stroke="#313131"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default SwitchIcon;
