import React from "react";

const HorizontalIcon = () => {
  return (
    <>
      <svg width="200" height="3" xmlns="http://www.w3.org/2000/svg">
        <line x1="0" y1="0" x2="20" y2="0" stroke="black" strokeWidth="5" />
      </svg>
    </>
  );
};

export default HorizontalIcon;
