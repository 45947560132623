import React from 'react'

const HocAccordion = (WrappedComponent) => {
  return (
    class searchWithHOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                expand:false,
            };
          }

          handleChange = () => {
            this.setState({expand:!this.state.expand})
          }

          componentDidMount(){
            this.setState({
              expand:this.props.defaultOpen
            })
          }
        render() {
          return (
            <>
              <WrappedComponent
              {...this.props}
              expanded={this.state.expand}
              handleChange={this.handleChange}  />
            </>
          );
        }
    }
  )
}

export default HocAccordion
