import { Box, Button, Paper, Typography, InputBase } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../../../context/MainProvider";
import { SidebarContext } from "../../../../../../context/SidebarProvider";
import CloseIcon from '@mui/icons-material/Close';
import { updateCompanyData } from "../../../../../../services/Apis";
const updateCompanyInformationData = [
  [
    {
    type:"text",
    label:"Name",
    placeholder:"name",
    disabled:true,
    key:"name"
  },
  {
    type:"text",
    label:"Description",
    placeholder:"enter description",
    disabled:false,
    key:"description"
  },
  ],

  [
    {
    type:"number",
    label:"Phone",
    placeholder:"enter phone number",
    disabled:false,
    key:"phone"
  },
  {
    type:"text",
    label:"Industry",
    placeholder:"industry",
    disabled:false,
    key:"industry"
  },
  ],

  [
    {
    type:"number",
    label:"Number of employees",
    placeholder:"number of employees",
    disabled:false,
    key:"numberOfEmployees"
  },
  {
    type:"number",
    label:"Annual revenue",
    placeholder:"annual revenue",
    disabled:false,
    key:"annualRevenue"
  },
  ],

  [
    {
    type:"text",
    label:"Account tier",
    placeholder:"account tier",
    disabled:false,
    key:"accountTier"
  },
  {
    type:"text",
    label:"Address",
    placeholder:"address",
    disabled:false,
    key:"address"
  },
  ],


  [
    {
    type:"text",
    label:"City",
    placeholder:"city",
    disabled:false,
    key:"city"
  },
  {
    type:"text",
    label:"State",
    placeholder:"state",
    disabled:false,
    key:"state"
  },
  ],

  [
    {
    type:"number",
    label:"Zipcode",
    placeholder:"zipcode",
    disabled:false,
    key:"zipcode"
  },
  {
    type:"text",
    label:"Country",
    placeholder:"country",
    disabled:false,
    key:"country"
  },
  ],
]
const UpdateCompany = ({ data:{companyDetails, handleGetCompanyDetails} }) => {
  const { handleCloseModal } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext);
  const [loading, setLoading] = useState(false);

  const [comapnyData, setComapnyData] = useState({
    name: "",
    description: "",
    phone:"",
    numberOfEmployees:0,
    annualRevenue:0,
    websites:[""],
    accountTier:"",
    industry:"",
    address:"",
    city:"",
    state:"",
    zipcode:"",
    country:""
  });

  useEffect(()=>{
    handleFilterKeys(comapnyData,companyDetails)
  },[companyDetails])

  const handleFilterKeys = (modalState, companyDetails) => {
    let newFilterData = {...modalState}
    Object.keys(modalState).forEach(keys=>{
      if(companyDetails.hasOwnProperty(keys)){
        newFilterData[keys] = companyDetails[keys]
      }
    })
    setComapnyData(newFilterData)
  }

  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setComapnyData((state)=>({
      ...state,
      [name]:value
    }))
   
  };

  const handleAddNewLink = () => {
    let tempCompanyData = {...comapnyData}
    tempCompanyData['websites'].push("")
    setComapnyData(tempCompanyData)
  }

  const handleCollectLink = (event,index, type="add") => {
    let tempCompanyData = {...comapnyData}
    if(type==="add"){
      tempCompanyData['websites'][index] = event.target.value;
    }else{
      tempCompanyData['websites'].splice(index,1);
    }
   
    setComapnyData(tempCompanyData)
  }


  const handleUpdateCompanyInformation = () => {
    setLoading(true)
    updateCompanyData(companyDetails.id,comapnyData).then(res=>{
      handleGetCompanyDetails();
      handleShowSnackPopup({
        message:"information update successfully",
      })
      handleCloseModal()
      setLoading(false)
    }).catch(err=>{
      setLoading(false)
      handleShowSnackPopup({
        message:"error while updating ",
        error:true
      })
    })

  }



  return (
    <>
      <Box sx={{ padding: "20px 40px", maxHeight:"100vh", overflow:"scroll" }}>
        <Box
          sx={{
            minWidth: "700px",
            display: "inline-block",
            height: "auto",
            position: "relative",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              Update company information
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              marginTop: "30px",
            }}
          >
            {
              updateCompanyInformationData.map(fieldsArr=>(
                <Box  sx={{display:'flex', gap:"10px"}} >
                {fieldsArr.map(({

                  type,
                  label,
                  placeholder,
                  disabled,
                  key
                }) => (
                  <Box
                  key={key}
                  sx={{ display: "flex", flexDirection: "column", gap: "3px", width:"50%" }}
                >
                  <Typography sx={{ fontSize: "14px" }}>{label}</Typography>
                  <Paper
                    component="box"
                    sx={{
                      p: "1px 2px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: 35,
                      padding: "10px",
                      boxShadow: "none",
                      ":focus": {
                        boxShadow: " 0px 0px 1px 1px #8F1EB3 !important",
                        // border:"none"
                        background: "red",
                      },
                      border: `1px solid ${"rgba(0,0,0,0.2)"}`,
                    }}
                  >
                    <InputBase
                      type={type}
                      disabled={disabled}
                      name={key}
                      sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                      placeholder={placeholder}
                      value={comapnyData[key]}
                      onChange={handleCollectData}
                    />
                  </Paper>
                </Box>
              

                ))}
                </Box>
              ))
            }

            <Box  sx={{display:'flex', gap:"10px"}} >
              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "5px", width:"50%" }}
              >
                <Typography sx={{ fontSize: "14px" }}>websites</Typography>
               {
                comapnyData.websites.map((  ele, index  )=>(
                  <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 35,
                    padding: "10px",
                    boxShadow: "none",
                    ":focus": {
                      boxShadow: " 0px 0px 1px 1px #8F1EB3 !important",
                      background: "red",
                    },
                    border: `1px solid ${"rgba(0,0,0,0.2)"}`,
                  }}
                >
                  <InputBase
                    name="websites"
                    sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                    placeholder="link"
                    value={ele}
                    onChange={(event)=>handleCollectLink(event,index)}
                    endAdornment={<>
                    <CloseIcon sx={{fontSize:"14px", cursor:"pointer", color:"#a84acb"}} onClick={()=>{
                      handleCollectLink({},index,'delete')
                    }} />
                    </>}
                  />
                </Paper>
                ))
               }
                <Box sx={{display:"flex", justifyContent:"flex-end"}} >
                <Button onClick={handleAddNewLink} variant="text" sx={{textTransform:"capitalize", width:"100px", justifySelf:"flex-end",
                background: "#F1F1F1",
                color: "black",
                height: "30px",
                textTransform: "inherit",
                fontSize: "13px",
                }} > Add link</Button>
                </Box>

              </Box>
              
            </Box>
          </Box>




          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleUpdateCompanyInformation}
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                {loading ? "updating..." : "update information"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default UpdateCompany;
