import React, { useEffect, useState } from "react";
import { Box, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import {  Divider, InputAdornment, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import SwitchIcon from "../../../../assets/searchIcons/SwitchIcon";
import { get } from "lodash";
import FilterAltIcon from '@mui/icons-material/FilterAlt';



const TicketFilterMenuList = ({ handleClose, onSelectFilter,customWorkflowData, currentFilterKeys, componentType, advanceFilter  }) => {
    const [ticketAttributes, setTicketAttributes] = useState([]);
    useEffect(()=>{
        let ticketAttributes = get(get(customWorkflowData,'entities').find(({id})=>id== componentType),'attributes',[]);
        setTicketAttributes(ticketAttributes)
    },[])
   const handleSubmitData = (element) => {
    handleClose()
    setTimeout(()=>{
        onSelectFilter(element)
    },100)
   }
   let tempAdvance = advanceFilter.map(ele=>ele.id)
   
  return (
    <Box sx={{ width: "200px", padding: "0px" }} className={"box"}>
      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        {ticketAttributes.map((element) => {
          const labelId = `checkbox-list-secondary-label-${element.id}`;
          let Icon = FilterAltIcon;
          if (tempAdvance.includes(element.id)) return;
          if(currentFilterKeys[element.id]) return;
          return (
            <ListItem key={element} disablePadding   onClick={()=>{
                handleSubmitData(element)
            }}>
              <ListItemButton>
                <ListItemText id={labelId} primary={element.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default TicketFilterMenuList;
