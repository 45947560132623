import { arrayToQueryString } from "../utils/helpers/helpers";
import { HttpClient } from "./HttpClient"

export const LoginApi = (data) => {
    const apiData = {
        method:"POST",
        url:"/auth/login",
        data:data
    }
    return HttpClient.custom(apiData)
}

export const forgetPassword = (data) => {
    const apiData = {
        method:"PUT",
        url:"/auth/forget-password",
        data:data
    }
    return HttpClient.custom(apiData)
}
export const resetPassword = (data) => {
    const apiData = {
        method:"PUT",
        url:"/auth/reset-password",
        data:data
    }
    return HttpClient.custom(apiData)
}

export const PullseTickets = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    const apiData = {
        method:"GET",
        url:`/ticket?${query}`,
    }
    return HttpClient.custom(apiData)
}

export const filterAllTickets = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    // 
    const apiData = {
        method:"GET",
        url:`/ticket?${query}`,
    }
    return HttpClient.custom(apiData)
}



export const createTicket = (data) => {
    const apiData = {
        method:"POST",
        url:`/ticket`,
        data
    }
    return HttpClient.custom(apiData)
}



export const getTicketConversation = (ticketId,page=1) => {
    const apiData = {
        method:"Get",
        url:`/conversation/${ticketId}?sort_order=desc&sort_by=createdAt&page=${page}&limit=10`,
    }   
    return HttpClient.custom(apiData)
}


export const updateTicketData = ({ticketId,updatedData}) => {
    const apiData = {
        method:"PUT",
        url:`/ticket/${ticketId}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}


export const getTicketDetails = ({ticketId}) => {
    const apiData = {
        method:"GET",
        url:`/ticket/${ticketId}`
    }
    return HttpClient.custom(apiData)
}

export const ImportCustomerData = (data) => {
    let formHeaders = {
        headers:{
            'Content-Type': 'multipart/form-data'
        }
    }
    const apiData = {
        method:"POST",
        url:`/customer/import`,
         data,
         ...formHeaders
    }
    return HttpClient.custom(apiData)

}

export const customerBulkActions = (updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/customer/bulk-action`,
        data:updatedData
    }
    return HttpClient.custom(apiData)

}

export const allCustomers = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    let baseUrl = `/customer`
    if(query.length){
        baseUrl += `?${query}`
    }
    const apiData = {
        method:"GET",
        url:baseUrl,
    }
    return HttpClient.custom(apiData)

}

export const createNewCustomer = (data) => {
    const apiData = {
        method:"POST",
        url:`/customer`,
        data:data
    }
    return HttpClient.custom(apiData)
}


export const getAllAgents = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    let url = `/user`
    if(query.length){
        url += `?${query}`
    }
    const apiData = {
        method:'GET',
        url:url
    }
    return HttpClient.custom(apiData)
}

export const createNewAgent = (data) => {
    const apiData = {
        method:'POST',
        url:`/user`,
        data:data
    }
    return HttpClient.custom(apiData)
}

export const deleteAgent = (id) => {
    const apiData = {
        method:'DELETE',
        url:`/user/${id}`,
    }
    return HttpClient.custom(apiData)
}
// teams 
export const getAllTeams = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    let url = `/team`
    if(query.length){
        url += `?${query}`
    }
    const apiData = {
        method:'GET',
        url:url
    }
    return HttpClient.custom(apiData)

}

export const  updateTeam = (id,updatedData) => {
    const apiData = {
        method:'PUT',
        url:`/team/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}

export const  deleteTeam = (id) => {
    const apiData = {
        method:'DELETE',
        url:`/team/${id}`,
    }
    return HttpClient.custom(apiData)
}
export const  createTeam = (data) => {
    const apiData = {
        method:'POST',
        url:`/team`,
        data:data
    }
    return HttpClient.custom(apiData)
}

// tags 
export const createTag = (data) => {
    const apiData = {
        method:'POST',
        url:`/tag`,
        data:data
    }
    return HttpClient.custom(apiData)
}
export const getTags = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    let url = `/tag`
    if(query.length){
        url += `?${query}`
    }
    const apiData = {
        method:'GET',
        url:url
    }
    return HttpClient.custom(apiData)
}

export const updateTag = (id,data) => {
    const apiData = {
        method:'PUT',
        url:`/tag/${id}`,
        data:data
    }
    return HttpClient.custom(apiData)
}

export const deleteTag = (id) => {
    const apiData = {
        method:'DELETE',
        url:`/tag/${id}`,
    }
    return HttpClient.custom(apiData)
}



export const updateCustomerDetails = (id,updatedData) => {
    const apiData = {
        method:'PUT',
        url:`/customer/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}

export const getCustomerDetails = (id) => {
      const apiData = {
        method:'GET',
        url:`/customer/${id}`,
    }
    return HttpClient.custom(apiData)
}

export const getProfile = () => {
    const apiData = {
        method:'GET',
        url:`/user/profile`
    }
    return HttpClient.custom(apiData)
}

export const agentProfile = () => {
    const apiData = {
        method:'GET',
        url:`/profile`
    }
    return HttpClient.custom(apiData)
}

export const updateAgenttProfile = (data) => {
    const apiData = {
        method:'PUT',
        url:`/profile/update`,
        data:data
    }
    return HttpClient.custom(apiData)
}

export const changeAgnentPassword = (data) => {
    const apiData = {
        method:'PATCH',
        url:`/profile/change-password`,
        data:data
    }
    return HttpClient.custom(apiData)
}

export const updateTicketTag = ({ticketId,updatedData}) => {
    const apiData = {
        method:'PUT',
        url:`/ticket/${ticketId}/tag`,
        data:updatedData
    }
    return HttpClient.custom(apiData)

}

export const updateTicketTopic = ({ticketId,updatedData}) => {
    const apiData = {
        method:'PUT',
        url:`/ticket/${ticketId}/topic`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}


// ticket-types 
export const getTicketType = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    let url = `/ticket-type`
    if(query.length){
        url += `?${query}`
    }
    const apiData = {
        method:'GET',
        url:url
    }
    return HttpClient.custom(apiData)
}

export const getTicketTypeDetails = (id) => {
    const apiData = {
        method:'GET',
        url:`/ticket-type/${id}`,
    }
    return HttpClient.custom(apiData)
}


export const createTicketType = (data) => {
    const apiData = {
        method:'POST',
        url:`/ticket-type`,
        data:data
    }
    return HttpClient.custom(apiData)
}

export const updateTicketType = (id, updatedData) => {
    const apiData = {
        method:'PUT',
        url:`/ticket-type/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)

}

export const deleteTicketType = (id) => {
    const apiData = {
        method:'DELETE',
        url:`/ticket-type/${id}`,
    }
    return HttpClient.custom(apiData)

}

// ticket topics 

export const getTopics = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    let url = `/ticket-topic`
    if(query.length){
        url += `?${query}`
    }
    const apiData = {
        method:'GET',
        url:url
    }
    return HttpClient.custom(apiData)
}

export const createTicketTopic = (data) => {
    const apiData = {
        method:'POST',
        url:`/ticket-topic`,
        data:data
    }
    return HttpClient.custom(apiData)

}

export const updateTicketTopicData = (id,updatedData) => {
    const apiData = {
        method:'PUT',
        url:`/ticket-topic/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
    
}


export const deleteTicketTopic = (id) => {
    const apiData = {
        method:'DELETE',
        url:`/ticket-topic/${id}`,
    }
    return HttpClient.custom(apiData)
}


export const createChatbotProfile = (data) => {
    const apiData = {
        method:"POST",
        url:`/chatbot-profile`,
        data
    }
    return HttpClient.custom(apiData)

}
export const updateChatbotProfile = (id,updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/chatbot-profile/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)

}

export const getChatbotProfile = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    let url = `/chatbot-profile`
    if(query.length){
        url += `?${query}`
    }
    const apiData = {
        method:'GET',
        url:url
    }
    return HttpClient.custom(apiData)

}


export const getChatbotProfileDetails = (id) => {
    const apiData = {
        method:"GET",
        url:`/chatbot-profile/${id}`
    }
    return HttpClient.custom(apiData)
}

export const uploadChatbotDocsFile = (data,type) => {
    let formHeaders = {
        headers:{
            'Content-Type': 'multipart/form-data'
            
        }
    }
    if(type !== 'form'){
        formHeaders = {}
    }
    const apiData = {
        method:"POST",
        url:`/chatbot-document`,
         data,
         ...formHeaders
        
    }
    return HttpClient.custom(apiData)
}

export const getChatBotDocuments = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    let url = `/chatbot-document`;
    if(query.length){
        url += `?${query}`
    }
    const apiData = {
        method:"GET",
        url:url,        
    }
    return HttpClient.custom(apiData)
}

export const chatbotBulkActionsApi = (data) => {
    const apiData = {
        method:"PUT",
        url:`/chatbot-document/bulk-action`,
        data:data
    }
    return HttpClient.custom(apiData)
}




// chatbot-end 

export const createNewCompany = (data) => {
    const apiData = {
        method:"POST",
        url:`/company`,
        data:data
    }
    return HttpClient.custom(apiData)
}

export const getAllComapany = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    const apiData = {
        method:"GET",
        url:`/company?${query}`,
    }
    return HttpClient.custom(apiData)
}

export const getCompanyDetails = (companyId) => {
    const apiData = {
        method:"GET",
        url:`/company/${companyId}`,
    }
    return HttpClient.custom(apiData)
}


export const updateCompanyData = (id,updatedData) => {
    const apiData = {
        method:'PUT',
        url:`/company/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)

}

export const deleteCompany = (id) => {
    const apiData = {
        method:'DELETE',
        url:`/company/${id}`,
    }
    return HttpClient.custom(apiData)
}


// custom fields 
export const setValueUsingCustomFields = (id,data) => {
    const apiData = {
        method:"PATCH",
        url:`/custom-field/${id}/set-value`,
        data:data
    }
    return HttpClient.custom(apiData)
}

export const getAllCustomFields = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    const apiData = {
        method:"GET",
        url:`/custom-field?${query}`,
    }
    return HttpClient.custom(apiData)
}

export const createCustomFields = (data) => {
    const apiData = {
        method:"POST",
        url:`/custom-field`,
        data:data
    }
    return HttpClient.custom(apiData)
}

export const getCustomeFields = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    const apiData = {
        method:"GET",
        url:`/custom-field?${query}`,
    }
    return HttpClient.custom(apiData)

}

export const deleteCustomFields = (id) => {
    const apiData = {
        method:"DELETE",
        url:`/custom-field/${id}`,
    }
    return HttpClient.custom(apiData)
}


export const getCustomFieldDetails = (id) => {
    const apiData = {
        method:"GET",
        url:`/custom-field/${id}`,
    }
    return HttpClient.custom(apiData)
}

export const updateCustomField = (id,updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/custom-field/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}

export const workflowEntityFields = () => {
    const apiData = {
        method:"GET",
        url:`/workflow/entity`,
    }
    return HttpClient.custom(apiData)
    
}

// custom workflow start

export const allWorkflow = (...allQuery) => {
    let query = arrayToQueryString(allQuery)
    console.log(query,"queryy111",allQuery)
    let url = `/workflow`
    if(query.length){
        url += `?${query}`
    }
    const apiData = {
        method:"GET",
        url:url,
    }
    return HttpClient.custom(apiData)
}


export const createWorkflow = (data) => {
    const apiData = {
        method:"POST",
        url:`/workflow`,
        data
    }
    return HttpClient.custom(apiData)
}

export const workflowDetails = (id) => {
    const apiData = {
        method:"GET",
        url:`/workflow/${id}`,
    }
    return HttpClient.custom(apiData)
}

export const updateWorkflowDetails = (id,updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/workflow/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}
// custom workflow end


// chatbot setting 
export const chatbotSettingApi = () => {
    const apiData = {
        method:"GET",
        url:`/setting/chatbot`,
    }
    return HttpClient.custom(apiData)
}

export const updateChatbotSetting = (updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/setting/chatbot`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}

// sentiment settings 

export const updateSentimentSetting = (updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/setting/sentiment`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}
export const sentimentSettings = () => {
    const apiData = {
        method:"GET",
        url:`/setting/sentiment`,
    }
    return HttpClient.custom(apiData)

}

// canned response 
export const allMacrosResponseApi = () => {
    const apiData = {
        method:"GET",
        url:`/canned-response`,
    }
    return HttpClient.custom(apiData)
}
export const createMacros = (data) => {
    const apiData = {
        method:"POST",
        url:`/canned-response`,
        data:data
    }
    return HttpClient.custom(apiData)

}

export const updateMacro = (id,updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/canned-response/${id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData)
}

export const deleteMacro = (id) => {
    const apiData = {
        method:"DELETE",
        url:`/canned-response/${id}`,
    }
    return HttpClient.custom(apiData)
}

export const macroDetails = (id) => {
    const apiData = {
        method:"GET",
        url:`/canned-response/${id}`,
    }
    return HttpClient.custom(apiData)
}

// canned response end 



// start permission 

export const agentRoles = () => {
    const apiData = {
        method:"GET",
        url:`/permission`,
    }
    return HttpClient.custom(apiData)
}


// qa settings 

export const getQaSettings = () => {
    const apiData = {
        method:"GET",
        url:`/setting/quality-assurance`,
    }
    return HttpClient.custom(apiData)
}

export const updateQaSettings = (updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/setting/quality-assurance`,
        data:updatedData,
    }
    return HttpClient.custom(apiData)

}

// reports api

export const reportList = () => {
    const apiData = {
        method:"GET",
        url:`/report`,
    }
    return HttpClient.custom(apiData)

}
export const renderReports = (reportId="") => {
    const apiData = {
        method:"GET",
        url:`/report/retrieved/${reportId}`,
    }
    return HttpClient.custom(apiData)

}


// workspace apis 

export const workspaceDetailApi = (workspaceId) => {
    const apiData = {
        method:"GET",
        url:`/workspace/${workspaceId}`,
    }
    return HttpClient.custom(apiData)

}


// custom support email apis 

export const createCustomSupportEmail = (data) => {
    const apiData = {
        method:"POST",
        url:`/custom-support-email`,
        data:data
    }
    return HttpClient.custom(apiData);
}


export const customSupportEmailList = () => {
    const apiData = {
        method:"GET",
        url:`/custom-support-email`,
    }
    return HttpClient.custom(apiData);
}


export const customSupportEmailDetail = (supportEmailId) => {
    const apiData = {
        method:"GET",
        url:`/custom-support-email/${supportEmailId}`,
    }
    return HttpClient.custom(apiData);
}


export const logoutApi = (data={}) => {
    const apiData = {
        method:"POST",
        url:`/auth/logout`,
        data:data
    }
    return HttpClient.custom(apiData);

}

// email-domain 
export const emailDomainApi = (data={}) => {
    const apiData = {
        method:"POST",
        url:`/email-domain`,
        data:data
    }
    return HttpClient.custom(apiData);
    
}

export const allEmailDomains = () => {
    const apiData = {
        method:"GET",
        url:`/email-domain`,
    }
    return HttpClient.custom(apiData)
}


// event-workflow
export const eventWorkflow = () => {
    const apiData = {
        method:"GET",
        url:`/event-workflow`,
    }
    return HttpClient.custom(apiData)

}


export const workflowEvents = () => {

    const apiData = {
        method:"GET",
        url:`/workflow/events`,
    }

    return HttpClient.custom(apiData)
}

export const createEventWorkflow = (data) => {

    const apiData = {
        method:"POST",
        url:`/event-workflow`,
        data:data
    }

    return HttpClient.custom(apiData)
}

export const eventWorkflowDetails = (event_workflow_id) => {
    
    const apiData = {
        method:"GET",
        url:`/event-workflow/${event_workflow_id}`,
    }

    return HttpClient.custom(apiData)
}

export const updateEventWorkflow = (event_workflow_id, updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/event-workflow/${event_workflow_id}`,
        data:updatedData
    }

    return HttpClient.custom(apiData)
}

// email-template 

export const emailTempletes = () => {
    const apiData = {
        method:"GET",
        url:`/email-template`,
    }
    return HttpClient.custom(apiData);
}

export const createTemplete = (data) => {
    const apiData = {
        method:"POST",
        url:`/email-template`,
        data:data
    }
    return HttpClient.custom(apiData);

}

export const emailTempleteDetails = (email_template_id) => {
    const apiData = {
        method:"GET",
        url:`/email-template/${email_template_id}`,
    }
    return HttpClient.custom(apiData);
}


export const updateEmailTemplete = (email_template_id, updatedData) => {
    const apiData = {
        method:"PUT",
        url:`/email-template/${email_template_id}`,
        data:updatedData
    }
    return HttpClient.custom(apiData);
}