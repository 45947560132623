import React, { useContext } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { Box, Toolbar, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { MainContext } from "../../../context/MainProvider";
const FilterToolbar = ({filtersKey,setFilterKeys}) => {
  const { handleFetchFilterTickets } = useContext(MainContext)
  const handleClearFilters = () => {
    setFilterKeys({})
    handleFetchFilterTickets({type:'name',data:""})
  }
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
        }}
      >
        <Paper
          component="div"
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: 40,
            padding: "10px",
            boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
          }}
        >
          <InputBase
            placeholder="Search"
            sx={{ ml: 1, flex: 1 }}
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon sx={{ color: "gray" }} />
              </InputAdornment>
            }
          />
        </Paper>
      </Box>
      {
        Object.keys(filtersKey).length ? 
        <Box>
        <Typography
          onClick={handleClearFilters}
          sx={{
            color: "black",
            fontWeight: "bold",
            cursor: "pointer",
          }}
        >
          clear
        </Typography>
      </Box>
      : null
      }
    
    </>
  );
};

export default FilterToolbar;
