import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Divider, InputAdornment, InputBase, Paper, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { MainContext } from "../../../context/MainProvider";
import { getTopics } from "../../../services/Apis";
import { get } from "lodash";
import { debounceWithImmediate } from "../../../utils/helpers/helpers";

const data = [
];

const TopicMenu = ({handleClose, onSelectFilter, selectedVal}) => {
  const [topic, setTopics] = useState([]);
  const [inputVal, setInputVal] = useState("")
  const { allTopics } = useContext(MainContext)
  useEffect(()=>{
    setTopics(allTopics)
  },[allTopics])


  const getTopicsData = (value) => {
    getTopics({type:'name',data:value}).then(res=>{
      let valArr = get(res,'data.data.docs',[])
      setTopics(valArr);
    })
  }

  const handleCollectData = (event) => {
    setInputVal(event.target.value)
    handleDebounce(event.target.value)
  }


  const handleFetchTeams = (value) => {
    getTopicsData(value)
  }
  const handleDebounce = useCallback(debounceWithImmediate(handleFetchTeams,()=>{},700),[])
  return (
    <Box sx={{ width: "300px", padding: "0px" }} className={"box"}>
      <Paper
        component="box"
        className="padding"
        sx={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: 40,
          padding: "10px",
          boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
        }}
      >
        <InputBase
          className=""
          placeholder="Search"
          value={inputVal}
          onChange={handleCollectData}
          sx={{ flex: 1, padding: 0 }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "gray" }} />
            </InputAdornment>
          }
        />
      </Paper>
      <Divider />

      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", maxHeight:'500px', overflowY:'scroll', overflowX:'hidden', padding:'10px 0px 0px 0px'  }}
      >
        {!topic.length && <>
        <Box sx={{display:'flex', justifyContent:'center', }} >
       <Typography variant="span" sx={{color:'gray', fontSize:'13px'}} >  No result found</Typography>
        </Box>
        </>}
        {topic.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          const selected = selectedVal=== value.id

          return (
            <ListItem key={value} disablePadding  sx={{background:selected && '#c166e2', color:selected && 'white'}} onClick={()=>{
              onSelectFilter(value)
              handleClose()

            }} >
              <ListItemButton>
                <ListItemText id={labelId} primary={value.name} sx={{'.MuiListItemText-secondary':{color:selected && 'white'}}}  secondary={value.description} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default TopicMenu;
