import {  Box, CssBaseline, Paper, ThemeProvider, Typography, createTheme } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { LoginApi } from "../../../services/Apis";
import { decryptBase64, encryptBase64, getCookie, handleSetToken, isEmail, setCookie, setWorkspaceId } from "../../../utils/helpers/helpers";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LoadingButton from '@mui/lab/LoadingButton';
import { HttpClient } from "../../../services/HttpClient";
import { SidebarContext } from "../../../context/SidebarProvider";
import { get } from "lodash";
import brandIcon from "../../../assets/Logo.png"
import login2Img from "../../../assets/login3.jpg"
import { Image } from "@mui/icons-material";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://www.pullse.ai/"   >
      Pullse AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function Login2() {
    const { handleShowSnackPopup } = useContext(SidebarContext);
    const [loginLoading,setLoginLoading] = useState(false)
    const [globalError, setGlobalError] = useState(false)
    const [loginData, setLoginData] = useState({
      username: "",
      password: "",
      recaptchaId: "",
    });
    const [onFormFocus, setOnFormFocus ] = useState(false) 
    let navigate = useNavigate();
    const location = useLocation();
  
    const handleCollectValue = (e) => {
      let name = e.target.name,
        val = e.target.value;
      setLoginData((state) => ({
        ...state,
        [name]: val,
      }));
    };
  
    useEffect(()=>{
      let encryptedEmail = getCookie('agent_email')
      let email = get(location.state,'email',"");
      let password = get(location.state,'password',"");
      setLoginData((state)=>({
        ...state,
        username:email,
        password
      }))
      if(!email){
        let encryptedEmail = getCookie('agent_email')
        let email  = decryptBase64(encryptedEmail);
        setLoginData((state)=>({
          ...state,
          username:email
        }))
  
      }
    },[])
    const validateLoginData = () => {
      if(!isEmail(loginData.username) || !(loginData.password.length > 5))
      {
        setGlobalError(true)
        return true
      }
      return false
    }
  
    const handleSubmitresult = async () => {
      let isError  = validateLoginData(loginData)
      if(isError) return;
      try {
        setLoginLoading(true)
        let response = await LoginApi(loginData);
        let email = loginData.username;
        let encryptedEmail = encryptBase64(email);
        setCookie('agent_email',encryptedEmail)
        setLoginLoading(false)
  
        handleSetToken(response.data.data.accessToken.token);
        setWorkspaceId(get(response,`data.data.defaultWorkspaceId`,""))
        handleShowSnackPopup({ message: "Login Success" });
        
        HttpClient.setAxiosDefaultConfig()
        navigate("/inbox/your-inbox");
        
      } catch (error) {
        handleShowSnackPopup({ message: "Invalid Credentials", error: true });
        setLoginLoading(false)
      }
    };
  
    const onHandleSubmit = (event) => {
      if(event.keyCode == 13 && onFormFocus){
        handleSubmitresult()
      }
    }
  
    let usernameError = globalError && !isEmail(loginData.username)
    let passwordError = globalError && !(loginData.password.length > 5)

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            // position:'relative'
          
            // background: `rgb(2,0,36)`,
            //     background: `linear-gradient(40deg, rgba(2,0,36,1) 13%, rgba(168,74,203,1) 52%)`,
               
           
          }}
        > 
        <Typography  sx={{fontWeight:'100',position:'absolute',top:'50%', left:'25%', zIndex:'11', color:'white', fontSize:"60px", transform:'translate(-50%,-50%)'}} > Welcome to <b> Pullse AI </b></Typography>
        <Typography  sx={{fontWeight:'100',position:'absolute',top:'60%', left:'25%', zIndex:'11', color:'white', fontSize:"20px", transform:'translate(-45%,-50%)', textAlign:'center'}} > Empower Your Customer Support with AI Innovation. Streamline <br />  Workflows, Enhance  Agent Performance, and Delight <br /> Customers with Pullse AI's Advanced Platform.</Typography>
        <img src={login2Img} style={{
          backgroundClip:'border-box',
          //  backgroundClip:'content-box',
          //  backgroundPosition:'center',
          //  backgroundRepeat:'no-repeat',
           width:'150%'
        }} />
        {/* <img src={login2Img}  /> */}

        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={8} square>
          <Box
            sx={{
              my: 12,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={brandIcon} />
            <Typography component="h1" variant="h5"  sx={{marginTop:"10px"}} >
              Sign in
            </Typography>
            <Box component="div"  sx={{ mt: 1 }}>
              <TextField
                error={usernameError}
                value={loginData.username}
                margin="normal"
                required
                fullWidth
                onChange={handleCollectValue}
                id="email"
                label="Email Address"
                name="username"
                autoComplete="email"
                autoFocus
                onFocus={()=>setOnFormFocus(true)}
                onBlur={()=>setOnFormFocus(false)}
                onKeyUp={onHandleSubmit}
              />
              <TextField
                error={passwordError}
                value={loginData.password}
                margin="normal"
                required
                onChange={handleCollectValue}
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onFocus={()=>setOnFormFocus(true)}
                onBlur={()=>setOnFormFocus(false)}
                 onKeyUp={onHandleSubmit}
                  />

                <LoadingButton
                  loading={loginLoading}
                  onClick={handleSubmitresult}
                  sx={{
                    width: "100%",
                    border: "1px solid #B9B9B9",
                    color: "#3E1599",   
                    marginTop:"10px"
                  }}
                  variant="outlined"
                  loadingIndicator="Loading…"
                >
                  Sign in
                </LoadingButton>

                <Typography  onClick={()=>{
                  navigate("forget-password")
                }}  sx={{textAlign:"right", cursor:"pointer", marginTop:"10px", color:"blue", fontSize:"13px", textDecoration:"underline"}} >Forget password</Typography>


              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}