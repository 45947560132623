import React, { useContext } from "react";
import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import OpenIcon from "../../../assets/searchIcons/OpenIcon";
import ClosedIcon from "../../../assets/searchIcons/ClosedIcon";
import SnoozedIcon from "../../../assets/searchIcons/SnoozedIcon";
import { MainContext } from "../../../context/MainProvider";

const data = [
  {
    name: "All",
    color: "#E89D73",
    Icon: OpenIcon,
    key:'all',
    value:'all'
  },
];
const AllFilterMenu = ({ handleClose , onSelectFilter, selectedVal}) => {
  return (
    <Box sx={{ width: "60px", padding: "0px" }} className={"box"}>
      <List
        dense
        
        
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", padding:'0' }}
      >
        {data.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          let selected = selectedVal === value.value

          return (
            <ListItem key={value} disablePadding  className="check"   sx={{background:selected && '#c166e2', color:selected && 'white'}} onClick={()=>{
              onSelectFilter(value);
              handleClose()
            }}>
              <ListItemButton>
                
                <ListItemText id={labelId} primary={value.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default AllFilterMenu;
