import React from "react";

const CopyPlusIcon = ({color='green', height=16, width=16, ...props}) => {
  return (
    <>
      <svg
        {...props}
        class="interface-icon o__standard o__standard__duplicate o__by-text"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill={color} d="M11 13.5C11 14.3284 10.3284 15 9.5 15H2.5C1.67157 15 1 14.3284 1 13.5L1 6.5C1 5.67157 1.67157 5 2.5 5H3L3 11.5C3 12.3284 3.67157 13 4.5 13L11 13V13.5Z"></path>
        <path
          fill={color} 
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M15 10C15 10.8284 14.3284 11.5 13.5 11.5L6 11.5C5.17157 11.5 4.5 10.8284 4.5 10L4.5 2.5C4.5 1.67157 5.17157 1 6 1L13.5 1C14.3284 1 15 1.67157 15 2.5L15 10ZM9.75 9.5C9.33579 9.5 9 9.16421 9 8.75L9 7L7.25 7C6.83579 7 6.5 6.66421 6.5 6.25C6.5 5.83579 6.83579 5.5 7.25 5.5L9 5.5V3.75C9 3.33579 9.33579 3 9.75 3C10.1642 3 10.5 3.33579 10.5 3.75V5.5H12.25C12.6642 5.5 13 5.83579 13 6.25C13 6.66421 12.6642 7 12.25 7H10.5L10.5 8.75C10.5 9.16421 10.1642 9.5 9.75 9.5Z"
        ></path>
      </svg>
    </>
  );
};

export default CopyPlusIcon;
