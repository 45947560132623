import axios from "axios"
import { deleteCookie, getCookie, getWorkspaceId, handleLogout } from "../utils/helpers/helpers";
import { get } from "lodash";
// axios.defaults.baseURL = 'http:http://localhost:5000/api/v1';
const setAxiosDefaultConfig = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.defaults.headers.common['Authorization'] =`Bearer ${getCookie("customerToken")}`
    axios.defaults.headers.post['Content-Type'] = 'application/json';
    // axios.defaults.withCredentials = true;
}
let workSpaceId = process.env.REACT_APP_WORKSPACE_ID;
axios.interceptors.request.use(function (config) {
  
  if (!config.url.includes('workspace_id')) {
    // Add workspace_id to the URL
    const separator = config.url.includes('?') ? '&' : '?';
    config.url += `${separator}workspace_id=${getWorkspaceId()}`;
  }
  
  return config;

  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Do something with response data
  return response;
}, function (error) {
  // 
  
  let errorResponse = get(error,'response.data.code',"");
  
  if(errorResponse === "UNAUTHORIZED"){
    handleLogout()
  }else{
    return Promise.reject(error);
  }
  
});
const LLm_service_instance = {
  baseURL: process.env.REACT_APP_LLM_SERVICE,
  headers: {'Content-Type': 'application/json'}
}
const llmService = axios.create(LLm_service_instance)

const llmCustom = (config) => {
  return llmService(config)
}
const custom = (config={},timeOut=2000) => {
    return axios(config)
}

export const HttpClient = {
    setAxiosDefaultConfig,
    custom,
    llmCustom
}