import React, { useContext, useEffect, useState } from 'react'
// import MainComponent from '../../../../fixedComponent/sideDashboard/MainComponent'
// import SideToolbar from '../../../../fixedComponent/sideToolbar/SideToolbar'
import { Box, Button, Divider} from '@mui/material'
import style from "./officeHours.module.scss"
import MainComponent from '../../../fixedComponent/sideDashboard/MainComponent'
import SideToolbar from '../../../fixedComponent/sideToolbar/SideToolbar'
import { MainContext } from '../../../context/MainProvider'
import SelectTimeComponent from './select-time/SelectTimeComponent'
const OfficeHoursComponent = ({ToolbarComponent}) => {
  const { handleOpenModal } = useContext(MainContext);


  const handleSelectTime = () => {
    handleOpenModal({
      state:true,
      Component:<SelectTimeComponent />
    })

  }
  return (
   <>
     <MainComponent className={style.allUsersBodyMainComponent} >
       <Box  sx={{padding:'0px 10px',  height:'95vh', background:'white !important'}} >
       <SideToolbar>
          {ToolbarComponent && (
            <ToolbarComponent   />
          )}
        </SideToolbar>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            gap: "10px",
            maxHeight:'88vh',

          }}
        >
          <Box sx={{display:"flex", justifyContent:"flex-end", marginTop:"10px"}} >  
          <Button
                varient="text"
                sx={{
                  textTransform: "capitalize",
                  background: `linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)`,
                  color: "white",
                }}
                onClick={handleSelectTime}
                className="create-data-modelling-btn"
              >
                {" "}
                + Create attributes
              </Button>
          </Box>

          
        </Box>
       </Box>
      </MainComponent>
   
   </>
  )
}

export default OfficeHoursComponent
