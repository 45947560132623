import { Box, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./email-setting.module.scss"
import { MainContext } from "../../../context/MainProvider";
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { get } from "lodash";
const EmailSettingToolbar = ({}) => {
  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
      <Box className="toolbarContent">
          <Typography  sx={{fontSize:"20px", fontWeight:"bold"}} > Email Setting</Typography>
        </Box>
      </Box>
    </>
  );
};

export default EmailSettingToolbar;
