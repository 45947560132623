import { Box, Typography } from "@mui/material";
import React from "react";
import Grid from "@mui/material/Grid";
import LoginRaw from "../design/LoginRaw";
import img from "../../../assets/image (2) 1 1.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

const Welcome = () => {
  return (
    <>
      <Box
        sx={{
          flexGrow: 1,
          maxHeight: "100vh",
          overflow: "hidden",
          marginTop: "0px",
        }}
      >
        <Grid container spacing={3} sx={{ marginTop: "0px" }}>
          <Grid item xs={5}>
            <LoginRaw />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
              height: "100vh",
            }}
          >
            <Box sx={{ marginTop: "100px" }}>
              <Box>
                {" "}
                <img src={img} style={{ height: "80px" }} />
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", gap: "40px" }}
              >
                <Box sx={{ width: "789px" }}>
                  <Typography
                    variant="h2"
                    sx={{ fontWeight: "100", color: "#313131" }}
                  >
                    Welcome.
                  </Typography>
                </Box>

                <Box sx={{ width: "789px" }}>
                  <TextField
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "80%" }}
                    placeholder=" email"
                  />
                </Box>

                <Box sx={{ width: "789px" }}>
                  <Button
                    sx={{
                      width: "80%",
                      border: "1px solid #B9B9B9",
                      color: "#3E1599",
                      textTransform: "unset",
                    }}
                    variant="outlined"
                  >
                    Sign in with SAML SSO
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Welcome;
