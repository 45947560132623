import React,{ useContext, useEffect, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
} from "@mui/material";
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import "./insatallation.css"
import { copyToClipboard } from "../../../../utils/helpers/helpers";
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";




const InstallationComponent = () => {
    const { currentWorkspaceDetail } = useContext(MainContext)
    const [copyState, setCopyState] = useState(false)
    useEffect(() => {
        Prism.highlightAll();
      }, []);

     

      let codePayload = `
      <script>
      (function () {
          let doc = document.createElement("div")
          doc.id = "pullse-chatbot"
          document.body.appendChild(doc);

          let style = document.createElement("link")
          style.href = "https://dev-chat-widget.pullseai.com/static/css/main.864f1962.css"
          style.rel="stylesheet"
          document.head.appendChild(style);

          let script = document.createElement("script")
          script.src = "https://dev-chat-widget.pullseai.com/static/js/main.0f977b92.js"
          document.body.appendChild(script);
          setTimeout(() => {
              window.initilizePullse({ token:"${get(currentWorkspaceDetail,'clientToken','testing')}"});
          }, 1000);
      })();
   </script>
      ` 
      const handleCopyData = () => {
        setCopyState(true)
        copyToClipboard(codePayload)
        setTimeout(()=>{
            setCopyState(false)
        },3000)
      }

    
  return (
    <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "40px",
            padding: "50px",
          }}
        >
             <div>
                <div style={{
                      borderRadius:"10px",
                      padding:"0px",
                      margin:'0px',
                      fontSize:"12px",
                      position:"relative",
                }}>

<span onClick={handleCopyData}  style={{
                position:"absolute",
                top:'5px',
                right:'10px',
                display:'inline-block',
                // height:'20px',
                // width:'50px',
                border:"1px solid #a84acb",
                padding:'3px 10px',
                margin:'0',
                borderRadius:"5px",
                cursor:"pointer",
                display:"flex",
                justifyContent:'center',
                alignItems:'center',
                color:"white"


            }} >
                {copyState ? "Copied" :"Copy"}
            </span>
            <pre  sx={{
                margin:"0",
                padding:"0",
                borderRadius:"10px"
            }} >
           
            <code className="language-javascript">
            {codePayload}
        </code>
      </pre>
      </div>
    </div>
      
        </Box>
      </AccordionDetails>
  )
}

export default InstallationComponent
