import React, { useContext, useEffect, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
} from "@mui/material";
import EmailBoxIcon from "../../../../assets/icons/EmailBoxIcon";
import UserIcon from "../../../../assets/searchIcons/UserIcon";
import PhoneNumberIcon from "../../../../assets/icons/PhoneNumberIcon";
import { get } from "lodash";
import FormSubmissonEditor from "../form-submission-editor/FormSubmissonEditor";
import PullseInputBox from "../../../commonComponent/pullse-input-box/PullseInputBox";
import AntSwitch from "../../../commonComponent/switch/AntiSwitch";
import PullseAntiSwitch from "../../../commonComponent/switch/PullseAntiSwitch";
import FormTabs from "../form-tabs/FormTabs";
import { extractInputNamesFromHTML, handleMakeFormForChatWidget } from "../../../../utils/helpers/helpers";

let defaultValue = {
  delta: [
    {
      insert: "Thanks for reaching out ",
    },
    {
      insert: {
        mention: {
          index: "0",
          denotationChar: "",
          id: "{{firstName}}",
          value: "{{firstName}}",
        },
      },
    },
    {
      insert: " \nhow may i help you\n",
    },
  ],
  value: `<p>Thanks for reaching out <span class="mention" data-index="0" data-denotation-char="" data-id="{{firstName}}" data-value="{{firstName}}"> <span contenteditable="false">{{firstName}}</span> </span> </p><p>how may i help you</p>`,
};



const formSelectInitialState = [
  {
    key:"firstName",
    Icon:UserIcon,
    labelName:'First Name'
  },
  {
    key:"lastName",
    Icon:UserIcon,
    labelName:'Last Name'
  },
  {
    key:"email",
    Icon:EmailBoxIcon,
    labelName:'Email'
  },
  {
    key:"mobile",
    Icon:PhoneNumberIcon,
    labelName:'Phone'
  }
]

const ContextAboutUpfront = ({ data, handleUpdateChatbotSetting }) => {
  const [formFields, setFormFields] = useState({
    firstName: true,
    lastName: false,
    email: false,
    mobile: false,
  });
  const [formBuildingState, setFormBuildingState] = useState({
    state: false,
    data: {
      preMessages: [],
      forms: [],
      afterFormSubmitted:{}
    },
  });

  const [isAuthenticated, setIsAuthenticated] = useState("authenticated");


  useEffect(()=>{
    setFormBuildingState(JSON.parse(JSON.stringify(data)))
    if(get(data,'state',false)){
      setIsAuthenticated("unAuthenticated")
    }
    if(Object.values(get(data,'data.forms[1]',{})).length){
      let tempMessgaes = get(data,'data.forms[1].message',<></>);
      let tempObj = {}
      
      let formFields = extractInputNamesFromHTML(tempMessgaes)
      formFields.forEach(key=>{
        tempObj[key] = true
      })
      setFormFields((state)=>({
        ...state,
        ...tempObj
      }))
    }
  },[data])

  const handleSelectFormField = (name) => {
    setFormFields((state) => ({
      ...state,
      [name]: !state[name],
    }));
  };
  // useEffect(() => {
  //   setFormBuildingState(data);
  // }, [data]);




  const handleCollectData  = (event) => {
    let tempData = {...formBuildingState};
    tempData.data.forms[0] = {
      "userType":"agent",
      "type":"text",
      "message":event.target.value
    }
    setFormBuildingState(tempData)
  }

  const handleCollectAfterFormSubmission = (value, delta, source, editor) => {
     let tempData  = {
      "userType":"agent",
      "type":"html",
      "message":value,
      delta:editor.getContents()
    }
    setFormBuildingState((state)=>({
      ...state,
      data:{
        ...state.data,
        afterFormSubmitted:tempData
      }
    }))
  }


  const handleCollectPreMessage = (value, delta, source, editor) => {
    let tempData = {...formBuildingState};
    tempData.data.preMessages[0] = {
      "userType":"agent",
      "type":"html",
      "message":value,
      delta:editor.getContents()
    }
    setFormBuildingState(tempData)
  }


  const handleSaveData = () => {
    let tempPayload = {
      ...formBuildingState,
      state:isAuthenticated === "authenticated" ? false : true,
    }
    let filterKeys = Object.keys(formFields).filter(key=>formFields[key]);
    let tempFormPayload = handleMakeFormForChatWidget(filterKeys);
    tempPayload['data']['forms'][1] = {
      "userType":"agent",
      "type":"html",
      "message":tempFormPayload,
    }
    delete tempPayload['data']['afterFormSubmitted']['delta']
    tempPayload['data']['preMessages'].forEach(ele=>{
      delete ele['delta']
    })
    handleUpdateChatbotSetting({
      formBuilding:{
        ...tempPayload,
      }
    })

  }  

  
  return (
    <>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "0px 20px",
          }}
        >
          <FormTabs handleCurrentTab={setIsAuthenticated} currentValue={isAuthenticated} />
          {isAuthenticated === "authenticated" ? (
            <>
              <Box sx={{ padding: "10px 0px" }}>
                <FormSubmissonEditor
                key={'#112local'}
                  value={get(formBuildingState,'data.preMessages[0]',{delta:{}})}
                  defaultValue={defaultValue}
                  onChange={handleCollectPreMessage}
                  placeholder="Welcome message write here ... "
                  isAuthenticated={true}
                />
              </Box>
            </>
          ) : (
            <>
              <Box sx={{ padding: "10px 0px" }}>
                <PullseInputBox
                 onChange={handleCollectData}
                  value={get(formBuildingState,'data.forms[0].message',"")}
                  inputProps={{
                    autoFocus: true,
                  }}
                  placeholder="Welcome message before submitting the form..."
                />
              </Box>
              <Typography sx={{ fontSize: "14px" }}>
                For faster resolutions and automated triaging, collect specific
                data from customers upfront.
              </Typography>
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                  Build a form with the data you’d like to collect
                </Typography>

                <Stack direction="row" spacing={1}  sx={{padding:"10px 0px"}} >
                  {
                  formSelectInitialState.map(({key,labelName,Icon})=>(
                  <Chip
                  key={key}
                  size="small"
                  onClick={()=>handleSelectFormField(key)}
                    sx={{
                      padding: "10px",
                      borderRadius: "10px",
                      cursor: "pointer",
                      background: formFields[key] && " #a84acb",
                      color: formFields[key] && "white",
                      ":hover":{
                        background:" #a84acb",
                        color: "white",
                      }
                    
                    }}
                    icon={
                      <Icon
                        color={formFields[key] ? "white" : "black"}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: "13px" }}>
                        {" "}
                       {labelName}
                      </Typography>
                    }
                  />))
                  }
                </Stack>

                <Box>
                  <FormSubmissonEditor  key={'#11'} isAuthenticated={false} value={get(formBuildingState,'data.afterFormSubmitted',{delta:{}})} onChange={handleCollectAfterFormSubmission} placeholder="Enter message after form submission  like thanks for submitting form {{form.firstName}}" />
                </Box>
              </Box>
            </>
          )}
          <Box sx={{display:'flex', justifyContent:"flex-end",gap:"10px"}} >
            <Button
            onClick={handleSaveData}
             sx={{
              background: "#f1f1fb",
              color: "#707c97",
              height: "26px",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined">Save</Button>
          </Box>
        </Box>
      </AccordionDetails>
    </>
  );
};

export default ContextAboutUpfront;
