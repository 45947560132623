import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import style from "./accountDetail.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { useNavigate } from "react-router-dom";
import { MainContext } from "../../../context/MainProvider";
import { get } from "lodash";
import { updateAgenttProfile } from "../../../services/Apis";
import { SidebarContext } from "../../../context/SidebarProvider";
import { throttle } from "../../../utils/helpers/helpers";
import UploadCommonComponent from "../../commonComponent/upload-hoc-component/UploadCommonComponent";

const AccountDetailComponent = ({ ToolbarComponent }) => {
  const navigate = useNavigate()
  const {profile, handleGetAgentProfile} = useContext(MainContext)
  const {handleShowSnackPopup} = useContext(SidebarContext)
  const [profileData, setProfileData] = useState({
    first_name:"",
    last_name:""
  })
  useEffect(()=>{
    setProfileData({
      first_name:get(profile,'fName',""),
      last_name:get(profile,'lName',"")
    })
  },[profile])

  const handleCollectData = (event) => {
    let {name, value} = event.target;
    setProfileData((state)=>({
      ...state,
      [name]:value
    }))
  }

  const updateprofileData = (data) => {
    updateAgenttProfile(data).then(res=>{
      handleShowSnackPopup({
        message:"profile updated successfull",
        time:3000
      })
      handleGetAgentProfile()
    }).catch(err=>{
      let errorMsg = get(err,'response.data.message',"")
      handleShowSnackPopup({
        message:errorMsg,
        error:true,
        time:3000
      })
    })
  }

  const handleUpdateProfile = useCallback(throttle(updateprofileData,2000),[]) 
  return (
    <>
      <MainComponent className={style["details-main-component"]}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>
            {ToolbarComponent && <ToolbarComponent heading={"Details"} />}
          </SideToolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "80vh",
            }}
          >
            <Box className="detail-header">
              {/* <Button className="setup-two-factor">
                Setup two factor authentication
              </Button> */}
              <Button className="change-password" variant="text" sx={{}}
              onClick={()=>{
                navigate('change-password')

              }}
              >
                Change password
              </Button>
            </Box>


           
   

            <Box className="detail-com-form-box">
              <Box  className="input-box-component" >
                <Typography  className="input-label" >First Name</Typography>
                <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 30,
                    padding: "10px",
                    boxShadow: "none",
                    border: `1px solid rgba(0,0,0,0.2) `,
                  }}
                >
                  <InputBase
                    onChange={handleCollectData}
                    value={profileData.first_name}
                    className="input-box"
                    type="email"
                    name="first_name"
                    sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                    placeholder="Enter First Name"
                  />
                </Paper>
              </Box>

              <Box  className="input-box-component" >
                <Typography  className="input-label" >Last Name</Typography>
                <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 30,
                    padding: "10px",
                    boxShadow: "none",
                    border: `1px solid rgba(0,0,0,0.2) `,
                  }}
                >
                  <InputBase
                    onChange={handleCollectData}
                    value={profileData.last_name}
                     className="input-box"
                    type="text"
                    name="last_name"
                    sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                    placeholder="Enter Last Name"
                  />
                </Paper>
              </Box>


              <Box  className="input-box-component" >
                <Typography  className="input-label" >Email</Typography>
                <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 30,
                    padding: "10px",
                    boxShadow: "none",
                    border: `1px solid rgba(0,0,0,0.2) `,
                  }}
                >
                  <InputBase
                    // onChange={handleCollectData}
                    disabled
                    value={profile.email}
                     className="input-box"
                    type="email"
                    name="email"
                    sx={{ ml: 1, flex: 1, fontSize: "14px", pointerEvents:"none" }}
                    placeholder="Enter Last Name"
                  />
                </Paper>
              </Box>

              <Box className="input-action-component" >
              <Button onClick={()=>handleUpdateProfile(profileData)} className="save-changes" variant="text" sx={{}}>
              Save changes
              </Button>
              </Box>
            </Box>
            <Divider  sx={{marginTop:"20px"}} />
            <Box className="profile-picture-upload">
              <Typography  className="heading" >Photo</Typography>
              <Box  className="image-component" >

              </Box>
              <UploadCommonComponent>

             
              <Button className="upload-image" variant="text" sx={{}}>
              Upload a new photo
              </Button>
              </UploadCommonComponent>
              <Typography   className="content" >
              Photo will be shown to your users when you send them messages or reply to them. Max photo size 1 MB.
              </Typography>

            </Box>

          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default AccountDetailComponent;
