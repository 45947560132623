import React from "react";
import { Box, Typography } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import SwitchIcon from "../../../assets/searchIcons/SwitchIcon";
import {  Divider, InputAdornment, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const data = [
  {
    display:'Language',
    name: "Language",
    color: "#E89D73",
    Icon: SwitchIcon,
    key:'language'
  },
  {
    display:'External ID',
    name: "External ID",
    color: "#E89D73",
    Icon: SwitchIcon,
    key:'external_id'
  },
  // {
  //   name: "AI Chatbot: Preview",
  //   color: "#738DE8",
  //   Icon: SwitchIcon,
  // },
  // {
  //   name: "Workflow: Priview",
  //   color: "#738DE8",
  //   Icon: SwitchIcon,
  // },
  // {
  //   name: "Ticket Type",
  //   color: "#738DE8",
  //   Icon: SwitchIcon,
  // },
];
const FilterMenu = ({ handleClose, onSelectFilter, selectedVal=[] }) => {
  return (
    <Box sx={{ width: "300px", padding: "0px" }} className={"box"}>
          <Paper
        component="form"
        className="padding"
        sx={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: 40,
          padding: "10px",
          boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
        }}
      >
        <InputBase
          className=""
          placeholder="Search"
          sx={{ flex: 1, padding: 0 }}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "gray" }} />
            </InputAdornment>
          }
        />
      </Paper>
      <Divider />
        <Typography sx={{textAlign:'center', color:'gray', padding:'10px', fontSize:'16px' }}  >
        Conversation Data Attributes
        </Typography>
      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
      >
        {data.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          let Icon = value.Icon;
          return (
            <ListItem key={value} disablePadding   onClick={()=>{
              onSelectFilter({
                display:value.display,
                key:value.key,
                name:value.name
              })
              handleClose()
            }}>
              <ListItemButton>
                <ListItemAvatar sx={{ display: "flex" }}>
                  <Icon />
                </ListItemAvatar>
                <ListItemText id={labelId} primary={value.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default FilterMenu;
