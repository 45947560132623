import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.REACT_APP_ENV === 'production') {
    
    console.error = () => {}
    console.debug = () => {}
}


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render( <App />);



reportWebVitals();
