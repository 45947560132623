import React, { useEffect, useRef } from "react";
import MenuBarCommonHoc from "./MenuBarCommonHoc";
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Box, TextField } from "@mui/material";
import Chip from '@mui/material/Chip';
import FaceIcon from '@mui/icons-material/Face';
import cssStyle from "./menubarCommon.module.scss"
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const MenuBarCommon = ({open,handleClose,handleClick, anchorEl, Icon, MenuComponent, label, style,onSelectFilter, selected, isDeleted=false, handleDelete=()=>{},defaultOpen=false,deleteIconStyle={}, onCloseWithoutValue=()=>{}, uniqueKey={},parentStyle={}}) => {
  const ref = useRef(null)
  useEffect(()=>{
    if(defaultOpen && ref){
        handleClick({currentTarget:ref.current})
    }
  },[ref])
  return (
    <>
      <Box
       className={cssStyle.menubarCommonMainClass}
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        ref={ref}
        sx={{cursor:'pointer',...parentStyle}}
      >
          <Chip className={selected ? `${cssStyle.filterSelected}` : cssStyle.filterUnSelected } icon={ Icon && <Icon />} 
          label={label} sx={{borderRadius:'5px', background:'white', padding:'2px 4px',...style}} onDelete={isDeleted ? handleDelete : undefined} deleteIcon={<HighlightOffIcon fontSize="14px"  className="deletedIconClass" style={{color:'white',fontSize:'18px', ...deleteIconStyle}}
          
          sx={{
            height: 'auto',
            '& .MuiChip-label': {
              display: 'block',
              whiteSpace: 'normal',
            },
          }}
          />}  />
        {/* Dashboard */}
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={(props)=>{
          handleClose(props)
          onCloseWithoutValue(props,uniqueKey)
        }}
        MenuListProps={{
          "aria-labelledby": "basic-buttonsss",
          style:{
            padding:'0px'
          }
        }}
      >
        <MenuComponent handleClose={handleClose}  onSelectFilter={onSelectFilter} selectedVal={selected}/>
      </Menu>
    </>
  );
};

export default MenuBarCommonHoc(MenuBarCommon);
