import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  StepContent,
  Typography,
} from "@mui/material";
import CannedResponseEditor from "../../../editors/cannedResponseEditor/CannedResponseEditor";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { MainContext } from "../../../../context/MainProvider";
import LoadingButton from '@mui/lab/LoadingButton';
import { macroDetails } from "../../../../services/Apis";
import { get } from "lodash";
import { convertDeltaToPlainText, convertHtmlToDelta, convertPalntextToDelta } from "../../../../utils/helpers/helpers";
import { SidebarContext } from "../../../../context/SidebarProvider";
const CreateCannedResponse = ({componentType}) => {
  const navigate = useNavigate();
  const   {   handleCreateMacro, handleUpdateMacro, } = useContext(MainContext)
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const [macroData, setMacroData] = useState({
      name:"",
      description:"",
      message:<></>,
      delta:{}
  })
  let {id: macroId} = useParams()

  const [loading, setLoading] = useState(false)


  const handleSetEditorValue = (value, delta, source, editor) => {
    setMacroData((state)=>({
      ...state,
      message:value,
      delta:editor.getContents()
    }))
    // 
  }

  useEffect(()=>{
    if(componentType === "update" && macroId){
        handleFetchMacroDetail()
    }
  },[componentType])
  const handleFetchMacroDetail = () => {
    macroDetails(macroId).then(res=>{
        let response = get(res,'data.data',{});
        // let delta = convertHtmlToDelta(get(response,'message',""))
        let newDelts = convertPalntextToDelta(get(response,'message',""))
        let delta = {
            // convertPalntextToDelta
            // convertzDeltaToPlainText
            
        }
        setMacroData((state)=>({
            ...state,
            name:get(response,'name',""),
            description:get(response,'description',""),
            message:get(response,'message',""),
            delta:newDelts
        }))
    })
  }

  const handleChange = (event) => {
    let { name, value } = event.target;
    setMacroData((state)=>({
      ...state,
      [name]:value
    }))
      
  }
  const handleCreateNewMacro = () => {
    let payload = {...macroData}
    let newMessage = convertDeltaToPlainText(payload.delta)
    delete payload['delta'];
    payload.message = newMessage;
    setLoading(true)
    if(componentType==="update"){
        handleUpdateMacro(macroId,payload).then(res=>{
          handleShowSnackPopup({message:"Canned response update successfully"})
            setLoading(false)
        }).catch(err=>{
          let errResponse = get(err,'response.data.message',"")
          handleShowSnackPopup({message:errResponse,error:true})
        })
    }else{
        handleCreateMacro(payload).then(res=>{
          handleShowSnackPopup({message:"Canned response created successfully"})
            setLoading(false)
           navigate("/settings/macros");
        }).catch(err=>{
          let errResponse = get(err,'response.data.message',"")
          handleShowSnackPopup({message:errResponse,error:true})
        })
    }
    }
   

    

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: "10px",
          maxHeight: "88vh",
          height: "88vh",
        }}
      >
        <Box
          sx={{
            marginTop: "50px",
            padding: "10px 100px ",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowBackIosIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // handleSetActionView('view')
                  navigate("/settings/macros");
                }}
              />
              Go back
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>Title</Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid rgba(0,0,0,0.1) `,
              }}
            >
              <InputBase
                name="name"
                value={macroData.name}
                onChange={handleChange}
                className="input-box"
                type="text"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="Enter Title"
                // onChange={handleCollectData}
              />
            </Paper>
          </Box>


          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>Description</Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid rgba(0,0,0,0.1) `,
              }}
            >
              <InputBase
                 onChange={handleChange}
                 value={macroData.description}
                className="input-box"
                type="text"
                name="description"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="Enter Title"
                // onChange={handleCollectData}
              />
            </Paper>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px" }}>
              {" "}
              <Typography sx={{ fontSize: "14px" }}>Body</Typography>
            </Box>
            <Box>
              <CannedResponseEditor onChange={handleSetEditorValue}
            placeholder={'hi {{ticket.name}}'}
            value={{
                delta:macroData.delta
            }} />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              maxWidth: "40%",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={() => {navigate("/settings/macros");}}
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                border: "none",
                ":hover": { borderColor: "none" },
              }}
              variant="outlined"
            >
              {" "}
              Cancel
            </Button>
            <LoadingButton
             loading={loading}
             onClick={handleCreateNewMacro}
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
              variant="outlined"
            >
            {componentType === "update" ? "Update" :"Save"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateCannedResponse;
