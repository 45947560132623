import React from "react";

const AlphabetIcon = ({height=16, width=16,color='black', ...props}) => {
  return (
    <>
      <svg
        { ...props}
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path  fill={color} d="M1.33081 11H2.90081L3.54081 9.42995H6.50081L7.14081 11H8.75081L5.75081 3.94995H4.33081L1.33081 11ZM4.09081 8.06995L5.02081 5.79995L5.95081 8.06995H4.09081Z"></path>
        <path  fill={color} d="M11.1912 11.1C11.9312 11.1 12.4412 10.83 12.8112 10.42V11H14.2812V7.88995C14.2812 7.16995 14.1012 6.57995 13.7012 6.17995C13.3212 5.79995 12.7212 5.57995 11.8912 5.57995C10.9812 5.57995 10.4012 5.74995 9.8112 6.00995L10.1912 7.16995C10.6812 6.98995 11.0912 6.87995 11.6712 6.87995C12.4312 6.87995 12.8212 7.22995 12.8212 7.85995V7.94995C12.4412 7.81995 12.0512 7.72995 11.5112 7.72995C10.2412 7.72995 9.3512 8.26995 9.3512 9.43995C9.3512 10.52 10.1812 11.1 11.1912 11.1ZM11.6512 10.05C11.1612 10.05 10.8212 9.80995 10.8212 9.39995C10.8212 8.89995 11.2212 8.63995 11.8712 8.63995C12.2412 8.63995 12.5812 8.71995 12.8412 8.83995V9.10995C12.8412 9.65995 12.3612 10.05 11.6512 10.05Z"></path>
      </svg>
    </>
  );
};

export default AlphabetIcon;
