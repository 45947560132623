import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./topicComponent.module.scss";

const TopicComponentToolbar = ({heading}) => {

  return (
    <>
      <Box className={style['topic-toolbar-main-container']}>
        <Box className="toolbarContent">
          <Typography sx={{fontWeight:"bold", fontSize:"22px"}} >{heading}</Typography>

        </Box>
      </Box>
    </>
  );
};

export default TopicComponentToolbar;
