import {
  AccordionDetails,
  Box,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./handoverWorkflow.module.scss";
import HelpIcon from "@mui/icons-material/Help";
import { LightTooltip } from "../../../../../commonComponent/tooltip/LightTooltip";
import CommonButton from "../answerBehave/answerMultipleQuestions/CommonButton";
import AntSwitch from "../../../../../commonComponent/switch/AntiSwitch";
import MiniEditor from "../../../../../commonComponent/mini-editor/MiniEditor";
import { get } from "lodash";
// import IntroduceMessageTab from "./introduceMessageTab/IntroduceMessageTab";

let botName = process.env.REACT_APP_AUTO_NAME;

const viewKeys = [
  "multiple_questions",
  "multiple_questions_teammate",
  "only_answer",
];
const HandoverWorkflowBody = ({data}) => {
  const [btnVal, setBtnVal] = useState("English (EN) default");
  const [handoverMessage, setHandoverMessage] = useState({value:<></>, delta:{}})

let {newProfileState, handleCollectData} = data
  const handleCollectHanoverMessage = (value, delta, source, editor) => {
    let handoverData = {
      value,
      delta:editor.getContents()
    }
    setHandoverMessage(handoverData)
  }
  useEffect(()=>{
    let message = get(newProfileState,'handoverMessages',[]);
    if(message.length){
      let mess = {
        value:message[0],
      }
      setHandoverMessage(mess);
    }
  },[])

  useEffect(()=>{
    let value = [handoverMessage.value];
    handleCollectData("handoverMessages",value)

  },[handoverMessage])

  
  return (
    <>
      <Divider />
      <AccordionDetails
        className={style["handover-workflow-body-main-container"]}
      >
        <Box className="body-content-container">
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography className="sub-header">
              When the customer asks to talk to the team, {botName} will route them to
              the team. If this handover is enabled, {botName} can provide additional
              help, such as walking them through a flow to collect information
              before handing over the conversation, saving time and effort for
              teammates.
            </Typography>
          </Box>
          <Box>
            <Box  sx={{display:'flex', gap:'20px'}} >
              <CommonButton
                buttonValue={btnVal}
                menuListItems={["English (EN) default"]}
                handleCollectData={(e) => setBtnVal(e)}
              />
              <Box  sx={{display:'flex',gap:'10px', alignItems:'flex-start'}} >
                <Box sx={{marginTop:"4px"}} ><AntSwitch /></Box>
                <Box>
                  <Typography  sx={{fontSize:'14px'}} >Enable this language</Typography>
                  <Typography  sx={{fontSize:'13px'}} >
                    When disabled, customers are routed to the team right away.
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box  sx={{padding:"50px"}} >
            <MiniEditor  value={handoverMessage}  onChange={(value, delta, source, editor)=>{handleCollectHanoverMessage(value, delta, source, editor)}} />
            </Box>
            {/* <IntroduceMessageTab /> */}
           
          </Box>
        </Box>
      </AccordionDetails>
    </>
  );
};

export default HandoverWorkflowBody;
