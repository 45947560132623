import React from 'react'

const MenuBarCommonHoc = (WrappedComponent) => {
  return (
    class searchWithHOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                anchorEl:null,
            };
          }
           handleClick = (event) => {
            this.setState({anchorEl:event.currentTarget})
          };
          handleClose = () => {
            this.setState({anchorEl:null})
          };

          // componentDidMount(){
          //   if(this.props.defaultOpen){
          //     this.setState({anchorEl:true})
          //   }
          // }
           
        render() {
          return (
            <>
                <WrappedComponent
                {...this.props}
                handleClick={this.handleClick}
                handleClose={this.handleClose}
                anchorEl={this.state.anchorEl}
                open={Boolean(this.state.anchorEl)}  />
            </>
          );
        }
    }
  )
}

export default MenuBarCommonHoc
