import React from "react";

const CalenderIcon = ({height=16,width=16, color='black',...props}) => {
  return (
    <>
      <svg
         {...props}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path fill={color} d="M5.5 8C5.5 8.55229 5.05228 9 4.5 9C3.94772 9 3.5 8.55229 3.5 8C3.5 7.44772 3.94772 7 4.5 7C5.05228 7 5.5 7.44772 5.5 8Z"></path>
        <path fill={color} d="M10.5 9C11.0523 9 11.5 8.55229 11.5 8C11.5 7.44772 11.0523 7 10.5 7C9.94771 7 9.5 7.44772 9.5 8C9.5 8.55229 9.94771 9 10.5 9Z"></path>
        <path fill={color} d="M8.5 8C8.5 8.55229 8.05229 9 7.5 9C6.94772 9 6.5 8.55229 6.5 8C6.5 7.44772 6.94772 7 7.5 7C8.05229 7 8.5 7.44772 8.5 8Z"></path>
        <path fill={color} d="M4.5 12C5.05228 12 5.5 11.5523 5.5 11C5.5 10.4477 5.05228 10 4.5 10C3.94772 10 3.5 10.4477 3.5 11C3.5 11.5523 3.94772 12 4.5 12Z"></path>
        <path fill={color} d="M8.5 11C8.5 11.5523 8.05229 12 7.5 12C6.94772 12 6.5 11.5523 6.5 11C6.5 10.4477 6.94772 10 7.5 10C8.05229 10 8.5 10.4477 8.5 11Z"></path>
        <path fill={color} d="M10.5 12C11.0523 12 11.5 11.5523 11.5 11C11.5 10.4477 11.0523 10 10.5 10C9.94771 10 9.5 10.4477 9.5 11C9.5 11.5523 9.94771 12 10.5 12Z"></path>
        <path
        fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2.5 1.5C2.5 0.947715 2.94772 0.5 3.5 0.5C4.05228 0.5 4.5 0.947715 4.5 1.5V2H10.5V1.5C10.5 0.947715 10.9477 0.5 11.5 0.5C12.0523 0.5 12.5 0.947715 12.5 1.5V2C13.3284 2 14 2.67157 14 3.5V13C14 14.1046 13.1046 15 12 15H3C1.89543 15 1 14.1046 1 13V3.5C1 2.67157 1.67157 2 2.5 2V1.5ZM2.5 7C2.5 6.44772 2.94772 6 3.5 6H11.5C12.0523 6 12.5 6.44772 12.5 7V12.5C12.5 13.0523 12.0523 13.5 11.5 13.5H3.5C2.94772 13.5 2.5 13.0523 2.5 12.5V7Z"
        ></path>
      </svg>
    </>
  );
};

export default CalenderIcon;
