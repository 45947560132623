import { Box, Typography, Button } from "@mui/material";
import React from "react";
import PluseIcon from "../../../assets/searchIcons/PluseIcon";
import { useNavigate } from "react-router-dom";
const WorkflowComponentToolbar = () => {
  const navigate = useNavigate()
  return (
    <>
      <Box sx={{width:"100%"}} >
      <Box className="toolbarContent" sx={{display:"flex", justifyContent:'space-between',alignItems:'center', width:"100%"}} >
          <Typography  sx={{fontSize:"20px", fontWeight:"bold"}} > Workflows</Typography> 
          <Button
            // onClick={handleCreateTicketTypes}
            onClick={()=>{
              navigate('add')
            }}
            startIcon={
              <PluseIcon color="#a84acb" />

            }
              variant="outlined"
              sx={{
                background: "#f1f1fb",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
            >
           Add new workflow
            </Button>
      </Box>
    
      </Box>
    </>
  );

};

export default WorkflowComponentToolbar;
