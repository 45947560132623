import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Popover,
  Select,
  StepContent,
  Tooltip,
  Typography,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
import moment from "moment";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { copyToClipboard } from "../../../../utils/helpers/helpers";
const DisplayEmailSettingResponse = ({customEmailSettingListData}) => {
  const { currentWorkspaceDetail } = useContext(MainContext)
  const navigate = useNavigate();
  const [copiedData, setCopiedData] = useState(false);

  const handleCopyData = (email) => {
    setCopiedData(true)
    copyToClipboard(email)
    setTimeout(()=>{
      setCopiedData(false)
    },2000)
  }


  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: "10px",
          maxHeight: "88vh",
          height: "88vh",
        }}
      >
          <Box  sx={{display:"flex", justifyContent:"flex-end"}} >
            <Box  sx={{display:"flex",  gap:'10px', padding:"10px", alignItems:"center"}}>
              <Typography sx={{fontSize:"13px", fontWeight:"bold"}} >

              Forwarding email 
              </Typography>

              <Typography sx={{fontSize:"13px", textDecoration:"underline", color:'#a84acb'}}>
                {get(currentWorkspaceDetail,'email',"")}
              </Typography>
          {copiedData ?   <TaskAltIcon sx={{fontSize:"14px", color:'green'}} /> :  <ContentPasteIcon sx={{fontSize:"14px", cursor:"pointer", color:'green'}}  onClick={()=>handleCopyData(get(currentWorkspaceDetail,'email',""))}/> 
       
          }
            </Box>
            
            
            </Box>
        <Box sx={{padding:"30px 50px"}}>
        
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "400", fontSize: "30px" }}>
          Response
          </Typography>
          <Button
            variant="outlined"
            sx={{
              textTransform: "inherit",
              height: "35px",
            }}
            onClick={() => {
                navigate('create')
            }}
          >
            Add Email settings
          </Button>
        </Box>

      <Box  sx={{marginTop:"20px"}} >
      <TableContainer component={Paper}  sx={{boxShadow:"none" }}>
      <Table sx={{ minWidth: 650, }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell  sx={{fontWeight:"bold"}}>Name</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Email	</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}} >Date created</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Last updated	</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Available for</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Usage (last 7 days) </TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Action </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {customEmailSettingListData.map((element) => {
            let createdData  = moment(get(element,'createdAt',''));
            return (
            <TableRow
              key={get(element,'id','')}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link to={get(element,'id','')}>
                {get(element,'name','')}
                </Link>
              </TableCell>
              <TableCell align="right"> { element.email}</TableCell>
              <TableCell align="right">{createdData.isValid()?  createdData.fromNow() : ""}</TableCell>

              <TableCell align="right">
              <Tooltip title="Delete macro" placement="top">
                <DeleteOutlineIcon sx={{color:"red", cursor:"pointer"}} onClick={()=>{}} />
               </Tooltip>             
                </TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>
          

        </Box>
      </Box>
    </>
  );
};

export default DisplayEmailSettingResponse;
