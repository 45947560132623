import { InputBase, Paper } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./pullse-input-box.scss"

const PullseInputBox = ({value,name="", placeholder="",type="text", error=false, onChange, parentProps={},inputProps}) => {
    const [inputPayload, setInputPayload] = useState({});

    const handleChangeVisibility = (key, value) => {
        setInputPayload((state)=>({
            ...state,
            [key]:value
        }))
    }

    useEffect(()=>{
        handleChangeVisibility('error',error)
    },[error])

  const makeClasseForInputBox = Object.keys(inputPayload).filter(key=>inputPayload[key]).join(' ');
  return (
    <>
      <Paper
        className={`pullseInputBoxContainer ${makeClasseForInputBox}`}
        component="box"
       {...parentProps}
      >
        <InputBase
        value={value}
        className="pullse-input-box"
        label="Outlined"
        onMouseEnter={ ()=> handleChangeVisibility('mouseEnterd', true) }
        onMouseLeave={ ()=> handleChangeVisibility('mouseEnterd', false)}
        onFocus={() => { handleChangeVisibility('focused', true)}}
        onBlur={() => { handleChangeVisibility('focused', false) }}
        onChange={onChange}
        name={name}
        type={type}
        placeholder={placeholder}
        {...inputProps}
        />
      </Paper>
    </>
  );
};

export default PullseInputBox;
