import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  StepContent,
  Typography,
} from "@mui/material";
import style from "./sentiments.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import { get } from "lodash";
import { MainContext } from "../../../context/MainProvider";
import { updateSentimentSetting } from "../../../services/Apis";
import { SidebarContext } from "../../../context/SidebarProvider";

const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  );

  let initialDataLocation = {
    first: {
      from: 0,
      to: 20,
    },
    second: {
      from: 21,
      to: 40,
    },
    third: {
      from: 41,
      to: 60,
    },
    fourth: {
      from: 61,
      to: 80,
    },
    fifth: {
      from: 81,
      to: 100,
    },
  }

const steps = [
  {
    label: "Very Dissatisfied",
    key: "first",
  },
  {
    label: "Dissatisfied",
    key: "second",
  },
  {
    label: "Neutral",
    key: "third",
  },
  {
    label: "Satisfied",
    key: "fourth",
  },
  {
    label: "Very Satisfied",
    key: "fifth",
  },
];

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: "transparent",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  // borderRadius: '50%',
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage: "",
    // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
    // boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage: "",
    // 'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: (
      <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: "40px" }} />
    ),
    2: <SentimentDissatisfiedIcon color="error" sx={{ fontSize: "40px" }} />,
    3: <SentimentSatisfiedIcon color="warning" sx={{ fontSize: "40px" }} />,
    4: <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: "40px" }} />,
    5: <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: "40px" }} />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}
const ColorlibConnector = styled(StepConnector)(({ theme }) => {
  return {
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "#fff",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        background: "#fff",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 3,
      border: 0,
      backgroundColor: "#fff",
      borderRadius: 1,
    },
  };
});

const SentimentsComponent = ({ ToolbarComponent }) => {
  const {handleGetSentimentSettings,
    allSentimentSettings} = useContext(MainContext)
    const { handleShowSnackPopup} = useContext(SidebarContext)
  const [sentimentSteps, setSentimentSteps] = useState(JSON.parse(JSON.stringify([...steps])  ));
  const [lableEditable, setLableEditable] = useState(-1);
  const [dataLocation, setDataLocation] = useState({
   ...initialDataLocation
  });

  const handleResetData = () => {
    let initialSteps = [...steps]
    let dataLocation = {...initialDataLocation}
    handleUpdateSentimentData(dataLocation,initialSteps,'reset')
  };

  const currenSteps = Object.values(dataLocation).filter(({ from, to }) => {
    if (from && to) {
      return true;
    }
    return false;
  });

  const handleCollectRangeData = (event, key) => {
    let value = event.target.value;
    if (isNaN(value)) {
      return;
    }
    let newValue = Number(value);

    let tempData = { ...dataLocation };
    if (key === "first") {
      tempData["first"].to = newValue;
      tempData["second"].from = newValue !== 100 ? newValue + 1 : "";
      tempData["second"].to = "";

      tempData["third"].from = "";
      tempData["third"].to = "";

      tempData["fourth"].from = "";
      tempData["fourth"].to = "";

      tempData["fifth"].from = "";
      tempData["fifth"].to = "";
    } else if (key === "second") {
      tempData["second"].to = newValue;

      tempData["third"].from = newValue !== 100 ? newValue + 1 : "";
      tempData["third"].to = "";

      tempData["fourth"].from = "";
      tempData["fourth"].to = "";

      tempData["fifth"].from = "";
      tempData["fifth"].to = "";
    } else if (key === "third") {
      tempData["third"].to = newValue;

      tempData["fourth"].from = newValue !== 100 ? newValue + 1 : "";
      tempData["fourth"].to = "";

      tempData["fifth"].from = "";
      tempData["fifth"].to = "";
    } else if (key === "fourth") {
      tempData["fourth"].to = newValue;

      tempData["fifth"].from = newValue !== 100 ? newValue + 1 : "";
      tempData["fifth"].to = 100;
    } else {
      // tempData["fifth"].to = newValue;
    }

    setDataLocation(tempData);
  };

  const handleCollectLableData = (event, index) => {
    let tempSentimentSteps = [...sentimentSteps];
    tempSentimentSteps[index].label = event.target.value;
    setSentimentSteps(tempSentimentSteps);
  }

  const onSubmitContent = () => {
    setLableEditable(-1);
  }
  // handleGetSentimentSettings

  useEffect(()=>{
    
    let tempLocalSteps = {}
    let sentimentSteps = [];
    Object.keys(allSentimentSettings).forEach(ele=>{
      tempLocalSteps[ele] = {
        to:allSentimentSettings[ele].to,
        from:allSentimentSettings[ele].from,
      }
      let obj = {
        label: allSentimentSettings[ele].label,
        key: ele,
      }
      sentimentSteps.push(obj);

    })
    if(sentimentSteps.length){
      setDataLocation(tempLocalSteps)
      setSentimentSteps(sentimentSteps)
    }
  },[allSentimentSettings ])


  const handleUpdateSentimentData = (data_location, sentiment_steps,type="update") => {
    let tempSentiment = [...sentiment_steps]
    let tempDataLocation = {
      ...data_location
    }
    tempSentiment.forEach(({key,label})=>{
      tempDataLocation[key] = {
        ...tempDataLocation[key],
        label
      }
    })
    updateSentimentSetting(tempDataLocation).then(res=>{
      handleGetSentimentSettings()
      if(type === 'reset'){
        handleShowSnackPopup({message:"Sentiment data reset succesfully" })
      }else{
        handleShowSnackPopup({message:"Sentiment data updated succesfully" })
      }
     
    }).catch(err=>{
      let error = get(err,'response.data.message',"error while updating data")
      handleShowSnackPopup({message:error, error:true})

    })
  }



  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
            }}
          >
            <Box sx={{ width: "100%", padding: "100px 10px" }}>
              <Stack sx={{ width: "100%" }} spacing={4}>
                <Stepper
                  alternativeLabel
                  activeStep={currenSteps.length}
                  connector={<ColorlibConnector />}
                >
                  {sentimentSteps.map(({ label, key }, index) => {
                    return (
                      <Step key={key}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>
                          {
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "10px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {lableEditable !== -1 &&
                              lableEditable === index ? (
                                <>
                                  <Paper
                                    component="box"
                                    sx={{
                                      p: "1px 2px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: "80%",
                                      height: 30,
                                      padding: "10px",
                                      boxShadow: "none",
                                      border: `1px solid rgba(0,0,0,0.2) `,
                                    }}
                                  >
                                    <InputBase
                                       autoFocus={true}
                                      onChange={(event) =>  { handleCollectLableData(event, index) }}
                                      value={label}
                                      onBlur={() => onSubmitContent()}
                                      onKeyDown={(event) => {
                                        event.key === "Enter" && onSubmitContent();
                                      }}
                                      className="input-box"
                                      type="email"
                                      name="  "
                                      sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                                      placeholder="enter lable"
                                    />
                                  </Paper>
                                </>
                              ) : (
                                <Typography
                                  sx={{ fontSize: "14px" }}
                                  onClick={() => {
                                    setLableEditable(index);
                                  }}
                                >
                                  {label}
                                </Typography>
                              )}
                              <Paper
                                component="box"
                                sx={{
                                  p: "1px 2px",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100px",
                                  height: 30,
                                  padding: "10px",
                                  boxShadow: "none",
                                  border: `1px solid rgba(0,0,0,0.2)`,
                                }}
                              >
                                <InputBase
                                  disabled
                                  value={get(dataLocation, `[${key}].from`, 0)}
                                  className="input-box"
                                  sx={{ ml: 0, flex: 1, fontSize: "14px" }}
                                />
                                <Divider orientation="vertical" />
                                <Select
                                  disabled={dataLocation[key].from === ""}
                                  onChange={(event) => {
                                    handleCollectRangeData(event, key);
                                  }}
                                  value={dataLocation[key].to}
                                  name="type"
                                  input={
                                    <InputBase
                                      variant="standard"
                                      sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                                    ></InputBase>
                                  }
                                >
                                  {arrayRange(
                                    dataLocation[key].from + 1,
                                    100,
                                    1
                                  ).map((item, index) => {
                                    return (
                                      <MenuItem value={item}>{item}</MenuItem>
                                    );
                                  })}
                                </Select>
                              </Paper>
                            </Box>
                          }
                        </StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              </Stack>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    maxWidth: "40%",
                    marginTop: "100px",
                  }}
                >
                  <Button
                    onClick={handleResetData}
                    sx={{
                      background: "#f1f1fb",
                      color: "#707c97",
                      height: "26px",
                      boxShadow: "none",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      padding: "3px 10px",
                      border: "none",
                      ":hover": { borderColor: "none" },
                    }}
                    variant="outlined"
                  >
                    {" "}
                    Reset
                  </Button>
                  <Button
                    onClick={() => handleUpdateSentimentData(dataLocation, sentimentSteps)}
                    disabled={!(dataLocation.fifth.to == 100)}
                    sx={{
                      background: "#f1f1fb",
                      color: "#707c97",
                      height: "26px",
                      boxShadow: "none",
                      fontSize: "12px",
                      textTransform: "capitalize",
                      padding: "3px 10px",
                      borderColor: "#a84acb",
                      color: "#a84acb",
                      ":hover": { borderColor: "#a84acb" },
                      textTransform: "inherit",
                    }}
                    variant="outlined"
                  >
                    Save
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default SentimentsComponent;
