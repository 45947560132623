import React from "react";

const BackOfficeTicketTypeIcon = ({color="#000", height=16, width=16, ...props}) => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.035 0a1 1 0 00-.832.445l-.977 1.467A.7.7 0 005.808 3h4.384a.7.7 0 00.582-1.088L9.797.445A1 1 0 008.965 0h-1.93zM4.031 1l-.054.08c-.974 1.462.074 3.42 1.831 3.42h4.384c1.757 0 2.805-1.958 1.83-3.42L11.97 1H12a2 2 0 012 2v9a2 2 0 01-2 2H4a2 2 0 01-2-2V3a2 2 0 012-2h.03zm7.043 5.892a.664.664 0 010 .998l-3.57 3.218a.768.768 0 01-1.015 0L4.926 9.7a.664.664 0 010-.998.768.768 0 011.015 0l1.055.95 3.063-2.76a.768.768 0 011.015 0zm-.201.222c.17.153.17.4 0 .553l-3.57 3.219 3.57-3.219c.17-.153.17-.4 0-.553zM5.74 8.924l1.256 1.132L5.74 8.925z"
        ></path>
      </svg>
    </>
  );
};

export default BackOfficeTicketTypeIcon;
