import React from "react";

const PhoneNumberIcon = ({color="#000", height=16, width=16}) => {
  return (
    <>
      <svg
        class="interface-icon o__standard o__standard__phone"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.63525 1.59194C5.39348 1.07387 4.77469 0.853629 4.25996 1.10244L2.46518 1.97C1.25616 2.55441 0.6933 3.96503 1.16778 5.22115L1.16931 5.22291L1.16945 5.226L1.169 5.22829C2.03853 7.31763 3.32309 9.27504 5.02268 10.9746C6.72214 12.674 8.67943 13.9585 10.7686 14.828L10.7711 14.8275L10.7745 14.8277L10.7771 14.8307L10.7796 14.8325C12.0355 15.3065 13.4457 14.7436 14.03 13.5348L14.8976 11.7398C15.1464 11.2251 14.9261 10.6064 14.4081 10.3646L11.86 9.17554C11.5005 9.00777 11.0764 9.06246 10.7713 9.31596L9.89005 10.048C9.53732 10.341 9.0306 10.3671 8.66669 10.0881C8.15597 9.69656 7.665 9.26711 7.1976 8.79972C6.73118 8.3333 6.30253 7.84341 5.91165 7.33385C5.63252 6.96996 5.65852 6.46315 5.95156 6.11035L6.68396 5.22859C6.93743 4.92343 6.99211 4.49943 6.82435 4.13995L5.63525 1.59194Z"
        ></path>
      </svg>
    </>
  );
};

export default PhoneNumberIcon;
