import React, { useContext, useEffect, useState } from "react";
import { get } from "lodash";
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import moment from "moment";
import { SidebarContext } from "../../../../../../../context/SidebarProvider";
import { MainContext } from "../../../../../../../context/MainProvider";
import {
  chatbotBulkActionsApi,
  getChatBotDocuments,
} from "../../../../../../../services/Apis";
import { useParams } from "react-router-dom";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LinkIcon from "@mui/icons-material/Link";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
const fileIcons = {
  FILE: AttachFileIcon,
  CONTENT: TextSnippetIcon,
  LINK: LinkIcon,
};

const AddDocsToChatbot = ({ docsKeys, type, chatbotId, handleGetBotDocuments }) => {
  const params = useParams()
  const [chatbotDocs, setChatbotDocs] = useState([]);
  const { handleCloseModal } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext);

  const [currentkeys, setCurrentkeys] = useState({});

  const handleCreatekeys = (value) => {
    let botKeys = {};
    chatbotDocs.forEach((ele) => {
      botKeys[ele.id] = value;
    });
    setCurrentkeys(botKeys);
  };

  const handleCheckedData = (event, key) => {
    let tempkeys = { ...currentkeys };
    let value = event.target.checked;
    if (key === "all") {
      chatbotDocs.forEach((ele) => {
        tempkeys[ele.id] = value;
      });
    } else {
      tempkeys[key] = value;
    }
    setCurrentkeys(tempkeys);
  };

  useEffect(() => {
    handleCreatekeys(false);
  }, [chatbotDocs]);

  useEffect(() => {
    handleGetChatbotProfiles();
  }, []);

  const handleGetChatbotProfiles = () => {
    getChatBotDocuments().then((res) => {
      let response = get(res, "data.data.docs", []);
      let newResponse = response.filter(ele=>!(ele['chatbotIds'].includes(chatbotId)))
      setChatbotDocs(newResponse);
    });
  };

  const handleAddBulkActions = () => {
    let newIds = Object.keys(currentkeys).filter((key) => currentkeys[key]);
    let payload = {
      action: type,
      ids: newIds,
      chatbot_ids: [chatbotId],
    };
    chatbotBulkActionsApi(payload)
      .then((res) => {
        handleShowSnackPopup({ message: "data updated successfully" });
        handleCloseModal();
        handleGetBotDocuments()
      })
      .catch((err) => {
        handleShowSnackPopup({ message: "error while  updating", error: true });
        handleCloseModal();
      });
  };
  const allChecked = Object.values(currentkeys).every((e) => e);
  return (
    <Box sx={{ padding: "20px 40px" }}>
      <Box
        sx={{
          width: "500px",
          display: "inline-flex",
          height: "340px",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table
            sx={{ maxHeight: "300px", overflowY: "auto" }}
            aria-label="simple table"
            size="small"
          >
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox" sx={{ fontWeight: "bold" }}>
                  <Checkbox
                    checked={allChecked}
                    onClick={(event) => handleCheckedData(event, "all")}
                    sx={{
                      color: "#a84acb",
                      "&.Mui-checked": {
                        color: "#a84acb",
                      },
                    }}
                  />
                </TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Document name</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                <TableCell align="right" sx={{ fontWeight: "bold" }}>
                  {" "}
                  CreatedAt{" "}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {chatbotDocs.map((element) => {
                let createdData = moment(get(element, "createdAt", ""));
                let Icon = fileIcons[get(element,'type','').toUpperCase()];
                return (
                  <TableRow
                    key={get(element, "id", "")}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        onClick={(event) =>
                          handleCheckedData(event, get(element, "id", ""))
                        }
                        checked={get(currentkeys, `[${element.id}]`, false)}
                        sx={{
                          color: "#a84acb",
                          "&.Mui-checked": {
                            color: "#a84acb",
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {get(element, "title", "")}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {  Icon && <Icon sx={{color:"green"}} />}
                    </TableCell>
                    <TableCell align="right">
                      {" "}
                      {createdData.isValid() ? createdData.fromNow() : " "}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: "10px" }}>
          <Button
            onClick={handleCloseModal}
            sx={{
              background: "#f1f1fb",
              color: "red",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor: "red",
              color: "red",
              ":hover": { borderColor: "red" },
              textTransform: "inherit",
            }}
            variant="outlined"
          >
            Cancel
          </Button>

          <Button
            disabled={!chatbotDocs.length}
            onClick={handleAddBulkActions}
            sx={{
              background: "#f1f1fb",
              color: "#707c97",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor: "#a84acb",
              color: "#a84acb",
              ":hover": { borderColor: "#a84acb" },
              textTransform: "inherit",
            }}
            variant="outlined"
          >
            {type === "addChatBots" ? "Add" : "Remove"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default AddDocsToChatbot;
