import { Box, Typography } from "@mui/material";
import React from "react";
import style from "./teamComponent.module.scss";

const TeamsComponentToolbar = ({heading }) => {

  return (
    <>
      <Box className={style['team-toolbar-component']}>
        <Box className="toolbarContent">
        <Typography sx={{fontWeight:"bold", fontSize:"22px"}} >{heading}</Typography>
        </Box>
      </Box>
    </>
  );
};

export default TeamsComponentToolbar;
