import React from "react";

const FocusIcon = ({color="black", height="20", width="20"}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M7.33333 2H3.77778C2.79594 2 2 2.79594 2 3.77778V7.33333M7.33333 18H3.77778C2.79594 18 2 17.2041 2 16.2222V12.6667M12.6667 2H16.2222C17.2041 2 18 2.79594 18 3.77778V7.33333M18 12.6667V16.2222C18 17.2041 17.2041 18 16.2222 18H12.6667M8 13H12C12.5523 13 13 12.5523 13 12V8C13 7.44771 12.5523 7 12 7H8C7.44771 7 7 7.44771 7 8V12C7 12.5523 7.44771 13 8 13Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
        />
      </svg>
    </>
  );
};

export default FocusIcon;
