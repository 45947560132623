import * as React from "react";
import Box from "@mui/material/Box";
import { Toolbar, Typography } from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import style from "./sidebarpanel.module.scss";
import Coll from "./collapseComponent/Coll";
import SearchIcon from "@mui/icons-material/Search";
import DrawIcon from "@mui/icons-material/Draw";
import { MainContext } from "../../context/MainProvider";
import { Link, useNavigate } from "react-router-dom";
import CreateTickets from "../inbox/yourInbox/createTickets/CreateTickets";
import { SidebarContext } from "../../context/SidebarProvider";
import { get } from "lodash";
export default function VerticalTabs({
  sideLeftPane,
  sidebarHeader,
}) {
  const [currentTab, setCurrentTab] = React.useState("your-inbox");



  let {  handleOpenModal } = React.useContext(MainContext);
  let { sidebarState, sidebarContents } = React.useContext(SidebarContext)
  

  const handleCreateNewTicket = () => {
    handleOpenModal({
      state:true,
      Component:<CreateTickets />
    })
  }
  

  const onlyForContentSidebar = sidebarHeader === "inbox";

  return (
    <Box
      sx={{
        flexGrow: 1,
        bgcolor: "background.paper",
        display: "",
        height: 224,
        width: sideLeftPane,
      }}
      className={style.sidebarpanleMainComponent}
    >
      <Toolbar>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            flexGrow: 1,
            padding: 0,
          }}
        >
          <Box sx={{ width: onlyForContentSidebar ? "50%" : "100%" }}>
            <Typography variant="span"  sx={{fontWeight:"bold", textTransform:"capitalize"}} >{sidebarHeader}</Typography>
          </Box>
          {onlyForContentSidebar && (
            <Box
              sx={{
                width: "50%",
                display: "flex",
                gap: "10px",
                justifyContent: "flex-end",
              }}
              className="headerContainer"
            >
              <DrawIcon className="drawIcon icon"  onClick={handleCreateNewTicket} />
            <Link to='search' >
            <SearchIcon className="searchbarIcon icon" /></Link>
            </Box>
          )}
        </Box>
      </Toolbar>
      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        {sidebarContents.map((ele) => {
          if (ele.type === "child") {
            let { Component, text, key,path } = ele;
            return (
              <>
                <Link to={path} key={key}  style={{textDecoration:'none'}}>
                  <ListItemButton
                    className={`listItemButtonsClass ${
                      get(sidebarState,'child','') === key && "selectedItemContainer"
                    }`}
                    key={key}>

                    {  get(sidebarState,'child','') === key && <span className="toogleKey"></span>}
                    <ListItemIcon className="listIconContainer">
                      <Component className="itemIcons" />
                    </ListItemIcon>
                    <ListItemText
                      primary={text}
                      className="itemText"
                      secondary={null}
                    />
                  </ListItemButton>
                </Link>
              </>
            );
          } else {
            let { heading } = ele;

            

            return (
              <Coll
                key={heading}
                {...ele}
                data={ele.data}
                heading={heading}
                currentTab={get(sidebarState,'child',"")}
                parentTab={get(sidebarState,'parent',"")}
              />
            );
          }
        })}
      </List>
    </Box>
  );
}
