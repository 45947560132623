/* eslint-disable react-hooks/exhaustive-deps */
import ReactQuill, { Quill } from "react-quill";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "quill-mention";
import "./cannedResponse.css"
import "react-quill/dist/quill.snow.css";
import editorCss from "./cannedResponse.module.scss"
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

export default function CannedResponseEditor({
  onChange,
  placeholder,
  value,
}) {
  

  const [mentionData, setMentionData] = useState([])
  const editor = useRef();
  useEffect(() => {
    setMentionData([
      { id: "{{ticket.title}}", value: "{{ticket.title}}" },
      { id: "{{ticket.status}}", value: "{{ticket.status}}" },
      { id: "{{ticket.assignee.name}}", value: "{{ticket.assignee.name}}" },
      { id: "{{ticket.assignee.first_name}}", value: "{{ticket.assignee.last_name}}" },
      { id: "{{ticket.name}}", value: "{{ticket.name}}" },
    ]);
  }, []);

  const modules = useMemo(() => ({
    toolbar: false,
    mention: {
      showDenotationChar:false,
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['{{'],
      source: function (searchTerm, renderList, mentionChar) {
        let values;
        if (mentionChar === '{{') {
          values = mentionData;
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = values.filter(
            (mention) =>
              mention.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );
          renderList(matches, searchTerm);
        }
      },
    },
  }), [mentionData]);

  return (
    <div className={`w-400 h-full ${editorCss.mentionCssClass}`}>
      <ReactQuill
        ref={editor}
        value={value.delta}
        theme={'snow'}
        modules={{ ...modules }}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}
