import  React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from "./customAccordian.module.scss"
import { Divider } from '@mui/material'
import ContentSouceHocCard from '../../../profileSetupPage/contentSouceHocCard/ContentSourceHocCard';
let name = process.env.REACT_APP_AUTO_NAME;
const CustomAccordian = ({ open,setOpen,Body,Header, className="", data={}}) => {
  return (
   <><div className={`${style.customAccordianMainClass} ${className}`} >
    <Accordion className={`accordianContainer  ${open && "activeAccordian"}`} expanded={open} onChange={setOpen} >
       {Header && <Header open={open} data={data} /> } 
      {Body&&  <Body data={data} />}
      </Accordion>
      </div>
   </>
  )
}

export default  ContentSouceHocCard(CustomAccordian)
