import React, { useContext } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { Box, Button, Chip, ListItemText } from "@mui/material";
import FileIcon from "../../../../../assets/icons/FileIcon";
import { MainContext } from "../../../../../context/MainProvider";
import ImportContentFileSourceModal from "./modalComponents/ImportContentFileSourceModal";

const ImportContentFilesSources = () => {
    const { handleOpenModal } = useContext(MainContext);

    const handleAddNewLinks = () => {
      handleOpenModal({
        state: true,
        Component: <ImportContentFileSourceModal />,
       
      });
    };
  return (
  <>
   <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box
            sx={{
              display: "flex",
              maxWidth:'70%',
              gap:'5px',
                
              flexDirection:'column'
            }}
          >
          
            <Typography sx={{fontSize:'15px', color:'#707C97'}} >
                <ol style={{listStyleType:'inherit'}}>
                    <li>Images are not scraped.</li>
                    <li>File size limit is 89 MB.</li>
                    <li>Files with multiple text columns, encrypted or password protected PDFs are not supported. </li>
                </ol>

            </Typography>
            
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            {/* <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <FileIcon />
              <Typography
                sx={{ fontSize: "16px", color: "#A84ACB", cursor: "pointer" }}
              >
                Learn more about PDF content for AI Chatbot
              </Typography>
            </Box> */}
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center", justifyContent:'flex-end' }}>
              {/* <Button
                sx={{ background: "#F1F1F1", color: "black", height: "30px" }}
              >
                {" "}
                Manage{" "}
              </Button> */}
              <Button
              onClick={handleAddNewLinks}
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
  
  
  </>
  )
}

export default ImportContentFilesSources
