import {
  Box,
  Button,
  InputBase,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PullseInputBox from "../../../commonComponent/pullse-input-box/PullseInputBox";
import { PullseTextArea } from "../../../commonComponent/pullse-text-area/PullseTextArea";
import { createEventWorkflow, eventWorkflowDetails, updateEventWorkflow } from "../../../../services/Apis";
import { SidebarContext } from "../../../../context/SidebarProvider";
import { get } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import LoadingButton from '@mui/lab/LoadingButton';


const AddWorkflow = ({ triggers, events = [], getEventWorkflowData=()=>{}, companentType }) => {
  const navigate = useNavigate()  
  const params = useParams()
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const [loading, setLoading] = useState(false)
  const [workflowData, setWorkflowData] = useState({
    name: "",
    description: "",
    workflowId: "",
    eventId: "",
  });

  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setWorkflowData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  useEffect(()=>{
    if(companentType === "update"){
        handleGetEventWorkflowDetails(get(params,'event_workflow_id',""))
    }
  },[])


  const handleGetEventWorkflowDetails = (event_workflow_id) => {
    eventWorkflowDetails(event_workflow_id).then(res=>{
        let response = get(res,'data.data',{});
        let details = {
            name:get(response,'name',""),
            description:get(response,'description',""),
            eventId:get(response,'eventId',""),
            workflowId:get(response,'workflowId',""),
        }
        setWorkflowData(details);
    })
  }

  const handleCreateEventWorkflow = () => {
    setLoading(true)
    createEventWorkflow(workflowData).then(res=>{
        handleShowSnackPopup({message:"workflow created successfully"})
        setLoading(false)
        getEventWorkflowData()
        navigate('/automation/workflows')
    }).catch(err=>{
        setLoading(false)
        handleShowSnackPopup({message:get(err,'response.data.message',"error while creating workflow"),error:true})
    })
  }

  const updateEventWorkflowData = () => {
    setLoading(true)
    updateEventWorkflow(get(params,'event_workflow_id',""), workflowData).then(res=>{
        setLoading(false)
        handleShowSnackPopup({message:"workflow updated successfully"})
    }).catch(err=>{
        setLoading(false)
        handleShowSnackPopup({message:get(err,'response.data.message',"error while creating workflow"),error:true})
    })
  }

  const handleSubmitData = () => {
    if(companentType === "update"){
        updateEventWorkflowData()
    }else{
        handleCreateEventWorkflow();
    }
  }


  return (
    <>
      <Box  sx={{padding:"10px"}} >
      <Typography
      onClick={() => {
        navigate("/automation/workflows");
      }}
        sx={{
            fontWeight: "400",
            fontSize: "20px",
            display: "flex",
            alignItems: "center",
            cursor: "pointer",
        }} >
        <ArrowBackIosIcon
            sx={{  fontSize:"20px" }}
              />
              Go back
            </Typography>
      </Box>
      <Box
        sx={{
          padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center", flexDirection:'column'
        }}
      >
       
        <Box
          sx={{
            width: "50%",
            display: "flex",
            gap: "10px",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{ textAlign: "left" }}
            >
              Workflow Name
            </InputLabel>
            <PullseInputBox
              value={workflowData.name}
              name="name"
              onChange={handleCollectData}
            />
          </Box>
          <Box sx={{ width: "100%" }}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{ textAlign: "left" }}
            >
              Description
            </InputLabel>
            <PullseTextArea
              value={workflowData.description}
              name="description"
              onChange={handleCollectData}
              placeholder="Enter description"
              minRows={4}
              sx={{
                "::placeholder": {
                  color: "gray",
                },
                resize: "none",
              }}
            />
          </Box>

          <Box sx={{ width: "100%" }}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{ textAlign: "left" }}
            >
              Rule
            </InputLabel>
            <Select
              defaultValue=""
              placeholder="text"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: "1px solid rgba(0,0,0,0.1)",
              }}
              onChange={handleCollectData}
              name="eventId"
              value={workflowData.eventId}
              input={
                <InputBase
                  placeholder="company"
                  variant="standard"
                  autoFocus={true}
                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                />
              }
            >
              {events.map(({ id, title, entity }) => (
                <MenuItem id={id} value={id} sx={{ fontSize: "13px" }}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box sx={{ width: "100%" }}>
            <InputLabel
              shrink
              htmlFor="bootstrap-input"
              sx={{ textAlign: "left" }}
            >
              Triggers
            </InputLabel>
            <Select
              defaultValue=""
              placeholder="text"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                borderRadius: "5px",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: "1px solid rgba(0,0,0,0.1)",
              }}
              onChange={handleCollectData}
              name="workflowId"
              value={workflowData.workflowId}
              input={
                <InputBase
                  placeholder="company"
                  variant="standard"
                  autoFocus={true}
                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                />
              }
            >
              {triggers.map(({ id, name }) => (
                <MenuItem
                  id={id}
                  value={id}
                  style={{ color: "black", fontSize: "13px" }}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              maxWidth: "40%",
              marginTop: "100px",
            }}
          >
            {/* <Button
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                border: "none",
                ":hover": { borderColor: "none" },
              }}
              variant="outlined"
            >
              {" "}
              Cancel
            </Button> */}
            <LoadingButton
            loading={loading}
            onClick={handleSubmitData}
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
              variant="outlined"
            >
              Save
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddWorkflow;
