import React, { useContext } from "react";
import { MainContext } from "../../context/MainProvider";
import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import { Box, Toolbar } from "@mui/material";
import LayoutLeft from "../../assets/searchIcons/LayoutLeft";
const SideToolbar = ({ children, className="" }) => {
  const { handleToogleLeftSPanel } = useContext(MainContext);
  return (
    <>
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            alignItems: "center",
            flexGrow: 1,
          }}
        >   
          <LayoutLeft onClick={handleToogleLeftSPanel} />
          {children}
        </Box>
      </Toolbar>
    </>
  );
};

export default SideToolbar;
