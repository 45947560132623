import { Box, Button, IconButton, Typography } from "@mui/material";
import React from "react";
import style from "./ticketTypedetail.module.scss";
import PluseIcon from "../../../../assets/searchIcons/PluseIcon";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CursorRightIcon from "../../../../assets/icons/CursorRightIcon";
import { useNavigate } from "react-router-dom";
const TicketTypeDetailToolbar = ({ heading, handleCreateAttributes, handleUpdateTicketTypes }) => {
  const navigate = useNavigate()
  return (
    <>
      <Box className={style["ticket-type-toolbar-component"]}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {" "}
            <Typography  onClick={()=>{
              navigate('/settings/ticket-types')
            }} sx={{fontSize:"12px", fontWeight:"bold", display:"flex", alignItems:'center', cursor:"pointer"}} >   Ticket Types    <CursorRightIcon /></Typography>
            {heading}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "50%",
              gap: "10px",
              alignItems:"center"
            }}
          >
            <IconButton
              onClick={handleUpdateTicketTypes}
              aria-label="delete"
              size="small"
              sx={{
                padding: "4px 8px",
                background: "#f1f1f1",
                borderRadius: "5px",
                border:"1px solid rgba(0,0,0,0.1)"
              }}
            >
              <ModeEditIcon sx={{ color: "black", fontSize: "18px" }} />
            </IconButton>
            <Button
              onClick={()=>handleCreateAttributes({type:'new'})}
              startIcon={<PluseIcon color="#a84acb" />}
              variant="outlined"
              sx={{
                background: "#f1f1fb",
               
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
             
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
            >
              Create attribute
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TicketTypeDetailToolbar;
