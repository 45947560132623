import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { Box } from "@mui/material";
import { makeChartData, randomColors } from "../../utils";
import { get } from "lodash";



ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      display:false
    },
    title: {
      display: true,
      text: " Bar Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(
        () => Math.floor(Math.random() * (1000  -1000 + 1)) + 1000
      ),
      backgroundColor: ["rgba(255, 99, 132, 0.5)","rgba(255, 99, 9, 0.5)", "rgba(5, 94, 9, 0.5)", "rgba(90, 99, 9, 0.5)"],
    },
  ],
};

const BarChartComponent = ({chartData}) => {
  const [barChartData, setBarChartData] = useState({
    labels:[],
    datasets:[]

  }) 
  useEffect(()=>{
    if(!chartData.hasOwnProperty('datasets')){
      let { labels, datasets} = makeChartData(chartData)
      
      setBarChartData({
          labels ,datasets
        })


    }else{
      let labels = [], datasets = [];
      get(chartData,'data.value',[]).forEach((ele,index)=>{
        labels.push(ele.label)
        datasets.push({
          label:ele.label,
          data:[ele.value, 5],
          backgroundColor:randomColors[index]
        })
        // datasets.push({
       
      })
      setBarChartData({
        labels ,datasets
      })
    }
   
  },[])

 

    const newChartData = {
      ...barChartData
     };

     
  return (
    <Box sx={{ width: "100%" }}>
      <Bar options={options} data={newChartData} />
    </Box>
  );
};

export default BarChartComponent;
