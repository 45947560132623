import { Box, Button, Typography } from "@mui/material";
import React from "react";
import style from "./email-templete.module.scss"
import PluseIcon from "../../../assets/searchIcons/PluseIcon";
import { useNavigate } from "react-router-dom";
const EmailTempleteToolbar = ({}) => {
  const navigate = useNavigate()

  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
      <Box className="toolbarContent" sx={{display:"flex", justifyContent:'space-between',alignItems:'center', width:"100%"}} >
          <Typography  sx={{fontSize:"20px", fontWeight:"bold"}} > Email templetes</Typography> 
          <Button
            // onClick={handleCreateTicketTypes}
            onClick={()=>{
              navigate("create")
            }}
            startIcon={
              <PluseIcon color="#a84acb" />

            }
              variant="outlined"
              sx={{
                background: "#f1f1fb",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
            >
           Create new templete
            </Button>
      </Box>
      </Box>
    </>
  );
};

export default EmailTempleteToolbar;
