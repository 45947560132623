import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
} from "@mui/material";
import style from "./teamMate.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import SearchBarComponent from "../searchComponent/SearchBarComponent";
import DisplayAgents from "./display-agents/DisplayAgents";
import UserIcon from "../../../assets/searchIcons/UserIcon";
import CreateNewAgent from "./createNew/CreateNewAgent";
import { createNewAgent, deleteAgent, getAllAgents } from "../../../services/Apis";
import { get } from "lodash";
let workspaceId = process.env.REACT_APP_WORKSPACE_ID;

const TeamMatesComponent = ({ ToolbarComponent }) => {
  const { handleOpenModal, allAgents, handleFetchAgents } = useContext(MainContext);
  const [agents, setAgents] = useState([])


  useEffect(()=>{
    setAgents(allAgents)
  },[allAgents])

  const handleSearchAllAgents =  useCallback((value) => {
      getAllAgents({type:'name', data:value}).then((res) => {
        let responseArr = get(res, "data.data.docs", []);
        setAgents(responseArr);
      })
  },[allAgents])

  const handleCreateNewAgent = (payload) => {
      return new Promise((resolve, reject)=>{
        createNewAgent(payload).then(res=>{
          handleFetchAgents()
          resolve(res)
        }).catch(err=>{
          reject(err)
        })
      }) 
  }


 
  const handleCreateTag = (type, data) => {
    handleOpenModal({
      state: true,
      Component: (
        <CreateNewAgent
          data={{
            type: type,
            handleCreateNewAgent
          }}
        />
      ),
    });
  };

  

  const handleDeleteAgent = (id) => {
    deleteAgent(id).then((res) => {
      handleSearchAllAgents("");
    })
    
  }


 
 
  return (
    <>
      <MainComponent className={style["tag-main-component"]}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>
            {ToolbarComponent && <ToolbarComponent heading={"Agents"} />}
          </SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <SearchBarComponent handleCollectValue={handleSearchAllAgents} />{" "}
            <Button
              startIcon={<UserIcon color="white" />}
              sx={{
                textTransform: "capitalize",
                background: `linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)`,
                color: "white",
              }}
              onClick={() =>
                handleCreateTag("Create", {
                  workspaceId,
                })
              }
            >
              Create New Agent
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "80vh",
            }}
          >
            <DisplayAgents allAgents={agents} handleDeleteAgent={handleDeleteAgent} />
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default TeamMatesComponent;
