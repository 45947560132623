import { Box } from '@mui/material'
import React from 'react'
import MaxtrixCard from './MaxtrixCard'

const MatrixChartComponent = ({data}) => {
  return (
    <MaxtrixCard content={`${data.data.value}`} header={data.title}  color={data.data.backgroundColor}/>
  )
}

export default MatrixChartComponent
