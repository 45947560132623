import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Box, Divider, InputAdornment, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { MainContext } from "../../../../context/MainProvider";
import { getTags, getTicketType } from "../../../../services/Apis";
import { get } from "lodash";
import { debounceWithImmediate } from "../../../../utils/helpers/helpers";

const TicketTypesMenuBar = ({handleClose, onSelectFilter, selectedVal}) => {
    const inputRef = useRef(null);
  const {ticketTypes} = useContext(MainContext);
  const [ticketType,setTicketType] = useState([]);
  const [inputVal, setInputVal] = useState("")
  const [focus, setFocus ] = useState([]) 

  useEffect(()=>{
    setTicketType(ticketTypes)
  },[ticketTypes])

  const getTagsData = (value) => {
    getTicketType({type:'name',data:value}).then(res=>{
      let valArr = get(res,'data.data.docs',[])
      setTicketType(valArr);
    })
  }
  useEffect(()=>{
    inputRef.current.focus();
  },[ticketTypes, inputRef])
  const handleCollectData = (event) => {
    setInputVal(event.target.value)
    handleDebounce(event.target.value)
  }

  const handleFetchTeams = (value) => {
    getTagsData(value)
  }
  const handleDebounce = useCallback(debounceWithImmediate(handleFetchTeams,()=>{},700),[])
  return (
    <Box sx={{ width: "300px", padding: "0px" }} className={"box"}>
      <Paper
        component="box"
        className="padding"
        sx={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: 35,
          padding: "10px",
          boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
        }}
      >
        <InputBase
        ref={inputRef}  
        autoFocus={true}
          className=""
          placeholder="Search"
          value={inputVal}
          sx={{ flex: 1, padding: 0 }}
          onChange={handleCollectData}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "gray" }} />
            </InputAdornment>
          }
        />
      </Paper>
      <Divider />

      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", maxHeight:'500px', overflowY:'scroll', overflowX:'hidden', padding:'10px 0px 0px 0px' }}

      >
        {ticketType.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          let selected = selectedVal === value.id

          return (
            <ListItem key={value}  style={{background:selected && '#c166e2',color:selected && 'white'}} disablePadding onClick={()=>{
         
                onSelectFilter(value)
                handleClose()
            }} >
              <ListItemButton>
            
                <ListItemText id={labelId} primary={value.name} sx={{'.MuiListItemText-secondary':{color:selected && 'white'}}} secondary={value.description} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default TicketTypesMenuBar;
