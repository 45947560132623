import React, { useEffect, useState } from "react";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import style from "./profileSetup.module.scss";
import Profile from "./profile/Profile";
import SetupPage from "./setupPage/SetupPage";
import { useNavigate, useParams } from "react-router-dom";
import { getChatbotProfile, getChatbotProfileDetails } from "../../../services/Apis";
import { get } from "lodash";
const ProfileSetupPage = ({ ToolbarComponent }) => {
  const [value, setValue] = React.useState(0);
  const params = useParams();
  const [profileData, setProfileData] = useState({});
  const navigate = useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getChatbotProfileDetails(params.id).then(res=>{
      let data = get(res,'data.data',{})
      setProfileData(data)
    })
  }, []);
  
  const handleUpdateProfile = () => {
    navigate(`/automation/profile/update/${params.id}`)

  }


  return (
    <>
      <MainComponent className={style.profileAutomationMainContainer}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && value == 0 && <ToolbarComponent handleUpdateProfile={handleUpdateProfile} />}</SideToolbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor="black"
                indicatorColor="secondary"
              >
                <Tab
                  label="Profile"
                  {...a11yProps(0)}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "inherit",
                    color: "black",
                  }}
                />
                <Tab
                  label="Setup"
                  {...a11yProps(1)}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "inherit",
                    color: "black",
                  }}
                />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Profile profileData={profileData} />
            </CustomTabPanel>
            <CustomTabPanel
              value={value}
              index={1}
              style={{ maxHeight: "90vh", overflowY: "scroll" }}
            >
              <SetupPage params={params} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              Item Three
            </CustomTabPanel>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default ProfileSetupPage;
