import { Box, Radio, Typography } from '@mui/material'
import React from 'react'

const BackToOffice = () => {
  return (
   <>
      <Box  sx={{display:'flex', flexDirection:"column", gap:"1px"}} >
      <Typography sx={{fontSize:"14px", color:"black", fontWeight:"bold"}} >
      Customer sharing settings
      </Typography>

      <Typography sx={{fontSize:"13px", color:"gray",}} >
      Back-office tickets can be optionally shared with customers, but don’t allow customer facing replies. Replies can be sent in a linked conversation.
      </Typography>
     
     {/* <Box sx={{ display:"flex", gap:"5px", alignItems:'center'}} >
     <Radio
     size='small'
      sx={{
        color:  "#a84acb",
        '&.Mui-checked': {
        color: "#a84acb",
        },
        padding:'5px 0px'
      }}
    />
    <Typography  sx={{fontSize:"13px", color:"gray"}} >
    Can't be shared with customers
    </Typography>
     </Box> */}

     {/* <Box sx={{ display:"flex", gap:"5px", alignItems:'center'}} >
     <Radio
     size='small'
      sx={{
        color:  "#a84acb",
        '&.Mui-checked': {
        color: "#a84acb",
        },
        padding:'5px 0px'
      }}
    />
    <Typography  sx={{fontSize:"13px", color:"gray"}} >
    Can be optionally shared with customers
    </Typography>
     </Box> */}

    </Box>
   
   </>
  )
}

export default BackToOffice
