import React, { useContext } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Chip, ListItemText } from "@mui/material";
import FileIcon from "../../../../../assets/icons/FileIcon";
import UseSnippetSourceModal from "./modalComponents/UseSnippetSourceModal";
import { MainContext } from "../../../../../context/MainProvider";
const UseSnippetSource = () => {

    const { handleOpenModal } = useContext(MainContext);

    const handleAddNewLinks = () => {
    handleOpenModal({
      state: true,
      Component: <UseSnippetSourceModal />,
    });
  };
  return (
  <>
   <AccordionDetails>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <Box
            sx={{
              display: "flex",
              maxWidth:'70%',
              gap:'5px',
                
              flexDirection:'column'
            }}
          >
          
            <Typography sx={{fontSize:'15px', color:'#707C97'}} >
For an article to be used by AI Chatbot, it needs to be in English, be published, be part of a collection, and be part of a live Help Center.

            </Typography>
            
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            {/* <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <FileIcon />
              <Typography
                sx={{ fontSize: "16px", color: "#A84ACB", cursor: "pointer" }}
              >
                How to add Snippets
              </Typography>
            </Box> */}
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center", justifyContent:'flex-end' }}>
              {/* <Button
                sx={{ background: "#F1F1F1", color: "black", height: "30px" }}
              >
                {" "}
                Manage{" "}
              </Button> */}
              <Button
              onClick={handleAddNewLinks}
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                }}
              >
                Add
              </Button>
            </Box>
          </Box>
        </Box>
      </AccordionDetails>
  
  
  </>
  )
}

export default UseSnippetSource
