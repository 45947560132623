import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Avatar,
  Box,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Toolbar,
  Typography,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
import style from "../yourInbox.module.scss";
import MiniCircle from "../../../commonComponent/miniCircle.js/MiniCircle";
import TripleDotMenu from "../../../../assets/icons/TripleDotMenu";
import StartIcon from "../../../../assets/icons/StartIcon";
import MessageBox from "../messageBox/MessageBox";
import MoonIcon from "../../../../assets/icons/MoonIcon";
import DownloadIcon from "../../../../assets/icons/DownloadIcon";
import SendingDropDown from "../sendingMessageBox/SendingDropDown";
import MessageTextBox from "../textBox/MessageTextBox";
import LightningFilledIcon from "../../../../assets/icons/LightningFilledIcon";
import SendingButton from "../../../../assets/icons/SendingButton";
import CrossIcon from "../../../../assets/icons/CrossIcon";
import ExternalLinkIcon from "../../../../assets/icons/ExternalLinkIcon";
import AccordionComponent from "../../../commonComponent/accordionComponent/AccordionComponent";
import Button from "@mui/material/Button";
import LayoutLeft from "../../../../assets/searchIcons/LayoutLeft";
import { useLocation, useParams } from "react-router-dom";
import { useSocket } from "../../../../context/SocketProvider";
import { get, debounce } from "lodash";
import {
  getTicketConversation,
  getTicketDetails,
  setValueUsingCustomFields,
  updateCustomerDetails,
  updateTicketData,
  updateTicketTag,
  updateTicketTopic,
  updateTicketType,
} from "../../../../services/Apis";
import { convertDeltaToText, convertTextToDelta, debounceWithImmediate, variabledText } from "../../../../utils/helpers/helpers";
import useScrollHook from "../../../../utils/hooks/scrollHook";
import { MainContext } from "../../../../context/MainProvider";
// import CheckIcon from '@mui/icons-material/Check';
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";

import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import MenuBarCommon from "../../../commonComponent/menuBarComponent/MenuBarCommon";
import AssignedToMenu from "../../../commonComponent/filterMenuData/AssignedToMenu";
import TeamMenu from "../../../commonComponent/filterMenuData/TeamMenu";
import TagsMenuBar from "../filterMenus/TagsMenuBar";
import TopicMenu from "../../../commonComponent/filterMenuData/TopicMenu";
import Tooltip from "@mui/material/Tooltip";
import useStateCallback from "../../../../utils/hooks/useStateCallback";
import TicketTypesMenuBar from "../filterMenus/TicketTypeMenuBar";
import SummarizeText from "./summarize-text/SummarizeText";
import NumberIcon from "../../../../assets/icons/NumberIcon";
import AlphabetIcon from "../../../../assets/icons/AlphabetIcon";
import DecimalIcon from "../../../../assets/icons/DecimalIcon";
import TrueFalseIcon from "../../../../assets/icons/TrueFalseIcon";
import CalenderIcon from "../../../../assets/icons/CalenderIcon";
import ListIcon from "../../../../assets/icons/ListIcon";
import AntSwitch from "../../../commonComponent/switch/AntiSwitch";
import TextEditorShortCuts from "../shortcuts/TextEditorShortCuts";
import { AiTextToolService, summarizeTicketConversation } from "../../../../services/AiToolService";

import SummarizeIcon from "../../../../assets/icons/SummarizeIcon";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ExpandTextIcon from "../../../../assets/icons/ExpandTextIcon";
import RephraseTextIcon from "../../../../assets/icons/RephraseTextIcon";
import StatusDropDown from "../common/statusDropDown/StatusDropDown";
import CustomeAttributeComponent from "../../../customeAttribute/CustomeAttributeComponent";
import PriorityDropdown from "../common/priorityDropdown/PriorityDropdown";
import { LightTooltip } from "../../../commonComponent/tooltip/LightTooltip";

let listIcon = {
  text: AlphabetIcon,
  number: NumberIcon,
  Decimal_Number: DecimalIcon,
  radio: TrueFalseIcon,
  date: CalenderIcon,
  select: ListIcon,
};

const attributes = [
  {
    key: "sno",
    value: "",
    display: "ID",
    editable: false,
  },
  {
    key: "title",
    value: "",
    display: "Subject",
    editable: false,
  },
  
  {
    key: "description",
    value: "",
    display: "Description",
    editable: false,
  },
];

const leadAttributes = [
  {
    key: "name",
    value: "",
    display: "Name",
    editable: false,
  },
  {
    key: "email",
    value: "",
    display: "Email",
    editable: false,
  },
  {
    key: "phone",
    value: "",
    display: "Phone",
    editable: false,
  },
];
const messageTypeObj = {
  REPLY: "REPLY",
  NOTE: "NOTE",
};

const currentAccordian = ["linked-tickets", "attributes", "leadData"];
const MessageBarComponent = ({ currentTicket, handleFetchFilterTicketDetails=()=>{}, setCurrentTicket }) => {
  const { handleOpenModal, customeFieldsData, allMacros } = useContext(MainContext);
  const [toggleDetailComponent, setToggleDetailComponent] = useState(false);
  const [textAreaMsg, setTextAreaMsg] = useState({
    value: "",
    content: "",
  });
  const [cardMessages, setCardMessages] = useStateCallback([]);
  const [updatedKeys, setUpdatedKeys] = useState([]);
  const [ticketDetail, setTicketDetail] = useState({});
  const [ticketLoading, setTicketLoading] = useState(true);
  const [cardAttributes, setCardAttributes] = useState({});
  const [currentOpen, setCurrentOpen] = useState("");
  const [ticketConverDetails, setTicketConverDetails] = useState({});
  const [messageType, setMessageType] = useState(messageTypeObj.REPLY);
  const [customFields, setCustomFields] = useState([]);
  const [currentCustomFieldData, setCurrentCustomFieldData] = useState({});
  const [summarizeText, setSummarizeText] = useState("")
  const [summarizeLoading, setSummarizeLoading] = useState(false)
  const [customFieldMode, setCustomFieldMode] = useState({
    type:'view',
    fieldValue:"",
    element:{},
    editIndex:null
  });
  const socket = useSocket();
  const params = useParams();
  const messageRef = useRef();

  const location = useLocation()
  const [ref, scrollTopContent] = useScrollHook();
  useEffect(()=>{
    if(Object.keys(currentTicket).length === 0) {
      handleFetchFilterTicketDetails(params.ticketId, false)
    }else{
      handleFetchFilterTicketDetails(params.ticketId, true)
    }
  },[])

  const handleSummarizeTicketConversation = () => {
    setSummarizeLoading(true)
    summarizeTicketConversation(params.ticketId).then((res) => {
      let response = get(res, "data.data", "");
      setSummarizeText(response);
      setSummarizeLoading(false)
    }).catch(err=>{
      setSummarizeLoading(false)
      setSummarizeText('error')
    })
  }

  const handleToggleDetailComponent = () => {
    setToggleDetailComponent(!toggleDetailComponent);
  };

  useEffect(() => {
    setTicketConverDetails(socket.coversationDetails);
  }, [socket.coversationDetails]);

  
  const aiToolApis = {
    rephrase:"",
    expand:"",
    summarize:""

  }
  
  const handleSummarizeTextThroughAi = (path) => {
    let tempTextAreaValue = {
      value:textAreaMsg.value,
      content:textAreaMsg.content
    }
    let tempContent =  convertTextToDelta("Loading.......")
    let contents = convertDeltaToText(textAreaMsg.content);
    if(contents.trim().length){
      setTextAreaMsg({
        value:"",
        content:tempContent
      })
      
      AiTextToolService(path,{text:contents}).then(res=>{
        let response = get(res,'data.data','')
        let tempContents =  convertTextToDelta(response)
        setTextAreaMsg({
          value:"",
          content:tempContents
        })
      }).catch(err=>{
        setTextAreaMsg(tempTextAreaValue)
      })
    }
  }

  const handleSetNewMessage = () => {
    if (textAreaMsg.value.length) {
      let ids = {
        mentionIds: [],
        tagIds: [],
      };
      textAreaMsg.content.forEach((ele) => {
        let editorData = get(ele, "insert.mention", {});
        let idType = get(editorData, "denotationChar", "");
        if (idType == "#") {
          ids.tagIds.push(editorData.id);
        } else if (idType == "@") {
          ids.mentionIds.push(editorData.id);
        }
      });
      socket.createMessage(textAreaMsg.value, ids, params.ticketId);
      setTextAreaMsg({
        value: <></>,
        content: "",
      });
    }
  };

  useEffect(() => {
    if (socket.socket) {
      // socket.onUserClicked({ ticketId: params.ticketId });
    }
    scrollContent();
  }, []);

  useEffect(() => {
    setCardMessages(get(socket, `messages[${params.ticketId}]`, []), () => {
      scrollContent();
    });
  }, [socket.messages, params.ticketId]);

  useEffect(() => {
    scrollContent();
  }, [socket.clientStartTyping]);

  const typingStart = () => {
    socket.onTypingStartEvent();
  };

  const typingEnd = () => {
    socket.onTypingStopEvent(textAreaMsg);
  };

  const onTyping = useCallback(
    debounceWithImmediate(typingEnd, typingStart, 2000),
    [socket.socket]
  );

  const scrollContent = () => {
    scrollTopContent();
  };

  const handleUpdateTicketAttributes = (attrType) => {
    let updatedObj = {};
    cardAttributes[attrType].forEach((ele) => {
      updatedObj[ele.key] = ele.value;
    });
    if (attrType == "leadAttributes") {
      let customerId = get(ticketDetail, "customer.id", "");
      updateCustomerDetails(customerId, updatedObj).then((res) => {
        getTicketDetail();
      });
    } else {
      updateTicketData({
        ticketId: params.ticketId,
        updatedData: updatedObj,
      }).then((res) => {
        getTicketDetail();
      });
    }
  };

  const getTicketDetail = () => {
    setTicketLoading(true);
    getTicketDetails({ ticketId: params.ticketId })
      .then((res) => {
        let ticketDetail = get(res, "data.data", {});
        setTicketDetail(ticketDetail);
        setTicketLoading(false);
      })
      .catch((err) => {
        setTicketDetail({});
        setTicketLoading(false);
      });
  };
  useEffect(() => {
    let tempAttr = { ...ticketDetail };
    let attr = {};
    let customerData = get(tempAttr, "customer", {});
    let cardAttributes = [...JSON.parse(JSON.stringify(attributes))];
    let customerAttributes = [...JSON.parse(JSON.stringify(leadAttributes))];
    customerAttributes.forEach((ele) => {
      ele.value = get(customerData, `${ele.key}`, "");
      ele.editable = false;
    });
    cardAttributes.forEach((ele) => {
      ele.value = get(tempAttr, `${ele.key}`, "");
      ele.editable = false;
    });
    attr["attribute"] = cardAttributes;

    if (Object.keys(customerData).length) {
      attr["leadAttributes"] = customerAttributes;
    }
    setCardAttributes(attr);
  }, [ticketDetail]);

  useEffect(() => {
    getTicketDetail();
  }, [params.ticketId]);

  useEffect(() => {
  }, [ticketDetail]);

  const handleSetAttributEditable = (index, type) => {
    let allAttributes = { ...cardAttributes };
    allAttributes[type][index]["editable"] = true;
    // return
    setCardAttributes(allAttributes);
  };

  const handleCollectAttribute = (event) => {
    let tempAttributes = { ...cardAttributes };
    let targetObj = event.target.name.split("-");
    let index = targetObj[0];
    let type = targetObj[2];
    tempAttributes[type][index].value = event.target.value;
    setCardAttributes(tempAttributes);
  };

  const handleUpdateTicketDetails = (data) => {
     return new Promise((resolve,reject)=>{
      updateTicketData({ ticketId: params.ticketId, updatedData: data }).then(
        (res) => {
          resolve(res)
          getTicketDetail();
        }
      ).catch(err=>{
        reject(err)
      })
     })
  };

  const handleUpdateTicketTag = (data) => {
    updateTicketTag({ ticketId: params.ticketId, updatedData: data }).then(
      (res) => {
        getTicketDetail();
      }
    );
  };

  const handleUpdateTicketTopic = (data) => {
    updateTicketTopic({ ticketId: params.ticketId, updatedData: data }).then(
      (res) => {
        getTicketDetail();
      }
    );
  };

  const handleUpdateTicketType = (data) => {
    updateTicketData({ ticketId: params.ticketId, updatedData: data }).then(
      (res) => {
        getTicketDetail();
      }
    );
  };

  let typeSender = "reciver";


  const [loadMoreCalled, setLoadMoreCalled] = useState(false);
  const handleFetchMoreConversation = () => {
    getTicketConversation(params.ticketId, ticketConverDetails.nextPage).then(
      (res) => {
        let convDetails = { ...get(res, "data.data") };
        delete convDetails["docs"];
        setTicketConverDetails(convDetails);
        let conver = get(res, "data.data.docs", []);
        let tempMessages = [...cardMessages];
        tempMessages.push(...conver);
        setLoadMoreCalled(false);

        setCardMessages(tempMessages);
      }
    );
  };

  useEffect(() => {
    const scrollableDiv = document.getElementById("scrollableDiv");
    const handleScroll = (event) => {
      if (
        !loadMoreCalled &&
        scrollableDiv.scrollTop * -1 >= scrollableDiv.scrollHeight - 800 &&
        get(ticketConverDetails, "hasNextPage", false)
      ) {
        handleFetchMoreConversation();
        setLoadMoreCalled(true);
      }
    };
    scrollableDiv.addEventListener("scroll", handleScroll);
    return () => {
      scrollableDiv.removeEventListener("scroll", handleScroll);
    };
  }, [loadMoreCalled, ticketConverDetails]);

  const handleSummarizeContent = () => {
    handleOpenModal({
      state: true,
      Component: <SummarizeText data={{}} />,
    });
  };

  const handleSetValueThroughCustomFields = () => {
    let payloadData = {
      entityId: params.ticketId,
      fieldValue: currentCustomFieldData.parent,
    };
    let id = currentCustomFieldData.child.id;
    setValueUsingCustomFields(id, payloadData).then((res) => {
      getTicketDetail();
      setCurrentCustomFieldData({});
    });
  };

  let customFieldsIds = Object.keys(get(ticketDetail, "customFields", {}));
  useEffect(() => {
    if (customFieldsIds.length) {
      let filtersKeys = [];

      customeFieldsData["ticket"].forEach((ele) => {
        if (customFieldsIds.includes(ele.id)) {
          filtersKeys.push(ele);
        }
      });
      setCustomFields(filtersKeys);
    }
  }, [ customFieldsIds.length, customeFieldsData]);

  const handleUpdateCustomFieldData = () => { 
    let payloadData = {
    entityId: params.ticketId,
    fieldValue: customFieldMode.fieldValue,
  };
  let id = customFieldMode.element.id;
  setValueUsingCustomFields(id, payloadData).then((res) => {
    getTicketDetail();
    setCustomFieldMode({
      type:'view',
      fieldValue:"",
      element:{},
      editIndex:null
      })
    });
  }

  const handleOpenShortCuts = () => {
    handleOpenModal({
      state:true,
     Component:<TextEditorShortCuts handleSummarizeTextThroughAi={handleSummarizeTextThroughAi} handleSetMicroStateInEditor={handleSetMicroStateInEditor}/>
      })

  }

  useEffect(()=>{
  },[textAreaMsg])


  const handleSetMicroStateInEditor = (element) => {
    let plainText = element.message;
    let normal = variabledText(plainText, ticketDetail);
    let delta = convertTextToDelta(normal)

    setTextAreaMsg({
      value:"",
      content:delta
    })
  }
  return (
    <>
      <MainComponent className={style.inboxSecondComponent}>
       <Box  className="mainComponentClass" >
       <Toolbar sx={{ padding: "0px 4px !important", background: "#FAFBFF" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <Avatar>
                {get(get(currentTicket, "title", ""), "[0]", "").toUpperCase()}
              </Avatar>
              {/* <Box sx={{marginTop:'10px'}} > */}
              <Typography
                sx={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {get(currentTicket, "title", "")}
              </Typography>
              {/* <Typography
                    sx={{
                      fontWeight: "bold",
                      cursor: "pointer",
                      fontSize:'10px',
                      visibility:socket.clientStartTyping ?'visible' :'hidden'
                    }}
                  >
                    typing...
                  </Typography> */}
              {/* </Box> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
                justifyContent: "flex-end",
              }}
            >
              <PriorityDropdown value={get(ticketDetail, "priority", "0")}  handleUpdateStatus={(id)=>{
                return handleUpdateTicketDetails({
                  priority:id
                })
              }}  />
              {/* <MiniCircle
                style={{ background: "transparent" }}
                onClick={() => {
                  handleUpdateTicketDetails({
                    priority: !get(ticketDetail, "priority", 0),
                  });
                }}
              >
                <StartIcon
                  color={
                    get(ticketDetail, "priority", 0) ? "orange" : "#707C97"
                  }
                />
              </MiniCircle> */}
              {/* <MiniCircle style={{}}>
                <TripleDotMenu height={15} width={15} />
              </MiniCircle> */}
              {get(ticketDetail, "status", "submitted") !== "closed" ? (
                <>
                  {/* <MiniCircle
                    onClick={() =>
                      handleUpdateTicketDetails({ status: "snoozed" })
                    }
                    style={{
                      background:
                        get(ticketDetail, "status", "closed") === "snoozed"
                          ? "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)"
                          : null,
                    }}
                  >
                    <MoonIcon
                      color={
                        get(ticketDetail, "status", "closed") === "snoozed"
                          ? "white"
                          : "#707C97"
                      }
                    />
                  </MiniCircle> */}
                </>
              ) : null}

              <StatusDropDown  handleUpdateStatus={(id)=>{
                return handleUpdateTicketDetails({
                  status:id
                })
              }}     value={get(ticketDetail, "status", "closed")} />

              {/* <MiniCircle
                onClick={() => {
                  handleUpdateTicketDetails({
                    status:
                      get(ticketDetail, "status", "closed") === "submitted"
                        ? "closed"
                        : "submitted",
                  });
                }}
                style={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                }}
              >
                <DownloadIcon
                  height="18"
                  width="18"
                  style={{
                    transform: `rotate(${
                      get(ticketDetail, "status", "") !== "closed"
                        ? "0deg"
                        : "180deg"
                    })`,
                  }}
                />
              </MiniCircle> */}

              {!toggleDetailComponent && (
                <MiniCircle style={{ background: "transparent" }}>
                  <LayoutLeft onClick={handleToggleDetailComponent} />
                </MiniCircle>
              )}
            </Box>
          </Box>
        </Toolbar>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // overflowY: "scroll",
            gap: "10px",
            height: "90vh",
            overflowY: "hidden",
            padding: "0px",
            // width:'50%'
          }}
        >
          <Box
            className="message-content"
            sx={{
              display: "flex",
              flexDirection: "column-reverse",
              gap: "20px",
              height: "76vh",
              overflow: "scroll",
              padding: "0px 0px 20px 0px !important",
            }}
            ref={ref}
            id="scrollableDiv"
          >
            {socket.clientStartTyping ? (
              <>
                <MessageBox
                  messageTime={""}
                  message={"typing....."}
                  type={"reciver"}
                />
              </>
            ) : null}

            {cardMessages.map((ele, index) => {
              let time = false;
              // if(  ele["userType"] !== get(cardMessages, `[${index + 1}]['userType']`, "")){
              //   time = true
              // }else{
              //   time = false
              // }
              return (
                <MessageBox
                  createdAt={time ? get(ele, "createdAt", "") : ""}
                  message={ele.message}
                  messageType={ele.type}
                  type={ele.userType === "customer" ? "reciver" : "sender"}
                />
              );
            })}
          </Box>
          <Divider></Divider>
          <Box
            className="message-box"
            sx={{
              height: "18vh",
              position: "relative",
              background: messageType === "NOTE" && "#efe986",
            }}
          >
            <Box
              sx={{
                display: "flex",
                // overflow:"hidden",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <SendingDropDown
                  messageType={messageType}
                  setMessageType={setMessageType}
                />

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignContent: "flex-end",
                    padding: "1px",
                  }}
                >
                  <MiniCircle
                    onClick={handleOpenShortCuts}
                    style={{
                      background: "#d3e1eb",
                      height: "25px  !important",
                      width: "23px !important",
                    }}
                  >
                    <LightningFilledIcon height={16} width={16} />
                  </MiniCircle>

             
                </Box>
              </Box>
              <MiniCircle
                style={{
                  background: `linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)`,
                  height: "25px  !important",
                  width: "23px !important",
                  position: "absolute",
                  right: "15px",
                  bottom: "0px",
                  marginBottom: "5px",
                }}
                onClick={handleSetNewMessage}
              >
                <SendingButton height={10} width={10} />
              </MiniCircle>

              <MessageTextBox
                onTyping={onTyping}
                textAreaMsg={textAreaMsg}
                setTextAreaMsg={setTextAreaMsg}
                handleSetNewMessage={handleSetNewMessage}
              />
            </Box>
          </Box>
        </Box>
       </Box>
      </MainComponent>
      {toggleDetailComponent && (
        <>
          <MainComponent
          className={style.inboxSideToolbar}
            style={{
              minWidth: "300px",
              paddingLeft: "0px",
              background: "white",
              borderLeft: "1px solid  #f4f4fb",
            }}
          >
            <Box className="sideToolbarCard" >
            
            <Toolbar sx={{ padding: "0px 4px !important" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <Typography sx={{ fontWeight: "bold" }}> Details </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                    justifyContent: "flex-end",
                  }}
                >
                  <MiniCircle style={{ background: "transparent" }}>
                    <ExternalLinkIcon height={20} width={20} color="#707C97" />
                  </MiniCircle>
                  <MiniCircle
                    style={{ background: "transparent" }}
                    onClick={handleToggleDetailComponent}
                  >
                    <CrossIcon />
                  </MiniCircle>
                </Box>
              </Box>
            </Toolbar>
            <Box sx={{ maxHeight: "89vh", overflow: "scroll" }}>
              {ticketLoading ? (
                <Stack spacing={1}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((ele) => (
                    <Skeleton
                      variant="rectangular"
                      width={"100%"}
                      height={10}
                    />
                  ))}
                </Stack>
              ) : (
              <Box  sx={{display:'flex', flexDirection:'column', gap:'1px'}} >
                   <AccordionComponent
                   defaultOpen={true}
                    className={style.inboxSideToolbarCard1}
                    heading={"Ticket data"}
                  >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems:'center',
                        width: "100%",
                        padding: "0px 15px",
                      }}
                    >
                      <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                        Assignee
                      </Typography>
                      <Typography
                        sx={{
                          color: "#707C97",
                          fontSize: "15px",
                          cursor: "pointer",
                        }}
                      >
                        <MenuBarCommon
                          handleDelete={() => {
                            handleUpdateTicketDetails({ assigneeId: null })
                          }}
                          isDeleted={
                            get(ticketDetail, "assignee.name", "").length
                          }
                          style={{
                            background: "transparent !important",
                            fontSize: "15px",
                            color: "gray !important",
                            padding: "0px !important ",
                            span: { padding: "0px" },
                            ".deletedIconClass": { color: "gray !important" },
                            gap: "10px",
                          }}
                          onSelectFilter={({ id }) =>
                            handleUpdateTicketDetails({ assigneeId: id })
                          }
                          selected={false}
                          
                          MenuComponent={AssignedToMenu}
                          Icon={() => <></>}
                          label={
                            get(ticketDetail, "assignee.name", "") ||
                            "Unassigned"
                          }
                        />
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "0px 15px",
                      }}
                    >
                      <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                        Team
                      </Typography>
                      <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                        <MenuBarCommon
                          handleDelete={() => {}}
                          isDeleted={get(ticketDetail, "team.name", "").length}
                          style={{
                            background: "transparent !important",
                            fontSize: "15px",
                            color: "gray !important",
                            padding: "0px !important ",
                            span: { padding: "0px" },
                            ".deletedIconClass": { color: "gray !important" },
                            gap: "10px",
                          }}
                          onSelectFilter={({ id }) =>
                            handleUpdateTicketDetails({ teamId: id })
                          }
                          selected={false}
                          MenuComponent={TeamMenu}
                          Icon={() => <></>}
                          label={get(ticketDetail, "team.name", "") || "N/A"}
                        />
                      </Typography>
                    </Box>
                  </Box>
                  </AccordionComponent>




                  <AccordionComponent
                   defaultOpen={true}
                    className={style.inboxSideToolbarCard1}
                    heading={"User data"}
                  >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "0px",
                    }}
                  >



                  <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "5px",
                      }}
                    >
                      {get(cardAttributes, "attribute", []).map(
                        (ele, index) => (
                          <>
                            <Box
                              key={ele.key}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "0px 0px",
                              }}
                            >
                              <Typography
                                sx={{ color: "#707C97", fontSize: "15px" }}
                              >
                                {ele.display}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#707C97",
                                  fontSize: "15px",
                                  cursor: "auto",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  textAlign: "center",
                                  gap: "5px",
                                }}
                              >
                                {ele.editable ? (
                                  <>
                                    <Paper
                                      component="box"
                                      sx={{
                                        p: "1px 1px",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "80%",
                                        height: 20,
                                        padding: "10px",
                                        boxShadow: "none",
                                        border: "1px solid rgba(0,0,0,0.2)",
                                      }}
                                    >
                                      <InputBase
                                        value={ele.value}
                                        name={`${index}-${ele.key}-attribute`}
                                        sx={{
                                          ml: 1,
                                          flex: 1,
                                          fontSize: "12px",
                                        }}
                                        placeholder={ele.display}
                                        onChange={handleCollectAttribute}
                                      />
                                    </Paper>
                                    <CheckCircleOutlineRoundedIcon
                                      onClick={() =>
                                        handleUpdateTicketAttributes(
                                          "attribute"
                                        )
                                      }
                                      sx={{
                                        fontSize: "18px",
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </>
                                ) : ele.value ? (
                                  <>
                                    <p
                                      style={{
                                        margin: "0px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleSetAttributEditable(
                                          index,
                                          "attribute"
                                        )
                                      }
                                    >
                                      {ele.value.toString().substring(0, 10)}
                                      {ele.value.toString().length > 10
                                        ? ".."
                                        : ""}
                                    </p>{" "}
                                    {/* <EditRoundedIcon
                                    style={{ color: "green", fontSize: "18px", cursor:'pointer' }}
                                    onClick={()=>handleSetAttributEditable(index,'attribute')}
                                  />{" "} */}
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      color: "#8F1EB3",
                                      margin: 0,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleSetAttributEditable(
                                        index,
                                        "attribute"
                                      )
                                    }
                                  >
                                    {" "}
                                    +Add{" "}
                                  </p>
                                )}
                              </Typography>
                            </Box>
                          </>
                        )
                      )}
                    </Box>

                    {/* custom fields attributes start */}
                  






























            <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "0px 0px",
                        marginTop:"4px"
                      }}
                    >
                      <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                        Tags
                      </Typography>

                      <Tooltip
                        title={get(ticketDetail, "tags", [])
                          .map((ele) => ele.name)
                          .join(" , ")}
                        placement="top"
                      >
                        <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                          <MenuBarCommon
                            handleDelete={() => {}}
                            isDeleted={get(ticketDetail, "tags", []).length}
                            style={{
                              background: "transparent !important",
                              fontSize: "15px",
                              color: "gray !important",
                              padding: "0px !important ",
                              span: { padding: "0px" },
                              maxWidth: "150px",
                              ".deletedIconClass": { color: "gray !important" },
                              gap: "10px",
                            }}
                            onSelectFilter={({ id }) =>
                              handleUpdateTicketTag({ entity_id: id })
                            }
                            selected={false}
                            MenuComponent={TagsMenuBar}
                            Icon={() => <></>}
                            label={
                              get(ticketDetail, "tags", [])
                                .map((ele) => ele.name)
                                .join(" , ") || "N/A"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "0px 0px",
                      }}
                    >
                      <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                        Topic
                      </Typography>

                      <Tooltip
                        title={get(ticketDetail, "topics", [])
                          .map((ele) => ele.name)
                          .join(" , ")}
                        placement="top"
                      >
                        <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                          <MenuBarCommon
                            handleDelete={() => {}}
                            isDeleted={get(ticketDetail, "topics", []).length}
                            style={{
                              background: "transparent !important",
                              fontSize: "15px",
                              color: "gray !important",
                              padding: "0px !important ",
                              span: { padding: "0px" },
                              maxWidth: "150px",
                              ".deletedIconClass": { color: "gray !important" },
                              gap: "10px",
                            }}
                            onSelectFilter={({ id }) =>
                              handleUpdateTicketTopic({ entity_id: id })
                            }
                            selected={false}
                            MenuComponent={TopicMenu}
                            Icon={() => <></>}
                            label={
                              get(ticketDetail, "topics", [])
                                .map((ele) => ele.name)
                                .join(" , ") || "N/A"
                            }
                          />
                        </Typography>
                      </Tooltip>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        padding: "0px 0px",
                      }}
                    >
                      <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                        Ticket-Type
                      </Typography>

                      <Tooltip
                        title={get(ticketDetail, "type.name", "")}
                        placement="top"
                      >
                        <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                          <MenuBarCommon
                            handleDelete={() => {}}
                            isDeleted={
                              get(ticketDetail, "type.name", "").length
                            }
                            style={{
                              background: "transparent !important",
                              fontSize: "15px",
                              color: "gray !important",
                              padding: "0px !important ",
                              span: { padding: "0px", maxWidth: "150px" },
                              ".deletedIconClass": { color: "gray !important" },
                              gap: "10px",
                            }}
                            onSelectFilter={({ id }) =>
                              handleUpdateTicketType({ typeId: id })
                            }
                            selected={false}
                            MenuComponent={TicketTypesMenuBar}
                            Icon={() => <></>}
                            label={get(ticketDetail, "type.name", "") || "N/A"}
                          />
                        </Typography>
                      </Tooltip>
                    </Box>

                    {ticketDetail.hasOwnProperty("sentiment") ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          padding: "0px 0px",
                        }}
                      >
                        <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                          Sentiment
                        </Typography>

                        <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color: "gray",
                          }}
                        >
                          {get(ticketDetail, "sentiment.text", "") ===
                          "negative" ? (
                            <>
                              <SentimentVeryDissatisfiedIcon color="error" /> (
                              {get(ticketDetail, "sentiment.score", 0)})
                            </>
                          ) : (
                            <>
                              <SentimentVerySatisfiedIcon color="success" /> (
                              {get(ticketDetail, "sentiment.score", 0)})
                            </>
                          )}
                        </Typography>
                      </Box>
                    ) : null}

                    {ticketDetail.hasOwnProperty("intents") && get(ticketDetail, "intents", []).length ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          padding: "0px 0px",
                        }}
                      >
                        <Typography sx={{ color: "#707C97", fontSize: "15px" }}>
                          Intents
                        </Typography>

                        <Typography
                          sx={{
                            color: "gray",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          {get(ticketDetail, "intents", []).map((ele) => (
                            <LightTooltip  title={
                             <Typography sx={{fontSize:"13px"}} >
                               {ele.key}
                             </Typography>
                            } >
                            <span>
                              {ele.key.substr(0, 10)}.. [{ele.confidence}]
                            </span>
                            </LightTooltip>
                          ))}
                        </Typography>
                      
                      </Box>
                    ) : null}
                      <CustomeAttributeComponent  handleGetComponentDetails={getTicketDetail} componentDetails={ticketDetail} componentEntityId={get(params,'ticketId','')}   componentTyp="ticket"  />

                  </Box>


                  </AccordionComponent>
                  {/* <AccordionComponent
                    open={currentOpen === currentAccordian[0]}
                    className={style.commonAccordionClass}
                    heading={"Linked Tickets"}
                    onClick={() =>
                      setCurrentOpen(
                        currentOpen == currentAccordian[0]
                          ? ""
                          : currentAccordian[0]
                      )
                    }
                  >
                    <Button
                      variant="contained"
                      sx={{
                        width: "100%",
                        borderRadius: "6px",
                        textTransform: "inherit",
                        background:
                          "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                      }}
                    >
                      Create linked ticket
                    </Button>
                  </AccordionComponent> */}

                  {/* <AccordionComponent
                    onClick={() =>
                      setCurrentOpen(
                        currentOpen == currentAccordian[1]
                          ? ""
                          : currentAccordian[1]
                      )
                    }
                    open={currentOpen == currentAccordian[1]}
                    heading={"Attributes"}
                    className={style.commonAccordionClass}
                  >
                 
                  </AccordionComponent> */}

                  <AccordionComponent
                  default
                    onClick={() =>
                      setCurrentOpen(
                        currentOpen == currentAccordian[2]
                          ? ""
                          : currentAccordian[2]
                      )
                    }
                    open={currentOpen == currentAccordian[2]}
                    className={style.commonAccordionClass}
                    heading={"Lead Data"}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      {get(cardAttributes, "leadAttributes", []).map(
                        (ele, index) => (
                          <>
                            <Box
                              key={ele.key}
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                padding: "0px 10px",
                              }}
                            >
                              <Typography
                                sx={{ color: "#707C97", fontSize: "15px" }}
                              >
                                {ele.display}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#707C97",
                                  fontSize: "15px",
                                  cursor: "auto",
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  textAlign: "center",
                                  gap: "5px",
                                }}
                              >
                                {ele.editable ? (
                                  <>
                                    <Paper
                                      component="box"
                                      sx={{
                                        p: "1px 1px",
                                        display: "flex",
                                        alignItems: "center",
                                        width: "80%",
                                        height: 20,
                                        padding: "10px",
                                        boxShadow: "none",
                                        border: "1px solid rgba(0,0,0,0.2)",
                                      }}
                                    >
                                      <InputBase
                                        value={ele.value}
                                        name={`${index}-${ele.key}-leadAttributes`}
                                        sx={{
                                          ml: 1,
                                          flex: 1,
                                          fontSize: "12px",
                                        }}
                                        placeholder={ele.display}
                                        onChange={handleCollectAttribute}
                                      />
                                    </Paper>
                                    <CheckCircleOutlineRoundedIcon
                                      onClick={() =>
                                        handleUpdateTicketAttributes(
                                          "leadAttributes"
                                        )
                                      }
                                      sx={{
                                        fontSize: "18px",
                                        color: "green",
                                        cursor: "pointer",
                                      }}
                                    />
                                  </>
                                ) : ele.value ? (
                                  <>
                                    <p
                                      style={{
                                        margin: "0px",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {ele.value.toString().substring(0, 10)}
                                      {ele.value.toString().length > 10
                                        ? ".."
                                        : ""}
                                    </p>{" "}
                                    <EditRoundedIcon
                                      style={{
                                        color: "green",
                                        fontSize: "18px",
                                        cursor: "pointer",
                                      }}
                                      onClick={() =>
                                        handleSetAttributEditable(
                                          index,
                                          "leadAttributes"
                                        )
                                      }
                                    />{" "}
                                  </>
                                ) : (
                                  <p
                                    style={{
                                      color: "#8F1EB3",
                                      margin: 0,
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      handleSetAttributEditable(
                                        index,
                                        "leadAttributes"
                                      )
                                    }
                                  >
                                    {" "}
                                    +Add{" "}
                                  </p>
                                )}
                              </Typography>
                            </Box>
                          </>
                        )
                      )}
                    </Box>
                  </AccordionComponent>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        width: "80%",
                        borderRadius: "6px",
                        textTransform: "inherit",
                        background:
                          "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                      }}
                      onClick={() => {
                        !summarizeLoading && handleSummarizeTicketConversation()
                        // summarizeTextApi({
                        //   query:'Pullse AI is a centralized customer support platform that leverages the power of artificial intelligence (AI) to provide a seamless experience for both customers and support agents. With Pullse AI, businesses can automate routine tasks, such as answering frequently asked questions or routing incoming inquiries to the appropriate department. This automation not only saves time and effort for support agents but also ensures that customers receive prompt and accurate responses. Additionally, Pullse AI can enhance agent performance by providing real-time insights and suggestions, allowing agents to deliver personalized and efficient support. Overall, Pullse AI is designed to improve the customer support process by combining the capabilities of AI with the expertise of human agents.'
                        // })
                      }}
                    >
                    {summarizeLoading ?  "Summarizing..." :" Summarize Text"}
                    </Button>
                  </Box>
                  <Box sx={{padding:"15px"}}>
                    <Typography  sx={{color:"gray", fontSize:"13px"}} >
                      {
                       summarizeLoading ? "loading...." : summarizeText
                      }
                    </Typography>
                  </Box>
                </Box>
              )}

            </Box>
            </Box>
          </MainComponent>
        </>
      )}
    </>
  );
};

export default MessageBarComponent;
