import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  Paper,
  TextareaAutosize,
  Typography,
} from "@mui/material";
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from "@mui/icons-material/Close";
import InputAdornment from "@mui/material/InputAdornment";
import { allCustomers, createTicket } from "../../../../services/Apis";
import { MainContext } from "../../../../context/MainProvider";
import { PullseTextArea } from "../../../commonComponent/pullse-text-area/PullseTextArea";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { get } from "lodash";
import { debounceWithImmediate } from "../../../../utils/helpers/helpers";
import { SidebarContext } from "../../../../context/SidebarProvider";
const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
  { title: 'Pulp Fiction', year: 1994 },
  {
    title: 'The Lord of the Rings: The Return of the King',
    year: 2003,
  },
  { title: 'The Good, the Bad and the Ugly', year: 1966 },
  { title: 'Fight Club', year: 1999 },
  {
    title: 'The Lord of the Rings: The Fellowship of the Ring',
    year: 2001,
  },
  {
    title: 'Star Wars: Episode V - The Empire Strikes Back',
    year: 1980,
  },
  { title: 'Forrest Gump', year: 1994 },
  { title: 'Inception', year: 2010 },
  {
    title: 'The Lord of the Rings: The Two Towers',
    year: 2002,
  },
  { title: "One Flew Over the Cuckoo's Nest", year: 1975 },
  { title: 'Goodfellas', year: 1990 },
  { title: 'The Matrix', year: 1999 },
  { title: 'Seven Samurai', year: 1954 },
  {
    title: 'Star Wars: Episode IV - A New Hope',
    year: 1977,
  },
  { title: 'City of God', year: 2002 },
  { title: 'Se7en', year: 1995 },
  { title: 'The Silence of the Lambs', year: 1991 },
  { title: "It's a Wonderful Life", year: 1946 },
  { title: 'Life Is Beautiful', year: 1997 },
  { title: 'The Usual Suspects', year: 1995 },
  { title: 'Léon: The Professional', year: 1994 },
  { title: 'Spirited Away', year: 2001 },
  { title: 'Saving Private Ryan', year: 1998 },
  { title: 'Once Upon a Time in the West', year: 1968 },
  { title: 'American History X', year: 1998 },
  { title: 'Interstellar', year: 2014 },
  { title: 'Casablanca', year: 1942 },
  { title: 'City Lights', year: 1931 },
  { title: 'Psycho', year: 1960 },
  { title: 'The Green Mile', year: 1999 },
  { title: 'The Intouchables', year: 2011 },
  { title: 'Modern Times', year: 1936 },
  { title: 'Raiders of the Lost Ark', year: 1981 },
  { title: 'Rear Window', year: 1954 },
  { title: 'The Pianist', year: 2002 },
  { title: 'The Departed', year: 2006 },
  { title: 'Terminator 2: Judgment Day', year: 1991 },
  { title: 'Back to the Future', year: 1985 },
  { title: 'Whiplash', year: 2014 },
  { title: 'Gladiator', year: 2000 },
  { title: 'Memento', year: 2000 },
  { title: 'The Prestige', year: 2006 },
  { title: 'The Lion King', year: 1994 },
  { title: 'Apocalypse Now', year: 1979 },
  { title: 'Alien', year: 1979 },
  { title: 'Sunset Boulevard', year: 1950 },
  {
    title: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
    year: 1964,
  },
  { title: 'The Great Dictator', year: 1940 },
  { title: 'Cinema Paradiso', year: 1988 },
  { title: 'The Lives of Others', year: 2006 },
  { title: 'Grave of the Fireflies', year: 1988 },
  { title: 'Paths of Glory', year: 1957 },
  { title: 'Django Unchained', year: 2012 },
  { title: 'The Shining', year: 1980 },
  { title: 'WALL·E', year: 2008 },
  { title: 'American Beauty', year: 1999 },
  { title: 'The Dark Knight Rises', year: 2012 },
  { title: 'Princess Mononoke', year: 1997 },
  { title: 'Aliens', year: 1986 },
  { title: 'Oldboy', year: 2003 },
  { title: 'Once Upon a Time in America', year: 1984 },
  { title: 'Witness for the Prosecution', year: 1957 },
  { title: 'Das Boot', year: 1981 },
  { title: 'Citizen Kane', year: 1941 },
  { title: 'North by Northwest', year: 1959 },
  { title: 'Vertigo', year: 1958 },
  {
    title: 'Star Wars: Episode VI - Return of the Jedi',
    year: 1983,
  },
  { title: 'Reservoir Dogs', year: 1992 },
  { title: 'Braveheart', year: 1995 },
  { title: 'M', year: 1931 },
  { title: 'Requiem for a Dream', year: 2000 },
  { title: 'Amélie', year: 2001 },
  { title: 'A Clockwork Orange', year: 1971 },
  { title: 'Like Stars on Earth', year: 2007 },
  { title: 'Taxi Driver', year: 1976 },
  { title: 'Lawrence of Arabia', year: 1962 },
  { title: 'Double Indemnity', year: 1944 },
  {
    title: 'Eternal Sunshine of the Spotless Mind',
    year: 2004,
  },
  { title: 'Amadeus', year: 1984 },
  { title: 'To Kill a Mockingbird', year: 1962 },
  { title: 'Toy Story 3', year: 2010 },
  { title: 'Logan', year: 2017 },
  { title: 'Full Metal Jacket', year: 1987 },
  { title: 'Dangal', year: 2016 },
  { title: 'The Sting', year: 1973 },
  { title: '2001: A Space Odyssey', year: 1968 },
  { title: "Singin' in the Rain", year: 1952 },
  { title: 'Toy Story', year: 1995 },
  { title: 'Bicycle Thieves', year: 1948 },
  { title: 'The Kid', year: 1921 },
  { title: 'Inglourious Basterds', year: 2009 },
  { title: 'Snatch', year: 2000 },
  { title: '3 Idiots', year: 2009 },
  { title: 'Monty Python and the Holy Grail', year: 1975 },
];
const CreateTickets = () => {
  const { workspaceId, handleFetchTickets, handleCloseModal } =
    useContext(MainContext);
    const {  handleShowSnackPopup } = useContext(SidebarContext)
    const [customerData, setCustomerData] = useState([])
    const [ticketDetails, setTicketDetails] = useState({
    to: "",
    subject: "",
    description: "",
  });
  const [globalValidation, setGlobalValidation] = useState(false);

  const handleFetFilteredCustomers = (value="") => {
    allCustomers({type:"name",data:value}).then(res=>{
      let response = get(res,'data.data.docs',[]);
      setCustomerData(response);
    })
  }
  
  useEffect(()=>{
    handleFetFilteredCustomers()
  },[])

  const handleCollectData = (e) => {
    setTicketDetails((state) => ({
      ...state,
      [e.target.name]: e.target.value,
    }));
  };
  const handleCheckTicketValidation = (payload) => {
    let valuesArr  = Object.values(ticketDetails).filter(Boolean)
    if( valuesArr.length == 3 && valuesArr.every(ele=>ele.length > 3)){
      return false
    }else{
      setGlobalValidation(true)
      return true
    }
  }
  const handleSubmitTicket = () => {
    let payload = {
      title: ticketDetails.subject,
      description: ticketDetails.description,
      workspace_id: workspaceId,
    };
   let isError =  handleCheckTicketValidation(payload)
   if(isError) return
    createTicket(payload).then((res) => {
      handleFetchTickets().then((resolve) => {
        handleShowSnackPopup({message:"New ticket created "})
        handleCloseModal();
      });
    });
  };
  let toVal = globalValidation && !(ticketDetails.to.length > 3)
  let subVal = globalValidation && !(ticketDetails.subject.length > 3)
  let descVal = globalValidation && !(ticketDetails.description.length > 3 )

  const handleFetchData = (event) => {
    let value = event.target.value
    handleFetFilteredCustomers(value)
    handleCollectData(event)

  }

  const handleDebounceFetch = useCallback( debounceWithImmediate(handleFetchData,()=>{},600),[])


  return (
    <>
      <Box sx={{ minWidth: "500px", padding:'5px' }}>
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          {" "}
          <CloseIcon sx={{ color: "gray", cursor:'pointer' }} onClick={handleCloseModal} />{" "}
        </Box>
        <Box sx={{p:4}} >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              {" "}
              Ticket 
            </Typography>
          </Box>
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <Paper
              component="div"
              sx={{
                p: "0px 0px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 30,
                boxShadow: "none",
                borderBottom: `1px solid ${toVal ? "red" : 'rgba(0,0,0,0.2)' }`,
                borderRadius: "initial",
              }}
            >
                <Autocomplete
                onChange={(event,reason)=>{
                  handleCollectData({
                    target:{
                      name:"to",
                      value:reason
                    }
                  })
                  
        
                }}

                value={ticketDetails.to}
              
            id="free-solo-demo"
            sx={{
              width:"100%", padding:"0",
            
            }}
            placeholder="To"
           
            freeSolo
            name="to"
            options={customerData.map((option) => option.email)}
            renderInput={(params) => <TextField name="to"  placeholder="To"  InputProps={{
              ...params.InputProps,
              startAdornment:(
                <InputAdornment position="start">
                  <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                    To
                  </Typography>
                 </InputAdornment>
              )
            }} onChange={handleDebounceFetch} className="inputBox111"  sx={{
              ".MuiAutocomplete-inputRoot":{
                "&::before":{display:'none !important'},
                "&::after":{display:'none !important'},
              },
            }}  {...params}  variant="standard" />}
      />
          



          {/* <InputBase
                value={ticketDetails.to}
                onChange={handleCollectData}
                autoComplete="off"
                name="to"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="Work email"
                startAdornment={
                  <InputAdornment position="start">
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      {" "}
                      To
                    </Typography>
                  </InputAdornment>
                }
                
              />
 */}


            </Paper>
            <Paper
              component="form"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 50,
                padding: "10px",
                boxShadow: "none",
                borderBottom: `1px solid ${subVal ? "red" : 'rgba(0,0,0,0.2)' }`,
                borderRadius: "initial",
              }}
            >
              <InputBase
                onChange={handleCollectData}
                value={ticketDetails.subject}
                autoComplete="off"
                name="subject"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="Work email"
                startAdornment={
                  <InputAdornment position="start">
                    <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                      {" "}
                      Subject
                    </Typography>
                  </InputAdornment>
                }
              />
            </Paper>
          </Box>

          <Box sx={{ marginTop: "20px" }}>
            <Box
              sx={{
                display: "flex",
                marginTop: "20px",
                alignItems: "center",
                maxHeight: "300px",
              }}
            >
              <PullseTextArea 
              onChange={handleCollectData}
              aria-label="empty textarea"
              value={ticketDetails.description}
              name="description"
              maxRows={10}
              minRows={15}
              placeholder="Description"
              style={{
                outline: "none",
                width: "100%",
                resize: "none",
                fontFamily: "sans-serif",
              }}
              startAdornment={"io"}
              />
              {/* <TextareaAutosize
              
              /> */}
            </Box>
          </Box>
          <Box sx={{ marginTop: "20px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Button
                  onClick={handleSubmitTicket}
                  sx={{
                    background:
                      "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                    color: "white",
                    height: "30px",
                    textTransform: "inherit",
                    fontSize: "13px",
                    padding: "10px 40px",
                  }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateTickets;
