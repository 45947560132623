import React from "react";
import ProfileAutomation from "./profilePage/ProfileAutomation";
import { Route, Routes } from "react-router-dom";
import ProfileToolbar from "./profilePage/ProfileToolbar";
import CreateProfile from "./profilePage/create-profile/CreateProfile";
import CreateProfileToolbar from "./profilePage/create-profile/CreateProfileToolbar";
import ProfileSetupPage from "./profileSetupPage/ProfileSetupPage";
import ProfileSetupToolbar from "./profileSetupPage/ProfileSetupToolbar";

const ProfileMainComponent = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<ProfileAutomation ToolbarComponent={ProfileToolbar} />} />
        <Route path="/create" element={<CreateProfile ToolbarComponent={CreateProfileToolbar } componentType={'create'} />} />
        <Route path="/update/:id" element={<CreateProfile ToolbarComponent={CreateProfileToolbar } componentType={'update'} />} />
        <Route path="/setup/:id" element={<ProfileSetupPage  ToolbarComponent={ProfileSetupToolbar} />} />
      </Routes>
    </>
  );
};

export default ProfileMainComponent;
