import React, { useContext } from "react";
import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import OpenIcon from "../../../../assets/searchIcons/OpenIcon";
import ClosedIcon from "../../../../assets/searchIcons/ClosedIcon";
import SnoozedIcon from "../../../../assets/searchIcons/SnoozedIcon";
import { MainContext } from "../../../../context/MainProvider";

const data = [
  {
    name: "Newest",
    color: "#E89D73",
    Icon: ()=><></>,
    key: "Newest",
    value:0
  },
  {
    name: "Priorities",
    color: "#E89D73",
    Icon: ()=><></>,
    key: "Priorities",
    value:1
  },
];

const PriorityMenuBar = ({ handleClose, onSelectFilter }) => {
  const { handleFetchTicketForInbox } = useContext(MainContext);
  const handleChangeTicketStatus = (key,value) => {
    onSelectFilter({ type: "priority", value: value });
    handleFetchTicketForInbox({ type: "priority", data: value});
    handleClose();
  };

  return (
    <>
      <Box sx={{ width: "100px", padding: "0px" }} className={"box"}>
        <List
          dense
          sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
        >
          {data.map((value) => {
            const labelId = `checkbox-list-secondary-label-${value.name}`;
            let Icon = value.Icon;

            return (
              <ListItem
                key={value}
                disablePadding
                onClick={() => handleChangeTicketStatus(value.key, value.value)}
                sx={{textAlign:'center'}}
              >
                <ListItemButton  sx={{textAlign:'center'}} >
                  <ListItemText id={labelId} primary={value.name} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>
    </>
  );
};

export default PriorityMenuBar;
