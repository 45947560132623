import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../components/auth/login/Login";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Welcome from "../components/auth/welcome/Welcome";
import ChangePassword from "../components/auth/changePassword/ChangePassword";
import { PrivateRoute, PublicRoute } from "../utils/helpers/helpers";
import Dashboard from "../components/sidebar/Sidebar";
import { nestedRoute } from "./nestedRoutes";
import SocketProvider from "../context/SocketProvider";
import MainProvider from "../context/MainProvider";
import Login2 from "../components/auth/login/Login2";
import ForgetPassword from "../components/auth/forgetPassword/ForgetPassword";
import ResetPassword from "../components/auth/resetPassword/ResetPassword";
const nestedPaths = ["/inbox", "/automation", "/support","/contacts",'/reports'];
const Routers = ({setPullseChatData}) => {
   
  return (
    <Routes>
      <Route
        path="/"
        index
        element={
          <>
            <PublicRoute>
              <GoogleOAuthProvider clientId="705666696791-oequp9f2rdtgp2sbm779ep9djbifukhm.apps.googleusercontent.com">
               <Login2 />
              </GoogleOAuthProvider>
            </PublicRoute>
          </>

        }
      />
      <Route
        path="forget-password"
        element={
          <PublicRoute>
            <ForgetPassword />
          </PublicRoute>
        }
      />
        <Route
        path="reset-password/:id"
        element={
          <PublicRoute>
            <ResetPassword />
          </PublicRoute>
        }
      />
      <Route
        path="welcome"
        element={
          <>
            <PublicRoute>
              <Welcome />
            </PublicRoute>
          </>
        }
      />
      <Route
        path="change-password/:token"
        element={
          <>
            <PublicRoute>
              <ChangePassword />
            </PublicRoute>
          </>
        }
      />
      
        {nestedRoute.map(({parentPath,childRoutes},index) => (
        <Route
          
        
        
          key={parentPath + index}
          path={parentPath + "/*"}
          element={<PrivateRoute key={parentPath}  >
            <MainProvider>
            <SocketProvider>
            <Dashboard key={parentPath}  childRoutes={childRoutes} setPullseChatData={setPullseChatData} />
            </SocketProvider>
            </MainProvider>
            </PrivateRoute>}
        />
      ))}

      <Route path="*"  element={<>Not Found</>}  />

      
    </Routes>
  );
};

export default Routers;
