import React from "react";

const MessageTextIcon = ({height=40, width=40, color="#313131"}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M12 12H26M12 20H20M19.4783 27.6522L11.1304 36V27.6522H8C5.79086 27.6522 4 25.8613 4 23.6522V8C4 5.79086 5.79086 4 8 4H32C34.2091 4 36 5.79086 36 8V23.6522C36 25.8613 34.2091 27.6522 32 27.6522H19.4783Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default MessageTextIcon;
