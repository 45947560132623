import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import {useDropzone} from 'react-dropzone'
import { get } from "lodash";
import { MainContext } from "../../../../../context/MainProvider";
import { SidebarContext } from "../../../../../context/SidebarProvider";
import UploadIcon from "../../../../../assets/icons/UploadIcon";
import { ImportCustomerData } from "../../../../../services/Apis";


const UploadContactsModal = () => {
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const [currentFile, setCurrentFile] = useState([])
  const [uploadLoading,setUploadLoading] = useState(false)
    const ref = useRef(null)
    const { handleCloseModal } = useContext(MainContext)
    const handleFileChange = (event) => {
      setCurrentFile(event.target.files)
    }

    useEffect(()=>{

    },[currentFile])

    useEffect(()=>{

      if(currentFile.length){
        if(currentFile[0]['type']!=='text/csv'){
          setTimeout(()=>{
            setCurrentFile([])
          },2000)
        }
      }

    },[currentFile])


    const handleUploadFile = () => {
      ref.current.click()

    }
    const onDrop = useCallback(acceptedFiles => {
      setCurrentFile(acceptedFiles)
    }, [])
    const {getRootProps, getInputProps, isDragActive} = useDropzone({noClick: true, onDrop})

   const handleUploadChatbotDocs = () => {
    if(!currentFile.length) return
    let data = {
      "customers":get(currentFile,'[0]',{}),
    }
    setUploadLoading(true)
    const formData = new FormData();
    Object.keys(data).forEach(key=>{
      formData.append(key,data[key])
    })
    for (const value of formData.values()) {
    }
    ImportCustomerData(formData).then(res=>{
        setUploadLoading(false)
        setTimeout(() => {
          handleShowSnackPopup({message:"file uploaded successfully", error:false})
          handleCloseModal()
      },200)
    }) .catch(err=>{
      setUploadLoading(false)
      handleShowSnackPopup({message:"error while uploading", error:true})

    })

   }

  return (
  <>  
  <Box  sx={{padding:"20px 40px"}} >
  <Box sx={{ width: "900px", display: "inline-block", height: "340px"}}>
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
            {" "}
            CSV upload
          </Typography>
        </Box>

        <Box  sx={{display:'flex', marginTop:'30px'}}   >
        
            <Box sx={{width:'50%'}} >
              <Box {...( getRootProps() )} sx={{border: isDragActive ? "2px solid #A84ACB" :`1px dashed black`, height:'200px', borderRadius:'10px', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', gap:'10px'}} >
               {
                isDragActive ? 
                <>
                  <UploadIcon height={100} width={100} />
                </> :
                currentFile.length ?  currentFile[0]['type'] !== 'text/csv' ? <>

              <Typography sx={{wordBreak:'break-all', textAlign:'center', padding:'10px', color:'orangered'}} >
                  Invliad File Type Support  Csv Only
              </Typography>
                
                
                
                </>  : <>
                <Typography sx={{wordBreak:'break-all', textAlign:'center', padding:'10px'}} >
                  {currentFile[0].name}
              </Typography>

              <Typography sx={{textDecoration:'underline', cursor:'pointer'}}  onClick={()=>setCurrentFile([])} >
               remove
              </Typography>
              
              </> :

              <>
               
            <Box sx={{display:'flex', gap:'20px', alignItems:'center'}} >
              <UploadIcon />
              <Typography sx={{fontWeight:'600'}}  >
              Drag and drop your file here
              </Typography>
              
              </Box>
              <Box>
               <Typography  sx={{cursor:'pointer',color:'#A84ACB', textDecoration:'underline', textDecorationSkip:'spaces'}}  onClick={handleUploadFile}>
               Or select a file from your computer
               <input  {...getInputProps()} accept="text/pdf" type="file" id="myfile" multiple={false} name="myfile" style={{display:'none'}} ref={ref}  onChange={handleFileChange} />
               </Typography>

              
             </Box>
              
              </>
                
               }
               
               
               
              
               


            


              </Box>

            </Box>
            <Box sx={{width:'50%', display:'flex', alignItems:'center'}} >
             <Box sx={{width:'80%'}} >
             <Typography sx={{color:'#707C97'}} >
                <ol style={{listStyleType:'disc'}} >
                  <li>Images are not scraped.</li>
                  <li>File size limit is 98 MB</li>
                  <li>Files with multiple text columns, encrypted or password protected csv are not supported</li>
                </ol>
              </Typography>
             </Box>

            </Box>
        </Box>






        <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            {/* <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <FileIcon />
              <Typography
                sx={{ fontSize: "16px", color: "#A84ACB", cursor: "pointer" }}
              >
                Learn more about PDF content for AI Chatbot
              </Typography>
            </Box> */}
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                onClick={handleUploadChatbotDocs}
                
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                  textTransform:'inherit',
                  fontSize:'13px',
                  padding:"10px 40px",
                }}
              >
             { uploadLoading ? "Loading..." :   "Upload" }
              </Button>
            </Box>
          </Box>
        {/* <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Button
              sx={{
                background: "#DB0629",
                color: "white",
                height: "30px",
                textTransform: "inherit",
                fontSize: "13px",
                ":hover": { background: "#DB0629" },
              }}
            >
              Delete
            </Button>
            <Button
            onClick={handleCloseModal}
              sx={{
                background:
                  "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                color: "white",
                height: "30px",
                textTransform: "inherit",
                fontSize: "13px",
              }}
            >
              Save and close
            </Button>
          </Box>
        </Box> */}
      </Box>
  </Box>
  
  </> )
}


export default UploadContactsModal