import React, { memo, useCallback, useEffect, useState } from "react";
import style from "../profileAutomation.module.scss";
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../../fixedComponent/sideToolbar/SideToolbar";
import { Box } from "@mui/material";
import CustomAccordian from "./customAccordian/CustomAccordian";
import HowContent from "./creationComponent/howAnswer/HowContent";
import HowHeader from "./creationComponent/howAnswer/HowHeader";
import ChatbotAnswerTypeBody from "./creationComponent/chatbot-answer-type/ChatbotAnswerTypeBody";
import ChatbotAnswerTypeHeader from "./creationComponent/chatbot-answer-type/ChatbotAnswerTypeHeader";
import ChannelsHeader from "./creationComponent/channels/ChannelsHeader";
import ChannelsBody from "./creationComponent/channels/ChannelsBody";
import AnswerBehaveHeader from "./creationComponent/answerBehave/AnswerBehaveHeader";
import AnswerBehaveBody from "./creationComponent/answerBehave/AnswerBehaveBody";
import IntroduceMessageHeader from "./creationComponent/introduceMessage/IntroduceMessageHeader";
import IntroduceMessageBody from "./creationComponent/introduceMessage/IntroduceMessageBody";
import HandoverWorkflowHeader from "./creationComponent/handoverworkflow/HandoverWorkflowHeader";
import HandoverWorkflowBody from "./creationComponent/handoverworkflow/HandoverWorkflowBody";
import { useParams } from "react-router-dom";
import { getChatbotProfile, getChatbotProfileDetails } from "../../../../services/Apis";
import { get } from "lodash";
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';

const CreateProfile = ({ ToolbarComponent, componentType }) => {
  const [loading, setLoading] = useState(false)
  const [newProfileState,setNewProfileState] = useState({
    name:"",
    status:"draft",
    channels:[],
    audience:[],
    introMessages: [],
    answerMode:"loop",
    afterAnswer:"route",
    ifCantAnswer:"route",
    handoverMessages:[],
    rules:{},
    matchType:'all'
  })
  const params = useParams()

  useEffect(() => {
    if(componentType === "update"){
      setLoading(true)
      getChatbotProfileDetails(params.id).then(res=>{
        let data = get(res,'data.data',{})
        let tempRules = handleModifiedRulesData(get(data,'rules',[]))
        setNewProfileState({
          name:get(data,'name',""),
          status:get(data,'status',"draft"),
          channels:get(data,'channels',[]),
          audience:get(data,'audience',[]),
          handoverMessages:get(data,'handoverMessages',[]),
          introMessages:get(data,'introMessages',[]),
          answerMode:get(data,'answerMode',""),
          afterAnswer:get(data,'afterAnswer',""),
          ifCantAnswer:get(data,'ifCantAnswer',""),
          rules:tempRules,
          matchType:get(get(data,'rules',[]),'[0].matchType','all')
        })

        
        setLoading(false)
      })
    }
  },[componentType])


//   {
//     "name": {
//         "value": "csdcs",
//         "key": "equals"
//     }
// }

  const handleModifiedRulesData = useCallback((arrayRulesData) => {
    if(!arrayRulesData.length) return;
    let newRules = get(arrayRulesData,`[0]`,{});
    let newMatchType = get(newRules,'matchType','any'); 
    let rulesProperties = {};
    get(newRules,'properties',[]).forEach(ele=>{
      let {
        field,
        operator,
        value,
      } = ele
      rulesProperties[field] = {
        value: typeof value === "object" ? get(value,`[0]`,'') : value,
        key:operator
      }
    })
    return rulesProperties
  },[])


  const handleCollectData = (type,data) => {
    setNewProfileState((state)=>({
      ...state,
      [type]:data
    }))
  }

  const handleSubmitRulesData = (data) => {
    handleCollectData('rules',data)
    console.log(newProfileState,"12345newProfileState",componentType,"rulesSet")

  }

  useEffect(()=>{
    console.log(newProfileState,"12345newProfileState",componentType)

  },[newProfileState])

  return (
    <MainComponent className={style.profileAutomationMainContainer}>
      <Box
        sx={{
          padding: "0px 10px",
          height: "95vh",
          background: "white !important",
        }}
      >
        <SideToolbar>{ToolbarComponent && <ToolbarComponent data={{newProfileState, handleCollectData}} componentType={componentType} profileId={params.id}  />}</SideToolbar>
        <Box  className="contentContainer" >
          {/* <CustomAccordian
            Body={ChatbotAnswerTypeBody}
            Header={ChatbotAnswerTypeHeader}
            data={{newProfileState,handleCollectData}}
          /> */}
          {
            loading ? 
            <>
              <Stack spacing={1}>
      <Skeleton variant="rectangular" width={'100%'} height={300} />
      <Skeleton variant="rectangular" width={'100%'} height={300} />
      <Skeleton variant="rectangular" width={'100%'} height={300} />
      <Skeleton variant="rectangular" width={'100%'} height={300} />
      <Skeleton variant="rectangular" width={'100%'} height={300} />
      <Skeleton variant="rectangular" width={'100%'} height={300} />

    </Stack>
            
            
            </>
            : <>
            
            <CustomAccordian
           defaultOpen={true}
            Body={ChannelsBody}
            Header={ChannelsHeader}
            data={{newProfileState,handleCollectData,handleSubmitRulesData, componentType}}
          />

          <CustomAccordian
           defaultOpen={true}
            Body={AnswerBehaveBody}
            Header={AnswerBehaveHeader}
            data={{newProfileState,handleCollectData}}
          />

         <CustomAccordian
          defaultOpen={false}
            Body={IntroduceMessageBody}
            Header={IntroduceMessageHeader}
            data={{newProfileState,handleCollectData}}
          />

          <CustomAccordian
           defaultOpen={false}
            Body={HandoverWorkflowBody}
            Header={HandoverWorkflowHeader}
            data={{newProfileState,handleCollectData}}
          />
            </>
          }
     
        </Box>
      </Box>
    </MainComponent>
  );
};

export default CreateProfile;

// chatbot answer type/
// channels
//answering behave
// introduction message
// /handover workflow
