import React from 'react'

const HocCommonButton = (WrappedComponent) => {
  return (
    class searchWithHOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                anchorEl:null,
            };
          }
      
         handleClick = (event) => {
          this.setState({anchorEl:event.currentTarget})
        };
         handleClose = () => {
          this.setState({anchorEl:null})
        };
        render() {
          return (
            <>
              <WrappedComponent
               {...this.props}
                anchorEl={this.state.anchorEl}
                handleClick={this.handleClick}
                handleClose={this.handleClose}
                open={Boolean(this.state.anchorEl)}  />
            </>
          );
        }
    }
  )
}

export default HocCommonButton
