import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./allUsers.module.scss";
import CursorDownIcon from "../../../../assets/icons/CursorDownIcon";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PlusBoxIcon from "../../../../assets/icons/PlusBoxIcon";
import { MainContext } from "../../../../context/MainProvider";
import CreateNewUser from "./createNewUser/CreateNewUser";
const AllUsersToolbar = ({handleGetAllUsers, filterValue}) => {
    const {handleOpenModal} = useContext(MainContext)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleCreateNewUser = (type) => {
    handleClose()
    handleOpenModal({
        state:true,
         Component: <CreateNewUser data={{
          type:type,
          handleGetAllUsers
         }} />, 
    })
  }
  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
        <Box className="toolbarContent">
          {/* <Button
            className="users-header"
            endIcon={<CursorDownIcon sx={{ color: "gray !important" }} />}
            variant="text"
          >
            Users
          </Button> */}

          {/* <Button
            className="filter-buttom"
            startIcon={
              <AddIcon
                style={{
                  fontSize: "14px",
                  color: "#8f1eb3",
                  fontWeight: "bold",
                }}
              />
            }
            variant="text"
          >
            Add filters
          </Button> */}
          <Box>
            <Button
              endIcon={<CursorDownIcon sx={{ color: "white !important" }} />}
              className="add-profile"
              sx={{
                textTransform: "inherit",
              }}
              id="basic-menu"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              New customer or visitor
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={()=>handleCreateNewUser('customer')}  sx={{display:'flex', gap:'10px', alignItems:"center"}} >  <PlusBoxIcon />  <Typography  sx={{color:"#222", fontSize:"15px"}} >Create new customer</Typography></MenuItem>
              <MenuItem onClick={()=>handleCreateNewUser('visitor')}  sx={{display:'flex', gap:'10px', alignItems:"center"}} >  <PlusBoxIcon />  <Typography  sx={{color:"#222", fontSize:"15px"}} >Create new visitor</Typography></MenuItem>
            </Menu>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AllUsersToolbar;
