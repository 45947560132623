import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  InputBase,
  Typography,
} from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { get } from "lodash";
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import IconButton from '@mui/material/IconButton';
import moment from "moment";
import TageIcon from "../../../../assets/searchIcons/TageIcon";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import { LoadingButton } from '@mui/lab';
const DisplayTagsComponent = ({data, setData, handleArchive=()=>{}, handleCreate=()=>{}, handleUpdate=()=>{}, handleBlur=()=>{}, Icon, component, isArchivedComponent=false, handleRestoreData=()=>{}}) => {
    const [focusTableRow, setFocusTableRow] = useState(-1);
    const [editableMode, setEditableMode] = useState(false);
    const [loading, setLoading] = useState(-1)
    const handleEditTag = (index) => {
        let tempTags = [...data];
        tempTags[index]['type'] = "edit";
        setData(tempTags)
        setEditableMode(true)
    }

    const handleArchivedRestore = (element) => {
        handleRestoreData(element)

    }


    

    const handleBlurInputBox = (index) => {
        setEditableMode(false)
        handleBlur()
        setFocusTableRow(-1)
    }

    const handleCallEvent = (index) => {
        setFocusTableRow(index)
    }

    const handleCollectData = (event,index) => {
        let {value} = event.target;
        let tempTags = [...data];
        tempTags[index]['name'] = value;
        setData(tempTags)
    }


    const handleArchiveData = (data) => {
        handleArchive(data)
    }


    const handleOnKeyDown = (event,index) => {
        if(event.keyCode === 13){
            let tempTags = [...data];
            let tempData = tempTags[index]
            let isNewData = tempData['type'] === "new"
            setFocusTableRow(index)
            setEditableMode(false)
            delete tempData['type'];
            setData(tempTags)
            if(isNewData){
                handleCreate(tempData)
            }else{
                handleUpdate(tempData.id,tempData)
            }
        }
    }



  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: "10px",
          maxHeight: "88vh",
          height: "88vh",
        }}
      >
        <Box sx={{padding:"0px 50px"}}>

      <Box  sx={{marginTop:"20px"}} >
      <TableContainer component={Paper}  sx={{boxShadow:"none" }}>
      <Table sx={{ minWidth: 650, }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell  sx={{fontWeight:"bold"}}>{component} name</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Created	</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}} >Created by</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Peoples	</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Companies</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Conversations </TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Messages </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((element,index) => {
            const modeType = get(element,'type','view');
            const name = get(element,'name',"");
            const createdAt = get(element,'createdAt','');
            const isViewMode = modeType === "view";
            
            let momentTime =  moment(createdAt)
            momentTime = momentTime.isValid()  ? momentTime.fromNow() : ""
            return (
            <TableRow
                onMouseEnter={()=>handleCallEvent(index)}  onMouseLeave={()=>handleCallEvent(-1)}
              key={get(element,'id',createdAt)}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell  component="th" scope="row"  sx={{display:"flex", justifyContent:'space-between', alignItems:'center', }} >
               
              <Box sx={{width:"100%"}} >
              {
                    isViewMode ? 
                    <>
                    <Box sx={{display:"flex", gap:"5px", alignItems:"center"}} >
                    {Icon && Icon}
                    
                    <Typography  sx={{fontSize:"14px"}} >{name}</Typography>
                    </Box>
                    </>
                    : <Paper
                    component="box"
                    
                    sx={{
                      p: "1px 2px",
                      display: "flex",
                      borderRadius:"7px",
                      alignItems: "center",
                      width: "60%",
                      height: 30,
                      boxShadow: "none",
                      border: `1px solid rgba(0,0,0,0.1) `,
                    //   opacity:"0.1"
                    }}
                  >
                    <InputBase
                      value={name}
                      onBlur={()=>handleBlurInputBox(index)}
                      onChange={(event)=>handleCollectData(event,index)}
                      onKeyDown={(event)=>handleOnKeyDown(event,index)}
                      autoFocus
                      className="input-box"
                      type="text"
                      name=""
                      sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                      placeholder=""
                    />
                  </Paper>
                }
                 
              </Box>


            <Box sx={{display:"flex", gap:"10px", alignItems:"center", visibility: editableMode ? "hidden" : (focusTableRow === index ) ?  "visible" : "hidden" }} >
            {
                isArchivedComponent ? 
                <>
                  <IconButton loading={true} onClick={()=>handleArchivedRestore(element)} aria-label="delete" size="small"  sx={{padding:"3px 10px", background:'#f1f1f1', borderRadius:"5px"}} >
                <RotateLeftIcon  sx={{color:'black', fontSize:"20px"}} />
                </IconButton>
                </>
                :
                <>
              
                <IconButton onClick={()=>handleEditTag(index)} aria-label="delete" size="small"  sx={{padding:"3px 10px", background:'#f1f1f1', borderRadius:"5px"}} >
                <ModeEditIcon sx={{color:'black', fontSize:"20px"}} />
                </IconButton>
                <Button onClick={()=>handleArchiveData(element)} color="error" sx={{ background:"#fef1f4",height: "26px",boxShadow: "none",fontSize: "12px",textTransform: "capitalize",padding: "3px 10px",textTransform: "inherit",}}>
                Archive
                </Button> 
                </>
            }
            </Box>


              </TableCell>
              <TableCell align="right"> { momentTime}</TableCell>
              <TableCell align="right">{ "NaN"}</TableCell>
              <TableCell align="right">{"0"}</TableCell>
              <TableCell align="right">{ "0"}</TableCell>
              <TableCell align="right">{ "0"}</TableCell>
              <TableCell align="right">{"0"
              }</TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>
          

        </Box>
      </Box>
    </>
  );
};

export default DisplayTagsComponent;
