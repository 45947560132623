import React from "react";
import { Box, Typography } from "@mui/material";
import chatbotLogo from "../../../../assets/brandChatbotImage.jpeg";
import MessageCard from "./MessageCard";

const ChatBoatStaticView = ({chatBoatData}) => {
  return (
    <>
      <Box sx={{ height: "90%", padding: "10px" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            paddingTop:'20px',
            gap:'10px'
          }}
        >
          <Typography sx={{fontWeight:'bold', fontSize:'20px'}} >Pullse Bot</Typography>
          <img src={chatbotLogo} style={{ height: "70px", width: "70px" }} />
          <Typography variant="body" sx={{fontSize:'16px', fontWeight:'500'}} > Ask a question</Typography>
        </Box>
        <Box sx={{display:'flex', flexDirection:'column', gap:'10px', paddingTop:'20px', maxHeight:'350px', overflowY:'scroll'}} >
            {
                chatBoatData.map(ele=>(
                    <MessageCard  {...ele} />

                ))
            }
        </Box>
      </Box>
    </>
  );
};

export default ChatBoatStaticView;
