import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import PropTypes from 'prop-types';
import CompanyPeoples from "../peoples/CompanyPeoples";
import CompanyTickets from "../Tickets/CompanyTickets";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding:"20px 5px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const CompanyTabs = ({companyPeoples, comapnyTickets}) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
       <Box sx={{ width: '100%' }}>
      <Box>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary"
        indicatorColor="secondary"
>
          <Tab label="People" sx={{fontWeight:"bold", textTransform:"capitalize"}} {...a11yProps(0)} />
          <Tab label="Conversation" sx={{fontWeight:"bold", textTransform:"capitalize"}} {...a11yProps(1)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
     <CompanyPeoples companyPeoples={companyPeoples} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
       <CompanyTickets comapnyTickets={comapnyTickets} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel>
    </Box>
    </>
  );
};

export default CompanyTabs;
