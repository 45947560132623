import React from 'react'
import ContentSouceHocCard from '../contentSouceHocCard/ContentSourceHocCard'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ListItemText } from '@mui/material';
import ImportExternalSources from '../contentSources/ImportExternalSources';
import PullseHelpCenterSource from '../contentSources/PullseHelpCenterSource';
import UseSnippetSource from '../contentSources/UseSnippetSource';
import ImportContentFilesSources from '../contentSources/ImportContentFilesSources';

const CommonContentCard = ({open,setOpen, heading, content, Component}) => {
    
  return (
  <>
   <Accordion sx={{border:'1px solid rgba(0,0,0,0.2)', boxShadow:'none', borderRadius:'5px'}} expanded={open} onChange={setOpen} >
   <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ transform: open ? `rotate(-180deg)` : `rotate(-90deg)` }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <ListItemText
          primary={heading}
          secondary={content}
        />
      </AccordionSummary>

        {/* <ImportExternalSources /> */}
        {/* <PullseHelpCenterSource /> */}
        {/* <UseSnippetSource />   */}
        {/* <ImportContentFilesSources /> */}
        <Component />
        
      </Accordion>
  
  </>  )
}

export default ContentSouceHocCard(CommonContentCard) 
