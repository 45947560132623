import {
  AccordionDetails,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./introduceMessage.module.scss";
import HelpIcon from "@mui/icons-material/Help";
import { LightTooltip } from "../../../../../commonComponent/tooltip/LightTooltip";
import IntroduceMessageTab from "./introduceMessageTab/IntroduceMessageTab";
import { v4 as uuidv4 } from 'uuid';
import { get } from "lodash";

const getNewEditorState = () => ({
  value:<></>,
  delta: {},
  id:uuidv4()
})



const IntroduceMessageBody = ({data}) => {
  console.log(data,'pullse-111')
  let { newProfileState, handleCollectData } = data;
  const [newEditorState, setNewEditorState] = React.useState([]);

  React.useEffect(()=>{
    handleAddNewEditor()
  },[])

  React.useEffect(()=>{
    if(get(newProfileState,'introMessages',[]).length){
      let tempMessages = newProfileState.introMessages.map(ele=>({
        value:ele,
        id:uuidv4()
      }))
      setNewEditorState(tempMessages)
    }
  },[])

  


  useEffect(()=>{
    let messages = newEditorState.map(ele=>ele.value)
    handleCollectData('introMessages',messages)
    console.log(newProfileState,"newEditorState")
  },[newEditorState])


  const handleAddNewEditor = () => {
    const tempEditorsData = [...newEditorState];
    tempEditorsData.push(getNewEditorState());
    setNewEditorState(tempEditorsData);
  };

  const handleDeleteEditorState = (id) => {
    let tempEditorsData = [...newEditorState];
    tempEditorsData = tempEditorsData.filter(ele=>ele.id !== id)
    setNewEditorState(tempEditorsData);
  }


  const handleCollectEditorValue = (value, delta, source, editor, index) => {
    let htmlValue = value;
    let deltaData = editor.getContents();
    let tempEditorStates = [...newEditorState];
    tempEditorStates[index].value = htmlValue
    tempEditorStates[index].delta = deltaData
    setNewEditorState(tempEditorStates)
  }
  
  return (
    <>
      <Divider />
      <AccordionDetails className={style["answer-behave-body-main-container"]}>
        <Box className="body-content-container">
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Typography className="sub-header">
              Customize the introduction message triggered at the beginning of a
              conversation.
            </Typography>
            <LightTooltip
              title="
              A customer becomes inactive if they haven't replied to a bot response within 3 minutes, whether the bot was able to an- swer or not."
              placement="top"
              arrow
            >
              <HelpIcon
                sx={{
                  fontSize: "15px",
                  cursor: "pointer",
                  color: "gray",
                }}
              />
            </LightTooltip>
          </Box>
          <Box className="contents">
            <Typography variant="span" className="answer-body" gutterBottom>
              It helps your customers better understand what the bot can do and
              how to interact with it. Only applies to Web, iOS and Android.
            </Typography>
          </Box>
          <Box>
            <IntroduceMessageTab handleDeleteEditorState={handleDeleteEditorState} handleCollectEditorValue={handleCollectEditorValue} handleAddNewEditor={handleAddNewEditor} newEditorState={newEditorState} setNewEditorState={setNewEditorState} />
          </Box>
        </Box>
      </AccordionDetails>
    </>
  );
};

export default IntroduceMessageBody;
