import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import style from "./actionsComponet.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import ConditionCard from "./condition-card/ConditionCard";
import DisplayTrigger from "./displayTriggers/DisplayTrigger";
import CreateTrigger from "./create-triggers/CreateTrigger";
import { Navigate, Outlet, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { arrayToQueryString, debounceWithImmediate } from "../../../utils/helpers/helpers";
import { allWorkflow } from "../../../services/Apis";
import { get } from "lodash";
import { SidebarContext } from "../../../context/SidebarProvider";
const ActionComponent = ({ ToolbarComponent }) => {
  const {   handleShowSnackPopup } = useContext(SidebarContext)
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams({
    type:'view'
  });
  const [workflow, setWorkflow] = useState([]);
  const [triggers, setTriggers] = useState({
    triggerName:"",
    description:"",
    category:"",
    conditionAll:[],
    conditionOr:[],
    actions:[]
  })
  const [currentStatus, setCurrentStatus] = useState('all')


  const handleSetCurrentStatus  = (value) => {
    setCurrentStatus(value)
  }





    const getAllWorkflow = (...searchQuery) => {
      let newSearchQuery = []
      if(currentStatus !== "all"){
        newSearchQuery.push({
          type:'status',
          data:currentStatus
        })
      }
      newSearchQuery.push(...searchQuery)
      allWorkflow(...newSearchQuery).then(res=>{
        let response = get(res,'data.data.docs',[])
        setWorkflow(response)
      })
    }

    useEffect(()=>{
      getAllWorkflow()
    },[currentStatus])

  const handleSetActionView = (value,new_created) => {
    setSearchParams({
      type:value
    })
  }

  const handleSetTabWhileCreated = () => {
    getAllWorkflow()
    navigate("/automation/actions")
    handleShowSnackPopup({message:"Workflow created successfully",error:false})
  }
  const handleFetchData = (event,type="name") => {
    if(event.target.value === ""){
      getAllWorkflow()
    }else{
      getAllWorkflow({type:type,data:event.target.value})
    }   
  }

  const handleSearchDebounce = useCallback(debounceWithImmediate(handleFetchData,()=>{},600),[currentStatus])


  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Routes>
        <Route path="/" element={ <DisplayTrigger handleSetCurrentStatus={handleSetCurrentStatus} currentStatus={currentStatus} handleSearchDebounce={handleSearchDebounce} handleSetActionView={handleSetActionView}  workflow={workflow} />} />
          <Route  path="create-workflow" element={<>
            <CreateTrigger handleSetActionView={handleSetActionView} componentType="create" handleSetTabWhileCreated={handleSetTabWhileCreated} />
          </>} />
          <Route  path="workflow/:id" element={<>
            <CreateTrigger handleSetActionView={handleSetActionView} componentType="update"  />
          </>} />
          <Route  path="*" element={<><Navigate to={'/settings/actions'} /></>} />
        </Routes>

        </Box>
      </MainComponent>
    </>
  );
};

export default ActionComponent;
