import React, { useContext, useEffect, useState } from "react";
import { Box, Chip, InputBase, MenuItem, Paper, Select } from "@mui/material";
import CrossIcon from "../../../../assets/icons/CrossIcon";
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
import useStateCallback from "../../../../utils/hooks/useStateCallback";
import PullseMultiSelectOptions from "../../../commonComponent/multi-select-component/PullseMultiSelectOptions";
import { isArrayString } from "../../../../utils/helpers/helpers";
import { PullseTextArea } from "../../../commonComponent/pullse-text-area/PullseTextArea";
const attr = [
  {
    text: "Ticket",
    key: "ticket",
    value: "ticket",
  },
  {
    text: "Company",
    key: "company",
    value: "company",
  },
  {
    text: "Customer",
    key: "customer",
    value: "customer",
  },
];
const defaultConditionOptions = [
  {
    text: "Yes",
    key: "yes",
    value: "yes",

  },{
    text: "No",
    key: "no",
    value: "no",
  }
]
const conditionAttr = [
  {
    text: "Is",
    key: "is",
    value: "is",
  },
  {
    text: "Is Not",
    key: "is not",
    value: "is not",
  },
  {
    text: "Is Null",
    key: "is null",
    value: "is null",
  },
  {
    text: "Is Not Null",
    key: "is not null",
    value: "is not null",
  },
];


// "resource": get(ele,'type',""),
// "field": get(ele,'attribute',""),
// "operator": get(ele,'condition',""),
// "value":get(ele,'value',"")

const closeValueField = ['isNotNull','isNull']
const ConditionCard = ({
  handleRemoveAction,
  index,
  type,
  workflowEntityFieldsData,
  handleCollectConditionalData,
  componentType,
  cardData,
  editable
}) => {
  const { customeFieldsData } = useContext(MainContext);

  const [isFirstTimeUpdated, setIsFirstTimeUpdated] = useState(false)
  const [actions, setActions] = useStateCallback({
    entities: [],
    attributes: [],
    operators: {},
    currentOperator: [],
  });
  const [valueFieldType, setValueFieldType] = useState({
    type:"",
    options:[],
    default:false,
    defaultoptions:defaultConditionOptions
  })

  // const [ entities, setEntities ] = useState([]);
  // const [attributes, setAttributes] = useState([]);
  // const [operators, setOperators] = useState({})
  // const [cardData, setObjValues] = useState({
  //   type: "",
  //   // attribute: "",
  //   // condition: "",
  //   // value: "",
  // });


const handleChangeDropdownValues = (name, value) => {
  // if(!isFirstTimeUpdated){
    
  // }
  if (name == "resource") {
    let { attributes } = actions.entities.find(({ id }) => id == value);
    attributes = attributes.map((ele) => ({
      key: ele.id,
      text: ele.name,
      value: ele.id,
      ...ele,
    }));
    setActions((state) => ({
      ...state,
      attributes: attributes,
      currentOperator:[]
    }));
    }
    if (name === "field") {
      let attrib = actions.attributes.find(({ id }) => id == value);
      // 
      console.log(attrib,"attrib11-1111")
      let options = []
      if (isArrayString(get(attrib,'options',[]))){
        options = get(attrib,'options',[]).map(ele=>({key:ele,text:ele,value:ele,id:ele,value:ele}))
      }else{
        options = get(attrib,'options',[]).map(ele=>({key:ele.id,text:ele.name,value:ele.id}))
      }
      setValueFieldType((state)=>({
        ...state,
        type:get(attrib,"fieldType",""),
        default:false,
        options:options,
      }))
      let operators =  get(actions,`operators[${get(attrib,'fieldType',"")}]`,[]).map((ele) => ({
        key: ele.id,
        text: ele.name,
        value: ele.id,
        ...ele
      }));
      setActions((state) => ({
        ...state,
        currentOperator: operators,
      }));
  }

  if(name === "operator"){
    let tempCurrentOperator = [...get(actions,'currentOperator',[])];
    let currentData = tempCurrentOperator.find(ele=>ele.id === value)
    
    if(get(currentData,'type','')==='text'){
      setValueFieldType((state)=>({
        ...state,
        type:"text",
        default:false,
        
      }))
    }else if(get(currentData,'type','')==='list'){
      if (get(currentData,'defaultOption',false)){
        setValueFieldType((state)=>({
          ...state,
          type:"list",
          default:true,
        }))
      }else{
        setValueFieldType((state)=>({
          ...state,
          default:false,
        }))

      }

    }

    



  }
}

useEffect(()=>{
  if(componentType === 'update'){
    let cardDataValues = {...cardData};
    if(actions.entities.length && !(cardDataValues.isNew)){
      handleChangeDropdownValues('resource', cardDataValues['resource'])
    }
  }
},[actions.entities.length])

useEffect(()=>{
  if(componentType === 'update'){
    let cardDataValues = {...cardData};
    if(actions.attributes.length && !(cardDataValues.isNew)){
    handleChangeDropdownValues('field',cardDataValues['field'])
    }
  }
},[actions.attributes.length])

useEffect(()=>{
  if(componentType === 'update'){
    let cardDataValues = {...cardData};
    if(get(actions,'currentOperator',[]).length && !(cardDataValues.isNew)){
    handleChangeDropdownValues('operator',cardDataValues['operator'])}
  }
},[get(actions,'currentOperator',[])])


  const handleCollectData = (event, ele) => {
    let { name, value } = event.target;
    
    if(!isFirstTimeUpdated){
      setIsFirstTimeUpdated(true)
    }
    handleChangeDropdownValues(name, value)
    // if (name == "resource") {
    //   let { attributes } = actions.entities.find(({ id }) => id == value);
    //   attributes = attributes.map((ele) => ({
    //     key: ele.id,
    //     text: ele.name,
    //     value: ele.id,
    //     ...ele,
    //   }));

    //   
    //   setActions((state) => ({
    //     ...state,
    //     attributes: attributes,
    //   }));
    // }
    // if (name === "field") {
    //   let attrib = actions.attributes.find(({ id }) => id == value);
    //   
    //   setValueFieldType((state)=>({
    //     ...state,
    //     type:attrib.fieldType,
    //     default:true,
    //     options:get(attrib,'options',[]).map(ele=>({key:ele.id,text:ele.name,value:ele.id})),
    //   }))
    //   let operators =  get(actions,`operators[${attrib.fieldType}]`,[]).map((ele) => ({
    //     key: ele.id,
    //     text: ele.name,
    //     value: ele.id,
    //     ...ele
    //   }));
    //   setActions((state) => ({
    //     ...state,
    //     currentOperator: operators,
    //   }));
    // }

    // if(name === "operator"){
    //   let tempCurrentOperator = [...actions.currentOperator];
    //   let currentData = tempCurrentOperator.find(ele=>ele.id === value)
    //   
    //   if(get(currentData,'type','')==='text'){
    //     setValueFieldType((state)=>({
    //       ...state,
    //       type:"text",
    //       default:false,
          
    //     }))
    //   }else if(get(currentData,'type','')==='list'){
    //     if (get(currentData,'defaultOption',false)){
    //       setValueFieldType((state)=>({
    //         ...state,
    //         type:"list",
    //         default:true,
    //       }))
    //     }else{
    //       setValueFieldType((state)=>({
    //         ...state,
    //         default:false,
    //       }))

    //     }

    //   }
    // }
    // type
    // attribute
    // condition
    // value
    // if(name === 'value'){
    //   
    // }
    
   
    let tempObjValues = {...cardData}
    // if(name==='value'){
    //   tempObjValues[name] = get(tempObjValues,`[${name}]`,"")+value;
    // }else{
    //   tempObjValues[name] = value;
    // }


    tempObjValues[name] = value;
    if(name === "resource"){
      delete tempObjValues['field']
      delete tempObjValues['operator']
      delete tempObjValues['value']
    }else if(name === "field"){
      delete tempObjValues['operator']
      delete tempObjValues['value']
    }else if(name === "operator"){
      delete tempObjValues['value']
    }

    
    handleCollectConditionalData(tempObjValues, type,index)
  };

  useEffect(() => {
      setActions({  entities: get(workflowEntityFieldsData, "entities", []).map(
          ({ id, name, attributes }) => ({
            key: id,
            value: id,
            text: name,
            attributes,
            id,
            name,
          })
        ),
        attributes: [],
        operators: get(workflowEntityFieldsData, "operators", "[]"),
      });
  }, [workflowEntityFieldsData]);

  let isNewRow = get(cardData,'isNew',false);

  const isDisbaledFields = !editable && componentType === "update";
  console.log(cardData,"operator1111", valueFieldType)
  return (
    <Box
      sx={{
        border: "1px solid rgba(0,0,0,0.1)",
        padding: "10px",
        borderRadius: "5px",
        ":hover": {
          boxShadow: "5px 5px 31px -8px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CrossIcon
         color={isDisbaledFields ? "gray" : "red" }
          height={14}
          width={14}
          style={{ cursor: "pointer" }}
          onClick={() => {
            if(!isDisbaledFields){
              handleRemoveAction(type, index);
            }
          }}
        />
      </Box>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
        <SelectBoxContainer
          disabled={isDisbaledFields}
          componentType={componentType}
          style={{ width: "200px !important" }}
          options={actions.entities}
          value={cardData.resource}
          onChange={handleCollectData}
          name={"resource"}
        />
        {get(cardData,'resource',"").length ? (
          <>
            <SelectBoxContainer
              disabled={isDisbaledFields}
              componentType={componentType}
              defaultOpen={isNewRow || isFirstTimeUpdated}
              isFirstTimeUpdated={isFirstTimeUpdated}
              style={{ width: "200px !important" }}
              options={actions.attributes}
              value={cardData.field}
              onChange={handleCollectData}
              name={"field"}
            />
          </>
        ) : null}
        {get(cardData,'field',"").length ? (
          <SelectBoxContainer
          disabled={isDisbaledFields}
            componentType={componentType}
            defaultOpen={isNewRow || isFirstTimeUpdated}
            isFirstTimeUpdated={isFirstTimeUpdated}
            style={{ width: "200px !important" }}
            options={actions.currentOperator}
            value={cardData.operator}
            onChange={handleCollectData}
            name={"operator"}
          />
        ) : null}
        { !closeValueField.includes(get(cardData,'operator',"")) &&
          get(cardData,'operator',"").length ?
          ['list','multi-select',].includes(valueFieldType.type) ?
          valueFieldType.type === 'list'?
          <>
          <SelectBoxContainer
          disabled={isDisbaledFields}
          componentType={componentType}
          multiple={false}
          defaultOpen={isNewRow || isFirstTimeUpdated}
          isFirstTimeUpdated={isFirstTimeUpdated}
          style={{ width: "200px !important" }}
          options={valueFieldType.default ? get(valueFieldType,'defaultoptions',[]) : get(valueFieldType,'options',[])}
          value={cardData.value}
          onChange={handleCollectData}
          name={"value"}
        />
        </>
          :
          <>
          <SelectBoxContainer
           disabled={isDisbaledFields}
            componentType={componentType}
            multiple={true}
            defaultOpen={isNewRow || isFirstTimeUpdated}
            isFirstTimeUpdated={isFirstTimeUpdated}
            style={{ width: "200px !important" }}
            options={valueFieldType.options}
            value={get(cardData,'value',[])}
            onChange={handleCollectData}
            name={"value"}
          />
          </>
          : valueFieldType.type === "text" ?
          <>
          <TextAreaContainer style={{width:"200px !important"}} 
            disabled={isDisbaledFields}
            componentType={componentType}
            isFirstTimeUpdated={isFirstTimeUpdated}
            value={get(cardData,'value',"")}
            onChange={handleCollectData}
            name={"value"} />
          </>
          :
          (
            <>
            <InputBoxContainer  style={{width:"200px !important"}} 
            disabled={isDisbaledFields}
            componentType={componentType}
            isFirstTimeUpdated={isFirstTimeUpdated}
            value={get(cardData,'value',"")}
            onChange={handleCollectData}
            name={"value"}/>
            </>
          )
          :
          null
        }   
      </Box>
    </Box>
  );
};

const SelectBoxContainer = ({ style, options=[], value, onChange, name, defaultOpen=false, multiple=false,componentType, isFirstTimeUpdated, disabled }) => {
  const [open, setOpen] = useStateCallback(defaultOpen)
  useEffect(()=>{
    if(name !== "resource" && isFirstTimeUpdated){
      setOpen(true)
    }
  },[options])
  console.log(options,"newOptions")
  // let newOptions = isArrayString(options) ? options.map(ele=>({key:ele,text:ele,value:ele})) : options;

  return (
    <Select
      multiple={multiple}
      open={open}
      disabled={disabled}
      autoFocus={true}
      onOpen={()=>{ setOpen(true) }}
      onClose={()=>{ setOpen(false) }}
      value={value}
      onChange={(event) => { onChange(event) }}
      name={name}
      sx={{
        width: "60%",
        border: "1px solid rgba(0,0,0,0.2)",
        height: 40,
        borderRadius: "5px",
        ...style,
        paddingLeft: "10px",
      }}
      input={
      <InputBase
        variant="standard"
        autoFocus={true}
        sx={{ ml: 1, flex: 1, fontSize: "13px" }}
      ></InputBase>
      }
    >
      {options.map((ele) => (
        <MenuItem key={ele.key} value={ele.value}>
          {ele.text}{" "}
        </MenuItem>
      ))}
    </Select>
  );
};

const InputBoxContainer = ({ style,name, value ,onChange, disabled }) => {
  return (
    <Paper
      component="box"
      sx={{
        p: "1px 2px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 40,
        padding: "10px",
        boxShadow: "none",
        border: `1px solid rgba(0,0,0,0.2)`,
        ...style,
      }}
    >
      <InputBase

        disabled={disabled}
        onChange={onChange}
        name={name}
        value={value}
        sx={{ ml: 1, flex: 1, fontSize: "14px" }}
        placeholder=""
      />
    </Paper>
  );
};



const TextAreaContainer = ({style,name, value ,onChange, disabled}) => {
  return (
    <PullseTextArea name={name} value={value} onChange={onChange} disabled={disabled} sx={{
      width:"200px",
      ...style
    }
    } />
  )
}

const SelectMultiDropDown = ({ style, options=[], value=[], onChange, name, defaultOpen=false, multiple=false, componentType, isFirstTimeUpdated, disabled }) => {
  return (
    <>
    <PullseMultiSelectOptions style={style} options={options} value={value} onSelect={onChange} name={name} defaultOpen={defaultOpen} multiple={multiple} componentType={componentType} isFirstTimeUpdated={isFirstTimeUpdated} disabled={disabled} />
    </>
  )
}

export default ConditionCard;
