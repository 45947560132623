import React from "react";

const ChevronDown = () => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 19 19"
        fill="none"
      >
        <path
          d="M4.75 7.125L9.5 11.875L14.25 7.125"
          stroke="#0D1C2E"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ChevronDown;
