import React from "react";
import { Box, Button, Typography } from '@mui/material'
import BarGroupIcon from '../../../../../assets/icons/BarGroupIcon'
import AutoSettingIcon from '../../../../../assets/icons/AutoSettingIcon'
import MessageTextIcon from '../../../../../assets/icons/MessageTextIcon'
import SendAlertIcon from "../../../../../assets/icons/SendAlertIcon";
import HorizontalIcon from "../../../../../assets/icons/HorizontalIcon";
import ChatbotData from "./chatbot-contents/ChatbotContents";
import { useParams } from "react-router-dom";



const ContantAddPage = ({handleGetBotDocuments}) => {
    const {id} = useParams()
  return (
    <>
    <Box  sx={{width:'100%', borderRadius:'5px',}} >
    <ChatbotData chatbotId={id} handleGetBotDocuments={handleGetBotDocuments}/>
    </Box>
    
    </>
  )
}

export default ContantAddPage;



