import { Box, Button, Input, InputBase, Paper } from "@mui/material";
import React, { useContext, useState } from "react";
import style from "./createProfile.module.scss";
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import CheckIcon from "@mui/icons-material/Check";
import LoadingButton from "@mui/lab/LoadingButton";
import Chip from '@mui/material/Chip';
import { MainContext } from "../../../../context/MainProvider";
import CrossIcon from "../../../../assets/icons/CrossIcon";
import { SidebarContext } from "../../../../context/SidebarProvider";
import { get } from "lodash";
let name = process.env.REACT_APP_AUTO_NAME;
const CreateProfileToolbar = ({data}) => {
  let {handleCollectData,newProfileState} = data;
  const { handleCreateChatbotProfile, handleUpdateChatbotProfile } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const [loading,setLoading] = useState(false);
  const navigate = useNavigate()
  const params = useParams()




//   {
//     "name": {
//         "value": "csdcs",
//         "key": "equals"
//     }
// }

// "field": "name",
// "resource": "customer",
// "operator": "equal", 
// "value": "Utkarsh"

  const handleFilterRulesData = () => {
    let tempProfileState = {...newProfileState};
    let rulesPayload = []
    if(Object.keys(get(tempProfileState,'rules',{})).length){
      rulesPayload =  Object.keys(get(tempProfileState,'rules',{})).map(key=>{
        let rulesKeys = tempProfileState['rules'][key]
        return {
          field:key,
          resource:"ticket",
          operator: get(rulesKeys,'key',''),
          value: get(rulesKeys,'value','')
        }
      })
      tempProfileState.rules = [{
        properties:rulesPayload,
        matchType:get(tempProfileState,'matchType','any')
      }]
    }else{
    delete tempProfileState['rules']
    }
    delete tempProfileState['matchType'];


    return tempProfileState
  }

  const handleSubmitData = ({componentType, profileId}) => {
    setLoading(true)
    let newPayload = handleFilterRulesData()
    if(componentType === "update"){
      handleUpdateChatbotProfile(profileId,newPayload).then(res=>{
        setTimeout(() => {
          handleShowSnackPopup({message:"profile updated successfully", time:4000})
          navigate('/automation/profile')
        }, 2000);
      }).catch(err=>{
        handleShowSnackPopup({message:"error while updating profile", time:4000, error:true})
        // setLoading(false)
      })
    }else{
      // console.log(newPayload,"1111newProfileState")
      handleCreateChatbotProfile(newPayload).then(res=>{
        setTimeout(() => {
          handleShowSnackPopup({message:"profile created successfully", time:4000})
          navigate('/automation/profile')
        }, 2000);
      }).catch(err=>{
        handleShowSnackPopup({message:"error while creating profile", time:4000, error:true})
        setLoading(false)
      })
    }
  }

  const handleSubmitResponseData = () => {
    if(get(params,'id',"").length){
      handleSubmitData({componentType:"update",profileId:get(params,'id',"")})
    }else{
      handleSubmitData({componentType:"create",profileId:get(params,'id',"")})
    }
  }


  return (
    <>
      <Box className={style.createProfileToolbarMainClass}>
        <Box className="content">
          <Box sx={{ width: "300px", padding: "0px" }}>
            <Paper
              component="box"
              sx={{
                p: "3px 0px",
                display: "flex",
                alignItems: "center",
                width: "300px",
                height: 30,
                // padding: "10px",
                boxShadow: "none",
                borderBottom: `1px dashed  rgba(0,0,0,0.2) `,
                borderRadius: "inherit",
              }}
            >
              <InputBase
                onChange={(e)=>handleCollectData(e.target.name,e.target.value)}
                value={newProfileState.name}
                type="text"
                name="name"
                sx={{ flex: 1, fontSize: "14px" }}
                placeholder={`Enter a title for you ${name} profile`}
              />
            </Paper>
           
          </Box>
          <Chip name='status' label={newProfileState.status == "active"  ?  "Live": "Draft"} className={`draftChip ${newProfileState.status == "active"  && 'activeChip'}`} sx={{cursor:'pointer'}} onClick={(e)=>handleCollectData('status',newProfileState.status === 'active' ? 'draft' : 'active')}  />
        </Box>
        <Box className="toolbarContent">

        <LoadingButton
          onClick={()=>{
            navigate('/automation/profile')
          }}
            loadingPosition="start"
            startIcon={<CrossIcon color="white" height={16} width={16} />}
            variant="outlined"
            sx={{
              background:"#ba3c3c",
              textTransform: "inherit",
              color: "#fff !important",
              padding: "5px 10px",
              border: "none",
              ":hover": {
                border: "none",
                color: "#fff !important",
                background:"#ba3c3c",
              },
            }}
          >
           Cancel
          </LoadingButton>
          <LoadingButton
            className="displaynote"
            loading={loading}
            loadingPosition="start"
            startIcon={<CheckIcon />}
            variant="outlined"
            sx={{
              textTransform: "inherit",
              color: "#424040 !important",
              padding: "5px 10px",
              border: "none",
              ":hover": {
                border: "none",
              },
            }}
            onClick={handleSubmitResponseData}
          >
            Save and close
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

export default CreateProfileToolbar;
