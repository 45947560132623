import React from "react";
import Box from "@mui/material/Box";
import style from "./loginRaw.module.scss";

const LoginRaw = () => {
  return (
    <Box className={style.loginRawMainClass}>
      <span className="circle">
        <span className="innerCircle"> </span>
      </span>
    </Box>
  );
};

export default LoginRaw;
