import { Box, Typography } from "@mui/material";
import React from "react";
import style from "./cannedResponse.module.scss"
const CannedResponseToolbar = ({handleGetAllUsers}) => {

  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
      <Box className="toolbarContent">
          <Typography  sx={{fontSize:"20px", fontWeight:"bold"}} > Canned Response</Typography>

        </Box>
      </Box>
    </>
  );
};

export default CannedResponseToolbar;
