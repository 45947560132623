import React, { useContext } from 'react'
import CollaspeHoc from '../collapseHoc/CollaspeHoc'
import Collapse from '@mui/material/Collapse';
import ListItemIcon from '@mui/material/ListItemIcon';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ListItemText from '@mui/material/ListItemText';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { SidebarContext } from '../../../context/SidebarProvider';
const Coll = (props) => {
    let {open, setOpen, data, heading, currentTab, parentTab} = props
    const { globalCounts } = useContext(SidebarContext)
  
   
  return (
   <>

    <ListItemButton  className='listItemButtonsClass' onClick={setOpen}>
        <ListItemText primary={heading} className='listItemForCollaspeHeading' />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
            {data.map(ele=>{
                let {text, Component,key,val,path} = ele
                
                return (
                  <Link to={path}  style={{textDecoration:'none'}} >
                    <ListItemButton   className={`listItemButtonsClass ${currentTab===key && 'selectedItemContainer'}`} >
                        {currentTab===key &&    <span className="toogleKey"  ></span>}
                        {Component && <ListItemIcon  className='listIconContainer'  >
                       <Component className='itemIcons' />
                    </ListItemIcon>}
                    <ListItemText primary={text} className='itemText' secondary={get(globalCounts,`${parentTab}[${path}]`,null)}   />
                  </ListItemButton>
                  </Link>
                )
            })}
         
        </List>
      </Collapse>
      </>
  )
}

export default CollaspeHoc(Coll)
