import React from "react";

const CursorRightIcon = ({height=18, width=18, color="gray", ...props}) => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.64906 3.89886C5.31711 4.23081 5.31711 4.769 5.64906 5.10094L8.54802 7.9999L5.64906 10.8989C5.31711 11.2308 5.31711 11.769 5.64906 12.1009C5.981 12.4329 6.51919 12.4329 6.85114 12.1009L10.3511 8.60094C10.6831 8.269 10.6831 7.73081 10.3511 7.39886L6.85114 3.89886C6.51919 3.56692 5.981 3.56692 5.64906 3.89886Z"
        ></path>
      </svg>
    </>
  );
};

export default CursorRightIcon;
