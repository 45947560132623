import React, { Component } from "react";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow:' 0px 4px 16px 0px rgba(0, 0, 0, 0.50)',
    // boxShadow: 24,
    p: 0,
    borderRadius:'5px',
    border:'none !important'
  };

const CommonModal = ({state,handleCloseModal,Component}) => {
  return (
    <>
      <Modal
        open={state}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            {Component && Component }
        </Box>
      </Modal>
    </>
  );
};

export default CommonModal;
