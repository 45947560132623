import { get } from 'lodash'
import React, { createContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { mainSidebarIcons } from '../components/sidebar/constant/sidebarConstant'
import BackdropLoading from '../fixedComponent/backdropLoading/BackdropLoading'
import SnackPopup from '../fixedComponent/snackbar/SnackPopup'
import { getCookie } from '../utils/helpers/helpers'

export const SidebarContext = createContext({})
const countData = {
  "inbox":{},
  "automation":{},
  "contacts":{},
  "settings":{},
  "account":{}
}

const sidebarKeys = {
    'inbox':'your-inbox',
    'automation':'profile',
    'contacts':"customer",
    'settings':'tags',
    'account':'details'
}

const SidebarProvider = ({children}) => {
    const location = useLocation();
    const navigate = useNavigate()
    const [sidebarState, setSidebarState] = useState({
        parent:'inbox',
        child:'your-inbox'
    })
    const [globalCounts, setGlobalCounts] = useState({...countData});

   
    const [sidebarContents, setSidebarContents] = useState([])
    const [snackPopup,setSnackPopup] = useState({
        open: false,
        vertical: 'top',
        horizontal: 'center',
        time:2000,
        message:'test',
        error:false
      })
      const [backdropLoading,setBackdropLoading] = useState(false);


    useEffect(()=>{
        let path = get(location,'pathname',"").split('/').filter(Boolean)
        
        let parent  = get(path,`[0]`,'index');  
        let child = get(path,`[1]`,"");
        // if(parent === "contacts"){
        //   child =  get(path,`[1]`,"") && `${get(path,`[1]`,"")}/${get(path,`[2]`,"")}`
        // }     
       
       let token  =  getCookie('customerToken')
       if(token){
        if(sidebarKeys[parent]){
          
            if(!child.length){
                navigate(`/${parent}/${sidebarKeys[parent]}`)
            }
            setSidebarState({
                parent,
                child
            })
           }
        }
    },[location])

    

    const handleSetCount = (parent, data) => {
      setGlobalCounts((state)=>({
        ...state,
        [parent]:{
          ...state[parent],
          ...data
        }
      }))
      
    }

    useEffect(()=>{
        setSidebarContents(get(mainSidebarIcons,`[${sidebarState.parent}][sidebarData]`,[]))

    },[sidebarState])

    useEffect(()=>{
      
    },[globalCounts])


    const handleShowSnackPopup = ({message ='',vertical='top',horizontal='center',time=2000,error=false}) => {
        setSnackPopup((state)=>({
          ...state,
          message,
          vertical,
          horizontal,
          time,
          error,
          open:true
        }))
        setTimeout(()=>{
          setSnackPopup((state)=>({
           ...state,
             message:"",
            open:false,
          }))
        },[time])
      }
  return (
   <>
   <SidebarContext.Provider
    value={{
    sidebarState,
    sidebarContents,
    setBackdropLoading,
    handleShowSnackPopup,
    globalCounts,
    handleSetCount
    // navigate
   
    
    }}>
    {children}

    <BackdropLoading {...{backdropLoading}} />
      <SnackPopup {...{...snackPopup,setSnackPopup}} />
   </SidebarContext.Provider>
   </>
  )
}

export default SidebarProvider
