import React, { useRef } from 'react';

function useScrollHook() {
  const ref = useRef(null);
  
  const scrollTopContent = () => {
    ref.current.scrollTo({ top: ref.current.scrollHeight, behavior: 'smooth' });
    // 
  }

  return [ref, scrollTopContent];
}

export default useScrollHook;
