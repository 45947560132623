import React from "react";
import HocAccordion from "../../../hocComponents/Accordion/HocAccordion";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const AccordionComponent = ({
  children,
  expand,
  handleChange,
  className,
  heading,
  onClick,
  expanded,
  open
}) => {

  return (
    <>
      <Accordion
        onChange={handleChange}
        expanded={expanded}
        className={className}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            sx={{
              color: "#0D1C2E",
            //   textAlign: "center",
            //   fontFamily: "Poppins",
              fontSize: "15px",
            //   fontStyle: "normal",
            //   fontWeight: 500,
            //   lineHeight: "normal",
            //   textTransform: "capitalize",
            }}
          >
            {heading}
          </Typography>
        </AccordionSummary>
        <AccordionDetails  sx={{
            padding:' 0px 16px 16px' 
        }}   >{children}</AccordionDetails>
      </Accordion>
    </>
  );
};

export default HocAccordion(AccordionComponent);
