import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import style from "./listComponent.module.scss";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import { Box, IconButton, ListItemIcon } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
import NumberIcon from "../../../../assets/icons/NumberIcon";
import AlphabetIcon from "../../../../assets/icons/AlphabetIcon";
import DecimalIcon from "../../../../assets/icons/DecimalIcon";
import TrueFalseIcon from "../../../../assets/icons/TrueFalseIcon";
import CalenderIcon from "../../../../assets/icons/CalenderIcon";
import ListIcon from "../../../../assets/icons/ListIcon";
import VisibilityIcon from '@mui/icons-material/Visibility';
// text, number, date, select, checkbox, radio, textarea, multiselect
let listIcon = {
    text:AlphabetIcon,
    number:NumberIcon,
    Decimal_Number:DecimalIcon,
    radio:TrueFalseIcon,
    date:CalenderIcon,
    select:ListIcon,
}

const ListComponent = ({ data, handleCreateTag, handleDeleteTag, handleDeleteCustomField, handleCreateDataModeling }) => {
  return (
    <>
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        className={style.listComponentMainContainer}
      >
        {data.map((ele) =>{
          let Icon = listIcon[ele.fieldType]
          return<>
            <ListItem
              secondaryAction={
                <>
                <Box  sx={{display:"flex", gap:"20px"}} >
                <IconButton edge="end" aria-label="delete">
                    <VisibilityIcon sx={{ color: "green" }} onClick={()=>{handleCreateDataModeling('view',ele.id)}}  />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteOutlineIcon sx={{ color: "red" }} onClick={()=>handleDeleteCustomField(ele.id)} />
                  </IconButton>
                </Box>
                </>
              }
              className="listItemContainer"
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={ele.name} secondary={ele.description} />
            </ListItem>
          </>
        })}
      </List>
    </>
  );
};

export default ListComponent;
