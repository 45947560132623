import React, { useContext, useEffect, useState } from "react";
// import MainComponent from '../../../../fixedComponent/sideDashboard/MainComponent'
// import SideToolbar from '../../../../fixedComponent/sideToolbar/SideToolbar'
import { Box, Divider } from "@mui/material";
import style from "./WebWidgetComponent.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import CommonContentCard from "./commponContentSouceCard/CommonContentCard";
import BotSettingIcon from "../../../assets/icons/BotSettingIcon";
import BotInboxSettingIcon from "../../../assets/icons/BotInboxSettingIcon";
import BotWorkflowSettingIcon from "../../../assets/icons/BotWorkflowSettingIcon";
import ChatBotSetting from "./contentSources/ChatbotSetting";
import SetBrandColorComponent from "./contentSources/SetBrandColorComponent";
import Customizelauncher from "./contentSources/Customizelauncher";
import ContextAboutUpfront from "./contentSources/ContextAboutUpfront";
import { chatbotSettingApi, updateChatbotSetting } from "../../../services/Apis";
import { get } from "lodash";
import InstallationComponent from "./contentSources/InstallationComponent";
import { SidebarContext } from "../../../context/SidebarProvider";
const initialChatbotSetting = {
  "name": "Pullse AI",
  "imageLink": "https://pullseaipublicassets.blob.core.windows.net/pullse-ai-public-assets/messenger/chatbot-icon.png"
}
const initialBrandSetting = {
  "actionColor": "#A84ACB",
          "backgroundColor": "#A84ACB"
}

const initialLauncherSettings = {
  "imageLink": "https://pullseaipublicassets.blob.core.windows.net/pullse-ai-public-assets/messenger/launcher-icon.png",
  "sideSpacing": "100",
  "bottomSpacing": "100"
}

const initialFormBuilding = {
  state:false
}
const WebWidgetComponent = ({ ToolbarComponent }) => {
  const { chatbotProfiles } = useContext(MainContext);
  const { setBackdropLoading } = useContext(SidebarContext)
  const [chatbotSettings, setChatbotSetting] = useState({
    file: {},
    imageLink: "",
    name: "",
  });
  const [brandSettings, setbrandSettins] = useState({
    actionColor: "",
    backgroundColor: "",
  });
  const [launcherSettings, setlauncherSettins] = useState({
    file: {},
    imageLink: "",
    sideSpacing: "",
    bottomSpacing: "",
  });
  const [formBuilding, setFormBuilding] = useState({
    "state": false,
    data:{
      preMessages:[],
      forms:[],
      afterFormSubmitted:[]
    }
  });
  useEffect(()=>{
    handleFetchChatbotSettings();
  },[])


  const handleFetchChatbotSettings = () => {
    setBackdropLoading(true)

    chatbotSettingApi().then(res=>{
      let response = get(res,'data.data',{});
      setChatbotSetting((state)=>({
        ...state,
        ...get(response,'chatbotSettings',initialChatbotSetting)
      }))
      setbrandSettins((state)=>({
        ...state,
        ...get(response,'brandSettings',initialBrandSetting)
      }))
      setlauncherSettins((state)=>({
        ...state,
        ...get(response,'launcherSettings',initialLauncherSettings)
      }))
      setFormBuilding((state)=>({
        ...get(response,'formBuilding',{})
      }))
      setBackdropLoading(false)
    })
  }
  const handleUpdateChatbotSetting = (updatedPayload) => {
    let payload = {
      chatbotSettings:{
        imageLink:chatbotSettings.imageLink,
        name:chatbotSettings.name,
      },
      brandSettings,
      launcherSettings:{
        imageLink: launcherSettings.imageLink,
        sideSpacing:launcherSettings.imageLink,
        bottomSpacing: launcherSettings.bottomSpacing,
      },
      formBuilding,
      ...updatedPayload
    }
    updateChatbotSetting(payload).then(res=>{
      handleFetchChatbotSettings()
    })
  }

  // let temp = {
  //     "chatbotSetting": {
         
  //     },
  //     "brandSetting": {
        
  //     },
  //     "launcherSetting": {
         
  //     },
  //     "formBuilding": {
  //         "state": false
  //     }
  // }

  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
              marginBottom: "10px",
              marginTop: "10px",
              padding: "20px",
            }}
          >
            <CommonContentCard
              heading={"Choose an identity for your bots"}
              content={
                "This is how Pullse bot and Workflows will appear to customers"
              }
              Icon={BotSettingIcon}
              Component={ ()=><ChatBotSetting data={chatbotSettings} handleUpdateChatbotSetting={handleUpdateChatbotSetting}/>}
            />
            <CommonContentCard
              heading={"Set your brand colors"}
              content={""}
              Icon={null}
              Component={()=><SetBrandColorComponent data={brandSettings} handleUpdateChatbotSetting={handleUpdateChatbotSetting} />}
            />
            <CommonContentCard
              heading={"Customize the Launcher"}
              content={""}
              Icon={null}
              Component={()=><Customizelauncher data={launcherSettings} handleUpdateChatbotSetting={handleUpdateChatbotSetting} />}
            />
            <CommonContentCard
              heading={"Get context about issues upfront"}
              content={""}
              Icon={null}
              Component={ ()=><ContextAboutUpfront data={formBuilding} handleUpdateChatbotSetting={handleUpdateChatbotSetting} />}
            />


            <CommonContentCard
              heading={"how to install pullse chat-bot"}
              content={""}
              Icon={null}
              Component={ ()=><InstallationComponent />}
            />
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default WebWidgetComponent;
