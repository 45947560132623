import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
} from "@mui/material";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
import AddDocsToChatbot from "./addDocsToChatbotprofileModal/AddDocsToChatbot";
import { MainContext } from "../../../../../../context/MainProvider";
import { SidebarContext } from "../../../../../../context/SidebarProvider";
import { chatbotBulkActionsApi, getChatBotDocuments } from "../../../../../../services/Apis";
const ChatbotData = ({chatbotId, handleGetBotDocuments}) => {
    const { handleOpenModal } = useContext(MainContext)
    const handleAddDocsToChatbot = (type) => {
        handleOpenModal({
            Component:<AddDocsToChatbot chatbotId={chatbotId}  type={type} handleGetBotDocuments={handleGetBotDocuments} />
        })
    }
  return (
   <>
      <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
                gap:"20px"
              }}
            >
            


              <Button
              disabled={false}
              onClick={()=>handleAddDocsToChatbot('addChatBots')}
              sx={{
              background: "#f1f1fb",
              color: "#707c97",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined"> 
              Connect contents</Button>
                

            </Box>
          </Box>
   
   
   </>
  )
}

export default ChatbotData
