import React, { Component, createContext, createRef, useCallback, useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import axios from "axios";
import { get } from "lodash";
import NotificationSound from "../assets/sound/water_droplet.mp3"
import { MainContext } from "./MainProvider";
import useChangeTab from "../utils/hooks/useChangeTab";
import useStateCallback from "../utils/hooks/useStateCallback";
import { getCookie, getWorkspaceId } from "../utils/helpers/helpers";
import { getTicketConversation } from "../services/Apis";

/**
 * Fetches socket server URL from env
 */
const SOCKET_URI = process.env.REACT_APP_SOCKET_URL;
const userName = 'testUser'
// let connection = io.connect(SOCKET_URI)

class CreateMessageClass {
  message = "";
  time = Date.now();
  ticketId = null
  username = ""
  constructor(text, roomId,username){
    this.message = text
    this.ticketId = roomId
    this.username = username
  }
}
// static changeTab = useChangeTab()
const SocketContext = createContext({});
const SocketProvider  = ({children}) => {
  const [socket,setSocket] = useStateCallback()
  const {handleFetchTickets, workspaceId} = useContext(MainContext)
  const notificationRef = useRef(null);
  const [userChatData, setUserChatData] = useState([]);
  const {user, setUser} = useState({})
  const [messages, setMessages] = useState({})
  const [clientStartTyping, setClientStartTyping] = useState(false)
  const [currentTicketId, setCurrentTicketId] = useState(null)
  const [coversationDetails, setCoversationDetails] = useState({})
  const [loading,setLoading] = useState({
    loading:false,
    errorMessage:"",
    error:false
  })
  const isCurrentTab = useChangeTab()

  useEffect(()=>{
    initSocketConnection()
  },[])
  // 




  function initSocketConnection() {
    let token = getCookie('customerToken')
    const socket = io.connect(SOCKET_URI, {
      // withCredentials: true,
      query: {
        token,
        userType: "agent",
        workspace_id:workspaceId
      },
    });
    setSocket(socket,()=>{
      setupSocketListeners(socket)
      initAxios();
    })
  }

  function initAxios() {
    axios.interceptors.request.use(
      (config) => {
        setLoading({ loading: true });
        return config;
      },
      (error) => {
        setLoading({ loading: false });
        setLoading({
          errorMessage: `Couldn't connect to server. try refreshing the page.`,
          error: true,
        });
        return Promise.reject(error);
      }
    );
    axios.interceptors.response.use(
      (response) => {
        setLoading({ loading: false });
        return response;
      },
      (error) => {
        setLoading({ loading: false });
        setLoading({
          errorMessage: `Some error occured. try after sometime`,
          error: true,
        });
        return Promise.reject(error);
      }
    );
  }

  function onClientDisconnected() {}

 function onReconnection() {
    if (this.state.ticketId) {
    }
  }

  function setupSocketListeners(socket) {
    socket.on("new message", onMessageRecieved);
    socket.on("reconnect", onReconnection);
    socket.on("disconnect", onClientDisconnected);
    socket.on("add user", onClientDisconnected);
    socket.on("typing", onClientStartTyping);
    socket.on("stop typing", onClientStopTyping);
    socket.on("new ticket",onNewTicketCreated);
   
  }
  function onNewTicketCreated(data){
    handleFetchTickets()
  }

  function onClientStartTyping(msg){
    setClientStartTyping(true)
  }
  function onClientStopTyping(msg){
    setClientStartTyping(false)
  }
  
  const getConverstion = (sno) => {
    getTicketConversation(sno).then(res=>{
      let convDetails = {...get(res,'data.data')};
      delete convDetails['docs']
      setCoversationDetails(convDetails)
      let conver = get(res,'data.data.docs',[])
      let tempMessages = {...messages};
      tempMessages[sno] = conver;
      setMessages(tempMessages)
    })
  }
  
  

  const onMessageRecieved =  ({ username, message, ticketId }) => {

    
    // 
    let roomId = ticketId
    let reciveMsgObj = {
      message,
      ticketId,
      userType:'customer',
      createdAt:new Date().toISOString(),
      read:false
    }
    setMessages((state)=>({
      ...state,
      [roomId]:[reciveMsgObj,...get(state,`[${roomId}]`,[])]
    }))
    // 
    // notificationRef.current && isCurrentTab && notificationRef.current.play()
  }

  function onTypingStartEvent(){  
    socket.emit('typing',{
      typing:true,
      message:'',
      username:userName
    })
  }
  function onTypingStopEvent(textAreaMsg){
    socket.emit('stop typing',{
      typing:true,
      message:textAreaMsg,
      username:userName
    })
  }

  useEffect(()=>{
    return
  },[])

  useEffect(()=>{
    if(currentTicketId){
      getConverstion(currentTicketId)
    }
    // 
  },[currentTicketId])
  


  function onUserClicked({ticketId}) {
    // socket.emit("add user", {
    //   username:userName, ticketId:ticketId
    // });
    // 

    
    setCurrentTicketId(ticketId)
  }

 function  createMessage(text, ids,roomId) {
    const msg = new CreateMessageClass(text, roomId,userName);
    socket.emit("new message",{
      ticketId:msg.ticketId,
      message:msg.message,
      username:userName,
      workspaceId: getWorkspaceId(),
      type:'html',
      ...ids
    })
    onTypingStopEvent("");
    let tempMessages = {...messages}
    let roomData = get(tempMessages,`${roomId}`,[])
    let newMsg = {
      ticketId:roomId,
      message:msg.message,
      userType:'agent',
      createdAt:new Date().toISOString(),
    }
    roomData.unshift(newMsg)
    tempMessages[roomId] = roomData;
    setMessages(tempMessages)
  }

  useEffect(()=>{
    
   
  },[coversationDetails])


  // 
  
    
    return (
      <>
        <SocketContext.Provider value={{
          socket:socket,
          userChatData:userChatData,
          user:user,
          messages:messages,
          currentTicketId:currentTicketId,
          createMessage:createMessage,
          onUserClicked:onUserClicked,
          onTypingStartEvent:onTypingStartEvent,
          onTypingStopEvent:onTypingStopEvent,
          clientStartTyping:clientStartTyping,
          coversationDetails
        }}>
           {/* <audio src={NotificationSound} ref={notificationRef}   /> */}
          {children}
        </SocketContext.Provider>
      </>
    );
}

export const useSocket = () => React.useContext(SocketContext);

export default SocketProvider;
