import React, { useRef, useState } from "react";

function useChangeTab() {
  const [state, setState] = useState(true);
  document.addEventListener("visibilitychange", (event) => {
    if (document.visibilityState == "visible") {
      setState(true);
    } else {
      setState(false);
    }
  });
  return state;
}

export default useChangeTab;
