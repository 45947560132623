import React, { useContext, useEffect, useState } from "react";
// import MainComponent from '../../../../fixedComponent/sideDashboard/MainComponent'
// import SideToolbar from '../../../../fixedComponent/sideToolbar/SideToolbar'
import {
  Box,
  Button,
  Divider,
  InputAdornment,
  InputBase,
  Paper,
} from "@mui/material";
import style from "./teamComponent.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import ListComponent from "./listComponent/ListComponent";
import {
  createTag,
  createTeam,
  deleteTag,
  deleteTeam,
  getAllTeams,
  getTags,
  updateTag,
  updateTeam,
} from "../../../services/Apis";
import { get } from "lodash";
import CreateNewTeams from "./createNew/CreateNewTeams";
import TagIcon from "../../../assets/icons/TagIcon";
import SearchIcon from "../../../assets/icons/SearchIcon";
import SearchBarComponent from "../searchComponent/SearchBarComponent";
import ContactIcon from "../../../assets/searchIcons/ContactIcon";
import DoNotDisturbIcon from '@mui/icons-material/DoNotDisturb';
let workspaceId = process.env.REACT_APP_WORKSPACE_ID;

const TeamsComponent = ({ ToolbarComponent }) => {
  const { chatbotProfiles, handleOpenModal } = useContext(MainContext);
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    handleGetTeams();
  }, []);

  const handleGetTeams = (obj = {}) => {
    getAllTeams(obj).then((res) => {
      let responseArr = get(res, "data.data.docs", []);
      setTeams(responseArr);
    });
  };
  const handleCreateNewTeam = (data) => {
    return new Promise((resolve, reject) => {
      createTeam(data)
        .then((res) => {
          resolve(true);
          handleGetTeams();
        })
        .catch(() => reject(true));
    });
  };
  const handleUpdateTeam = (id, data) => {
    return new Promise((resolve, reject) => {
      updateTeam(id, data)
        .then(() => {
          resolve(true);
          handleGetTeams();
        })
        .catch(() => {
          reject(true);
        });
    });
  };
  const handleCreateTeam = (type, data) => {
    handleOpenModal({
      state: true,
      Component: (
        <CreateNewTeams
          data={{
            type: type,
            data,
            handleUpdate:handleUpdateTeam,
            handleCreate:handleCreateNewTeam,
          }}
        />
      ),
    });
  };

  const handleDeleteTeam = (id) => {
    deleteTeam(id).then((res) => {
      handleGetTeams();
    });
  };
  const getFilterTeams = (value) => {
    handleGetTeams({ type: "name", data: value });
  };

  return (
    <>
      <MainComponent className={style['team-main-component']}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent  heading={'Teams'} />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
            }}
          >
            <SearchBarComponent handleCollectValue={getFilterTeams} />{" "}
            <Button
              startIcon={<ContactIcon color="white" />}
              sx={{
                textTransform: "capitalize",
                background: `linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)`,
                color: "white",
              }}
              onClick={() =>
                handleCreateTeam("create", {
                  workspaceId,
                })
              }
            >
              Create New Team
            </Button>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "80vh",
            }}
          >
           {teams.length ?  <ListComponent
              data={teams}
              handleCreate={handleCreateTeam}
              handleDelete={handleDeleteTeam}
            />
          :
          <Box  sx={{height: "80vh", display:"flex",justifyContent:"center",alignItems:"center"}} >
          <DoNotDisturbIcon  sx={{fontSize:"100px",color:"gray"}} />
          </Box>}
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default TeamsComponent;
