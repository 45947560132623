import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from '@mui/material/Alert';
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
const SnackPopup = ({ vertical = "top", horizontal="center",open, message="test", error }) => {
  // 
  return (
    <>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={3000} 
        key={vertical + horizontal}
        
      >
         <Alert severity={ error ? "error":"success" }sx={{ width: '100%' }}>
          {message}
        </Alert>
        </Snackbar>
    </>
  );
};

export default SnackPopup;
