import  React, { useContext } from "react";
import dayjs from "dayjs";
import { Box, Button, Divider } from "@mui/material";
// import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import { TimePicker } from "@mui/x-date-pickers/TimePicker";
// import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
// import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
// import { StaticTimePicker } from "@mui/x-date-pickers/StaticTimePicker";
import { MainContext } from '../../../../context/MainProvider'
const SelectTimeComponent = () => {
  const {handleCloseModal} = useContext(MainContext)
    const handleColectTime = (event) => {
        
    }
  return (
    <>
      <Box sx={{ padding: "20px 40px", minHeight: "300px" }}>
        <Box
          sx={{
            minWidth: "400px",
            display: "inline-block",
            minHeight: "100px",
            position: "relative",
          }}
        >
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer
              components={[
                "TimePicker",
                "MobileTimePicker", 
                "DesktopTimePicker",
                "StaticTimePicker",
              ]}
            >
                <Box  sx={{display:"flex"}} >
                <DemoItem >
                <StaticTimePicker onChange={handleColectTime}  componentsProps={{ actionBar: { actions: [] } }} defaultValue={dayjs("2022-04-17T15:30")} />
                 </DemoItem>
                  <DemoItem >
                <StaticTimePicker onChange={handleColectTime}  shouldDisableTime={()=>{}} componentsProps={{ actionBar: { actions: [] } }} defaultValue={dayjs("2022-04-17T15:30")} />
              </DemoItem>
                </Box>
           
            </DemoContainer>
          </LocalizationProvider> */}
        </Box>
        <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <Button
                // onClick={handleSubmitData}
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Create
              </Button>
            </Box>
      </Box>
    </>
  );
};

export default SelectTimeComponent;
