import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { Box } from "@mui/material";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";

const DashboardSkelton = ({ numbers = 9 }) => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={2}>
        {[...Array(numbers).keys()].map((ele) => (
          <Grid item sm={4}>
            <Skeleton variant="rounded" width={"100%"} height={200} />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default DashboardSkelton;
