import React from 'react'
import ContentSouceHocCard from '../contentSouceHocCard/ContentSourceHocCard'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ListItemText } from '@mui/material';
const CommonContentCard = ({open,setOpen, heading, content, Component, Icon}) => {
    
  return (
  <>
   <Accordion sx={{border:'none', boxShadow:'none', borderRadius:'5px',transition:'none !important',".MuiCollapse-vertical":{
    transitionDuration:'none !important',
    webkitTransition: "none",
    mozTransition: "none",
    oTransition: "none",
    msTransition: "none",
    transition: "none",

   }, "::before":{
    display:"none"
   }}} className='parentAccordian' expanded={open} onChange={setOpen} >
   <AccordionSummary
   className='AccordionSummary1111122222'
      style={{transition:'none !important'}}
        expandIcon={
          <ExpandMoreIcon
            style={{ transform: open ? `rotate(-180deg)` : `rotate(-90deg)` }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={{display:"flex", gap:"10px", alignItems:"center", }} >
          <Box sx={{position:"relative"}}>
          {Icon && <Icon  color={'#a84acb'} />}
         <span  style={{
          // zIndex:'1',
          display:!open ? "none" : 'inline-block',
          position:'absolute',
          height:'100%',
          width:"2px",
          background:"#f7f7f7",
          left:'50%',
          top:'100%',
          transform:'translate(-50%,0%)'
         }} ></span>
          </Box>

        <ListItemText
          primary={heading}
          secondary={content}
        />
        </Box>
      
      </AccordionSummary>

        {/* <ImportExternalSources /> */}
        {/* <PullseHelpCenterSource /> */}
        {/* <UseSnippetSource />   */}
        {/* <ImportContentFilesSources /> */}
        <Component />
        
      </Accordion>
  
  </>  )
}

export default ContentSouceHocCard(CommonContentCard) 
