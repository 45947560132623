import React from "react";

const TrueFalseIcon = ({height,width=16,color="black",...props}) => {
  return (
    <>
      <svg
      {...props}
        class="interface-icon o__standard o__standard__boolean"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill={color}
          d="M5 3C2.23858 3 0 5.23858 0 8C0 10.7614 2.23858 13 5 13H11C13.7614 13 16 10.7614 16 8C16 5.23858 13.7614 3 11 3H5ZM11 11C12.6569 11 14 9.65685 14 8C14 6.34315 12.6569 5 11 5C9.34315 5 8 6.34315 8 8C8 9.65685 9.34315 11 11 11Z"
        ></path>
      </svg>
    </>
  );
};

export default TrueFalseIcon;
