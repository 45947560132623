import * as React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Box, Button, Divider, InputBase, Paper, Typography } from "@mui/material";
import Radio from '@mui/material/Radio';
import { get } from "lodash";

const numberValues  = [
    {
        "id": "equals",
        "name": "Equals"
    },
    {
        "id": "notEquals",
        "name": "Not equals"
    },
    {
        "id": "greaterThan",
        "name": "Greater than"
    },
    {
        "id": "lessThan",
        "name": "Less than"
    },
    {
        "id": "between",
        "name": "Between"
    },
    {
        "id": "isNotNull",
        "name": "Is not null"
    },
    {
        "id": "isNull",
        "name": "Is null"
    }
]

export default function NumberOperators({onSelectFilter, currentComponent, handleClose, componentData}) {
  const [checked, setChecked] = React.useState("equals");
  const [fieldValue, setValue] = React.useState("")


  const handleToggle = (value) => () => {
    setChecked(value)
    if(["isNull","isNotNull"].includes(value)){
      setValue(value)
    }

  };
  

  React.useEffect(()=>{
    if(Object.keys(componentData).length){
      let operator = get(componentData,'key','true')
      let value = get(componentData,'value','')
      setChecked(operator)
      setValue(value)
    }

  },[componentData])

  
  const handleSubmitData = () => {
    if(!fieldValue.length) return
    onSelectFilter({
        parent:currentComponent,
        key:checked,
        value:fieldValue
    })
    handleClose()
  }

  return (
    <Box>
    <List sx={{ width: "300px", bgcolor: "background.paper", padding:'5px',height:"auto", overflow:"hidden",  }}>
      {numberValues.map((value) => {
        const labelId = `checkbox-list-label-${value.id}`;

        return (
          <ListItem
            sx={{ display: "flex", flexDirection: "column" }}
            key={value}
            disablePadding
          >
            <ListItemButton
              role={undefined}
              onClick={handleToggle(value.id)}
              dense
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: 30,
                padding:"0px 9px",
                borderRadius:"5px",
                ":hover":{
                  background:"none"
                }

              }}
            >
              <ListItemIcon  sx={{minWidth:"initial"}} >
              <Radio
              size="small"
             edge="start"
             checked={checked == value.id}
             sx={{
              color: "#a84acb",
              '&.Mui-checked': {
                color: "#a84acb",
              },
            }}
             tabIndex={-1}
             disableRipple
             inputProps={{ "aria-labelledby": labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} sx={{color:"gray"}} primary={value.name} />
            </ListItemButton>
         {!["isNull","isNotNull"].includes(checked) && checked == value.id &&   <Box sx={{ width: "100%", display:"flex", }}>
              <Paper
                component="box"
                sx={{
                  p: "1px 2px",
                  marginLeft:"0px",
                  display: "flex",
                  alignItems: "center",
                  width: "80%",
                  height: 40,
                  padding: "10px",
                  boxShadow: "none",
                  border: `1px solid rgba(0,0,0,0.1)`,
                  borderRadius:"5px",
                  marginLeft:"40px",
                  // box-shadow: 
                  boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px"
                }}
              >
                <InputBase
                value={fieldValue}
                onChange={({target:{value}})=>{
                  setValue(value)
                }}
                
                  name="username"
                  sx={{ ml: 1, flex: 1, fontSize: "14px"}}
                  placeholder=""
                />
              </Paper>
            </Box>}
          </ListItem>
        );
      })}
    </List>
    <Divider/>
    <Button onClick={handleSubmitData} sx={{width:'100%', textTransform:"capitalize", color:"#a84acb"}}  varient="outlined" >Done</Button>
    </Box>
  );
}
