import {
  Box,
  CssBaseline,
  Paper,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { LoginApi, forgetPassword } from "../../../services/Apis";
import {
  decryptBase64,
  encryptBase64,
  getCookie,
  handleSetToken,
  isEmail,
  setCookie,
  setWorkspaceId,
} from "../../../utils/helpers/helpers";
import { useNavigate, useLocation, Link } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { HttpClient } from "../../../services/HttpClient";
import { SidebarContext } from "../../../context/SidebarProvider";
import { get } from "lodash";
import brandIcon from "../../../assets/Logo.png";
import login2Img from "../../../assets/login3.jpg"

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.pullse.ai/">
        Pullse AI
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();

export default function ForgetPassword() {
  const { handleShowSnackPopup } = useContext(SidebarContext);
  const [loginLoading, setLoginLoading] = useState(false);
  const [globalError, setGlobalError] = useState(false);
  const [loginData, setLoginData] = useState({
    email: "",
  });
  const [onFormFocus, setOnFormFocus] = useState(false);
  let navigate = useNavigate();
  const location = useLocation();
  const [emailSent, setEmailSent] = useState(false);
  const handleCollectValue = (e) => {
    let name = e.target.name,
      val = e.target.value;
    setLoginData((state) => ({
      ...state,
      [name]: val,
    }));
  };

  useEffect(() => {
    let email = get(location.state, "email", "");
    setLoginData((state) => ({
      ...state,
      email: email,
    }));
    if (!email) {
      let encryptedEmail = getCookie("agent_email");
      let email = decryptBase64(encryptedEmail);
      setLoginData((state) => ({
        ...state,
        email: email,
      }));
    }
  }, []);
  const validateLoginData = () => {
    if (!isEmail(loginData.email)) {
      setGlobalError(true);
      return true;
    }
    return false;
  };

  const handleSubmitresult = async () => {
    let isError = validateLoginData(loginData);
    if (isError) return;
    setLoginLoading(true);
    forgetPassword({ email: loginData.email })
      .then((res) => {
        handleShowSnackPopup({ message: "Email sent successfully" });
        setEmailSent(true);
      })
      .catch((err) => {
        handleShowSnackPopup({ message: "Email not exist", error: true });
        setLoginLoading(false);
      });
  };

  const onHandleSubmit = (event) => {
    if (event.keyCode == 13 && onFormFocus) {
      handleSubmitresult();
    }
  };

  let usernameError = globalError && !isEmail(loginData.email);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid container component="main" sx={{ height: "100vh" }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            // background: `rgb(2,0,36)`,
            // background: `linear-gradient(40deg, rgba(2,0,36,1) 13%, rgba(168,74,203,1) 52%)`,
          }}
        >
            <Typography  sx={{fontWeight:'100',position:'absolute',top:'50%', left:'25%', zIndex:'11', color:'white', fontSize:"60px", transform:'translate(-50%,-50%)'}} > Welcome to <b> Pullse AI </b></Typography>
        <Typography  sx={{fontWeight:'100',position:'absolute',top:'60%', left:'25%', zIndex:'11', color:'white', fontSize:"20px", transform:'translate(-45%,-50%)', textAlign:'center'}} > Empower Your Customer Support with AI Innovation. Streamline <br />  Workflows, Enhance  Agent Performance, and Delight <br /> Customers with Pullse AI's Advanced Platform.</Typography>
             <img src={login2Img} style={{
          backgroundClip:'border-box',
          //  backgroundClip:'content-box',
          //  backgroundPosition:'center',
          //  backgroundRepeat:'no-repeat',
           width:'150%'
        }} />
        </Grid>
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={8} square>
          <Box
            sx={{
              my: 12,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={brandIcon} />
            <Typography component="h1" variant="h5" sx={{ marginTop: "10px" }}>
              Forget Password
            </Typography>
            <Box component="div" sx={{ mt: 1, width: "90%" }}>
              {emailSent ? (
                <>
                  <Typography
                    sx={{
                      textAlign: "center",
                      marginTop: "20px",
                      fontSize: "25px",
                      color: "green",
                    }}
                  >
                    Email sent successfully
                  </Typography>
                  <Typography
                    sx={{
                      textAlign: "center",
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  >
                    Please check your mail to reset your password
                  </Typography>
                </>
              ) : (
                <>
                  <TextField
                    error={usernameError}
                    value={loginData.email}
                    margin="normal"
                    required
                    fullWidth
                    onChange={handleCollectValue}
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    onFocus={() => setOnFormFocus(true)}
                    onBlur={() => setOnFormFocus(false)}
                    onKeyUp={onHandleSubmit}
                  />
                  <LoadingButton
                    loading={loginLoading}
                    onClick={handleSubmitresult}
                    sx={{
                      width: "100%",
                      border: "1px solid #B9B9B9",
                      color: "#3E1599",
                      marginTop: "10px",
                    }}
                    variant="outlined"
                    loadingIndicator="Loading…"
                  >
                    forget password
                  </LoadingButton>
                 

                </>
              )}
               <Typography  onClick={()=>{
                  navigate("/")
                }}  sx={{textAlign:"right", cursor:"pointer", marginTop:"10px", color:"blue", fontSize:"13px", textDecoration:"underline"}} >login here</Typography>

              <Copyright sx={{ mt: 5 }} />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}
