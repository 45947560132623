import React from "react";

const AnalogClockIcon = ({height=40, width=40, color="#a84acb", ...props}) => {
  return (
    <>
      <svg {...props} height={height} width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path fill={color} d="M20 0a20 20 0 0 1 20 20c0 11.046-8.954 20-20 20S0 31.046 0 20 8.954 0 20 0zm0 38a18 18 0 0 0 18-18c0-9.941-8.059-18-18-18S2 10.059 2 20s8.059 18 18 18zm13.54-26.54v-.03c4.488 7.106 2.712 16.473-4.065 21.444C22.7 37.845 13.231 36.726 7.8 30.312S2.828 14.374 8.85 8.51C14.87 2.647 24.402 2.44 30.67 8.04l-1.33 1.49c-5.459-4.924-13.796-4.787-19.09.314-5.295 5.1-5.742 13.427-1.025 19.065 4.718 5.639 12.993 6.668 18.948 2.357 5.954-4.311 7.56-12.494 3.677-18.736l1.69-1.07zm-7.833 12.833l-1.414 1.414-5-5A1 1 0 0 1 19 20v-9h2v8.586l4.707 4.707z"></path>
      </svg>
    </>
  );
};

export default AnalogClockIcon;
