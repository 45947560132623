import MessageRoundedIcon from "@mui/icons-material/MessageRounded";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import PeopleIcon from "@mui/icons-material/People";
import PhotoCameraFrontIcon from "@mui/icons-material/PhotoCameraFront";
import PersonRemoveAlt1Icon from "@mui/icons-material/PersonRemoveAlt1";

import { icons } from "../../../assets/icons/index";
const botName = process.env.REACT_APP_AUTO_NAME

// const sideBarComponentsData = {
//   'inbox':[],
//   'contact': [],
//   'Automation': [],
//   'Outbound':[],
//   'pieChart':[]
// };
const getSidebarItems = (mainSidebarIcons) => {
  return  Object.values(mainSidebarIcons).map(ele=>ele)
}


const mainSidebarIcons = {
  'inbox':{
    header:'Inbox',
    name: "inbox",
    Icon: icons.InboxIcon,
    key: "inbox",
    link:'/inbox',
    sidebarData: [
      {
        text: "Your Inbox",
        Component: MessageRoundedIcon,
        type: "child",
        key: "your-inbox",
        val: 1,
        path:'your-inbox'
      },
      {
        text: "Mentions",
        Component: TrackChangesIcon,
        type: "child",
        key: "mentions",
        val: 2,
        path:'mentions'
      },
      {
        text: "All",
        Component: PeopleIcon,
        type: "child",
        key: "all",
        val: 2,
        path:'all'
      },
      {
        text: "Tickets",
        Component: PhotoCameraFrontIcon,
        type: "child",
        key: "tickets",
        val: 2,
        path:'tickets'
      },
  
      {
        text: "Unassigned",
        Component: PersonRemoveAlt1Icon,
        type: "child",
        key: "unassigned",
        val: 22,
        path:'unassigned'
      },
      {
        heading: "Teams",
        type: "teams",
        data: [
          {
            text: "All Teams",
            Component: null,
            key: "all-teams",
            val: 7,
            path:"all-teams"
          },
        ],
      },
      // {
      //   text: "Dashboard",
      //   Component: GridViewIcon,
      //   type: "child",
      //   key: "dashboard",
      //   val: 2,
      //   path:'dashboard'
      // },
      // {
      //   heading: "Teams",
      //   type: "parent",
      //   data: [
      //     {
      //       text: "Support Team",
      //       Component: SettingsIcon,
      //       key: "Teams_Support_Team",
      //       val: 1,
      //     },
      //   ],
      // },
      // {
      //   heading: "Teammates",
      //   type: "parent",
      //   data: [
      //     {
      //       text: "Sunil Joo",
      //       Component: AccountCircleIcon,
      //       key: "Teammates_Sunil_Joo",
      //       val: 5,
      //     },
      //   ],
      // },
      // {
      //   heading: "Views",
      //   type: "parent",
      //   data: [
      //     {
      //       text: "Support Team View",
      //       Component: DashboardIcon,
      //       key: "Views_Support_Team_View",
      //       val: 1,
      //     },
      //   ],
      // },
    ],
  },
  'automation':{
    header:'Automation',
    name: "Automation",
    Icon: icons.AutomationIcon,
    key: "automation",
    link:'/automation/profile',
    sidebarData: [
      {
        heading: "AI Chatbot",
        type: "parent",
        data: [
          {
            text: "Profile",
            Component: null,
            key: "profile",
            path:'profile',
            val: null,
          },
          // {
          //   text: "Custom Answers",
          //   Component: null,
          //   key: "custom-answers",
          //   val: null,
          //   path:'custom-answers',
          // },
          {
            text: "AI Chatbot Content",
            Component: null,
            key: `${botName}-contents`,
            val: null,
            path:`${botName}-contents`,
          },
          {
            text: "Actions",
            Component:null,
            type: "child",
            key: "actions",
            val: null,
            path:'actions'
          },
          {
            text: "Workflows",
            Component:null,
            type: "child",
            key: "workflows",
            val: null,
            path:'workflows'
          },
        ],
      },
      // {
      //   text: "Basics",
      //   Component: PeopleAltIcon,
      //   key: `${botName}_Basics`,
      //   val: null,
      //   type:'child',
      //   path:`${botName}-basic`,
      // },
      // {
      //   text: "Workflows",
      //   Component: SchemaIcon,
      //   key: `${botName}_Workflows`,
      //   val: null,
      //   type:'child',
      //   path:`${botName}-workflows`,
      // },
      // {
      //   text: "Settings",
      //   Component: SettingsIcon,
      //   key: `${botName}_Settings`,
      //   val: null,
      //   type:'child',
      //   path:`${botName}-settings`,
        
      // },
    ]
  },
  // 'support':{
  //   header:'Proactive Support',
  //   name: "Outbound",
  //   Icon: icons.OutBondIcon,
  //   key: "support",
  //   link:'/support',
  //   sidebarData:[]
  // },
 'contacts':{
    header:'Contacts',
    name: "contact",
    Icon: icons.ContactIcon,
    key: "contacts",
    link:'/contacts',
    sidebarData:[
      {
        heading: "Contact",
        type: "parent",
        data: [
          {
            text: "Customer",
            Component: null,
            key: "customer",
            val: 7,
            path:"customer"
          },
          {
            text: "Visitor",
            Component: null,
            key: "visitor",
            val: 3,
            path:"visitor"
          },
        ],
      },
      {
        heading: "Companies",
        type: "parent",
        data: [
          {
            text: "All",
            Component: null,
            key: "company",
            path:"company"
          },
          // {
          //   text: "Active",
          //   Component: null,
          //   key: "Companies_Active",
          //   val: 0,
          // },
          // {
          //   text: "New",
          //   Component: null,
          //   key: "Companies_New",
          //   val: 0,
          // },
          // {
          //   text: "Slipping Away",
          //   Component: null,
          //   key: "Companies_Slipping_Away",
          //   val: 0,
          // },
        ],
      },
    ]
  },
//  'reports':{
//     header:"Reports",
//     name: "pieChart",
//     Icon: icons.PiechartIcon,
//     key: "reports",
//     link:'/reports',
//     sidebarData:[]
//   },
  'settings':{
    header:"Setting",
    name: "setting",
    Icon: icons.SettingIcon,
    key: "settings",
    link:'/settings',
    sidebarData:[
      {
        text: "Tags",
        Component:()=><></>,
        type: "child",
        key: "tags",
        val: 1,
        path:'tags'
      },
      {
        text: "Topics",
        Component:()=><></>,
        type: "child",
        key: "topics",
        val: 1,
        path:'topics'
      },
      
      {
        text: "Teams",
        Component:()=><></>,
        type: "child",
        key: "teams",
        val: 1,
        path:'teams'
      },
      {
        text: "Agents",
        Component:()=><></>,
        type: "child",
        key: "agents",
        val: 1,
        path:'agents'
      },
      // {
      //   text: "Ticket-types",
      //   Component:()=><></>,
      //   type: "child",
      //   key: "ticket-types",
      //   val: 1,
      //   path:'ticket-types'
      // },
      // {
      //   text: "Office-Hours",
      //   Component:()=><></>,
      //   type: "child",
      //   key: "offce-hours",
      //   val: 1,
      //   path:'offce-hours'
      // },
      {
        text: "Web-Widget",
        Component:()=><></>,
        type: "child",
        key: "web-widget",
        val: 1,
        path:'web-widget'
      },
      {
        text: "Data-Modelling",
        Component:()=><></>,
        type: "child",
        key: "data-modelling",
        val: null,
        path:'data-modelling'
      },
      {
        text: "Sentiments",
        Component:()=><></>,
        type: "child",
        key: "sentiments",
        val: null,
        path:'sentiments'
      },
      {
        text: "Performance",
        Component:()=><></>,
        type: "child",
        key: "performace",
        val: null,
        path:'performace'
      },

      {
        text: "Macros",
        Component:()=><></>,
        type: "child",
        key: "macros",
        val: null,
        path:'macros'
      },
      {
        text: "Reports",
        Component:()=><></>,
        type: "child",
        key: "reports",
        val: null,
        path:'reports'
      },
      {
        text: "Email templetes",
        Component:()=><></>,
        type: "child",
        key: "email-templete",
        val: null,
        path:'email-templete'
      },
      {
        text: "Email setting",
        Component:()=><></>,
        type: "child",
        key: "email-setting",
        val: null,
        path:'email-setting'
      },
      {
        text: "Domains",
        Component:()=><></>,
        type: "child",
        key: "domain",
        val: null,
        path:'domain'
      },
      {
        text: "Sender email addresses",
        Component:()=><></>,
        type: "child",
        key: "senders",
        val: null,
        path:'senders'
      },
      {
        text: "Email forwarding",
        Component:()=><></>,
        type: "child",
        key: "email-forwarding",
        val: null,
        path:'email-forwarding'
      },
      {
        text: "Auto reply",
        Component:()=><></>,
        type: "child",
        key: "email-auto-reply",
        val: null,
        path:'email-auto-reply'
      },
    ]
  },

  'account':{
    header:"Accounts",
    name: "Accounts",
    Icon: icons.UserIcon,
    key: "account",
    link:'/account',
    sidebarData:[
      {
        text: "Your details",
        Component:()=><></>,
        type: "child",
        key: "details",
        val: 1,
        path:'details'
      },
      // {
      //   text: "Notifications",
      //   Component:()=><></>,
      //   type: "child",
      //   key: "notifications",
      //   val: 1,
      //   path:'notifications'
      // },
    ]
  },
  // {
  //   header:"Automation",
  //   name: "automation",
  //   Icon: icons.BookIcon,
  //   key: "automation",
  //   link:'/automation',
  //   sidebarData:[
  //     // text: "Your Inbox",
  //     // Component: MessageRoundedIcon,
  //     // type: "child",
  //     // key: "inbox",
  //     // val: 1,
  //     // path:'your-inbox'

  //     {
  //       heading: "AI Chatbot",
  //       type: "parent",
  //       data: [
  //         {
  //           text: "Profiles",
  //           Component: null,
  //           key: "Profiles",
  //           type: "child",
  //           val: 7,
  //           path:'profiles'
  //         },
  //         {
  //           text: "Custom Answers",
  //           Component: null,
  //           key: "customer-answers",
  //           type: "child",

  //           val: 3,
  //           path:'customer-answers'
  //         },
  //         {
  //           text: "AI Chatbot Content",
  //           type: "child",
  //           Component: null,
  //           key: "fin-content",
  //           val: 0,
  //           path:'fin-content'
  //         },
  //       ],
  //     },

  //   ]
  // },  
};

const sideBarItems = getSidebarItems(mainSidebarIcons)

export {  mainSidebarIcons, sideBarItems };
