/* eslint-disable react-hooks/exhaustive-deps */
import ReactQuill, { Quill } from "react-quill";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "quill-mention";
import "./formSubmissionEditor.css"
import "react-quill/dist/quill.snow.css";
import editorCss from "./formSubmissionEditor.module.scss"
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

export default function FormSubmissonEditor({
  onChange=()=>{},
  placeholder="",
  value={},
  autoFocus=false,
  isAuthenticated=false,
  defaultValue={
    delta:{},
    value:<></>
  }
}) {


  const [focus, setFocus] = useState(false)
  const [mentionData, setMentionData] = useState([]);
  const [mouseEnter, setMouseEnter] = useState(false)
  const editor = useRef();
  useEffect(() => {
    if(isAuthenticated){
      setMentionData([
        { id: "{{firstName}}", value: "{{firstName}}" },
        { id: "{{lastName}}", value: "{{lastName}}" },
        { id: "{{email}}", value: "{{email}}" },
      ]);
    }else{
      setMentionData([
        { id: "{{form.firstName}}", value: "{{form.firstName}}" },
        { id: "{{form.lastName}}", value: "{{form.lastName}}" },
        { id: "{{form.email}}", value: "{{form.email}}" },
        { id: "{{form.phone}}", value: "{{form.phone}}" },
      ]);
    }

  }, []);

  useEffect(()=>{
    if(editor){
      if(autoFocus){
        editor.current.focus()
      }else{
        editor.current.blur()
      }
    }
  },[])

  const modules = useMemo(() => ({
    toolbar: false,
    mention: {
      showDenotationChar:false,
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['{{'],
      source: function (searchTerm, renderList, mentionChar) {
        let values;
        if (mentionChar === '{{') {
          values = mentionData;
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = values.filter(
            (mention) =>
              mention.value.toLowerCase().indexOf(searchTerm.toLowerCase()) !== -1
          );
          renderList(matches, searchTerm);
        }
      },
    },
  }), [mentionData]);

  const handleManageFocus =  useCallback((value=false) => {
    setFocus(value)
  },[])
  let stylePayload = {}
  if(mouseEnter){
    stylePayload['border'] = '1px solid #a84acb'
  }
  if(focus){
    stylePayload['border'] = '1px solid #a84acb'
    stylePayload['boxShadow'] =  "0 0 0 3px #f8e6ff"
  }
  

  return (
    <div  style={stylePayload} className={`w-400 h-full ${editorCss.mentionCssClass} `}  onMouseEnter={()=>{setMouseEnter(true)}} onMouseLeave={()=>setMouseEnter(false)} >
      <ReactQuill
        ref={editor}
        value={value?.delta || value?.message || defaultValue.delta}
        theme={'snow'}
        modules={{ ...modules }}
        onChange={onChange}
        onFocus={()=>{
          handleManageFocus(true)
        }}
        onBlur={()=>{
          handleManageFocus(false)
        }}
        placeholder={placeholder}
      />
    </div>
  );
}
