
import { Box } from '@mui/material'
import React from 'react'

const CardBoxComponentWrapper = ({children}) => {
  return (
  <Box sx={{  background:"#fbf9fe", padding:"10px", borderRadius:"3px", border:"1px solid rgba(0,0,0,0.1)"}} >
    {children}
  </Box>
  )
}

export default CardBoxComponentWrapper;
