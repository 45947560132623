import React from "react";

const NewMessageIcon = ({color='#707C97',height=20,width=20}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M17.5587 2.44132L8.67204 11.328M2.72591 6.86272L16.5642 2.0617C17.4164 1.76604 18.234 2.58364 17.9383 3.43584L13.1373 17.2741C12.8084 18.2221 11.4771 18.2481 11.1115 17.3136L8.91407 11.698C8.80432 11.4176 8.58243 11.1957 8.30196 11.0859L2.68636 8.88852C1.75191 8.52286 1.7779 7.19162 2.72591 6.86272Z"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};

export default NewMessageIcon;
