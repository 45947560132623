import React from "react";

const ListIcon = ({height=16,width=16,color='black',...props}) => {
  return (
    <>
      <svg
      {...props}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.15 3.85059C5.15 3.38114 5.53056 3.00059 6 3.00059H14C14.4694 3.00059 14.85 3.38114 14.85 3.85059C14.85 4.32003 14.4694 4.70059 14 4.70059H6C5.53056 4.70059 5.15 4.32003 5.15 3.85059Z"
        ></path>
        <path
          fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.15 8C5.15 7.53056 5.53056 7.15 6 7.15H14C14.4694 7.15 14.85 7.53056 14.85 8C14.85 8.46944 14.4694 8.85 14 8.85H6C5.53056 8.85 5.15 8.46944 5.15 8Z"
        ></path>
        <path
          fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.15 12.1494C5.15 11.68 5.53056 11.2994 6 11.2994H14C14.4694 11.2994 14.85 11.68 14.85 12.1494C14.85 12.6189 14.4694 12.9994 14 12.9994H6C5.53056 12.9994 5.15 12.6189 5.15 12.1494Z"
        ></path>
        <path   fill={color} d="M3.5 3.85059C3.5 4.54094 2.94036 5.10059 2.25 5.10059C1.55964 5.10059 1 4.54094 1 3.85059C1 3.16023 1.55964 2.60059 2.25 2.60059C2.94036 2.60059 3.5 3.16023 3.5 3.85059Z"></path>
        <path   fill={color} d="M3.5 8C3.5 8.69036 2.94036 9.25 2.25 9.25C1.55964 9.25 1 8.69036 1 8C1 7.30964 1.55964 6.75 2.25 6.75C2.94036 6.75 3.5 7.30964 3.5 8Z"></path>
        <path   fill={color} d="M3.5 12.1494C3.5 12.8398 2.94036 13.3994 2.25 13.3994C1.55964 13.3994 1 12.8398 1 12.1494C1 11.4591 1.55964 10.8994 2.25 10.8994C2.94036 10.8994 3.5 11.4591 3.5 12.1494Z"></path>
      </svg>
    </>
  );
};

export default ListIcon;
