import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Divider, InputAdornment, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { MainContext } from "../../../context/MainProvider";
import { get } from "lodash";
import { debounceWithImmediate } from "../../../utils/helpers/helpers";
import { getAllTeams } from "../../../services/Apis";
const randColor = () =>  {
  return "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase();
}


const TeamMenu = ({handleClose, onSelectFilter, selectedVal}) => {
  const {allTeams} = useContext(MainContext)
  const [teams, setTeams] = useState([])
  const [inputVal, setInputVal] = useState("")

  useEffect(()=>{
    let tempTeams = [...allTeams]
    tempTeams.forEach(ele=>{
      ele['color'] = randColor()
    })
    setTeams(tempTeams)
  },[allTeams])
  const makeNameSmaller = (name) => {
    return name[0].toUpperCase();
  };


  const getTeamsData = (value) => {
    getAllTeams({type:'name',data:value}).then(res=>{
      let valArr = get(res,'data.data.docs',[])
      setTeams(valArr);
    })
  }

  const handleCollectData = (event) => {
    setInputVal(event.target.value)
    handleDebounce(event.target.value)
  }

  const handleFetchTeams = (value) => {
    getTeamsData(value)
  }
  const handleDebounce = useCallback(debounceWithImmediate(handleFetchTeams,()=>{},700),[])
  return (
    <Box sx={{ width: "300px", padding: "0px" }} className={"box"}>
      <Paper
        component="box"
        className="padding"
        sx={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: 40,
          padding: "10px",
          boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
        }}
      >
        <InputBase
          className=""
          value={inputVal}
          placeholder="Search"
          sx={{ flex: 1, padding: 0 }}
          onChange={handleCollectData}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "gray" }} />
            </InputAdornment>
          }
        />
      </Paper>
      <Divider />

      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" , maxHeight:'500px', overflowY:'scroll', overflowX:'hidden', padding:'10px 0px 0px 0px'   }}
      >
        {teams.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          let selected = selectedVal == value.id

          return (
            <ListItem key={value} disablePadding  sx={{ background:selected && '#c166e2', color: selected &&'white' }} onClick={()=>{
              onSelectFilter(value)
              handleClose()
            }} >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{ width: 27, height: 27, background: value.color }}
                  >
                    {makeNameSmaller(value.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={labelId} primary={value.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default TeamMenu;
