import React from "react";

const UploadIcon = ({height=40, width=40, color='#313131'}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M7.5924 25.0926C4.34631 25.0926 1.71484 22.8787 1.71484 19.6327C1.71484 16.3866 4.34631 13.7551 7.5924 13.7551C7.81314 13.7551 8.03103 13.7673 8.24546 13.791V13.7551H8.3263C8.27294 13.3272 8.24546 12.8913 8.24546 12.449C8.24546 6.67817 12.9236 2 18.6944 2C22.6023 2 26.0091 4.14523 27.8014 7.32229C28.2394 7.25786 28.6875 7.22449 29.1434 7.22449C34.1929 7.22449 38.2863 11.3179 38.2863 16.3673C38.2863 20.524 35.5124 23.6927 31.7148 24.7372M19.712 33V18M19.712 18L13.7148 24.1862M19.712 18L25.7148 24.1862"
          stroke={color}
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default UploadIcon;
