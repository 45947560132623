import {
  Box,
  Button,
  Paper,
  Typography,
  InputBase,
  TextareaAutosize,
  Divider,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../context/MainProvider";
const SwitchTabPopupAlert = ({ handleChangeTab }) => {
  const { handleCloseModal } = useContext(MainContext);

  return (
    <>
      <Box
        sx={{
          padding: "20px 40px",
          minHeight: "100px",
          borderBottom: "5px solid #a84acb",
        }}
      >
        <Box
          sx={{
            width: "400px",
            display: "inline-block",
            minHeight: "100px",
            position: "relative",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              Change default sender address
            </Typography>
          </Box>

          <Box sx={{ marginTop: "10px" }}>
            <Typography sx={{ fontWeight: "normal", fontSize: "14px" }}>
              This will change the default sender address for all the teammates
              in this workspace.
            </Typography>
          </Box>

          <Box sx={{ marginTop: "10px" }}>
            <Typography sx={{ fontWeight: "normal", fontSize: "14px" }}>
              Are you sure you want to continue?
            </Typography>
          </Box>

          <Box sx={{ marginTop: "10px" }}>
            <Divider />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <Button
              onClick={()=>{
                handleChangeTab();
                handleCloseModal();
              }}
                sx={{
                  background: "#db0629",
                  color: "white",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                  ":hover": {
                    background: "#841531",
                  },
                }}
              >
                {"Yes, change address"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SwitchTabPopupAlert;
