import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  StepContent,
  Typography,
} from "@mui/material";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { Route, Routes } from "react-router-dom";
import DisplayWorkflow from "./display-workflow/DisplayWorkflow";
import AddWorkflow from "./add-workflow/AddWorkflow";
import { allWorkflow, eventWorkflow, workflowEvents } from "../../../services/Apis";
import { get } from "lodash";
const WorkflowComponent = ({ ToolbarComponent }) => {
  const [eventWorkflowData, setEventWorkflowData ] = useState([]);
  const [workflowEventsData, setWorkflowEventsData] = useState([]);
  const [triggers, setTriggers] = useState([]);

  const handleGetWorkflowEventsData = () => {
    workflowEvents().then(res=>{
      let response = get(res,'data.data.docs',[]);
      setWorkflowEventsData(response);
    }).catch(err=>{    })
  }

  const handleGetTriggers = () => {
    allWorkflow().then(res=>{
      let response = get(res,'data.data.docs',[])
      setTriggers(response)
    })
  }

  useEffect(() => {
    handleGetTriggers();
    handleGetWorkflowEventsData();
    getEventWorkflowData()
  },[])


  const getEventWorkflowData = () => {
    eventWorkflow().then(res=>{
      let response = get(res,'data.data.docs');
      setEventWorkflowData(response)
    }).catch(err=>{

    })
  };
  return (
    <>
      <MainComponent >
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "87vh",
              padding:"10px 0px 0px 0px"
            }}
          >
          <Routes>
            
            <Route path="/" element={<DisplayWorkflow  eventWorkflowData={eventWorkflowData} />}  />
            <Route path="add" element={<AddWorkflow getEventWorkflowData={getEventWorkflowData} events={workflowEventsData} companentType="create" triggers={triggers} />}  />
            <Route path=":event_workflow_id" element={<AddWorkflow getEventWorkflowData={getEventWorkflowData} companentType="update"  events={workflowEventsData} triggers={triggers} />}  />

            <Route />
          </Routes>

          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default WorkflowComponent;





