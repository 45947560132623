import React from "react";
import { Box, Typography } from "@mui/material";
import chatbotLogo from "../../../../assets/brandChatbotImage.jpeg";
import style from "./chatboat.module.scss"

const MessageCard = ({message, icon, time, type}) => {
    let isSender = type === 'sender';
  return (
    <>
        <Box sx={{ width: "90%", display: "flex", gap: "5px"}}  className={isSender ? style.sendMessageClass : style.reciverMessageClass} >
        <Box
            sx={{
              minWidth: "60px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
          {icon &&  <img src={chatbotLogo} style={{ height: "40px", width: "40px" }} />}
          </Box>
          <Box sx={{ display:'flex', flexDirection:'column', gap:'5px'}} >
        <Box
          className={'cardContent'}
            sx={{
              background: "#f2f2f2",
              padding: "15px",
              borderRadius: "10px",
             
            }}
          >
            <Typography sx={{ textAlign: "left", fontSize: "15px" }}>
           {message}
            </Typography>
          </Box>
          <Box style={{justifySelf:'flex-start'}} className="messageTimeClass" >
            <Typography sx={{fontSize:'13px', color:'gray   '}} >
              {time}
            </Typography>
          </Box>
        </Box>
        </Box>

        {/* <Box sx={{ width: "80%", display: "flex", gap: "5px" }} className={style.reciverMessageClass} >
          <Box
            sx={{
              minWidth: "60px",
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "center",
            }}
          >
          {icon &&  <img src={chatbotLogo} style={{ height: "40px", width: "40px" }} />}
          </Box>
        <Box sx={{ display:'flex', flexDirection:'column', gap:'5px'}} >
        <Box
          className={'cardContent'}
            sx={{
              background: "#f2f2f2",
              padding: "15px",
              borderRadius: "10px",
             
            }}
          >
            <Typography sx={{ textAlign: "left", fontSize: "15px" }}>
              I have ingested your content, you can give me a spin! Just type
              any question your customers might have. Don't be shy, I love a
              good chat!
            </Typography>
          </Box>
          <Box style={{justifySelf:'flex-start'}} >
            <Typography sx={{fontSize:'13px', color:'gray   '}} >
                Bot. 5m ago.
            </Typography>
          </Box>
        </Box>
         
        </Box> */}
       
    </>
  );
};

export default MessageCard;
