import React, { useEffect, useState } from "react";
import { Box, Button, InputBase, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { get } from "lodash";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { LoadingButton } from "@mui/lab";
import EditIcon from "../../../../../assets/icons/EditIcon";
import { useNavigate } from "react-router-dom";
const DisplayTable = ({
    dataList=[]
}) => {
    const navigate = useNavigate()


  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: "10px",
        }}
      >
        <Box sx={{ padding: "0px 10px 0px 10px" }}>
          <TableContainer
            component={Paper}
            sx={{ boxShadow: "none", border: "1px solid rgba(0,0,0,0.1)" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              size="small"
              aria-label="simple table"
            >
              <TableHead sx={{ background: "#f7f7f7" }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    {" "}
                    Description{" "}
                  </TableCell>
                  <TableCell align="left" sx={{ fontWeight: "bold" }}>
                    Created at
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: "bold" }}>
                    {" "}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dataList.map((element, index) => {
                  const description = get(element,'description',"");
                  const name = get(element, "name", "");
                  const id = get(element,'id',"")
                  const createdAt = get(element, "createdAt", "");
                  let momentTime = moment(createdAt);
                  momentTime = momentTime.isValid() ? momentTime.fromNow() : "";
                  return (
                    <TableRow
                    
                      key={get(element, "id", createdAt)}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                      >
                        <Typography sx={{ fontSize: "14px" }}>
                         {name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left"> {description}</TableCell>
                      <TableCell align="left">{momentTime}</TableCell>
                      <TableCell align="right">
                        {
                          <IconButton
                          onClick={()=>{
                            navigate(id)
                          }}
                          
                            aria-label="delete"
                            size="small"
                            sx={{
                              padding: "3px 10px",
                              background: "#f1f1f1",
                              borderRadius: "5px",
                            }}
                          >
                            <ModeEditIcon
                              sx={{ color: "black", fontSize: "18px" }}
                            />
                          </IconButton>
                        }
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </>
  );
};

export default DisplayTable;
