import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import style from "../introduceMessage.module.scss";
import AntSwitch from "../../../../../../commonComponent/switch/AntiSwitch";
import TextEditor from "../../../../../../editors/textEditor/TextEditor";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";
import MiniEditor from "../../../../../../commonComponent/mini-editor/MiniEditor";
import { v4 as uuidv4 } from 'uuid';
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, minHeight: "500px" }} className="tab-parent">
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default function IntroduceMessageTab({handleAddNewEditor=()=>{}, setNewEditorState=()=>{}, newEditorState=[],handleDeleteEditorState=()=>{},handleCollectEditorValue=()=>{}}) {
  const [value, setValue] = React.useState(0);
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 


 
  return (
    <Box sx={{ width: "100%" }} className={style["introduce-message-tab"]}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor={"secondary"}
          indicatorColor="secondary"
          aria-label="basic tabs example"
          className="introduce-tab"
        >
          <Tab
            label={
              <Box className={"tabs-headers"}>
                <Typography className="heading">English (En)</Typography>
              </Box>
            }
            {...a11yProps(0)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0} className="tab-body">
        <Box className="tab-body-container">
          <Box className="container1 container">
            <Box className="editor-parent-container">
              {newEditorState.map((ele,index) => (
                <Box className="editor-container"  key={ele.id} >
                 <MiniEditor key={ele.id} value={ele}  onChange={(value, delta, source, editor)=>handleCollectEditorValue(value, delta, source, editor, index)} />
                  <DeleteIcon sx={{ color: "#cf0404", cursor:'pointer' }} onClick={()=>handleDeleteEditorState(ele.id)} />{" "}
                </Box>
              ))} 

              <Box className="btn-container">
                <Button
                    type="button"
                  sx={{ color: "#8F1EB3" }}
                  startIcon={<AddIcon />}
                  size="small"
                  onClick={() => handleAddNewEditor()}
                >
                  Add
                </Button>
              </Box>
            </Box>
          </Box>
          {/* <Box className="container2 container">
            <Box className="container2-sub-container">
              <Box className="container2-box">
                <AntSwitch />{" "}
                <Typography variant="subtitle2" className="container2-header">
                  Show bot intro message in English
                </Typography>
              </Box>

              <Box className="sub-heading-container">
                {" "}
                <Typography className="sub-heading">
                  Use our default message, or customise it with your own words
                  to greet your customers, and let them know what to do next.
                  The languages are configured from Messenger settings.
                </Typography>{" "}
              </Box>
              <Box></Box>
            </Box>
          </Box> */}
        </Box>
      </CustomTabPanel>
    </Box>
  );
}
