import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import style from "./sender-email-component.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import CommonContentCard from "./commponContentSouceCard/CommonContentCard";
import {
  chatbotSettingApi,
  updateChatbotSetting,
} from "../../../services/Apis";
import { get } from "lodash";
import OpenEmailBoxIcon from "../../../assets/icons/OpenEmailBoxIcon";
import Chip from "@mui/material/Chip";
import CardDataComponent from "./content-source/CardDataComponent";
import UnauthenticatedCard from "./content-source/UnauthenticatedCard";
import DomainContentDisplayTable from "./domain-content-display/DomainContentDisplayTable";
import SwitchTabComponent from "./tabs/SwitchTabComponent";
import AntSwitch from "../../commonComponent/switch/AntiSwitch";
import PullseAntiSwitch from "../../commonComponent/switch/PullseAntiSwitch";
const initialChatbotSetting = {
  name: "Pullse AI",
  imageLink:
    "https://pullseaipublicassets.blob.core.windows.net/pullse-ai-public-assets/messenger/chatbot-icon.png",
};
const initialBrandSetting = {
  actionColor: "#A84ACB",
  backgroundColor: "#A84ACB",
};

const initialLauncherSettings = {
  imageLink:
    "https://pullseaipublicassets.blob.core.windows.net/pullse-ai-public-assets/messenger/launcher-icon.png",
  sideSpacing: "100",
  bottomSpacing: "100",
};

const initialFormBuilding = {
  state: false,
};
const SenderEmailComponent = ({ ToolbarComponent }) => {
  const { chatbotProfiles } = useContext(MainContext);
  const [chatbotSettings, setChatbotSetting] = useState({
    file: {},
    imageLink: "",
    name: "",
  });
  const [brandSettings, setbrandSettins] = useState({
    actionColor: "",
    backgroundColor: "",
  });
  const [launcherSettings, setlauncherSettins] = useState({
    file: {},
    imageLink: "",
    sideSpacing: "",
    bottomSpacing: "",
  });
  const [formBuilding, setFormBuilding] = useState({
    state: false,
  });
  useEffect(() => {
    handleFetchChatbotSettings();
  }, []);

  const handleFetchChatbotSettings = () => {
    chatbotSettingApi().then((res) => {
      let response = get(res, "data.data", {});
      setChatbotSetting((state) => ({
        ...state,
        ...get(response, "chatbotSettings", initialChatbotSetting),
      }));
      setbrandSettins((state) => ({
        ...state,
        ...get(response, "brandSettings", initialBrandSetting),
      }));
      setlauncherSettins((state) => ({
        ...state,
        ...get(response, "launcherSettings", initialLauncherSettings),
      }));
      setFormBuilding((state) => ({
        ...state,
        ...get(response, "formBuilding", initialFormBuilding),
      }));
    });
  };
  const handleUpdateChatbotSetting = (updatedPayload) => {
    let payload = {
      chatbotSettings: {
        imageLink: chatbotSettings.imageLink,
        name: chatbotSettings.name,
      },
      brandSettings,
      launcherSettings: {
        imageLink: launcherSettings.imageLink,
        sideSpacing: launcherSettings.imageLink,
        bottomSpacing: launcherSettings.bottomSpacing,
      },
      formBuilding,
      ...updatedPayload,
    };
    updateChatbotSetting(payload).then((res) => {
      handleFetchChatbotSettings();
    });
  };

  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
              marginBottom: "10px",
              marginTop: "10px",
              padding: "20px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                Default sender address
              </Typography>
              <Typography sx={{ fontSize: "14px", color: "black" }}>
                Set the default email address you want to use for your outbound
                emails and notifications
              </Typography>
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <SwitchTabComponent />
            </Box>

            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                gap:"10px"
              }}
            >
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
                Map to inbound email address
              </Typography>

              <Box sx={{display:"flex", gap:"10px", alignItems:"flex-start"}} >
               <Box  sx={{padding:"5px 10px 10px 10px"}} >
               <PullseAntiSwitch /></Box> <Typography  sx={{fontSize:"14px"}} >
                Replies to inbound emails will be sent from the same address that the customer contacted you on. For example, emails sent to support@company.com will receive replies from that same addres
                </Typography>
              </Box>
            </Box>


            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                gap:"10px"
              }}
            >
              <Typography sx={{ fontSize: "15px", fontWeight: "bold" }}>
              Manage Custom Addresses
              </Typography>

              <Box sx={{display:"flex", gap:"10px", alignItems:"flex-start"}} >
               <Typography  sx={{fontSize:"14px"}} >
               Custom addresses can be used as ”from” and ”reply-to” addresses when you send emails. This is useful if you'd like certain outbound emails to appear as coming from a team, or if you'd like to change how your name appears in email clients.

                </Typography>
              </Box>
            </Box>




            <Box
              sx={{
                marginTop: "10px",
                display: "flex",
                flexDirection: "column",
                gap:"10px"
              }}
            >
              <DomainContentDisplayTable />
            </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default SenderEmailComponent;
