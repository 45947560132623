import React from "react";

const CheckIcon = ({color='white', width=14,height=14, ...props}) => {
  return (
    <>
      <svg
       {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 14 14"
        fill="none"
      >
        <path
          d="M9.80001 4.90039L5.62363 9.10039L4.20001 7.66873"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default CheckIcon;
