import React from "react";

const CaretDownIcon = ({color="#707C97",height=19,width=19}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 19 19"
        fill="none"
      >
        <path
          d="M4.75 7.125L9.5 11.875L14.25 7.125"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default CaretDownIcon;
