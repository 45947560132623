import React from 'react'
import { Avatar, Box, Typography } from "@mui/material";
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import moment from 'moment';
const TicketCard = (props) => {
    let { 

        id, title,
        description,
        status,
        type,
        customer,
        createdAt,
        tags

    } = props;

    let createdTime = moment(createdAt).fromNow()
  return (
   
    <Box className="conversaton-card">
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <Avatar sx={{ height: "32px", width: "32px", background:"#a84acb" }}>{title[0]}</Avatar>
          <Typography sx={{ fontSize: "15px" }}>
            {title}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: "14px", color: "gray" }}>
            {createdTime}
          </Typography>
        </Box>
      </Box>
      {/* <Box>
        <Typography sx={{ fontSize: "14px", color: "gray" }}></Typography>
      </Box> */}

      <Box
        sx={{
          display: "flex",
          gap: "5px",
        }}
      >{tags.map(ele=>(
        <Box sx={{background:"gray", color:"white", margin:0, padding:0, padding:"0px 8px",borderRadius:"8px",}}>
        <Typography  sx={{fontSize:"13px"}}>
        #{ele.name} 
        </Typography>
         </Box>
      ))
         }

       
      </Box>
    </Box>
   
  )
}

export default TicketCard
