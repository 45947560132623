import React from "react";

const ExternalLinkIcon = (color="#707C971", height=24, width=24) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M10.8 2.3999H6.00002C4.0118 2.3999 2.40002 4.01168 2.40002 5.9999V18C2.40002 19.9882 4.0118 21.6 6.00002 21.6H18C19.9882 21.6 21.6 19.9882 21.6 18V13.1999M15.5994 2.40019L21.6 2.3999M21.6 2.3999V7.80005M21.6 2.3999L11.3991 12.5996"
          stroke={"#707C97"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default ExternalLinkIcon;
