import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Chip,
  Divider,
  InputBase,
  MenuItem,
  Popover,
  Select,
  StepContent,
  Tooltip,
  Typography,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
import moment from "moment";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';


const DisplayAgents = ({allAgents, handleDeleteAgent}) => {
  return (
   <>
     <Box  sx={{marginTop:"20px"}} >
      <TableContainer component={Paper}  sx={{boxShadow:"none" }}>
      <Table sx={{ minWidth: 650, }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell  sx={{fontWeight:"bold"}}>Name</TableCell>
            <TableCell  sx={{fontWeight:"bold"}}>	Role	</TableCell>
            <TableCell  sx={{fontWeight:"bold"}} >Status</TableCell>
            <TableCell   sx={{fontWeight:"bold"}} >Date created</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Action </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allAgents.map((element) => {
            let createdData  = moment(get(element,'createdAt',''));
            return (
            <TableRow
              key={get(element,'id','')}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {/* <Link to={get(element,'id','')}> */}
                {get(element,'name','')}
                {/* </Link> */}
              </TableCell>
              <TableCell > {get(element,`roles`,[]).join(",") }</TableCell>
              <TableCell >{element.status === "active" ? <Chip sx={{borderRadius:"5px"}} label="Active" color="success"  /> : <Chip sx={{borderRadius:"5px"}} label="InActive" color="error" />}</TableCell>
              <TableCell> { createdData.isValid() ? createdData.fromNow() :" "}</TableCell>
              <TableCell align="right">
              <Tooltip title="Delete Agent" placement="top">
                <DeleteOutlineIcon sx={{color:"red", cursor:"pointer"}} onClick={() => handleDeleteAgent(get(element,'id',''))} />
               </Tooltip>             
                </TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>
   </>
  )
}

export default DisplayAgents
