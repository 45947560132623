import {
  AccordionDetails,
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import style from "./channelsStyle.module.scss";
import AntSwitch from "../../../../../commonComponent/switch/AntiSwitch";
import logo from "../../../../../../assets/icons/pullseLogo.png";
import { Image } from "@mui/icons-material";
import AppleIcon from "@mui/icons-material/Apple";
import AndroidIcon from "@mui/icons-material/Android";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PeopleIcon from "@mui/icons-material/People";
import CheckIcon from '@mui/icons-material/Check';
import CustomAccordian from "../../customAccordian/CustomAccordian";
import AudianceTableHeader from "./audianceTable/AudianceTableHeader";
import AudianceTableBody from "./audianceTable/AudianceTableBody";
import ContactFilters from "../../../../../contact/filters/ContactFilters"
import AudianceRuleCondition from "./condition/ChannelsConditionAction";
import { get } from "lodash";
let tempAudiance = ['customer','visitor'];

const ChannelsBody = ({data}) => {
  const {newProfileState,componentType, handleCollectData, handleSubmitRulesData} = data
  let { channels, audience } = newProfileState;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [firsTimeUpdated, setFirstTimeUpdated] = useState(true);
  const [audiance,setAudiance] = useState({
    customer:true,
    visitor:false
  })
  console.log(data,"data11111")


  useEffect(()=>{
    if(firsTimeUpdated){
      let tempAudi = {
        customer:true,
        visitor:false
      }
      audience.forEach(ele=>{
        tempAudi[ele] = true
      })
      setAudiance(tempAudi)
      setFirstTimeUpdated(false)
    }

  },[audience])
  const handleCollectAudianceData = (value) => {
    if(audience.length == 1 && audiance[value]) return
      setAudiance((state)=>({
        ...state,
        [value]:!audiance[value]
      }))
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSelectChannel = (event) => {
    let {name, checked} = event.target;
    let tempChannels = [...channels];
    if(tempChannels.includes(name) && !checked){
      let index = tempChannels.indexOf(name);
      tempChannels.splice(index,1);
    }else{
      tempChannels.push(name)
    }
    handleCollectData('channels',tempChannels)
  }
  useEffect(()=>{
    let tempAud = {...audiance};
    tempAud = Object.keys(tempAud).map(ele=>tempAud[ele] && ele).filter(Boolean);
    handleCollectData('audience',tempAud)
  },[audiance])

  useEffect(()=>{
    let obj = { 
      customer:true,
      visitor:false
    }
    let tempAudiance = [...audience];
    tempAudiance.forEach(ele => {
     obj[ele] = true
    })
    setAudiance(obj)
  },[])
  return (
    <>
      <Divider />
      <AccordionDetails className={style["channels-body-main-container"]}>
        <Box className="body-content-container">
          <Box className="channel-headers">
            <Typography className="channel-heading">Channels</Typography>
            <Typography className="sub-heading">
              Select the channels you would like this trigger to apply to. You
              can use channel attributes to configure channel-specific actions
              and content. Functionality may vary by channel.
            </Typography>
          </Box>

          <Box className="channel-app-container">
            <Box className="apps">
              <Typography className="app-heading"> Pullse AI</Typography>
              <Box className="app-box">
                {" "}
                <AntSwitch name="web" checked={channels.includes('web')}   onChange={handleSelectChannel} /> <Image src={logo} />{" "}
                <Typography className="app-name"> Web </Typography>{" "}
              </Box>
              <Box className="app-box">
                {" "}
                <AntSwitch name="ios" checked={channels.includes('ios')} onChange={handleSelectChannel} /> <AppleIcon />{" "}
                <Typography className="app-name"> iOS </Typography>{" "}
              </Box>
              <Box className="app-box">
                {" "}
                <AntSwitch name="android" checked={channels.includes('android')}   onChange={handleSelectChannel}/> <AndroidIcon />{" "}
                <Typography className="app-name"> Android </Typography>{" "}
              </Box>
            </Box>
            {/* <Box className="apps">
              <Typography className="app-heading"> Social</Typography>
              <Box className="app-box">
                {" "}
                <AntSwitch /> <Image src={logo} />{" "}
                <Typography className="app-name"> Web </Typography>{" "}
              </Box>
              <Box className="app-box">
                {" "}
                <AntSwitch /> <AppleIcon />{" "}
                <Typography className="app-name"> iOS </Typography>{" "}
              </Box>
              <Box className="app-box">
                {" "}
                <AntSwitch /> <AndroidIcon />{" "}
                <Typography className="app-name"> Android </Typography>{" "}
              </Box>
            </Box> */}
          </Box>

          <Divider />

          <Box className="channel-audiance">
            <Box className="channel-audiance-container">
              {" "}
              <Typography className="channel-audiance-heading">
                Audience
              </Typography>{" "}
            </Box>
            <Box className="channel-audiance-container">
              {" "}
              <Typography className="channel-audiance-sub-heading">
                Applies to all channels
              </Typography>{" "}
            </Box>
            <Box className="channel-audiance-container"  sx={{ display:"flex", gap:'10px', alignItems:"center"}} >
              <Box className="audience-button">
                <Button
                  startIcon={<PeopleIcon />}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  className="audience"
                  sx={{
                    textTransform:'capitalize'

                  }}
                >
                  {Object.keys(audiance).map(ele=> audiance[ele] ? ele : "").filter(Boolean).join(" , ")}
                 
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                    sx: {
                      padding: "5px 10px",
                      minWidth:"200px"
                    },
                    className: "audience-menu-container",
                  }}
                >
                  {tempAudiance.map(ele=>(
                     <MenuItem className="audience-menu-items"  sx={{justifyContent:'space-between', color:audiance[ele] &&'#a84acb'}} onClick={() => {handleCollectAudianceData(ele)}}>
                     {ele} {audiance[ele] && <CheckIcon />}
                   </MenuItem>
                  ))}
                </Menu>
              </Box>
             
            
             


              <Box className="audience-button">
                <ContactFilters  componentType={'customer'} componentFace={componentType === "update" ? componentType : "create"} text="Audience rule" handleSubmitData={handleSubmitRulesData} filterValues={get(newProfileState,'rules',{})} />

              </Box>
              <AudianceRuleCondition value={get(newProfileState,'matchType','any')} handleCurrentTab={(value)=>{
                handleCollectData('matchType', value)
              }} />

            </Box>
          </Box>

          {/* <CustomAccordian Header={AudianceTableHeader} Body={AudianceTableBody} className={style['audiance-accordian-table']} /> */}


        </Box>
      </AccordionDetails>
    </>
  );
};

export default ChannelsBody;
