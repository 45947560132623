import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  InputBase,
  Paper,
  Typography,
} from "@mui/material";
import style from "./changePassword.module.scss";
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../../fixedComponent/sideToolbar/SideToolbar";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { changeAgnentPassword } from "../../../../services/Apis";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { LightTooltip } from "../../../commonComponent/tooltip/LightTooltip";
import { SidebarContext } from "../../../../context/SidebarProvider";
import { get } from "lodash";
import { deleteCookie } from "../../../../utils/helpers/helpers";
import { MainContext } from "../../../../context/MainProvider";
const allInputs = [
  {
    lable: "Current password",
    placeholder: "Current password",
    name: "password",
    key: "password",
  },
  {
    lable: "New password",
    placeholder: "New password",
    name: "new_password",
    key: "new_password",
  },
  {
    lable: "Confirm password",
    placeholder: "Confirm password",
    name: "confirm_password",
    key: "confirm_password",
  },
];
const ChangePasswordComponent = ({ ToolbarComponent }) => {
  const { handleShowSnackPopup,  navigate } = useContext(SidebarContext)
  const { profile } = useContext(MainContext)
  const [changePasswordState, setChangePasswordState] = useState({
    logout_all: true,
    password: "",
    new_password: "",
    confirm_password: "",
  });
  const [isGlobalError, setIsGlobalError] = useState(false);

  const [inputTypes, setInputTypes] = useState({
    password: {
      type: "password",
    },
    new_password: {
      type: "password",
    },
    confirm_password: {
      type: "password",
    },
  });

  const handleChangeVisibility = (key, value) => {
    setInputTypes((state) => ({
      ...state,
      [key]: {
        type: value,
      },
    }));
  };

  const handleCollectValue = (event) => {
    let { name, value } = event.target;

    if (name === "logout_all") {
      value = event.target.checked;
    }
    setChangePasswordState((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const handleChangePassword = () => {
    if (
      changePasswordState.new_password !==
        changePasswordState.confirm_password ||
      changePasswordState.new_password.length < 2 ||
      changePasswordState.confirm_password.length < 2
    ) {
      setIsGlobalError(true);
      return;
    } else {
      let tempPayload = {
        ...changePasswordState,
      };
      delete tempPayload["confirm_password"];
      changeAgnentPassword(tempPayload).then((res) => {
        handleShowSnackPopup({
          message:"password updated successfully",
          time:3000
        })
        if(tempPayload.logout_all){
          deleteCookie('customerToken')
          navigate('/',{
            state:{
              email:profile.email,
          password:changePasswordState.new_password
        }})
        }      
      }).catch(error=>{
        let errorRes = get(error,'response.data.message',"")
        handleShowSnackPopup({
          message:errorRes,
          error:true,
          time:3000
        })

      })
    }
  };
  const isIncorrectPass =  isGlobalError &&
    changePasswordState.new_password !== changePasswordState.confirm_password;
  return (
    <>
      <MainComponent className={style["details-main-component"]}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>
            {ToolbarComponent && (
              <ToolbarComponent heading={"Change Password"} />
            )}
          </SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "80vh",
            }}
          >
            <Box className="detail-com-form-box">
              {allInputs.map(({ key, name, placeholder, lable }) => (
                <Box className="input-box-component" key={key}>
                  <Typography className="input-label">{lable}</Typography>
                  <Paper
                    component="box"
                    sx={{
                      p: "1px 2px",
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      height: 30,
                      padding: "10px",
                      boxShadow: "none",
                      border: `1px solid ${
                        key === "confirm_password" && isIncorrectPass
                          ? "red"
                          : "rgba(0,0,0,0.2)"
                      } `,
                    }}
                  >
                    <InputBase
                      className="input-box"
                      type={inputTypes[key]["type"]}
                      name={name}
                      onPaste={(e) => {
                        if (["confirm_password"].includes(key)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={handleCollectValue}
                      value={changePasswordState[key]}
                      sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                      placeholder={`Enter ${placeholder}`}
                      endAdornment={
                        <InputAdornment position="end">
                          {inputTypes[key]["type"] === "text" ? (
                            <>
                              <VisibilityOffIcon
                                sx={{ cursor: "pointer", color: "#a84ace" }}
                                onClick={() => {
                                  handleChangeVisibility(key, "password");
                                }}
                              />
                            </>
                          ) : (
                            <>
                              <VisibilityIcon
                                sx={{ cursor: "pointer", color: "#a84ace" }}
                                onClick={() => {
                                  handleChangeVisibility(key, "text");
                                }}
                              />
                            </>
                          )}
                        </InputAdornment>
                      }
                    />
                  </Paper>
                </Box>
              ))}

              <Box
                className="input-box-component"
                sx={{ flexDirection: "row !important", alignItems: "center" }}
              >
                <FormControlLabel
                  className="form-box-for-checkbox"
                  value="end"
                  control={
                    <Checkbox
                      name={"logout_all"}
                      onChange={handleCollectValue}
                      checked={changePasswordState.logout_all}
                      size="small"
                      sx={{
                        color: "#8F1EB3",
                        "&.Mui-checked": {
                          color: "#8F1EB3",
                        },
                      }}
                    />
                  }
                  label={"logout all devices"}
                  labelPlacement="end"
                />
                <LightTooltip
                  title={
                    <Typography sx={{ fontSize: "13px" }}>
                      You will also logout
                    </Typography>
                  }
                  placement="top"
                >
                  <ErrorOutlineIcon sx={{ fontSize: "14px" }} />
                </LightTooltip>
              </Box>

              <Box className="input-action-component">
                <Button
                  onClick={handleChangePassword}
                  className="save-changes"
                  variant="text"
                  sx={{}}
                >
                  Change password
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default ChangePasswordComponent;
