import React from "react";

const ContactSideBarIcon = ({ color, height=20, width=20 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 35 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.3333 8.16667C16.3333 6.87983 15.2868 5.83333 14 5.83333C12.7131 5.83333 11.6666 6.87983 11.6666 8.16667C11.6666 9.4535 12.7131 10.5 14 10.5C15.2868 10.5 16.3333 9.4535 16.3333 8.16667ZM18.6666 8.16667C18.6666 10.7403 16.5736 12.8333 14 12.8333C11.4263 12.8333 9.33331 10.7403 9.33331 8.16667C9.33331 5.593 11.4263 3.5 14 3.5C16.5736 3.5 18.6666 5.593 18.6666 8.16667ZM5.83331 23.3333C5.83331 18.83 9.49781 15.1667 14 15.1667C18.5021 15.1667 22.1666 18.83 22.1666 23.3333C22.1666 23.9773 21.6451 24.5 21 24.5C20.3548 24.5 19.8333 23.9773 19.8333 23.3333C19.8333 20.1168 17.2165 17.5 14 17.5C10.7835 17.5 8.16665 20.1168 8.16665 23.3333C8.16665 23.9773 7.64515 24.5 6.99998 24.5C6.35481 24.5 5.83331 23.9773 5.83331 23.3333Z"
          fill="url(#paint0_linear_183_659)"
        />
        <mask
          id="mask0_183_659"
          style={{"maskType":"luminance"}}
          maskUnits="userSpaceOnUse"
          x="5"
          y="3"
          width="18"
          height="22"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.3333 8.16667C16.3333 6.87983 15.2868 5.83333 14 5.83333C12.7131 5.83333 11.6666 6.87983 11.6666 8.16667C11.6666 9.4535 12.7131 10.5 14 10.5C15.2868 10.5 16.3333 9.4535 16.3333 8.16667ZM18.6666 8.16667C18.6666 10.7403 16.5736 12.8333 14 12.8333C11.4263 12.8333 9.33331 10.7403 9.33331 8.16667C9.33331 5.593 11.4263 3.5 14 3.5C16.5736 3.5 18.6666 5.593 18.6666 8.16667ZM5.83331 23.3333C5.83331 18.83 9.49781 15.1667 14 15.1667C18.5021 15.1667 22.1666 18.83 22.1666 23.3333C22.1666 23.9773 21.6451 24.5 21 24.5C20.3548 24.5 19.8333 23.9773 19.8333 23.3333C19.8333 20.1168 17.2165 17.5 14 17.5C10.7835 17.5 8.16665 20.1168 8.16665 23.3333C8.16665 23.9773 7.64515 24.5 6.99998 24.5C6.35481 24.5 5.83331 23.9773 5.83331 23.3333Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_183_659)">
          <rect width="28" height="28" fill={color} />
        </g>
        <g clipPath="url(#clip0_183_659)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M23.3333 8.16667C23.3333 6.87983 22.2868 5.83333 21 5.83333C19.7131 5.83333 18.6666 6.87983 18.6666 8.16667C18.6666 9.4535 19.7131 10.5 21 10.5C22.2868 10.5 23.3333 9.4535 23.3333 8.16667ZM25.6666 8.16667C25.6666 10.7403 23.5736 12.8333 21 12.8333C18.4263 12.8333 16.3333 10.7403 16.3333 8.16667C16.3333 5.593 18.4263 3.5 21 3.5C23.5736 3.5 25.6666 5.593 25.6666 8.16667ZM12.8333 23.3333C12.8333 18.83 16.4978 15.1667 21 15.1667C25.5021 15.1667 29.1666 18.83 29.1666 23.3333C29.1666 23.9773 28.6451 24.5 28 24.5C27.3548 24.5 26.8333 23.9773 26.8333 23.3333C26.8333 20.1168 24.2165 17.5 21 17.5C17.7835 17.5 15.1666 20.1168 15.1666 23.3333C15.1666 23.9773 14.6451 24.5 14 24.5C13.3548 24.5 12.8333 23.9773 12.8333 23.3333Z"
            fill="url(#paint1_linear_183_659)"
          />
          <mask
            id="mask1_183_659"
            style={{"maskType":"luminance"}}
            maskUnits="userSpaceOnUse"
            x="12"
            y="3"
            width="18"
            height="22"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.3333 8.16667C23.3333 6.87983 22.2868 5.83333 21 5.83333C19.7131 5.83333 18.6666 6.87983 18.6666 8.16667C18.6666 9.4535 19.7131 10.5 21 10.5C22.2868 10.5 23.3333 9.4535 23.3333 8.16667ZM25.6666 8.16667C25.6666 10.7403 23.5736 12.8333 21 12.8333C18.4263 12.8333 16.3333 10.7403 16.3333 8.16667C16.3333 5.593 18.4263 3.5 21 3.5C23.5736 3.5 25.6666 5.593 25.6666 8.16667ZM12.8333 23.3333C12.8333 18.83 16.4978 15.1667 21 15.1667C25.5021 15.1667 29.1666 18.83 29.1666 23.3333C29.1666 23.9773 28.6451 24.5 28 24.5C27.3548 24.5 26.8333 23.9773 26.8333 23.3333C26.8333 20.1168 24.2165 17.5 21 17.5C17.7835 17.5 15.1666 20.1168 15.1666 23.3333C15.1666 23.9773 14.6451 24.5 14 24.5C13.3548 24.5 12.8333 23.9773 12.8333 23.3333Z"
              fill="white"
            />
          </mask>
          <g mask="url(#mask1_183_659)">
            <rect x="7" width="28" height="28" fill={color} />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_183_659"
            x1="9.91665"
            y1="7"
            x2="20.7623"
            y2="17.8959"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7CB8F7" />
            <stop offset="0.934101" stopColor="#2A8BF2" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_183_659"
            x1="16.9166"
            y1="7"
            x2="27.7623"
            y2="17.8959"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#7CB8F7" />
            <stop offset="0.934101" stopColor="#2A8BF2" />
          </linearGradient>
          <clipPath id="clip0_183_659">
            <rect
              width="14"
              height="28"
              fill={color}
              transform="translate(21)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default ContactSideBarIcon;
