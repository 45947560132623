import React from "react";
import { Box, Button, Typography } from "@mui/material";
import MessageTextBox from "../../../inbox/yourInbox/textBox/MessageTextBox";
import BarGroupIcon from "../../../../assets/icons/BarGroupIcon";
import AutoSettingIcon from "../../../../assets/icons/AutoSettingIcon";
import MessageTextIcon from "../../../../assets/icons/MessageTextIcon";
import { useNavigate } from "react-router-dom";
const botName = process.env.REACT_APP_AUTO_NAME;
const contentData = [
  {
    Icon: MessageTextIcon,
    content: "Monitor how AI Chatbot interacts with your customers",
    buttonName: `Go to ${botName} inbox `,
    action: () => `/inbox/your-inbox`,
  },
  {
    Icon: BarGroupIcon,
    content: `Take a closer look at how AI Chatbot impacts your
        support metrics`,
    buttonName: "View the report",
    action: () => `/settings/reports`,
  },
  {
    Icon: AutoSettingIcon,
    content: `Manage AI Chatbot usage`,
    buttonName: "View the report",
    action: () => `/settings/reports`,
  },
];

const ReportCard = () => {
   const navigate =  useNavigate()
  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#F7F7F7",
          borderRadius: "5px",
          padding: "15px",
        }}
      >
        <Typography sx={{ fontSize: "18px", padding: "10px 10px" }}>
          Pullse’s AI Answers Are Now Live! 🤗
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "15px",
            borderRadius: "16px",
            marginTop: "20px",
          }}
        >
          {contentData.map((ele) => (
            <ReportDetailCard {...ele}  navigate={navigate}/>
          ))}
        </Box>
      </Box>
    </>
  );
};

const ReportDetailCard = ({ Icon, content, buttonName,navigate, action }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
          height: "231px",
          flexDirection: "column",
          background: "white",
          width: "400px",
          borderRadius: "16px",
          padding: "10px",
        }}
      >
        <Icon />
        <Typography
          sx={{ textAlign: "center", color: "#707C97", fontSize: "15px" }}
        >
          {content}
        </Typography>

        <Button
        onClick={()=>{
            navigate(action())
        }}
          variant="contained"
          sx={{
            background:
              "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
            textTransform: "inherit",
          }}
        >
          {buttonName}
        </Button>
      </Box>
    </>
  );
};

export default ReportCard;
