import React from 'react'
import CardBoxComponentWrapper from './CardBoxComponentWrapper'
import { Box, Typography } from '@mui/material'

const ForwardingEmailHeaderComponent = () => {
  return (
  <CardBoxComponentWrapper>
    <Box sx={{display:"flex", flexDirection:"column", gap:"10px"}}>
        <Box>
            <Typography sx={{color:"gray", fontSize:"15px", fontWeight:"600"}} variant='h1' >
            Default inbound address
            </Typography>

        </Box>

        <Box>
        <Typography sx={{ fontSize:"14px", }} >
        Forward email from your normal support email address (such as help@myapp.com) to Intercom so you can reply to emails from your inbox. We will automatically detect emails that have been forwarded to Intercom and add the email address to the list of company addresses to ignore below.

            </Typography>

            
        </Box>

    </Box>
    
  </CardBoxComponentWrapper>
  )
}

export default ForwardingEmailHeaderComponent
