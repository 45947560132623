import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Popover,
  Select,
  StepContent,
  Tooltip,
  Typography,
} from "@mui/material";

import { Link, useNavigate } from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
import moment from "moment";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const DisplayCannedResponse = () => {

  const {
    handleCreateMacro,
    handleupdateMacro,
    handleDeleteMacro,
    allMacros
  } = useContext(MainContext);

  const handleDeleteMyMacro = (id) => {
    handleDeleteMacro(id);
  }
  const navigate = useNavigate()
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: "10px",
          maxHeight: "88vh",
          height: "88vh",
        }}
      >
        <Box sx={{padding:"30px 50px"}}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontWeight: "400", fontSize: "30px" }}>
          Response
          </Typography>
          <Button
            variant="outlined"
            sx={{
              textTransform: "inherit",
              height: "35px",
            }}
            onClick={() => {
                navigate('create')
            }}
          >
            Add canned response
          </Button>
        </Box>

      <Box  sx={{marginTop:"20px"}} >
      <TableContainer component={Paper}  sx={{boxShadow:"none" }}>
      <Table sx={{ minWidth: 650, }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell  sx={{fontWeight:"bold"}}>Name</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}} >Date created</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Last updated	</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Available for</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Usage (last 7 days) </TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Action </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {allMacros.map((element) => {
            let createdData  = moment(get(element,'createdAt',''));
            let updatedData = moment(get(element,'updatedAt',''));
            return (
            <TableRow
              key={get(element,'id','')}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                <Link to={get(element,'id','')}>
                {get(element,'name','')}
                </Link>
              </TableCell>
              <TableCell align="right"> { createdData.isValid() ? createdData.fromNow() :" "}</TableCell>
              <TableCell align="right">{updatedData.isValid()?  updatedData.fromNow() : ""}</TableCell>
              <TableCell align="right">{"All agents"}</TableCell>
              <TableCell align="right">{"NAN"}</TableCell>
              <TableCell align="right">
              <Tooltip title="Delete macro" placement="top">
                <DeleteOutlineIcon sx={{color:"red", cursor:"pointer"}} onClick={()=>handleDeleteMyMacro(get(element,'id',''))} />
               </Tooltip>             
                </TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>
          

        </Box>
      </Box>
    </>
  );
};

export default DisplayCannedResponse;
