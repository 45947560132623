import { Box, Checkbox, InputBase, InputLabel, Paper, Typography } from '@mui/material'
import React from 'react'
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';
import { CheckBox } from '@mui/icons-material';
import CustomerType from './CustomerType';
import BackToOffice from './BackToOffice';
import TrackerType from './TrackerType';
import { PullseTextArea } from '../../../../commonComponent/pullse-text-area/PullseTextArea';
import { get } from 'lodash';
import PullseInputBox from '../../../../commonComponent/pullse-input-box/PullseInputBox';





const CreateCustomerTicketType = ({currentTicketTypeTab, ticketTypeData, handleCollectData}) => {

    const currentComponentType = {
        customer:"When the teammate resolving the ticket is also responsible for communicating with the customer. These tickets are always shared with the customer and allow customer facing replies",
        office:"To assign back-office work to separate teams, distinct from the team responsible for customer communication handling.",
        tracker:"For widespread problems that affect multiple customers."
    }


    const currentComponent = {
        customer:CustomerType,
        tracker:TrackerType,
        office:BackToOffice
    }

    let Component = currentComponent[currentTicketTypeTab]
    const currenttext = currentComponentType[currentTicketTypeTab]
  return (
    <Box  sx={{display:"flex", gap:"10px", flexDirection:"column", padding:"15px 0px"}} >
        <Typography  sx={{fontSize:"13px"}} >
        {currenttext}
        </Typography>
        <Box>
        <InputLabel sx={{fontWeight:"bold"}} shrink htmlFor="bootstrap-input">
          Name
        </InputLabel>
        <PullseInputBox inputProps={{
            autoFocus:true
        }} value={get(ticketTypeData,'name','')} name='name' onChange={handleCollectData} placeholder='e.g. Feature request' />
        </Box>
        <Box>
        <InputLabel  sx={{fontWeight:"bold"}} shrink htmlFor="bootstrap-input">
          Description
        </InputLabel>
        <PullseTextArea style={{
            resize:'none'

        }} value={get(ticketTypeData,'description',"")} onChange={(event)=>{
            if(255 - get(ticketTypeData,'description',"").trim().length  > 0){
                handleCollectData(event)
            }

            
            
            }} name='description' aria-label="minimum height" minRows={3} placeholder="e.g. Capture idea for new features" />
        <InputLabel sx={{fontWeight:"bold"}} shrink htmlFor="bootstrap-input">
        {`${255 - get(ticketTypeData,'description','').trim().length} characters remaining`}
        </InputLabel>

        </Box>


                  <Component />
         {/* <CustomerType /> */}
         {/* <BackToOffice />          */}
                  {/* <TrackerType /> */}
     



        {/* <Box  sx={{display:'flex', flexDirection:"column", gap:"1px"}} >
      <Typography sx={{fontSize:"14px", color:"black", fontWeight:"bold"}} >
      Pullse AI Autofill
      </Typography>
      <Box sx={{display:'flex',  gap:"5px", alignItems:"flex-start"}} >
             <Checkbox
            checked={get(ticketTypeData,'autoFill',false)}
            onChange={handleCollectData}
            name="autoFill"
            size='small'
            sx={{
                color:'#a84acb ',
                '&.Mui-checked': {
                color:'#a84acb ',
                },
                padding:0
            }}
            />
      <Typography sx={{fontSize:"13px", color:"gray",}} >
      Customer tickets are always shared with customers and allow customer facing replies.
      </Typography>
      </Box>

      
     
        </Box> */}
    </Box>
        )
}

export default CreateCustomerTicketType
