import React  from "react";
import { Box, Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { get } from "lodash";
import IconButton from "@mui/material/IconButton";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const DisplayWorkflow = ({eventWorkflowData}) => {
    let dataList = []
    const navigate = useNavigate()
  return (
    <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      overflowY: "scroll",
      gap: "10px",
    }}
  >
    <Box sx={{ padding: "0px 10px 0px 10px" }}>
      <TableContainer
        component={Paper}
        sx={{ boxShadow: "none", border: "1px solid rgba(0,0,0,0.1)" }}
      >
        <Table
          sx={{ minWidth: 650 }}
          
          aria-label="simple table"
        >
          <TableHead sx={{ background: "#f7f7f7" }}>
            <TableRow>
              <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
                {" "}
                eventId{" "}
              </TableCell>
              <TableCell align="left" sx={{ fontWeight: "bold" }}>
              workflowId
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {eventWorkflowData.map((element, index) => {
              const name = get(element, "name", "");
              const workflowId = get(element, "workflowId", "");
              const id = get(element,'id',"")
              const eventId = get(element, "eventId", "");
              return (
                <TableRow
                
                  key={get(element, "id", " ")}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell
                    component="th"
                    scope="row"
                  >
                    <Typography sx={{ fontSize: "14px", color:"blue", textDecoration:"underline", cursor:'pointer', }} onClick={()=>{
                        navigate(`${id}`)
                    }} >
                     {name}
                    </Typography>
                  </TableCell>
                  <TableCell align="left"> {eventId}</TableCell>
                  <TableCell align="left">{workflowId}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Box>
  )
}

export default DisplayWorkflow
