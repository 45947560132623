import React, { useContext, useEffect, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Chip,
  Input,
  InputAdornment,
  InputBase,
  ListItemText,
  Paper,
} from "@mui/material";
import FileIcon from "../../../../assets/icons/FileIcon";
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import { LightTooltip } from "../../../commonComponent/tooltip/LightTooltip";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import { Image } from "@mui/icons-material";
import chatbotImagelink from "../../../../assets/chatbot-image-link.png";
import widgetToggleLogo from "../../../../assets/widget-toggle-white.png";
import { get } from "lodash";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const createurl = (fileObj) => {
    return URL.createObjectURL(fileObj)
  
  }
  

const Customizelauncher = ({ data, handleUpdateChatbotSetting }) => {
    const [launcherSettingsState, setlauncherSettinsState] = useState({
        file: {},
        imageLink: "",
        sideSpacing: "",
        bottomSpacing: "",
      });

      useEffect(()=>{
        setlauncherSettinsState(data)
      },[data])

      const handleCollectFile = (event) => {
        let getFile  = get(event,`target.files[0]`,{});
        if(Object.keys(getFile)){
          let imageLink = createurl(getFile)
          setlauncherSettinsState((state)=>({
            ...state,
            file:getFile,
            imageLink:imageLink
          }))
        }    
      }

  const handleCollectColor = (event) => {
    let { name, value } = event.target;
    setlauncherSettinsState((state) => ({
      ...state,
      [name]: value,
    }));
  };
  const handleResetState = () => {
    setlauncherSettinsState(data)
  }

  const handleSaveData = () => {
    handleUpdateChatbotSetting({
      launcherSettings:{
        imageLink:launcherSettingsState.imageLink,
        sideSpacing:launcherSettingsState.sideSpacing,
        bottomSpacing: launcherSettingsState.bottomSpacing,
      }
    })
  }


  return (
    <>
      <AccordionDetails>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px 50px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              flexDirection: "column",
              maxWidth: "60%",
              border: "1px solid gray",
              padding: "15px",
              borderRadius: "5px",
              border: "1px solid rgba(0,0,0,0.2)",
              background: "#f8f8f8",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                fontWeight: "bold",
              }}
            >
              <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
                Custom launcher
              </Typography>
              <LightTooltip
                placement="top"
                title="Show your logo on your Messenger's
            launcher. Add a PNG, JPG, or GIF that's
            under 50KB. Square images (72x72px)
            with transparent backgrounds work best.            
            "
              >
                <HelpOutlinedIcon sx={{ fontSize: "14px" }} />
              </LightTooltip>
            </Box>

            <Box sx={{ display: "flex", gap: "50px", alignItems: "center" }}>
              <Box
                sx={{
                  padding: "10px",
                  borderRadius: "50%",
                  height: "50px",
                  width: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#a84acb",
                  overflow:"hidden"
                }}
              >
                <img
                  src={get(launcherSettingsState,'imageLink',chatbotImagelink)}
                  style={{
                    height: "40px",
                    width: "40px",
                    borderRadius:"50%"
                  }}
                  alt="error while loading image"
                />
              </Box>

              <Button
                component="label"
                variant="outlined"
                sx={{
                  background: "#f1f1fb",
                  color: "#707c97",
                  height: "26px",
                  boxShadow: "none",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  padding: "3px 10px",
                  borderColor: "#a84acb",
                  color: "#a84acb",
                  ":hover": { borderColor: "#a84acb" },
                  textTransform: "inherit",
                }}
                startIcon={<CloudUploadIcon />}
              >
                Upload file
                <VisuallyHiddenInput type="file" onChange={handleCollectFile} />
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",

              gap: "40px",
              maxWidth: "60%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                maxWidth: "60%",
              }}
            >
              <Typography  sx={{fontSize:"14px"}}>Side spacing:</Typography>
              <Paper
                component="box"
                sx={{
                  p: "1px 2px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 30,
                  padding: "10px",
                  boxShadow: "none",
                  border: `1px solid rgba(0,0,0,0.2) `,
                }}
              >
                <InputBase
                  onChange={handleCollectColor}
                  value={get(launcherSettingsState,'sideSpacing',0) }
                  className="input-box"
                  type="number"
                  name="sideSpacing"
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  endAdornment={
                    <InputAdornment position="end">px</InputAdornment>
                  }
                />
              </Paper>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                maxWidth: "60%",
              }}
            >
              <Typography  sx={{fontSize:"14px"}} >Bottom spacing:</Typography>
              <Paper
                component="box"
                sx={{
                  p: "1px 2px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 30,
                  padding: "10px",
                  boxShadow: "none",
                  border: `1px solid rgba(0,0,0,0.2) `,
                }}
              >
                <InputBase
                  onChange={handleCollectColor}
                  value={get(launcherSettingsState,'bottomSpacing',0)}
                  type="number"
                  className="input-box"
                  name="bottomSpacing"
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  endAdornment={
                    <InputAdornment position="end">px</InputAdornment>
                  }
                />
              </Paper>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              maxWidth: "40%",
            }}
          >
            <Button onClick={handleResetState} sx={{ 
              
              
              
              background: "#f1f1fb",
              color: "#707c97",
              height: "26px",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              border:'none',
              ":hover":{borderColor: "none", }}}  variant="outlined" >   Reset</Button>
            <Button
            onClick={handleSaveData}
             sx={{
              background: "#f1f1fb",
              color: "#707c97",
              height: "26px",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined">Save</Button>
 

          </Box>
        </Box>
      </AccordionDetails>
    </>
  );
};

export default Customizelauncher;
