import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Box } from '@mui/material';
import { makeChartData } from '../../utils';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: 'Dataset 2',
      data: labels.map(() =>  Math.floor(Math.random() * (1000 - -1000 + 1)) + 1000),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};







const AreaChartComponent = ({chartData}) => {
  const [areaChartData, setAreaChartData] = useState({
    labels:[],
    datasets:[{
      backgroundColor: [],
      data: [] }]

  }) 
  useEffect(()=>{
    let { labels, datasets } = makeChartData(chartData)
    setAreaChartData({
      labels,
      datasets
    })
  },[])

  

    const newChartData = {
      ...areaChartData
     };
  return (
   <Box sx={{width:"100%",}} >
        <Line options={options} data={newChartData} chartData={data} />
   </Box>
  )
}

export default AreaChartComponent

