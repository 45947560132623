import {
  Box,
  Button,
  Paper,
  Typography,
  InputBase,
  TextareaAutosize,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
import { SidebarContext } from "../../../../context/SidebarProvider";

const fields = [
  {
    type: "text",
    key: "first_name",
    placeholder: "First Name",
  },
  {
    type: "text",
    key: "last_name",
    placeholder: "Last Name",
  },
  {
    type: "email",
    key: "email",
    placeholder: "email",
  },
  {
    type: "password",
    key: "password",
    placeholder: "Password",
  },
  {
    type: "password",
    key: "confirm_password",
    placeholder: "Confirm Password",
  },
];
const CreateNewAgent = ({ data }) => {
  const { handleCloseModal, rolePremissions } = useContext(MainContext);
  const {handleShowSnackPopup} = useContext(SidebarContext)
  const [loading, setLoading] = useState(false);
  const [currentAgent, setCurrentAgent] = useState({
    "first_name":"",
    "last_name": "",
    "email": "",
    "role": "",
    "password": "",
    "confirm_password": ""
  });

  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setCurrentAgent((state) => ({
      ...state,
      [name]: value,
    }));
  };
   
 
  let { type, handleCreateNewAgent } = data;
 
  const handleSubmitData = () => {
    handleCreateNewAgent(currentAgent).then(res=>{
      handleShowSnackPopup({
        message:"agent created successfully"
      })
      handleCloseModal()
    }).catch(err=>{
      handleShowSnackPopup({
        message:"error while creating agent",
        error:true
      })
    })
  }
  

  return (
    <>
      <Box sx={{ padding: "20px 40px 20px 40px", minHeight: "350px" }}>
        <Box
          sx={{
            width: "400px",
            display: "inline-block",
            minHeight: "300px",
            position: "relative",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              {type} agents
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              marginTop: "30px",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {fields.map(({ type, key, placeholder }, index) => (
              <Box sx={{}} key={key}>
                <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 40,
                    padding: "10px",
                    boxShadow: "none",
                    border: `1px solid ${"rgba(0,0,0,0.2)"}`,
                  }}
                >
                  <InputBase
               
                   value={get(currentAgent,`${key}`,"")}
                   onChange={handleCollectData}
                    type={type}
                    autoFocus
                    
                    name={key}
                    sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                    placeholder={placeholder}
                  />
                </Paper>
              </Box>
            ))}

            <Box>
              <Select
                sx={{
                  width: "100%",
                  border: "1px solid rgba(0,0,0,0.1)",
                  fontSize: "13px",
                  paddingLeft: "5px",
                  height: 40,
                  borderRadius: "5px",
                }}
                value={get(currentAgent,'role',"")}
                onChange={handleCollectData}
                name="role"
                input={
                  <InputBase
                    variant="standard"
                    autoFocus={true}
                    sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                  ></InputBase>
                }
              >
                {
                  rolePremissions.map(ele=>(
                    <MenuItem
                    name="role"
                    value={ele.id}
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                   {ele.name}
                  </MenuItem>
                  ))
                }
              
              </Select>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Button
              onClick={handleCloseModal}
              sx={{
                background: "#F1F1F1",
                color: "black",
                height: "30px",
                textTransform: "inherit",
                fontSize: "13px",
              }}
            >
              Cancel
            </Button>
            <Button
            onClick={handleSubmitData}
              sx={{
                background:
                  "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                color: "white",
                height: "30px",
                textTransform: "inherit",
                fontSize: "13px",
              }}
            >
              {'Create'}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateNewAgent;
