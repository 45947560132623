import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Button, InputBase, MenuItem, Paper, Select } from "@mui/material";
import CrossIcon from "../../../../assets/icons/CrossIcon";
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
import useStateCallback from "../../../../utils/hooks/useStateCallback";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { debounceWithImmediate } from "../../../../utils/helpers/helpers";
import Badge from '@mui/material/Badge';
import { PullseTextArea } from "../../../commonComponent/pullse-text-area/PullseTextArea";
const defaultRadioOptions = [
  {
    text: "On",
    key: "on",
    value: "on",
    id:"on",
    name:"On"

  },{
    text: "Off",
    key: "off",
    value: "off",
    id:"off",
    name:"Off"
  }
]
const ActionCard = ({
  handleRemoveAction,
  index,
  type,
  componentType,
  cardData,
  editable,
  workflowEntityFieldsData,
  handleCollectConditionalData,
}) => {
  const [defaultActionsData, setDefaultActionsData] = useState([]);
  const [actionDropValues, setActionDropValues] = useState({
      actions: [],
      attributes: {},
      options: {},
    });

    let initialAttributeValue = {
      key:"",
      value:"",
      fieldType:""
  }

  const [existFields, setExistFields] = useState({})

  const [multiAttributes, setMultiAttributes] = useState([{...initialAttributeValue}]);
  const [priorityFields, setPriorityFields] = useState({})

  const { customeFieldsData } = useContext(MainContext);
  const isDisbaledFields = !editable && componentType === "update";
  const [updateData, setUpdateData] = useState(false)
  const [updatedAttributesData, setUpdatedAttributeData] = useState({});
  const [dataRendered, setDataRendered] = useState(false);  
  const [disbaleAttributeButton , setDisableAttributeButton] = useState(false);


  console.log(existFields,actionDropValues,"actionDropValues", cardData)


  useEffect(()=>{
    let existKeyValue = {}
     multiAttributes.forEach(ele=>{
      let existFieldKey = ele.key;
      if(existFieldKey && existFieldKey.length){
        existKeyValue[existFieldKey] = true;
      }
    })
    setExistFields(existKeyValue);
  },[multiAttributes])

  useEffect(()=>{
      if(cardData.type){

        // 
      let tempActionData =  JSON.parse(JSON.stringify(get(workflowEntityFieldsData,'actions',[]))).find(ele=>ele.id==get(cardData,'type',''));
      let preAttributrs = get(tempActionData,'attributes',[])
      let tempPriorityFields = {}
    
      if(preAttributrs && preAttributrs.length){
        // 
        preAttributrs = preAttributrs.map(ele=>{
          tempPriorityFields[ele.id]  = {
            required:get(ele,'required',false)
          }
         return{
            fieldType:ele.fieldType,
            key:ele.id,
            value: ""
          }
          
        })

        // 
        setPriorityFields(tempPriorityFields)
        setMultiAttributes(preAttributrs)
        setDataRendered(true)
      }else{
        setMultiAttributes([{...initialAttributeValue}])
        setExistFields({})
        setPriorityFields({})
      }
      }
  
  },[get(cardData,'type',''),workflowEntityFieldsData?.actions])


  useEffect(()=>{
    multiAttributes.forEach(ele=>{
      if(ele.key){
        setExistFields((state)=>({
          ...state,
          [ele.key]:true
        }))
      }
    })
  },[multiAttributes])

useEffect(()=>{
  if(componentType === 'create'){



  }
},[componentType, workflowEntityFieldsData])

// 

console.log(multiAttributes,"multiAttributes")

  useEffect(() => {
    let tempAttributes = {...get(actionDropValues, "attributes", {}) };    
      let newOptions = {};
      Object.keys(tempAttributes).forEach((ele) => {
      if(!['create_ticket','update_ticket'].includes(ele)){
        return;
      }
      // 
      let arributes = tempAttributes[ele];
      arributes.forEach((ele) => {

          // 
      if(["list","multi-select"].includes(get(ele,'fieldType',""))) {
          // 
          newOptions[ele.id] = ele?.options && get(ele,'options',[]).map((ele) => ({
            key: ele.id,
            text: ele.name,
            value: ele.id,
          }));
        }
      });
    });

    // 
    // 
    setActionDropValues((state) => ({
      ...state,
      options: {...newOptions},
    }));
  }, [actionDropValues.attributes]);

  useEffect(() => {
    // 
  }, [actionDropValues]);

  useEffect(()=>{
    // setMultiAttributes([initialAttributeValue])
  },[get(cardData,`type`,"")])


  const handleSetNewAttributes = ({newAttrData, cardTempData}) => {
    // 
    let tempCardData = {...cardTempData}
    let tempAttributes = { };
    newAttrData.forEach(ele=>{
        if(ele.key && ele.value){
            tempAttributes[ele.key] = ele.value;
        }
    })
    tempCardData['attributes'] = tempAttributes
    // 
    handleCollectConditionalData(tempCardData, type,index)        
  }

  useEffect(()=>{
    debounceCall({newAttrData:multiAttributes, cardTempData:cardData})
  },[multiAttributes])


  const debounceCall = useCallback(debounceWithImmediate(handleSetNewAttributes, ()=>{},1000),[])



  useEffect(()=>{
    if(!updateData && componentType === "update"){
        let allAttributes = get(cardData,'attributes',{})
        setUpdatedAttributeData(allAttributes)
    }else{
        setUpdateData(true)
    }
  },[componentType])

  // 

  useEffect(()=>{
    if( componentType === "update"){
        let  mainArr = []
        Object.keys(updatedAttributesData).forEach(attrKey=>{
            let obj = {}
            let isChecked = false
            Object.values(actionDropValues.attributes).forEach(arrVaule=>{
                arrVaule.forEach((val)=>{
                    if(attrKey === val.id  && !isChecked){
                       obj  = {
                        key: attrKey,
                        value:updatedAttributesData[attrKey],
                        fieldType:val.fieldType,
                        isDupicate:false
                       }
                       isChecked = true
                    }
                })

            })
            mainArr.push(obj);
        })
    //   setMultiAttributes(get(updatedAttributesData,'attributes',[]))
    setMultiAttributes(mainArr)
  
    }
   

  },[actionDropValues])
//   useEffect(()=>{
//     
//     // handleSetNewAttributes(multiAttributes, cardData)
//   },[multiAttributes])


  useEffect(() => {
    let actions = JSON.parse(JSON.stringify([...get(workflowEntityFieldsData, "actions", [])]));
    let currentAttribute = JSON.parse(JSON.stringify([...get(workflowEntityFieldsData, "entities", [])]));
    let attributesKeys = {};
    setDefaultActionsData(actions);
    let newActions = actions.map((ele) => {
      let findAttribute = currentAttribute.find(({ id }) => id == ele.entity);
      if (findAttribute) {
        ele.attributes = findAttribute.attributes;
      }
      return ele;
    });
    newActions.forEach((ele) => {
      attributesKeys[ele.id] = ele.attributes.map((ele) => ({
        key: ele.id,
        text: ele.name,
        value: ele.id,
        ...ele,
      }));
    });
    newActions = newActions.map((ele) => ({
      key: ele.id,
      text: ele.name,
      value: ele.id,
    }));
    setActionDropValues({
      actions: newActions,
      attributes: attributesKeys,
      options: {},
    });
  }, [workflowEntityFieldsData]);


  const handleCollectData = (event, element) => {
    let { name, value } = event.target;
    let tempObjValues = {
      ...cardData,
      [name]: value,
    };
    if (name === "type") {
      delete tempObjValues["attribute"];
    }

    // 
    // setMultiAttributes
    handleCollectConditionalData(tempObjValues, type, index);
  };


  const handleDeleteNewAttribute = (index,ele) => {
    let tempMultiAttributes = [...multiAttributes]
    if(tempMultiAttributes.length === 1){
      setMultiAttributes([{...initialAttributeValue}])
    }else{
        tempMultiAttributes.splice(index, 1)
        tempMultiAttributes = checkDuplicatesOrNot(tempMultiAttributes)
     setMultiAttributes(tempMultiAttributes)
    }
    // let tempExistFields = {...existFields};
    // delete tempExistFields[get(ele,'key',"")];
    // setExistFields(tempExistFields)
  }

  const checkDuplicatesOrNot = (tempArr)=>{
    let originalKeys = {};
    return tempArr.map(ele=>{
        let tempValues = {...ele}
            if(originalKeys[ele.key]){
                tempValues['isDupicate'] = true;
        }else{
            tempValues['isDupicate'] = false;
            originalKeys[tempValues.key] = true;
        }
        return tempValues;
    })
  }

  const handleCollectAttributeData = (event, element,index, type="key") => {
    let tempMultiAttributes = [...multiAttributes]
    if(type==="value"){
      tempMultiAttributes[index].value = event.target.value;

    }else{
        tempMultiAttributes[index].fieldType = get(element,'fieldType','string');
        tempMultiAttributes[index].key = event.target.value
        tempMultiAttributes[index].value = "";
    }
    
    tempMultiAttributes = checkDuplicatesOrNot(tempMultiAttributes)    
    setMultiAttributes(tempMultiAttributes);
  }

  console.log("multiAttributes",actionDropValues.options)
  return (
    <Box
      sx={{
        border: "1px solid rgba(0,0,0,0.1)",
        padding: "10px",
        borderRadius: "5px",
        ":hover": {
          boxShadow: "5px 5px 31px -8px rgba(0,0,0,0.1)",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <CrossIcon
        
          height={14}
          width={14}
          color={isDisbaledFields ? "gray" : "red" }
          style={{ cursor: "pointer"}}
          onClick={() => {
            if(!isDisbaledFields){
                handleRemoveAction(type, index);
            }
          }}
        />
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <SelectBoxContainer
         disableOptions={{}}
          disabled={isDisbaledFields}
          componentType={componentType}
          style={{ width: "200px !important" }}
          options={get(actionDropValues,'actions',[])}
          value={cardData.type}
          onChange={handleCollectData}
          name={"type"}
        />
        <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
          {get(cardData, "type", "").length ? (
            <>
              {multiAttributes.map((ele,index) => {
                let   isDupicate = get(ele,`isDupicate`,false)
                


                // 
                return   (
                    <>
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                      <Badge badgeContent={'duplicate'} invisible={!isDupicate} anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }} size="small" color="error">
                        <SelectBoxContainer
                          disableOptions={existFields}
                          disabled={isDisbaledFields || get(priorityFields,`[${ele.key}].required`, false)}
                          componentType={componentType}
                          defaultOpen={false
                            
                            // Object.keys(priorityFields).length ? priorityFields[ele.key] === true ? false : true  :  dataRendered ? updateData :false
                          }
                          isFirstTimeUpdated={true}
                          style={{ width: "200px !important" }}
                          options={get(actionDropValues,`attributes[${get(cardData,'type','')}]`,[])}
                          value={get(ele,'key',"")}
                          onChange={ (event, element)=>handleCollectAttributeData(event,element, index, "key")}
                          name={"attributes"}
                        />
                        </Badge>
    
    
                        {get(ele, "key", "").length ? (
                          ["list","multi-select",'radio'].includes(ele.fieldType) ? 

                           ['list','radio'].includes(ele.fieldType) ? <>
                          <SelectBoxContainer
                            disableOptions={{}}
                            disabled={isDisbaledFields}
                            componentType={componentType}
                            multiple={false}
                            defaultOpen={false}
                            isFirstTimeUpdated={true}
                            style={{ width: "200px !important" }}
                            options={ele.fieldType === 'radio' ? defaultRadioOptions : get(actionDropValues.options,`[${ele.key}]`,[])}
                            value={ele.value}
                            onChange={ (event, element)=>handleCollectAttributeData(event,element, index, "value")}
                            name={"value"}
                            />
                          
                          </>
                          :
                          (

                            <>
                              <MultiSelectBoxContainer
                               disableOptions={{}}
                                disabled={isDisbaledFields}
                                componentType={componentType}
                                multiple={true}
                                defaultOpen={false}
                                isFirstTimeUpdated={true}
                                style={{ width: "200px !important" }}
                                options={
                                 get(actionDropValues.options,`[${ele.key}]`,[])
                                } 
                                value={ typeof get(ele,'value',[]) === "string" ? [get(ele,'value',"")] :   get(ele,'value',[])}
                                onChange={ (event, element)=>handleCollectAttributeData(event,element={}, index, "value")}
                                name={"value"}
                              />
                            </>
                          ) : (
                            ele.fieldType === "text"?
                            <>
                            <TextAreaContainer  style={{ width: "200px !important" }}
                                disabled={isDisbaledFields}
                                componentType={componentType}
                                isFirstTimeUpdated={true}
                                value={ele.value}
                                onChange={ (event, element)=>handleCollectAttributeData(event,element, index, "value")}
                                name={"value"} />
                            </>
                            :
                            <>
                            
                              <InputBoxContainer
                                style={{ width: "200px !important" }}
                                disabled={isDisbaledFields}
                                componentType={componentType}
                                isFirstTimeUpdated={true}
                                value={ele.value}
                                onChange={ (event, element)=>handleCollectAttributeData(event,element, index, "value")}
                                name={"value"}
                              />
                            </>
                          )
                        ) : null}
                        <Box  sx={{ display:"flex",
                        justifyContent:"center",
                        alignItems:"center"}} >
                          {
                            !get(priorityFields,`[${get(ele,'key','')}].required`, false) ? <>
                               <DeleteForeverIcon    disabled={isDisbaledFields} onClick={()=>{
                            if(!isDisbaledFields){
                                handleDeleteNewAttribute(index, ele)
                            }
                          
                        }}  sx={{cursor:"pointer", color: isDisbaledFields ? "disabled":"red"}} />
                            </>
                            :
                            null
                          }
                     
                     </Box>
                      </Box>
                     
                    </>
                  )

              }


            
              )}
            </>
          ) : null}
        {  <Box
            sx={{
              display: `flex`,
              justifyContent: `flex-end`,
              alignItems: `flex-end`,
            }}
          >
          {multiAttributes.every((ele, index)=> get(ele,'key',"").length) && !(multiAttributes.length ===  get(actionDropValues,`attributes[${get(cardData,'type','')}]`,[]).length) && <Button
          disabled={isDisbaledFields}
            onClick={()=>{
                setMultiAttributes(state=>([...state,initialAttributeValue]))
            }}
              sx={{
                textTransform: "inherit",
                height:"30px",
                boxShadow:"0px 3px 5px rgba(0,0,0,0.1)",
              }}
            >
              {" "}
              Add attribute
            </Button>}
          </Box>}
        </Box>
      </Box>
    </Box>
  );
};

const SelectBoxContainer = ({
  style,
  disableOptions={},
  options = [],
  value,
  onChange,
  name,
  defaultOpen = false,
  multiple = false,
  componentType,
  isFirstTimeUpdated,
  disabled,
}) => {
  const [open, setOpen] = useStateCallback(defaultOpen);
  //   useEffect(() => {
  //     if (name !== "type" && isFirstTimeUpdated) {
  //       setOpen(true);
  //     }
  //   }, [options]);
  return (
    <Select
      multiple={multiple}
      open={open}
      disabled={disabled}
      autoFocus={true}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
      //   onChange={(event) =>{
      //     // setOpen(false,()=>{
      //     
      //     onChange(event);
      //     }}
      name={name}
      sx={{
        width: "60%",
        border: "1px solid rgba(0,0,0,0.2)",
        height: 40,
        borderRadius: "5px",
        ...style,
        paddingLeft: "10px",
      }}
      input={
        <InputBase
          variant="standard"
          autoFocus={true}
          sx={{ ml: 1, flex: 1, fontSize: "13px" }}
        ></InputBase>
      }
    >
      {options.map((ele) => (
        <MenuItem
        disabled={get(disableOptions,ele.key,false)}
          key={ele.key}
          value={ele.value}
          onClick={(event) => {
            onChange(
              {
                target: {
                  name: name,
                  value: ele.value,
                },
              },
              ele
            );
          }}
        >
          {ele.text}{" "}
        </MenuItem>
      ))}
    </Select>
  );
};








const MultiSelectBoxContainer = ({
  style,
  disableOptions={},
  options = [],
  value,
  onChange,
  name,
  defaultOpen = false,
  componentType,
  isFirstTimeUpdated,
  disabled,
}) => {
  const [open, setOpen] = useStateCallback(defaultOpen);
  //   useEffect(() => {
  //     if (name !== "type" && isFirstTimeUpdated) {
  //       setOpen(true);
  //     }
  //   }, [options]);
  return (
    <Select
      multiple={true}
      open={open}
      disabled={disabled}
      autoFocus={true}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      value={value}
        onChange={(event) =>{
          // setOpen(false,()=>{
          // 
          onChange(event);
          }}
      name={name}
      sx={{
        width: "60%",
        border: "1px solid rgba(0,0,0,0.2)",
        height: 40,
        borderRadius: "5px",
        ...style,
        paddingLeft: "10px",
      }}
      input={
        <InputBase
          variant="standard"
          autoFocus={true}
          sx={{ ml: 1, flex: 1, fontSize: "13px" }}
        ></InputBase> } >
      {options.map((ele) => (
        <MenuItem
        disabled={get(disableOptions,ele.key,false)}
          key={ele.key}
          value={ele.value}
        >
          {ele.text}
        </MenuItem>
      ))}
    </Select>
  );
};
const InputBoxContainer = ({ style, name, value, onChange, disabled }) => {
  return (
    <Paper
      component="box"
      sx={{
        p: "1px 2px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        height: 40,
        padding: "10px",
        boxShadow: "none",
        border: `1px solid rgba(0,0,0,0.2)`,
        ...style,
      }}
    >
      <InputBase
        disabled={disabled}
        onChange={(event)=>onChange(event,{})}
        name={name}
        value={value}
        sx={{ ml: 1, flex: 1, fontSize: "14px" }}
        placeholder=""
      />
    </Paper>
  );
};


const TextAreaContainer = ({style, name, value, onChange, disabled}) => {
  return (
    <PullseTextArea name={name} value={value} onChange={onChange} disabled={disabled} sx={{
      width:"200px",
      ...style
    }
    } />
  )
}

export default ActionCard;
