import React from "react";

const EmailBoxIcon = ({color="#313131",height="16",width="16"}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M2.79998 4.39922L7.54461 7.68396C7.81856 7.87363 8.18139 7.87363 8.45534 7.68396L13.2 4.39922M3.19998 12.7992H12.8C13.6836 12.7992 14.4 12.0829 14.4 11.1992V4.79922C14.4 3.91556 13.6836 3.19922 12.8 3.19922H3.19998C2.31632 3.19922 1.59998 3.91556 1.59998 4.79922V11.1992C1.59998 12.0829 2.31632 12.7992 3.19998 12.7992Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default EmailBoxIcon;
