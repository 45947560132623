import React, { useEffect, useState } from "react";
import style from "../answerBehave.module.scss";
import { Box, Typography } from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { LightTooltip } from "../../../../../../commonComponent/tooltip/LightTooltip";
import CommonButton from "./CommonButton";
import { get } from "lodash";
let name = process.env.REACT_APP_AUTO_NAME;

  
const AnswerMultipleQuestions = ({data}) => {
    // const [alreadyAnsweredKey, setAlreadyAnsweredKey] = useState('Automatically closed')
    // const [couldnotAnsweredKey, setCouldnotAnsweredKey] = useState('Automatically closed')
  let {handleCollectData} = data;
    console.log(data,"datadatadata")
    const handleSetTeamMates = (type,value) =>  {
      let newVal = "close";
      if( value==="Passed to a teammate"){
        newVal = "route"
      }
      handleCollectData(type,newVal)
    }

    let multipleAnswers = {
      "afterAnswer":get(data,'newProfileState.afterAnswer',"") === "route" ? "Passed to a teammate" : "Automatically closed",
      "ifCantAnswer": get(data,'newProfileState.ifCantAnswer',"") === "route" ? "Passed to a teammate" : "Automatically closed",
    } 

  return (
    <>
      <Box className={style.answerMultipleQuestions}>
        <Box className="heading-container">
          <Box sx={{display:'flex', gap:'10px', alignItems:'center'}} >  
          </Box>
          <Box className="body-content" > <Typography  className="heading" >If {name} already answered the question:</Typography> 
                <CommonButton buttonValue={multipleAnswers.afterAnswer} menuListItems={['Automatically closed','Passed to a teammate']} handleCollectData={(value)=>handleSetTeamMates("afterAnswer", value)} />
          </Box>
          <Box className="body-content" >   <Typography className="heading" >If {name} couldn’t answer the question:</Typography>
          <CommonButton buttonValue={multipleAnswers.ifCantAnswer} menuListItems={['Automatically closed','Passed to a teammate']} handleCollectData={(value)=>handleSetTeamMates("ifCantAnswer", value)} />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AnswerMultipleQuestions;
