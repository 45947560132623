import {
  AccordionDetails,
  Box,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import style from "./answerBehave.module.scss";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import HelpIcon from "@mui/icons-material/Help";

import AnswerMultipleQuestions from "./answerMultipleQuestions/AnswerMultipleQuestions";
import { LightTooltip } from "../../../../../commonComponent/tooltip/LightTooltip";
import OnlyAnswersOnce from "./answerMultipleQuestions/OnlyAnswersOnce";
import AnswerMultipleNeverPass from "./answerMultipleQuestions/AnswerMultipleNeverPass";
let name = process.env.REACT_APP_AUTO_NAME;

  
const AnswerBehaveBody = ({data}) => {
   let { answerMode } = data.newProfileState;
   let {handleCollectData} = data;
   console.log(data.newProfileState,"112233")
   const handleSetValue = (value) =>{
    handleCollectData('answerMode', value)
   }
  return (
    <>
      <Divider />
      <AccordionDetails className={style["answer-behave-body-main-container"]}>
        <Box className="body-content-container">
          <Box>
            <Typography className="answer-behave-heading">
              {`Set how ${name} should behave when answering multiple questions`}
            </Typography>
          </Box>
          <Box className="selection-main-container">
            <FormControl>
              <FormControlLabel
                className="selection-form-lable"
                value="end"
                control={
                  <Radio
                  // onChange={()=>setCurrentView(viewKeys[0]) }
                  onChange={()=>handleSetValue('loop') }
                  checked={answerMode === "loop"}
                    sx={{
                      color: "#8F1EB3",
                      "&.Mui-checked": {
                        color: "#8F1EB3",
                      },
                    }}
                    className="radio-box"
                    size="small"
                  />
                }
                label={
                  <Typography className="radio-lable">
                    Answer multiple questions{" "}
                  </Typography>
                }
              />
            </FormControl>

           

          </Box>
          <Box className="selection-main-container">
            <FormControl>
              <FormControlLabel
                className="selection-form-lable"
                value="end"
                control={
                  <Radio
                  onChange={()=>handleSetValue('once') }
                  checked={answerMode === "once"}
                    sx={{
                      color: "#8F1EB3",
                      "&.Mui-checked": {
                        color: "#8F1EB3",
                      },
                    }}
                    className="radio-box"
                    size="small"
                  />
                }
                label={
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Typography className="radio-lable">
                      Only answer once{" "}
                    </Typography>
                    <LightTooltip
                      title={`Enter a message to tell the custor.
                        This mode is disabled when using Al
                        Answers. ${name} is conversational and can ask
                        clarifying questions before delivering an
                        answer.`}
                      placement="top"
                      arrow
                    >
                      <HelpIcon
                        sx={{
                          fontSize: "15px",
                          cursor: "pointer",
                          color: "gray",
                        }}
                      />
                    </LightTooltip>
                  </Box>
                }
              />
            </FormControl>
            {/* {currentView===viewKeys[2] &&  <OnlyAnswersOnce />} */}
          </Box>
          {<AnswerMultipleQuestions data={data} />}

          {/* <Box className="selection-main-container">
            <FormControl>
              <FormControlLabel
                className="selection-form-lable"
                value="end"
                control={
                  <Radio
                  onChange={()=>setCurrentView(viewKeys[1]) }
                  checked={currentView===viewKeys[1]}
                    sx={{
                      color: "#8F1EB3",
                      "&.Mui-checked": {
                        color: "#8F1EB3",
                      },
                    }}
                    className="radio-box"
                    size="small"
                  />
                }
                label={
                  <Typography className="radio-lable">
                    Answer multiple questions and never pass to teammate{" "}
                  </Typography>
                }
              />
            </FormControl>
            {
            currentView===viewKeys[1] && 
             <AnswerMultipleNeverPass />}
          </Box> */}

         
        </Box>
      </AccordionDetails>
    </>
  );
};

export default AnswerBehaveBody;
