import React from 'react';
import 'chart.js/auto';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  ChartOptions
} from 'chart.js/auto';

import {
  Chart,
} from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Title,
  Tooltip,
  Legend
);


export const options = {
    responsive: true,
    plugins: {
      title: {
        display: true,
        text: 'my data',
        fontSize: 22,
      },
    },
  };

// const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

// export const data = {
//   labels,
//   datasets: [
//     // {
//     //   type: 'line',
//     //   label: 'Dataset 1',
//     //   borderColor: 'rgb(153, 162, 235)',
//     //   borderWidth: 2,
//     //   fill: true,
//     //   data: labels.map(() => Math.floor(Math.random() * (1000 - -1000 + 1)) + 1000),
//     // },
//     {
//     //   type: 'line',
//       label: 'Dataset 2',
//       backgroundColor: 'rgb(75, 192, 192)',
//       data: labels.map(() => Math.floor(Math.random() * (1000 - -1000 + 1)) + 1000),
//       borderColor: 'white',
//       borderColor: 'rgb(153, 162, 235)',
//       borderWidth: 2,
//       fill: true,
//     },
//     // {
//     //   type: 'bar',
//     //   label: 'Dataset 3',
//     //   backgroundColor: 'rgb(153, 162, 235)',
//     //   data: labels.map(() => Math.floor(Math.random() * (1000 - -1000 + 1)) + 1000),
//     // },
//   ],
// };

export function MainChartComponent({data}) {
//   const printDatasetAtEvent = (dataset) => {
//     if (!dataset.length) return;

//     const datasetIndex = dataset[0].datasetIndex;

//     
//   };

//   const printElementAtEvent = (element) => {
//     if (!element.length) return;

//     const { datasetIndex, index } = element[0];

//     
//   };

//   const printElementsAtEvent = (elements) => {
//     if (!elements.length) return;

//     
//   };

//   const chartRef = useRef(null);

//   const onClick = (event) => {
//     const { current: chart } = chartRef;

//     if (!chart) {
//       return;
//     }

//     printDatasetAtEvent(getDatasetAtEvent(chart, event));
//     printElementAtEvent(getElementAtEvent(chart, event));
//     printElementsAtEvent(getElementsAtEvent(chart, event));
//   };

let {  chartData, type} = data


  return (
    <>
    
 
            <Chart
            type={type}
         
            data={chartData}
          />

    </>
  );
}
