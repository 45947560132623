import { arrayToQueryString } from "../utils/helpers/helpers";
import { HttpClient } from "./HttpClient"
export const AiTextToolService = (path,data) => {
    const apiData = {
        method:"POST",
        url:`/ai-tool/text/${path}`,
        data:data
    }
    return HttpClient.custom(apiData)
}



export const summarizeTicketConversation = (ticket_sno) => {
    const apiData = {
        method:"POST",
        url:`/ai-tool/summarize/ticket-conversation`,
        data:{
            ticket_sno
        }
    }
    return HttpClient.custom(apiData)
    
}
