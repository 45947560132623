import { Box } from '@mui/material';
import React, { useEffect, useState } from 'react'
import MenuBarCommon from '../../commonComponent/menuBarComponent/MenuBarCommon';
import PluseIcon from '../../../assets/searchIcons/PluseIcon';
import { useParams, useSearchParams } from 'react-router-dom';
import { arrayToQueryString, capitalizeFirstCharacterOfWords } from '../../../utils/helpers/helpers';
import { workflowEntityFields } from '../../../services/Apis';
import { get } from 'lodash';
import ListOperators from './filtersMenuData/operoters/ListOperators';
import TicketFilterMenuList from './filtersMenuData/TicketFilterMenuList';
import StringOperators from './filtersMenuData/operoters/StringOperators';
import NumberOperators from './filtersMenuData/operoters/NumberOperators';
import MultiSelectOperators from './filtersMenuData/operoters/MultiSelectOperators';
import RadioOperators from './filtersMenuData/operoters/RadioOperators';
import { elements } from 'chart.js';
import BooleanOperators from './filtersMenuData/operoters/BooleanOperators';
import TextOperator from './filtersMenuData/operoters/TextOperator';
const ContactFilters = ({componentType, text="Filters", handleSubmitData=()=>{},filterValues, componentFace}) => {
    const [advanceFilter,setAdvanceFilters] = useState([])
    const [filtersKey, setFilterKeys] = useState({})
    const [searchParams, setSearchParams] = useSearchParams();
    const [forAll, setForAll] = useState(true)
    const [currentFilterKeys, setCurrentFilterKeys] = useState({})
    const [customWorkflowData, setCustomWorkflowData] = useState({});
    const [isDisable, setIsDisable] = useState(false);
    const [attributesOptions, setAttributesOptions] = useState([]);
    const [updateComponent, setUpdateComponent] = useState(false)







    useEffect(()=>{
        if(Object.keys(customWorkflowData).length){
            let ticketAttributes = get(get(customWorkflowData,'entities').find(({id})=>id=="customer"),'attributes',[]);
            let tempOptions = ticketAttributes.filter(ele=>get(ele,'fieldType',"") == "list" && get(ele,'options',[]).length)
            setAttributesOptions(tempOptions)
            if(ticketAttributes.length == advanceFilter.length){
                setIsDisable(true)
            }else{
                setIsDisable(false)
            }
        }
       
    },[advanceFilter.length])

    useEffect(()=>{

      if(componentFace === 'create'){
        setTimeout(()=>{
          setUpdateComponent(true)
        },1000)
      }else{
        if(!updateComponent){
          setFilterKeys(filterValues)
          handleSetFiltersList()
        }
      }
       

    },[Object.keys(filterValues).length,Object.keys(customWorkflowData).length, componentFace])
    console.log(filterValues,"profileFilterValue")



    useEffect(()=>{
     
      // console.log(filtersKey,"filtersKey-filtersKey")
    },[filtersKey])

    const handleSetFiltersList = () => {
      let ticketAttributes = get(get(customWorkflowData,'entities',[]).find(({id})=>id== componentType),'attributes',[]);
      let filterObj = Object.keys(filterValues);
      

      let tempAdvanceFilters = []

      ticketAttributes.forEach(ele=>{
        if(filterObj.includes(ele.id)){
          tempAdvanceFilters.push(ele)
        }
      })
      let tempFilterKeys = {}
      tempAdvanceFilters.forEach(({id})=>{
          tempFilterKeys[id] = true;
      })
      // setCurrentFilterKeys(tempFilterKeys)
      // handleSubmitData(tempFilterKeys)
      setAdvanceFilters(tempAdvanceFilters)
      if(ticketAttributes.length){
        setTimeout(()=>{
          setUpdateComponent(true)
        },1000)
      }
     
    }

    console.log('checking')



    const handleAddNewFilter = (data,add=1) => {
        let allFilterKeys = {...filtersKey}
        let key = `${data.parent.id}`
        allFilterKeys[key] = {
           value:data.value,
           key:data.key
        };
        handleSubmitData(allFilterKeys)
        setFilterKeys(allFilterKeys);
      }
    const handleAddAdvenceFilter = (value) => {
        let tempAdvanceFilters = [...advanceFilter]
        tempAdvanceFilters.push(value)
        let tempFilterKeys = {}
        tempAdvanceFilters.forEach(({id})=>{
            tempFilterKeys[id] = true;
        })
        setCurrentFilterKeys(tempFilterKeys)
        handleSubmitData(tempFilterKeys)
        setAdvanceFilters(tempAdvanceFilters)
      }


      const handleDeleteFilter = (element,index=-1) => {
       
        let allFilters = {...filtersKey};
        delete allFilters[element.id];
        setFilterKeys(allFilters);

        let tempCurrentFilters = {...currentFilterKeys};
        delete tempCurrentFilters[element.id];
        setCurrentFilterKeys(tempCurrentFilters);
        
       
        let tempAllFilter = [...advanceFilter];
       let  newIndex = index;
        if(index == -1){
            let newIndex = tempAllFilter.length -1
        }
            tempAllFilter.splice(newIndex,1)
        
       
        setAdvanceFilters(tempAllFilter);
      }
      


      useEffect(()=>{
        workflowEntityFields().then(res=>{
            let response = get(res,'data.data',{});
            setCustomWorkflowData(response)
        })
      },[])





      console.log(filtersKey,"filtersKey")

  return (
    <>
    <Box sx={{width:"100%", display:"flex", gap:"10px", padding:"10px", maxWidth: "600px",
    flexWrap: "wrap"}}>
        {advanceFilter.map((ele,i)=>{
            const isExistKey = Object.keys(get(filtersKey,`[${ele.id}]`,{})).length;
            const componentData = get(filtersKey,`[${ele.id}]`,{})
            const isOpen = advanceFilter.length == i + 1 
            const currentComponent = {
                string:StringOperators,
                number:NumberOperators,
                date:()=><></>,
                "multi-select":MultiSelectOperators,
                radio:RadioOperators,
                list:ListOperators,
                boolean:BooleanOperators,
                text:TextOperator
            }
            let Component = currentComponent[ele.fieldType]
            const isDisplayKey  = !(['true','false','isNull','isNotNull'].includes(get(filtersKey,`[${ele.id}]`,{}).key))
            const getLable = isExistKey  ?  `${ele.name} ${isDisplayKey && get(filtersKey,`[${ele.id}]`,{}).key} ${get(filtersKey,`[${ele.id}]`,{}).value}` :ele.name
            return <MenuBarCommon
            key={`${ele.key}-${ele.fieldType}`}
            defaultOpen={updateComponent ? isOpen : false}
            style={{ background: !isExistKey && "#f1f1fb !important", color:isExistKey ? "white" :"black !important" }}
            deleteIconStyle={{ color: isExistKey ? "white" :"black !important" }}
            onSelectFilter={(data)=>{ handleAddNewFilter(data)}}
            selected={isExistKey}
            isDeleted={isExistKey}
            handleDelete={()=>handleDeleteFilter(ele,i)}
            filKey={ele.key}
            MenuComponent={(props)=><Component {...props} currentComponent={ele} attributesOptions={attributesOptions} componentData={componentData} />}
            Icon={()=> <></>}
            label={`${capitalizeFirstCharacterOfWords(getLable)}`}
            uniqueKey={ele}
            onCloseWithoutValue={(props, data)=>{
              if(!Object.keys(componentData).length){
                handleDeleteFilter(data)
              }
                
            }}
            />
            })}
            {!isDisable && <MenuBarCommon
             key={"filterTest"}
             defaultOpen={false}

              onSelectFilter={(value)=>{handleAddAdvenceFilter(value)}}
              MenuComponent={(props)=> <TicketFilterMenuList {...props} customWorkflowData={customWorkflowData} currentFilterKeys={currentFilterKeys} componentType={componentType} advanceFilter={advanceFilter}/>}
              style={{
                background:"#f1f1fb !important"
              }}
              Icon={()=> <PluseIcon color={"black"} />}
              label={text}
            />}
    </Box>
    </>
  )
}

export default ContactFilters;
