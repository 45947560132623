import React, { useState } from 'react'
import ImportExternalSources from "./contentSources/ImportExternalSources";
import PullseHelpCenterSource from "./contentSources/PullseHelpCenterSource";
import UseSnippetSource from "./contentSources/UseSnippetSource";
import ImportContentFilesSources from "./contentSources/ImportContentFilesSources";
import { Box, Typography } from "@mui/material";
import CommonContentCard from "./commponContentSouceCard/CommonContentCard";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from 'react-router-dom';

const setupPageContensts =  [
    // {
    //   heading:'Import external content',
    //   content:'Import content from public URLs, like knowledge bases or websites',
    //   Component:ImportExternalSources,
    //   key:'1'
    // },
    // {
    //   heading:'Use your Pullse Help Center',
    //   content:'Let AI Chatbot learn from the support content in your Help Center',
    //   Component:PullseHelpCenterSource,
    //   key:'2'
    // },
    {
      heading:'Use Snippets',
      content:'Create plain text content specific for AI Chatbot, not publicly available',
      Component:UseSnippetSource,
      key:'3'
    },
    {
      heading:'Import content from files',
      content:`Upload PDF files and we'll fetch all the text data inside`,
      Component:ImportContentFilesSources,
      key:'4'
    }
  
  ]


const AddChatbotContents = () => {
    const [currentCard,setCurrentCard] = useState('')

    const navigate = useNavigate()
  return (
       <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              flexWrap: "wrap",
              minWidth: "300px",
              marginTop:"10px",
              padding:"20px"
            }}
          >
             <Typography
              sx={{
                fontWeight: "400",
                fontSize: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowBackIosIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate("/automation/pullse-contents");
                }}
              />
              Go back
            </Typography>
            {setupPageContensts.map((ele) => {
              return <CommonContentCard currentCard={currentCard} setCurrentCard={setCurrentCard}  {...ele} />;
            })}
          </Box>
  )
}

export default AddChatbotContents
