import React from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { Box } from '@mui/material';

ChartJS.register(LinearScale, PointElement, LineElement, Tooltip, Legend);

export const options = {
  scales: {
    y: {
      beginAtZero: true,
    },
  },
};

export const data = {
  datasets: [
    {
      label: 'A dataset',
      data: Array.from({ length: 100 }, () => ({
        x:   Math.floor(Math.random() * (1000 - -1000 + 1)) + 1000,
          
        y:   Math.floor(Math.random() * (1000 - -1000 + 1)) + 1000
        ,
      })),
      backgroundColor: 'rgba(255, 99, 132, 1)',
    },
  ],
};




const ScatterChartComponent = () => {
  return (
   <Box  sx={{width:"100%", }}>
    <Scatter options={options} data={data} />
   </Box>
  )
}

export default ScatterChartComponent
