import React, { useContext, useEffect, useState } from 'react'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { get } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LoadingButton from '@mui/lab/LoadingButton';
import { SidebarContext } from '../../../../../context/SidebarProvider';
const initilaStatusOptions = [
    {
        "id": 3,
        "name": "Critical",
        "color": "#FF0000"
    },
    {
        "id": 2,
        "name": "High",
        "color": "#FF0000"
    },
    {
        "id": 1,
        "name": "Medium",
        "color": "#c2aa21"
    },
    {
        "id": 0,
        "name": "Low",
        "color": "#3178CA"
    }
]
const PriorityDropdown = ({value, statusOptions=initilaStatusOptions, handleUpdateStatus}) => {

    const { handleShowSnackPopup } = useContext(SidebarContext)
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentStatus, setCurrentStatus] = useState({name:'N/A'})
    const open = Boolean(anchorEl);
    const [updating, setUpdating] = useState(false)
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    useEffect(()=>{
        let status = initilaStatusOptions.find(ele=>ele.id == value);
        if(status){
            setCurrentStatus(status)
        }
    },[value])

    const handleChangeValue = (id) => {
        if(get(currentStatus,'id','') !== id){
            setUpdating(true)
            handleUpdateStatus(id).then(res=>{
                setUpdating(false)
                handleShowSnackPopup({message:"Status updated successfully"})
            }).catch(err=>{
                setUpdating(false)
                handleShowSnackPopup({message:"error while updating status",error:true})
            })
        }
    }
  return (
   <>
    <div>
      <LoadingButton
      loading={updating}
      endIcon={<KeyboardArrowDownIcon />}
        id="basic-button"
        sx={{
            textTransform:"capitalize",
            fontWeight:"bold",
            color:"#a84acb"
        }}
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        {currentStatus.name}
      </LoadingButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {
        statusOptions.map(({id,name})=>(
                <MenuItem  onClick={(event)=>{
                    handleClose(event)
                    handleChangeValue(id)
                }}>{name}</MenuItem>
            ))
        }       
      </Menu>
    </div>
   
   </>
  )
}

export default PriorityDropdown
