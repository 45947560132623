import React, { useContext, useState } from 'react';
import {TextareaAutosize} from '@mui/base/TextareaAutosize';
import style from "./messageTextbox.module.scss";
import { debounceWithImmediate } from '../../../../utils/helpers/helpers';
// import MentionsEditor from '../../../editors/mentionsEditor/MentionEditor';
import MenEditor from '../../../editors/mentionEditor/MenEditor';
import { MainContext } from '../../../../context/MainProvider';

const MessageTextBox = ({ setTextAreaMsg, textAreaMsg, onTyping, handleSetNewMessage }) => {
  const { allTags, allAgents } = useContext(MainContext)
  // const [focus, setFocus] = useState(false)

  // const handleSubmit = ({key}) => {
  //   if(key==='Enter' && focus){
  //     handleSetNewMessage()
  //   }


  const handleCollectValue = (value, delta, source, editor) => {
    
    // 
    setTextAreaMsg({
      value:value,
      content:editor.getContents()
    })
    onTyping()
  }


  return ( 
    <>
    
   {/* <MentionsEditor /> */}

   <MenEditor tags={allTags} mentions={allAgents} placeholder={'enter your text here, enter mention start with @ and tag #'}  onChange={handleCollectValue} value={textAreaMsg} />
    
    
   


      {/* <TextareaAutosize
        value={textAreaMsg}
        onChange={(e) => {
          setTextAreaMsg(e.target.value);
          onTyping(); // Call the combined function here
        }}
        className={style.messageTextBoxClass}
        aria-label="empty textarea"
        minRows={3}
        maxRows={3}
        onFocus={()=>setFocus(true)}
        onBlur={()=>setFocus(false)}
        placeholder="Use CtrlK for Shortcuts"
        sx={{ outerHeight: '100%', width: '320px' }}
        onKeyUp={handleSubmit}
      /> */}
     </>
  );
};

export default MessageTextBox;
