import { Avatar, Box, Button, Chip, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./companyProfile.module.scss";
import CursorDownIcon from "../../../../../assets/icons/CursorDownIcon";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PlusBoxIcon from "../../../../../assets/icons/PlusBoxIcon";
import { MainContext } from "../../../../../context/MainProvider";
import LocationIcon from "../../../../../assets/icons/LocationIcon";
import ClockIcon from "../../../../../assets/icons/ClockIcon";
import CaretDownIcon from "../../../../../assets/icons/CaretDownIcon";
import UserIcon from "../../../../../assets/searchIcons/UserIcon";
const CompanyProfileToolbar = ({handleGetAllUsers}) => {
 

  return (
    <>
      <Box className={style.userprofileToolbarComponent}>
        <Box className="toolbarContent">
        </Box>
      </Box>
    </>
  );
};

export default CompanyProfileToolbar;
