import { Avatar, Box, Typography } from "@mui/material";
import React from "react";
import TicketCard from "./TicketCard";

const CompanyTickets = ({comapnyTickets}) => {
  return (
    <Box sx={{display:"flex", flexDirection:"column", gap:"10px"}} >
        {
            comapnyTickets.map(ele=>(
                <TicketCard {...ele} key={ele.id} />
            ))
        }
    </Box>
   
  );
};

export default CompanyTickets;
