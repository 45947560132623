import React from "react";

const BotSettingIcon = ({ color = "black", height = 40, width = 40 }) => {
  return (
    <>
      <svg
        width={width}
        height={height}
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          fill={color}
          d="M32 6H8C7.46957 6 6.96086 6.21071 6.58579 6.58579C6.21071 6.96086 6 7.46957 6 8V32C6 32.5304 6.21071 33.0391 6.58579 33.4142C6.96086 33.7893 7.46957 34 8 34H32C32.5304 34 33.0391 33.7893 33.4142 33.4142C33.7893 33.0391 34 32.5304 34 32V8C34 7.46957 33.7893 6.96086 33.4142 6.58579C33.0391 6.21071 32.5304 6 32 6ZM8 4C6.93913 4 5.92172 4.42143 5.17157 5.17157C4.42143 5.92172 4 6.93913 4 8V32C4 33.0609 4.42143 34.0783 5.17157 34.8284C5.92172 35.5786 6.93913 36 8 36H32C33.0609 36 34.0783 35.5786 34.8284 34.8284C35.5786 34.0783 36 33.0609 36 32V8C36 6.93913 35.5786 5.92172 34.8284 5.17157C34.0783 4.42143 33.0609 4 32 4H8Z"
        ></path>
        <path
          fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M26 13.5H14C13.2044 13.5 12.4413 13.8161 11.8787 14.3787C11.3161 14.9413 11 15.7044 11 16.5C11 17.2956 11.3161 18.0587 11.8787 18.6213C12.4413 19.1839 13.2044 19.5 14 19.5H26C26.7956 19.5 27.5587 19.1839 28.1213 18.6213C28.6839 18.0587 29 17.2956 29 16.5C29 15.7044 28.6839 14.9413 28.1213 14.3787C27.5587 13.8161 26.7956 13.5 26 13.5ZM14 11.5C12.6739 11.5 11.4021 12.0268 10.4645 12.9645C9.52678 13.9021 9 15.1739 9 16.5C9 17.8261 9.52678 19.0979 10.4645 20.0355C11.4021 20.9732 12.6739 21.5 14 21.5H26C27.3261 21.5 28.5979 20.9732 29.5355 20.0355C30.4732 19.0979 31 17.8261 31 16.5C31 15.1739 30.4732 13.9021 29.5355 12.9645C28.5979 12.0268 27.3261 11.5 26 11.5H14ZM34 28.5H6V26.5H34V28.5Z"
        ></path>
      </svg>
    </>
  );
};

export default BotSettingIcon;
