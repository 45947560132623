import { BrowserRouter, useNavigate } from "react-router-dom";
import Routers from "./routers/Routers";
import "./index.css";
import { HttpClient } from "./services/HttpClient";
import SidebarProvider from "./context/SidebarProvider";
import { get } from "lodash";
import { PullseChatWidget } from "chat-widget";
import {  useState } from "react";
// import ChatWidget from "./components/chatwidget/ChatWidget";
HttpClient.setAxiosDefaultConfig()

function App() {

  const [pullseChatData, setPullseChatData] = useState({})
  return (
    <>
      <BrowserRouter>
      <SidebarProvider>
       <Routers setPullseChatData={setPullseChatData} />
      </SidebarProvider>
      {/* <PullseChatWidget
        data={pullseChatData}
            /> */}
      </BrowserRouter>
    </>
  );
}

export default App;
