import React, { useContext } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MainContext } from "../../../../context/MainProvider";
const buttonText = [{
 text:"Summarize",
 type:"summarize"
  
   
},{
 text: "Expand Text",
 type:"expand"
},{
  text:"Rephrase Text",
  type:"rephrase"
}];

const TextEditorShortCuts = ({handleSummarizeTextThroughAi, handleSetMicroStateInEditor}) => {
  const {  handleCloseModal, allMacros } = useContext(MainContext)
  const handlePerformAction = (type) => {
    handleSummarizeTextThroughAi(type)
    handleCloseModal()
  }
  const handleSetMacro = (element) => {
    handleSetMicroStateInEditor(element)
    handleCloseModal()

  }
  return (
    <>
      <Box sx={{ minWidth: "400px", padding: "5px", boxShadow: "none" }}>
        <Box sx={{ p: 2 }}>
          <Box  sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}} >
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              {" "}
              Shortcuts
            </Typography>
            <CloseIcon  sx={{fontSize:"20px", fontWeight:"500", cursor:"pointer"}} onClick={handleCloseModal} />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              padding: "10px",
            }}
          >
             <Divider  sx={{color:"gray", fontSize:"13px"}} textAlign="left" >AI Tools</Divider>
            <Box sx={{ display: "flex", gap: "10px", flexWrap:'wrap' }}>
             
              {buttonText.map(({text,type}) => (
                <Button
                onClick={()=>handlePerformAction(type)}
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                    width:"48%"
                  }}
                >
                  {text}
                </Button>
              ))}
            </Box>
            <Divider sx={{color:"gray", fontSize:"13px"}} textAlign="left" > Canned response</Divider>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width:"100%",
                height: 35,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid #a84acb`,
              }}
            >
              <Select
                onChange={() => {}}
                value={"ankit"}
                name="type"
                className="select-component"
                input={
                  <InputBase
                    className="inputbase-component"
                    variant="standard"
                    autoFocus={true}
                    sx={{
                      ml: 1,
                      flex: 1,
                      fontSize: "13px",
                      ".mui-component-select-type": {
                        display: "flex",
                      },
                    }}
                  ></InputBase>
                }
              >
                <MenuItem value={"ankit"} disabled>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#a84acb",
                      }}
                    >
                      {"Select canned response"}
                    </Typography>
                  </Box>
                </MenuItem>


                {
                  allMacros.map(ele=>(
                    <>
                     <MenuItem onClick={()=>handleSetMacro(ele)}  key={ele.id} value={{
                            id: ele.id,
                            name: ele.name,
                          }} >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "#a84acb",
                      }}
                    >
                       {ele.name}
                    </Typography>
                  </Box>
                </MenuItem>
                    
                    </>
                  ))
                }
              </Select>
            </Paper>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TextEditorShortCuts;
