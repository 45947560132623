import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  StepContent,
  Typography,
} from "@mui/material";
import style from "./cannedResponse.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Chip from '@mui/material/Chip';
import CannedResponseEditor from "../../editors/cannedResponseEditor/CannedResponseEditor";
import DisplayCannedResponse from "./display-canned-response/DisplayCannedResponse";
import { Navigate, Outlet, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import CreateCannedResponse from "./create-canned-response/CreateCannedResponse";
const CannedResponseComponent = ({ ToolbarComponent }) => {
  const [cannedResponse, setCannedResponse] = useState({
    title: "",
    body: "",
  });

  const handleCollectData  = (event) => {
    let {name, value} = event.target;
    setCannedResponse((state)=>({
      ...state,
      [name]:value
    }))

  }

  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />

          <Routes>
        <Route path="/" element={<DisplayCannedResponse />} />
          <Route  path="create" element={<>
          <CreateCannedResponse componentType={'create'} />
          </>} />

          <Route  path=":id" element={<>
          <CreateCannedResponse  componentType={'update'} />
          </>} />
        
          <Route  path="*" element={<><Navigate to={'/settings/canned-response'} /></>} />
        </Routes>

          
         
        </Box>
      </MainComponent>
    </>
  );
};

export default CannedResponseComponent;
