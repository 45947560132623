import React from "react";

const LightningFilledIcon = ({color="#707C97", height=24, width=24}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M5.28901 13.6315L12.2782 2.64854C12.5467 2.22652 13.2 2.41675 13.2 2.91698V10.7C13.2 10.7552 13.2448 10.8 13.3 10.8H18.2397C18.6442 10.8 18.8813 11.2553 18.6493 11.5867L11.7096 21.5005C11.4293 21.901 10.8 21.7026 10.8 21.2138V14.5C10.8 14.4447 10.7552 14.4 10.7 14.4H5.71084C5.31629 14.4 5.07719 13.9644 5.28901 13.6315Z"
          fill={color}
          stroke={color}
          strokeWidth="1"
        />
      </svg>
    </>
  );
};

export default LightningFilledIcon;
