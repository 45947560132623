import React, { useContext, useEffect, useState } from "react";
import { chatbotBulkActionsApi, getChatbotProfile } from "../../../../../services/Apis";
import { get } from "lodash";
import { Box, Button, Checkbox, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import moment from 'moment';
import { MainContext } from "../../../../../context/MainProvider";
import { SidebarContext } from "../../../../../context/SidebarProvider";

const AddDocsToChatbot = ({docsKeys, type}) => {
  const [chatbotProfiles, setChatbotProfiles] = useState([]);
  const { handleCloseModal } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext)

  const [currentkeys ,setCurrentkeys] = useState({}) 

  const handleCreatekeys = (value) => {
      let botKeys = {};
      chatbotProfiles.forEach(ele=>{
          botKeys[ele.id] = value;
      })
      setCurrentkeys(botKeys)
  }

  const handleCheckedData  = (event,key) => {
      let tempkeys = {...currentkeys}
      let value = event.target.checked;
      if(key==='all'){
        chatbotProfiles.forEach(ele=>{
              tempkeys[ele.id] = value;
          })
          // handleCreatekeys(value)
      }else{
          tempkeys[key]  = value;
      }
      setCurrentkeys(tempkeys)
  }

  useEffect(()=>{
      handleCreatekeys(false)
  },[chatbotProfiles])

  useEffect(() => {
    handleGetChatbotProfiles()
  }, []);

  const handleGetChatbotProfiles = () => {
    getChatbotProfile().then((res) => {
      let response = get(res, "data.data.docs", []);
      setChatbotProfiles(response);
      
    });
  };

//   {
//     "action": "delete",
//     "ids": [
//       "string"
//     ],
//     "chatbot_ids": [
//       "string"
//     ]
//   }

  const handleAddBulkActions = () => {
    let newIds = Object.keys(docsKeys).filter(key => docsKeys[key]);
    let tempChatbotIds = Object.keys(currentkeys).filter(key => currentkeys[key])

    let payload = {
        "action":type,
        "ids":newIds,
        "chatbot_ids": tempChatbotIds
    }
    chatbotBulkActionsApi(payload).then((res) => {
        handleShowSnackPopup({message:"data updated successfully",})
        handleCloseModal()
    }).catch(err=>{
        handleShowSnackPopup({message:"error while  updating",error:true})
    })

  }
  const allChecked = Object.values(currentkeys).every(e=>e);
  return (
    <Box sx={{ padding: "20px 40px" }}>
      <Box sx={{ width: "500px", display: "inline-flex", height: "340px", flexDirection:"column", justifyContent:'space-between' }}>
      <TableContainer component={Paper}    sx={{boxShadow:"none" }}>
      <Table sx={{ maxHeight:"300px", overflowY:"auto" }} aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
          <TableCell padding="checkbox"  sx={{fontWeight:"bold"}}>
          <Checkbox
                checked={allChecked}
                    onClick={(event)=>handleCheckedData(event,'all')}
                    sx={{
                      color: "#a84acb",
                      "&.Mui-checked": {
                        color: "#a84acb",
                      },
                    }}
                  />
          </TableCell>
            <TableCell  sx={{fontWeight:"bold"}}>Bot Name</TableCell>
            <TableCell  sx={{fontWeight:"bold"}}>Status</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	CreatedAt	</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chatbotProfiles.map((element) => {
            let createdData  = moment(get(element,'createdAt',''));
            return (
            <TableRow
              key={get(element,'id','')}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                 <TableCell padding="checkbox">
                 <Checkbox
                     onClick={(event)=>handleCheckedData(event,get(element,'id',''))}
                    checked={get(currentkeys,`[${element.id}]`,false)}
                    sx={{
                      color: "#a84acb",
                      "&.Mui-checked": {
                        color: "#a84acb",
                      },
                    }}
                  />
                    </TableCell>
              <TableCell component="th" scope="row">
                {get(element,'name','')}
              </TableCell>
              <TableCell component="th" scope="row">
                <Chip  sx={{borderRadius:"5px", padding:"0px", height:"25px"}} color={get(element,'status','')==="active" ?"success" : "error"} label={ get(element,'status','')==="active" ?"Active" :"Inactive" } /> 
               
                
              </TableCell>
              <TableCell align="right"> { createdData.isValid() ? createdData.fromNow() :" "}</TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>

        <Box  sx={{display:"flex", justifyContent:"flex-end", gap:"10px"}} >
        <Button
            onClick={handleCloseModal}
              sx={{
              background: "#f1f1fb",
              color: "red",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'red',
             color: "red",":hover":{borderColor: "red", }, textTransform:"inherit" }} variant="outlined"> 
              Cancel</Button>

              <Button
              onClick={handleAddBulkActions}
              sx={{
              background: "#f1f1fb",
              color: "#707c97",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined"> 
              { type === "addChatBots" ? "Add" : "Remove"}
              </Button>


        </Box>
      </Box>
    </Box>
  );
};

export default AddDocsToChatbot;
