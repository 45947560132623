import { CheckBox } from '@mui/icons-material';
import { Box, Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import LinkIcon from "@mui/icons-material/Link";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";

const fileIcons = {
  FILE: AttachFileIcon,
  CONTENT: TextSnippetIcon,
  LINK: LinkIcon,
};

const ChatbotTableContents = ({chatbotDocuments}) => {
   
  return (
   <>
       <Box  sx={{marginTop:"20px"}} >
      <TableContainer component={Paper}  sx={{boxShadow:"none" }}>
      <Table sx={{ minWidth: 650, }} aria-label="simple table" size='small' >
        <TableHead>
          <TableRow>
         
            <TableCell  sx={{fontWeight:"bold"}}>Title</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}} >File Type</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Created	</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {chatbotDocuments.map((element) => {
            let createdData  = moment(get(element,'createdAt',''));
            let Icon = fileIcons[get(element,'type','').toUpperCase()];
            return (
            <TableRow
              key={get(element,'id','')}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {get(element,'title','')}
              </TableCell>
              <TableCell align="right">{
                Icon && <Icon sx={{color:"green"}} />
              }</TableCell>
              <TableCell align="right"> { createdData.isValid() ? createdData.fromNow() :" "}</TableCell>
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </TableContainer>
      </Box>
   </>
  )
}

export default ChatbotTableContents
