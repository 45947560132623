import React from "react";

const BarGroupIcon = ({height=40, width=40, color='#313131'}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M15.0941 35V17.7067C15.0941 17.1544 15.5418 16.7067 16.0941 16.7067H24.1912C24.7435 16.7067 25.1912 17.1544 25.1912 17.7067V35M15.0941 35L15.0967 27.1337C15.0969 26.5813 14.6492 26.1333 14.0967 26.1333H6C5.44772 26.1333 5 26.581 5 27.1333V34C5 34.5523 5.44772 35 6 35H15.0941ZM15.0941 35H25.1912M25.1912 35V6C25.1912 5.44772 25.6389 5 26.1912 5H34C34.5523 5 35 5.44772 35 6V34C35 34.5523 34.5523 35 34 35H25.1912Z"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default BarGroupIcon;
