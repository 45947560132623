import React from "react";

const SendAlertIcon = ({height=101, width=101, color="#313131"}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 101 101"
        fill="none"
      >
        <g clipPath="url(#clip0_785_13390)">
          <path
            d="M68.6217 31.9101L46.1938 54.338M23.0389 60.7376L28.2749 55.5015M33.5109 67.0208L36.6526 63.8792M42.9358 74.3513L45.0303 72.2569M47.0224 55.5604L51.3525 63.6249C52.9917 66.6779 53.8114 68.2044 54.8125 68.6042C55.6818 68.9513 56.664 68.8671 57.4615 68.3771C58.38 67.8128 58.9279 66.1691 60.0237 62.8816L68.2132 38.3131C69.1779 35.419 69.6602 33.972 69.3171 33.0097C69.0185 32.1722 68.3595 31.5133 67.5221 31.2146C66.5598 30.8715 65.1127 31.3538 62.2186 32.3185L37.6164 40.5193C34.3382 41.6121 32.699 42.1584 32.1347 43.0756C31.6447 43.8719 31.5594 44.8528 31.9047 45.7217C32.3024 46.7225 33.8227 47.5434 36.8633 49.1853L45.0988 53.6322C45.5638 53.8834 45.7964 54.0089 45.9999 54.1702C46.1805 54.3133 46.3439 54.477 46.4866 54.658C46.6473 54.8619 46.7723 55.0947 47.0224 55.5604Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_785_13390">
            <rect
              width="71.087"
              height="71.087"
              fill="white"
              transform="translate(0 50.2656) rotate(-45)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  );
};

export default SendAlertIcon;
