import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
} from "@mui/material";
import style from "./email-templete.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { Route, Routes } from "react-router-dom";
import CreateEmailTemplete from "./createTemplete/CreateEmailTemplete";
import DisplayEmailTemplete from "./displayTemplete/DisplayEmailTemplete";
import { emailTempletes } from "../../../services/Apis";
import { get } from "lodash";
const EmailTempleteComponent = ({ ToolbarComponent }) => {
  const [emailTempletesData , setEmailTempletesData] = useState([]);


  const handleGetEmailTempletes = () => {
    emailTempletes().then(res=>{
      let response = get(res,'data.data.docs',[]);
      setEmailTempletesData(response);
    }).catch(err=>{

    })
  } 



  useEffect(()=>{
    handleGetEmailTempletes()
  },[])


  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
         

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "87vh",
              padding:"10px 0px 0px 0px"
            }}
          >
          <Routes>
            <Route path="/" element={<DisplayEmailTemplete  emailTempletesData={emailTempletesData}/>}  />
            <Route path="create" element={<CreateEmailTemplete handleGetEmailTempletes={handleGetEmailTempletes} componentType="create" />}  />
            <Route path=":templete_id" element={<CreateEmailTemplete  handleGetEmailTempletes={handleGetEmailTempletes} componentType="update" />}  />
            <Route />
          </Routes>

          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default EmailTempleteComponent;
