import {
  Box,
  Button,
  Paper,
  Typography,
  InputBase,
  TextareaAutosize,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../../../../context/MainProvider";
import { get } from "lodash";
import NumberIcon from "../../../../assets/icons/NumberIcon";
import AlphabetIcon from "../../../../assets/icons/AlphabetIcon";
import DecimalIcon from "../../../../assets/icons/DecimalIcon";
import TrueFalseIcon from "../../../../assets/icons/TrueFalseIcon";
import CalenderIcon from "../../../../assets/icons/CalenderIcon";
import ListIcon from "../../../../assets/icons/ListIcon";
import CloseIcon from "@mui/icons-material/Close";
import { createCustomFields, getCustomFieldDetails, updateCustomField } from "../../../../services/Apis";
import { SidebarContext } from "../../../../context/SidebarProvider";
import CreateIcon from "@mui/icons-material/Create";
import VisibilityIcon from "@mui/icons-material/Visibility";
// text, number, date, select, checkbox, radio, textarea, multiselect
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
let textFieldsValues = {
  fieldType: "",
  name: "",
  description: "",
  visibleTo: ["customer"],
  entityType: "",
  placeholder: "",
  isRequired: true,
  entityId:null
};

const dropdownItems = [
  {
    name: "Text",
    Icon: AlphabetIcon,
    key: "text",
  },
  {
    name: "Number",
    Icon: NumberIcon,
    key: "number",
  },
  // {
  //   name: "Decimal Number",
  //   Icon: DecimalIcon,
  //   key: "number",
  // },
  {
    name: "True or False",
    Icon: TrueFalseIcon,
    key: "radio",
  },
  {
    name: "Date",
    Icon: CalenderIcon,
    key: "date",
  },
  {
    name: "List",
    Icon: ListIcon,
    key: "select",
  },
];

const visibleTo = [
  {
    key: "customer",
    text: "Customer",
  },
  {
    key: "agent",
    text: "Agent",
  },
  {
    key: "admin",
    text: "Admin",
  },
];

const CreateDataModelling = ({ data }) => {
  const { handleCloseModal } = useContext(MainContext);
  const { handleShowSnackPopup } = useContext(SidebarContext);
  const [currentDataModel, setCurrentDataModel] = useState("text");
  const [currentValues, setCurrentValues] = useState(
    JSON.parse(JSON.stringify(textFieldsValues))
  );
  const [editable, setEditable] = useState(false);

  let { type, createData, entityType, handleGetCustomerFields, id, entityId=null, creationLocation="data-modelling" } = data;
  useEffect(() => {
    if (type == "new") {
      setEditable(true);
    } else {
      setEditable(false);
      handleGetCustomFieldDetails()

    }
    

  }, [type]);
  const handleFilterKeys = (modalState, companyDetails) => {
    let newFilterData = {...modalState}
    Object.keys(modalState).forEach(keys=>{
      if(companyDetails.hasOwnProperty(keys)){
        newFilterData[keys] = companyDetails[keys]
      }
    })
    return newFilterData
  }

  

  const handleGetCustomFieldDetails = () => {
    let tempTextFieldValues = {...textFieldsValues}
    getCustomFieldDetails(id).then(res=>{
      
      let customeFieldDetails = get(res,'data.data',{})
      if(customeFieldDetails['fieldType'] === "select"){
        tempTextFieldValues["options"] = [];
        tempTextFieldValues["defaultValue"] = ""
      }
      setCurrentDataModel(customeFieldDetails['fieldType'] )
    let data = handleFilterKeys(tempTextFieldValues,customeFieldDetails)
    setCurrentValues(data);
    })
  }

  useEffect(() => {
   if(type==='new'){
    let tempFieldValues = { ...textFieldsValues };
    tempFieldValues["fieldType"] = currentDataModel;
    if (currentDataModel === "select") {
      tempFieldValues["defaultValue"] = "Ticket Management";
      tempFieldValues["options"] = [""];
    }
    setCurrentValues(tempFieldValues);
   }
  }, [currentDataModel]);

  const handleCollectData = (event) => {
    let value = event.target.value;
    setCurrentDataModel(value);
  };

  const handleCollectInputData = (event) => {
    let { name, value } = event.target;
    let tempCurrentValues = { ...currentValues };

    tempCurrentValues[name] = value;
    
    setCurrentValues(tempCurrentValues);
  };

  const handleSubmitData = () => {
    if(type==='new'){
      handleCreateCustomFields();
    }else{
      handleUpdateCustomFields()
    }
  };

  const handleCollectOptions = (value = "", type = "add", index) => {
    let tempCurrentValues = { ...currentValues };
    if (type == "add") {
      tempCurrentValues["options"].push("");
    } else if (type === "update") {
      tempCurrentValues["options"][index] = value;
    } else {
      if (tempCurrentValues["options"].length === 1) {
        tempCurrentValues["options"] = [""];
      } else {
        tempCurrentValues["options"].splice(index, 1);
      }
    }
    
    setCurrentValues(tempCurrentValues);
  };

  const handleUpdateCustomFields = () => {
    let temppCustomFields = {...currentValues};
    if(creationLocation !== "ticket-type"){
      delete temppCustomFields['entityId']
    }
    updateCustomField(id,temppCustomFields)
      .then((res) => {
        handleGetCustomerFields({ type: "entity_type", data: entityType });
        handleShowSnackPopup({
          message: "fields updated successfully",
          time: 3000,
        });
        handleCloseModal();
      })
      .catch((err) => {
        let error = get(err, "response.data.message", "");
        handleShowSnackPopup({
          message: error,
          error: true,
          time: 3000,
        });
      });
  };

  const handleCreateCustomFields = () => {
    let temppCustomFields = {...currentValues};
    temppCustomFields['entityType'] = entityType
    if(creationLocation === "ticket-type"){
      temppCustomFields['entityId'] = entityId;
    }else{
      delete temppCustomFields['entityId']
    }
    createCustomFields(temppCustomFields)
      .then((res) => {
        handleGetCustomerFields({ type: "entity_type", data: entityType });
        handleShowSnackPopup({
          message: "fields created successfully",
          time: 3000,
        });
        handleCloseModal();
      })
      .catch((err) => {
        let error = get(err, "response.data.message", "");
        handleShowSnackPopup({
          message: error,
          error: true,
          time: 3000,
        });
      });
  };

  
  return (
    <>
      <Box
        sx={{
          padding: "20px 40px",
          minHeight: "300px",
          maxHeight: "100vh",
          overflow: "scroll",
        }}
      >
        <Box
          sx={{
            minWidth: "500px",
            display: "inline-block",
            minHeight: "100px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              Create {entityType} attribute
            </Typography>
            {type === "view" && (
              <>
                {" "}
                {!editable ? (
                  <CreateIcon
                    sx={{ color: "green", cursor: "pointer" }}
                    onClick={() => setEditable(true)}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{ color: "green", cursor: "pointer" }}
                    onClick={() => setEditable(false)}
                  />
                )}{" "}
              </>
            )}
          </Box>

          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                minWidth: "100px",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid rgba(0,0,0,0.2)`,
              }}
            >
              <Select
                onChange={handleCollectData}
                value={currentDataModel}
                disabled={type=='view'}
                name="type"
                className="select-component"
                input={
                  <InputBase
                    className="inputbase-component"
                    variant="standard"
                    autoFocus={true}
                    sx={{
                      ml: 1,
                      flex: 1,
                      fontSize: "13px",
                      ".mui-component-select-type": {
                        display: "flex",
                      },
                    }}
                  ></InputBase>
                }
              >
                {dropdownItems.map(({ name, key, Icon }) => (
                  <MenuItem value={key}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Icon
                        color={currentDataModel === key ? "#a84acb" : "black"}
                      />
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: currentDataModel === key ? "#a84acb" : "black",
                        }}
                      >
                        {name}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Box>

          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: "7px",
            }}
          >
            <Typography sx={{ fontSize: "14px" }}>name</Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                disabled={!editable}
                label="Controlled"
                value={get(currentValues, "name", "")}
                onChange={handleCollectInputData}
                autoFocus
                name="name"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="name"
              />
            </Paper>
            <Typography sx={{ fontSize: "14px" }}>
              description (optional)
            </Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${"rgba(0,0,0,0.2)"}`,
              }}
            >
              <InputBase
                disabled={!editable}
                onChange={handleCollectInputData}
                value={get(currentValues, "description", "")}
                autoFocus
                name="description"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="description"
              />
            </Paper>

            {currentDataModel === "select" && (
              <>
                <Typography sx={{ fontSize: "14px" }}>list values</Typography>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    overflow: "hidden",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {get(currentValues, "options", []).map((ele, index) => (
                    <Paper
                      key={ele + index}
                      component="box"
                      sx={{
                        p: "1px 2px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: 40,
                        padding: "10px",
                        boxShadow: "none",
                        border: `1px solid ${"rgba(0,0,0,0.2)"}`,
                      }}
                    >
                      <InputBase
                        disabled={!editable}
                        onChange={(event) => {
                          handleCollectOptions(
                            event.target.value,
                            "update",
                            index
                          );
                        }}
                        value={ele}
                        autoFocus
                        name="options"
                        sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                        placeholder="options"
                        endAdornment={
                          <CloseIcon
                            sx={{
                              fontSize: "14px",
                              cursor: "pointer",
                              color: "#a84acb",
                            }}
                            onClick={() => {
                              handleCollectOptions("", "delete", index);
                            }}
                          />
                        }
                      />
                    </Paper>
                  ))}

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    {" "}
                    <Button
                      onClick={() => handleCollectOptions("", "add")}
                      sx={{
                        background:
                          "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                        color: "white",
                        height: "25px",
                        textTransform: "inherit",
                        fontSize: "13px",
                      }}
                      variant="text"
                    >
                      Add
                    </Button>
                  </Box>
                </Box>
              </>
            )}
          </Box>

          {/* <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              flexDirection: "column",
              gap: "15px",
            }}
          >
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "250px",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid rgba(0,0,0,0.2)`,
              }}
            >
              <Select
                labelId="demo-multiple-name-label"
                name="visibleTo"
                className="select-component"
                onChange={handleCollectInputData}
                
                multiple
                renderValue={(values) => {
                  return values.map((e) => capitalizeFirstLetter(e)).join(",");
                }}
                disabled={type=='view'}
                value={currentValues.visibleTo}
                input={
                  <InputBase
                    className="inputbase-component"
                    variant="standard"
                    sx={{
                      ml: 1,
                      flex: 1,
                      fontSize: "13px",
                      ".mui-component-select-type": {
                        display: "flex",
                      },
                    }}
                  ></InputBase>
                }
              >
                {visibleTo.map(({ text, key }) => (
                  <MenuItem key={key} value={key}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", color: "black" }}>
                        {text}
                      </Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </Paper>
          </Box> */}

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "30px",
              position: "relative",
              bottom: "0px",
              right: "0px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              {editable && (
                <Button
                  onClick={handleSubmitData}
                  sx={{
                    background:
                      "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                    color: "white",
                    height: "30px",
                    textTransform: "inherit",
                    fontSize: "13px",
                  }}
                >
                 {type==='new' ?  'Create' : "update"}
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateDataModelling;

// data modeling
// Text
// Number
// Decimal Number
// True or False
// Date
// List

{
  /* <MenuItem value="Text">Text</MenuItem>
<MenuItem value="Number">Number</MenuItem>
<MenuItem value="Decimal_Number">Decimal Number</MenuItem>
<MenuItem value="True_or_False">True or False</MenuItem>
<MenuItem value="Date">Date</MenuItem>
<MenuItem value="List">List</MenuItem> */
}
