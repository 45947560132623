import { Box, Chip, Typography } from '@mui/material'
import React from 'react'
import DesktopComputerIcon from '../../../../assets/icons/DesktopComputerIcon'
import ContactSideBarIcon from '../../../../assets/icons/ContactSideBarIcon'
import { get } from 'lodash'

const Profile = ({profileData={}}) => {
  
  return (
  <>
  <Box  sx={{display:'flex', flexDirection:'column', gap:'25px', padding:'10px'}}>
    <Box sx={{display:'flex', gap:'20px', alignItems:'center'}} > 
     <Typography variant='span' sx={{fontWeight:'500', fontWeight:'20px', fontSize:'16px', }} >How AI Chatbot should answer</Typography>
    <Typography sx={{color:'#707C97', fontSize:'14px'}} >Disabled</Typography>
     </Box>

     <Box sx={{display:'flex', gap:'20px', alignItems:'center'}} > 
     <Typography variant='span' sx={{fontWeight:'500', fontWeight:'20px', fontSize:'16px'}} >Show AI Chatbot to</Typography>
     <Chip icon={ <DesktopComputerIcon /> } label={get(profileData,'channels',[]).join(' , ')} sx={{borderRadius:'5px', background:'#E8E8E8', fontWeight:'600',paddingLeft:'10px'}} />
     <Chip  icon={<ContactSideBarIcon color={'black'}  />} label={get(profileData,'audience',[]).join(' , ')} sx={{borderRadius:'5px', background:'#E8E8E8', fontWeight:'600', paddingLeft:'10px'}} />
     </Box>

     <Box sx={{display:'flex', gap:'20px', alignItems:'center'}} > 
     <Typography variant='span' sx={{fontWeight:'500', fontWeight:'20px', fontSize:'16px'}} >How AI Chatbot should behave</Typography>
    <Typography sx={{color:'#707C97', fontSize:'14px'}} >One-time</Typography>
     </Box>

     {/* <Box sx={{display:'flex', gap:'20px', alignItems:'center'}} > 
     <Typography variant='span' sx={{fontWeight:'500', fontWeight:'20px', fontSize:'16px'}} >Have AI Chatbot introduce itself</Typography>
    <Typography sx={{color:'#707C97', fontSize:'14px'}} >Disabled in one-time mode</Typography>
     </Box> */}


     {/* <Box sx={{display:'flex', gap:'20px', alignItems:'center'}} > 
     <Typography variant='span' sx={{fontWeight:'500', fontWeight:'20px', fontSize:'16px'}} >How AI Chatbot should hand over</Typography>
    <Typography sx={{color:'#707C97', fontSize:'14px'}} >Disabled</Typography>
     </Box> */}

     {/* <Box sx={{display:'flex', gap:'20px', alignItems:'center'}} > 
     <Typography variant='span' sx={{fontWeight:'500', fontWeight:'20px', fontSize:'16px'}} >Schedule</Typography>
     <Chip  label='Start sending immediately' sx={{borderRadius:'5px', background:'#E8E8E8', fontWeight:'600'}} />
     <Chip  label='Never stop sending' sx={{borderRadius:'5px', background:'#E8E8E8', fontWeight:'600'}} />
     <Chip  label='Any day, any time' sx={{borderRadius:'5px', background:'#E8E8E8', fontWeight:'600'}} />
     </Box> */}


  </Box>
  
  
  </>)
}

export default Profile
