import React, { useEffect, useRef } from "react";
import { Box } from "@mui/material";
import MessageCard from "./MessageCard"; // Import your MessageCard component

const ChatBoatContainer = ({ messages, loading }) => {
  const scrollerDivRef = useRef(null);

  useEffect(() => {
    // Function to scroll to the bottom
    const scrollToBottom = () => {
      if (scrollerDivRef.current) {
        scrollerDivRef.current.scrollTop = scrollerDivRef.current.scrollHeight;
      }
    };

    // Scroll to the bottom when new messages arrive
    scrollToBottom();
  }, [messages,loading]);
  let loadingEle = {
    message: `thinking...`,
    type: "reciver",
    icon: true,
    time: "",
  };
  return (
    <Box
      sx={{ height: "100%", padding: "10px", overflow: "scroll" }}
      id="scrollerDiv"
      ref={scrollerDivRef}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          paddingTop: "20px",
          overflowY: "scroll",
        }}
      >
        
        {messages.map((ele) => (
          <MessageCard key={ele.id} {...ele} />
        ))}
         {loading ? (
          <MessageCard key={"4387yr48734y7r43"} {...loadingEle} />
        ) : null}
       
      </Box>
    </Box>
  );
};

export default ChatBoatContainer;
