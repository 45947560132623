import React, { useContext, useState } from "react";
import { Box, Button, Divider, InputBase, Paper, Typography } from "@mui/material";
import style from "./email-forwarding-component.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import ForwardingEmailHeaderComponent from "./component/ForwardingEmailHeaderComponent";
import ForwardingEmailBody from "./component/ForwardingEmailBody";
import { copyToClipboard } from "../../../utils/helpers/helpers";
import { SidebarContext } from "../../../context/SidebarProvider";
const EmailForwardingComponent = ({ ToolbarComponent }) => {
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const [newEmailAddress, setNewEmailAddress] = useState({
    state:false,
    value:""
  });

  const [forwardingEmailData, setForwardingEmailData] = useState({
    existEmail:"",
    forwardingEmail:"",
    domainEmailAddress:""
  })

  const handleCollectForwardingEmailData = (event) => {
    const {name, value} = event.target;
    setForwardingEmailData((state)=>({
      ...state,
      [name]:value
    }))
  }

  const handleCopyData = (data) => {
    copyToClipboard(data);
    handleShowSnackPopup({message:"Copied to clipboard"});
  }
  const handleAddNewEmailAddress = (state) => {
    setNewEmailAddress({ 
      state:state,
      value:""
    })
  }

  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
              marginBottom: "10px",
              marginTop: "10px",
              padding: "20px",
            }}
          >
            <Box>
              <ForwardingEmailHeaderComponent />
            </Box>
            <Box sx={{ marginTop: "10px" }}>
              <Divider />
            </Box>
            <Box sx={{ marginTop: "10px" }}>
            <ForwardingEmailBody forwardingEmailData={forwardingEmailData}  handleCollectForwardingEmailData={handleCollectForwardingEmailData} handleCopyData={handleCopyData} />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
              <Divider />
            </Box>

            <Box sx={{ marginTop: "10px", display:"flex", flexDirection:"column",  }}>
             <Typography variant="body" sx={{color:"gray", fontSize:"15px", fontWeight:"600"}} >
             Company addresses to ignore in group conversations
             </Typography>
             <Typography variant="body" sx={{color:"gray", fontSize:"13px", fontWeight:"500", margin:"2px 0px"}} >
             When you’re CCed in group conversations, we’ll ignore company addresses so you don’t receive duplicate notifications or similar conversations.
             </Typography>
             <Typography variant="body" sx={{color:"gray", fontSize:"13px", fontWeight:"500"}} >
             Add multiple company email addresses that you use for support here. We’ll attempt to detect and add any email addresses you forward to your inbox to this list.
             </Typography>
             <Box sx={{marginTop:"10px", display:"flex", gap:"5px", width:"400px"}} >
            {newEmailAddress.state && <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 30,
                    padding: "10px",
                    boxShadow: "none",
                    border: `1px solid rgba(0,0,0,0.1) `,
                  }}
                >
                  <InputBase
                    className="input-box"
                    type="email"
                    name="first_name"
                    sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                    placeholder="help@myapp.com"
                  />
                </Paper>}
             </Box>
             <Box sx={{marginTop:"10px", display:"flex", gap:"5px"}} >
            <Button
              onClick={()=>handleAddNewEmailAddress(true)}
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                  }}
                  //   onClick={handleAddNewDomain}
                >
                  Add email address
                </Button>
               {newEmailAddress.state && <Button
              onClick={()=>handleAddNewEmailAddress(false)}
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                  }}
                  //   onClick={handleAddNewDomain}
                >
                  Cancel
                </Button>}
            </Box>
            </Box>
           

          </Box>

        
        </Box>
      </MainComponent>
    </>
  );
};

export default EmailForwardingComponent;
