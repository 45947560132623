import { Box, Typography } from '@mui/material'
import React from 'react'
const CustomerType = () => {
  return (
  <>
        <Box  sx={{display:'flex', flexDirection:"column", gap:"1px"}} >
      <Typography sx={{fontSize:"14px", color:"black", fontWeight:"bold"}} >
      Customer sharing settings
      </Typography>

      <Typography sx={{fontSize:"13px", color:"gray",}} >
      Customer tickets are always shared with customers and allow customer facing replies.
      </Typography>
     
        </Box></>
  )
}

export default CustomerType
