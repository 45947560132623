import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from "./channelsStyle.module.scss"
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
let name = process.env.REACT_APP_AUTO_NAME
const ChannelsHeader = ({open}) => {
  return (<>
  <AccordionSummary
          className={style['chatboat-answer-type-header-main-container']}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography    className='accordianHeading' >{`How ${name } to`} {open ? <ExpandMoreIcon /> : <NavigateNextIcon />} </Typography>

        </AccordionSummary>
  
  
  </>
  )
}

export default ChannelsHeader
