import React, { useContext, useEffect, useState } from 'react'
import MainComponent from '../../../../fixedComponent/sideDashboard/MainComponent'
import SideToolbar from '../../../../fixedComponent/sideToolbar/SideToolbar'
import { Box, Divider, Typography, Button} from '@mui/material'
import style from "./allUsers.module.scss"
import { MainContext } from '../../../../context/MainProvider'
import TelegramIcon from '@mui/icons-material/Telegram';
import TageIcon from '../../../../assets/searchIcons/TageIcon'
import NewMessageIcon from '../../../../assets/icons/NewMessageIcon'
import CaretDownIcon from '../../../../assets/icons/CaretDownIcon'
import MenuSharpIcon from '@mui/icons-material/MenuSharp';
import FormatListBulletedSharpIcon from '@mui/icons-material/FormatListBulletedSharp';
import GlobalIcon from '../../../../assets/icons/GlobalIcon'
import DensityMediumSharpIcon from '@mui/icons-material/DensityMediumSharp';
import CompnayTable from './companyTable/CompanyTable'
import { allCustomers, getAllComapany } from '../../../../services/Apis'
import { get } from 'lodash'
import TagIcon from '../../../../assets/icons/TagIcon'
import UpdateCompany from './companyProfile/update-company/UpdateCompany'
import { SidebarContext } from '../../../../context/SidebarProvider'
import ContactFilters from '../../filters/ContactFilters'
import ExcelIcon from '../../../../assets/icons/ExcelIcon'
const AllCompany = ({ToolbarComponent, filterValue}) => {
  const {
    compinesData,
      handleGetAllCompines,
      
  } = useContext(MainContext)
  const { handleSetCount } = useContext(SidebarContext)
  const [companyLoadingData, setCompanyLoadingData] = useState(false)


    useEffect(()=>{
      setCompanyLoadingData(true)
      handleGetAllCompines().then(res=>{
        let response = get(res,'data.data.docs',[])
        handleSetCount('contacts',{
          [`${filterValue}`]:response.length
        })

        setCompanyLoadingData(false)
      }).catch(err=>{
        setCompanyLoadingData(false)
      })
    },[])



  

  return (
   <>
     <MainComponent className={style.allUsersBodyMainComponent} >
       <Box  sx={{padding:'0px 10px',  height:'95vh', background:'white !important'}} >
       <SideToolbar>
          {ToolbarComponent && (
            <ToolbarComponent  handleGetAllCompines={handleGetAllCompines} setCompanyLoadingData={setCompanyLoadingData} />
          )}
        </SideToolbar>
        {/* <ContactFilters componentType="company" /> */}
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            // overflowY: "scroll",
            gap: "10px",
            maxHeight: "80vh",
            height:"100%"

          }}
        >
          
            <Box className="body-headers-tool" >
                <Box className='body-headers-1 body-header-container'  >
                    <Typography className='display-total-users' >{compinesData.length} company</Typography>
                    <Button
                  className="header-first-btn"
                  variant="contained"
                  startIcon={<ExcelIcon />}
                >
                  Export data
                </Button>
                    {/* <Button className='header-first-btn'  variant="contained"  startIcon={<NewMessageIcon height={14} />} >New message</Button> */}
                    {/* <Button className='header-first-btn'  variant="contained"  startIcon={<TagIcon />} >Add Tags</Button>
                    <Button className='header-first-btn'  variant="contained"  endIcon={<CaretDownIcon />} >More</Button> */}

                </Box>
                {/* <Box className='body-headers-2 body-header-container' >
                <Button className='header-first-btn   '  variant="contained" endIcon={<CaretDownIcon />}  startIcon={<MenuSharpIcon style={{ transform:'rotate(90deg)' }} />}  />
                </Box> */}

            </Box>

            <Box  className="users-table-container" sx={{
              height:"100%",              
              maxHeight: "70vh",
                height:"100%"
            }} >
                <CompnayTable companies={compinesData} loading={companyLoadingData} />


            </Box>

        </Box>
       </Box>
      </MainComponent>
   
   </>
  )
}

export default AllCompany
