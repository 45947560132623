import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import { get } from "lodash";
import AiChatbotContentTable from "../table-component/AiChatbotContentTable";
import { useNavigate } from "react-router-dom";
import { chatbotBulkActionsApi, getChatBotDocuments } from "../../../../services/Apis";
import { MainContext } from "../../../../context/MainProvider";
import AddDocsToChatbot from "./addDocsToChatbotprofileModal/AddDocsToChatbot";
import { SidebarContext } from "../../../../context/SidebarProvider";
const ChatbotContents = () => {
    const navigate = useNavigate()
    const { handleOpenModal } = useContext(MainContext)
    const [chatbotDocuments, setChatbotDocuments] = useState([]);
    const { handleShowSnackPopup } = useContext(SidebarContext)

   const  handleGetChatBotDocuments = () => {
        getChatBotDocuments().then((res) => {
            let response = get(res, "data.data.docs", []);
            setChatbotDocuments(response);
          });
    };
    useEffect(() => {
        handleGetChatBotDocuments()
    }, []);

    const [currentkeys ,setCurrentkeys] = useState({}) 

    const handleCreatekeys = (value) => {
        let botKeys = {};
        chatbotDocuments.forEach(ele=>{
            botKeys[ele.id] = value;
        })
        setCurrentkeys(botKeys)
    }

    const handleCheckedData  = (event,key) => {
        let tempkeys = {...currentkeys}
        let value = event.target.checked;
        if(key==='all'){
            chatbotDocuments.forEach(ele=>{
                tempkeys[ele.id] = value;
            })
            // handleCreatekeys(value)
        }else{
            tempkeys[key]  = value;
        }
        setCurrentkeys(tempkeys)
    }

    useEffect(()=>{
        handleCreatekeys(false)
    },[ chatbotDocuments])

    let isChecked = Object.values(currentkeys).some(e=>e);

    const handleAddDocsToChatbot = (type) => {
        handleOpenModal({
            Component:<AddDocsToChatbot docsKeys={currentkeys} type={type} />
        })
    }

    const handleDeleteBulkActions = () => {
        let tempDocsIds = Object.keys(currentkeys).filter(key => currentkeys[key])
        let payload = {
            "action":"delete",
            "ids":tempDocsIds,
            "chatbot_ids": []
        }
        chatbotBulkActionsApi(payload).then((res) => {
            handleShowSnackPopup({message:"data deleted successfully",})
            handleGetChatBotDocuments()
        }).catch(err=>{
            handleShowSnackPopup({message:"error while  deleting",error:true})
        })
    
      }
  return (
   <>
      <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "10px",
                gap:"20px"
              }}
            >
              {/* <Select
                sx={{
                  width: "200px",
                  border: "1px solid rgba(0,0,0,0.1)",
                  fontSize: "13px",
                  paddingLeft: "5px",
                  height: "40px",
                  borderRadius: "5px",
                }}
                name="attrType"
                placeholder="bulk actions"
                input={
                  <InputBase
                    placeholder="bulk actions"
                    variant="standard"
                    autoFocus={true}
                    sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                  ></InputBase>
                }
              >
                <MenuItem
                  name="attrType"
                  sx={{
                    fontSize: "13px",
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  ele.name
                </MenuItem>
              </Select> */}
            
                
                {/* <Button
                disabled
                onClick={handleDeleteBulkActions}
              sx={{
              background: "#f1f1fb",
              color: "red",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'red',
             color: "red",":hover":{borderColor: "red", }, textTransform:"inherit" }} variant="outlined"> 
              Delete</Button> */}

              <Button
                disabled={!isChecked}
              onClick={()=>handleAddDocsToChatbot('removeChatBots')}
              sx={{
              background: "#f1f1fb",
              color: "#707c97",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined"> 
              Remove Chat Bots</Button>


              <Button
              disabled={!isChecked}
              onClick={()=>handleAddDocsToChatbot('addChatBots')}
              sx={{
              background: "#f1f1fb",
              color: "#707c97",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined"> 
              Add Chat Bots</Button>
                

              <Button
              onClick={()=>navigate('add-contents')}
              sx={{
              background: "#f1f1fb",
              color: "#707c97",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined"> 
              Add Documentions</Button>
            </Box>
            <AiChatbotContentTable chatbotDocuments={chatbotDocuments} handleCheckedData={handleCheckedData} currentkeys={currentkeys} />
          </Box>
   
   
   </>
  )
}

export default ChatbotContents
