import React from 'react'

const ContentSouceHocCard = (WrappedComponent) => {
  return (
    class searchWithHOC extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
              open:this.props.defaultOpen || false,
              currentKey:''
            };
          }
          
          setOpen = () => {
            this.setState({open:!this.state.open})
          }
        render() {

          return (
            <>
              <WrappedComponent
               {...this.props}
                setOpen={this.setOpen}
                open={this.state.open}  />
            </>
          );
        }
    }
  )
}

export default ContentSouceHocCard
