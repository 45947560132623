import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import userImage from "../../assets/24.png";
import Badge from "@mui/material/Badge";
import { get } from "lodash";
import moment from "moment";
import css from "./sidebar.module.scss"
const UserCard = ({onClick, cardDetails,cardLastMessage, handleOpenChatView, unreadMessages, active}) => {
  let title = get(cardDetails,'title',"") ||  get(cardDetails,'user.name',"") 
  let description = cardLastMessage
  let status = get(cardDetails,'status',"");
  let language = get(cardDetails,'language',"")
  let closed = status == 'Close';
  let sno = get(cardDetails,'sno',null);
  let lastMessage = cardLastMessage
  console.log(get(cardDetails,'lastMessage',<></>),"lastMessages",cardDetails)
  return (
    <>
    <Box  className={css.ticketCardContainer} >
      <Box  className={"ticketCardsMainClass"} key={description + title + sno} sx={{ minWidth: 100, padding: "10px",  background:active ? "#f7e2ff" : 'white', borderRadius:'4px', boxShadow:'0px 1px 6px rgba(0,0,0,0.1)' }} onClick={()=>handleOpenChatView(cardDetails, cardDetails.sno)}  >
        {/* <CardContent> */}
          <Grid container spacing={2}   >
            <Grid
              item
              xs={10}
              sx={{ justifyContent: "flex-start", alignItems: "center" }}
            >
              <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Box sx={{ position: "relative", borderRadius: "50%", height:'40px', width:"40px",  minWidth:"40px", background:'#a84acb', display:'flex', justifyContent:'center', alignItems:'center'  }}>
                  <Typography sx={{color:'white', fontSize:"20px"}} >
                    {get(title,'[0]','N')}

                  </Typography>
                  {/* <span
                    style={{
                      display: "inline-block",
                      width: "10px",
                      borderRadius: "50%",
                      position: "absolute",
                      background: closed ? 'red': "#00C305",
                      top: "2px",
                      left: "2px",
                      boxShadow: " 0px 0px 0px 2px rgba(255,255,255,1)",
                      height: "10px",
                    }}
                  ></span> */}
                  {/* <img src={userImage} style={{ borderRadius: "50%", height:'40px' }} />{" "} */}
                </Box>
                <Box>
                  <Typography sx={{ fontWeight: "bold" }}>{title}</Typography>
                  <Typography variant="span" sx={{ color: "#2A8BF2" , fontSize:'13px'}}>
                    {/* from facebook */}
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Typography className="displayUserLastMessage" variant="span"   color={"gray"} >
                  <span   style={{
                    maxHeight:'100px',
                    overflow:"hidden",
                    whiteSpace: "break-spaces",
                    display: "flex",
                    alignItems: "end",
                    "> hr":{
                    
                    }
                  }}  className="messageContainer" dangerouslySetInnerHTML={{__html:get(cardDetails,'lastMessage',<></>)}}  />
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{ justifyContent: "flex-end", display: "flex" }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "100%",
                }}
              >
                <Typography sx={{ color: "gray", fontSize:'12px', textAlign:'center', textWrap:'nowrap' }}>{moment(unreadMessages.lastMessageTime).isValid() ? moment(unreadMessages.lastMessageTime).fromNow():""}</Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                {unreadMessages.unreadMessages ?   <span
                    style={{
                      display: "inline-flex",
                      height: "20px",
                      width: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      background: "#F36",
                      color: "#fff",
                      borderRadius: "50%",
                      fontSize:'14px'
                    }}
                  >
                   {unreadMessages.unreadMessages}
                  </span> : null}
                </Box>
              </Box>
            </Grid>
          </Grid>
        {/* </CardContent> */}
      </Box>
      </Box>
    </>
  );
};

export default UserCard;
