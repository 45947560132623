import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import style from "./audianceTable.module.scss"
import { Avatar } from '@mui/material';
import moment from 'moment';

function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }
//   {
//     name:"ankit@email.com",
//     type:["Lead","User"],
//     conversation_rating:"Unknow",
//     first_seen:"2 months ago",
//     sign_up,:"Unknow"
//     last_seen:"2 months ago",
//     web_sessions:"0",
//     city:["Gurugram","Unknow"]

// }
  
  const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
  ];

  let data = [{
    "name": "cborrill0@tinypic.com",
    "type": "User",
    "conversation_rating": "Good",
    "first_seen": "2021-11-20T15:47:35Z",
    "sign_up": "Unknown",
    "last_seen": "2021-12-26T23:10:19Z",
    "web_sessions": 717,
    "city": "Unknown"
  }, {
    "name": "sgyngell1@wordpress.com",
    "type": "Lead",
    "conversation_rating": "Neutral",
    "first_seen": "2021-08-29T23:33:50Z",
    "sign_up": "Good",
    "last_seen": "2021-10-23T11:47:30Z",
    "web_sessions": 495,
    "city": "Unknown"
  }, {
    "name": "lkarmel2@businessweek.com",
    "type": "User",
    "conversation_rating": "Good",
    "first_seen": "2021-12-13T11:37:48Z",
    "sign_up": "Neutral",
    "last_seen": "2021-12-28T05:10:17Z",
    "web_sessions": 414,
    "city": "Unknown"
  }, {
    "name": "bgirdler3@pagesperso-orange.fr",
    "type": "Lead",
    "conversation_rating": "Bad",
    "first_seen": "2021-12-26T22:08:05Z",
    "sign_up": "Good",
    "last_seen": "2021-09-05T12:21:34Z",
    "web_sessions": 283,
    "city": "Unknown"
  }, {
    "name": "jlocal4@163.com",
    "type": "User",
    "conversation_rating": "Neutral",
    "first_seen": "2021-09-28T22:17:40Z",
    "sign_up": "Bad",
    "last_seen": "2021-08-09T14:45:01Z",
    "web_sessions": 284,
    "city": "Unknown"
  }, {
    "name": "mprocter5@nymag.com",
    "type": "User",
    "conversation_rating": "Bad",
    "first_seen": "2021-08-23T16:05:03Z",
    "sign_up": "Neutral",
    "last_seen": "2021-09-18T15:46:14Z",
    "web_sessions": 156,
    "city": "Unknown"
  }, {
    "name": "keyre6@timesonline.co.uk",
    "type": "Lead",
    "conversation_rating": "Unknown",
    "first_seen": "2021-12-16T03:44:46Z",
    "sign_up": "Bad",
    "last_seen": "2021-09-29T10:52:06Z",
    "web_sessions": 496,
    "city": "Gurugram"
  }, {
    "name": "aharnwell7@mapquest.com",
    "type": "User",
    "conversation_rating": "Bad",
    "first_seen": "2021-09-30T09:04:36Z",
    "sign_up": "Neutral",
    "last_seen": "2021-09-28T20:37:22Z",
    "web_sessions": 382,
    "city": "Unknown"
  }, {
    "name": "mrigg8@paypal.com",
    "type": "User",
    "conversation_rating": "Bad",
    "first_seen": "2021-10-26T01:50:04Z",
    "sign_up": "Bad",
    "last_seen": "2021-10-09T18:34:52Z",
    "web_sessions": 4,
    "city": "Gurugram"
  }, {
    "name": "dcookley9@washingtonpost.com",
    "type": "User",
    "conversation_rating": "Unknown",
    "first_seen": "2021-11-02T05:22:11Z",
    "sign_up": "Unknown",
    "last_seen": "2022-01-02T21:34:58Z",
    "web_sessions": 381,
    "city": "Unknown"
  }, {
    "name": "ccancotta@dot.gov",
    "type": "User",
    "conversation_rating": "Unknown",
    "first_seen": "2021-08-23T05:40:18Z",
    "sign_up": "Neutral",
    "last_seen": "2021-12-19T09:44:47Z",
    "web_sessions": 927,
    "city": "Gurugram"
  }, {
    "name": "bkmieciakb@marketwatch.com",
    "type": "User",
    "conversation_rating": "Good",
    "first_seen": "2021-09-24T14:56:02Z",
    "sign_up": "Neutral",
    "last_seen": "2021-12-02T17:34:52Z",
    "web_sessions": 264,
    "city": "Gurugram"
  }, {
    "name": "agobbetc@bigcartel.com",
    "type": "User",
    "conversation_rating": "Neutral",
    "first_seen": "2021-09-06T11:22:50Z",
    "sign_up": "Good",
    "last_seen": "2021-08-30T02:33:10Z",
    "web_sessions": 141,
    "city": "Gurugram"
  }, {
    "name": "iplacidod@posterous.com",
    "type": "User",
    "conversation_rating": "Neutral",
    "first_seen": "2021-11-13T00:49:08Z",
    "sign_up": "Good",
    "last_seen": "2021-08-19T15:51:00Z",
    "web_sessions": 174,
    "city": "Unknown"
  }, {
    "name": "lmeneuxe@gmpg.org",
    "type": "User",
    "conversation_rating": "Unknown",
    "first_seen": "2021-08-31T19:21:55Z",
    "sign_up": "Neutral",
    "last_seen": "2021-09-21T06:17:56Z",
    "web_sessions": 657,
    "city": "Gurugram"
  }, {
    "name": "ngronowef@ocn.ne.jp",
    "type": "Lead",
    "conversation_rating": "Unknown",
    "first_seen": "2021-10-18T17:30:29Z",
    "sign_up": "Neutral",
    "last_seen": "2021-08-22T18:29:58Z",
    "web_sessions": 828,
    "city": "Unknown"
  }, {
    "name": "bcranng@sbwire.com",
    "type": "User",
    "conversation_rating": "Unknown",
    "first_seen": "2021-10-11T04:47:11Z",
    "sign_up": "Neutral",
    "last_seen": "2021-11-17T19:36:43Z",
    "web_sessions": 906,
    "city": "Unknown"
  }]

const AudianceTable = () => {
  return (<>
  
  <TableContainer className={style['audiance-table-container']} >
      <Table sx={{ minWidth: 650 }} aria-label="caption table">
        <caption>And 1 people like this</caption>
        <TableHead>
          <TableRow>
            <TableCell align="left">Name</TableCell>
            <TableCell align="left">Type</TableCell>
            <TableCell align="center">Conversation Rating</TableCell>
            <TableCell align="left">First Seen</TableCell>
            <TableCell align="left">Signed up</TableCell>
            <TableCell align="left">Last Seen</TableCell>
            <TableCell align="left">Web sessions</TableCell>
            <TableCell align="left">City</TableCell>
   
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(({name, city, web_sessions, last_seen, sign_up, first_seen, conversation_rating, type}) => (
            <TableRow key={name}>
              <TableCell component="th" scope="row"   sx={{display:'flex',alignItems:'center', gap:'10px'}} >
              <Avatar  sx={{height:'30px', width:"30px"}} >{name[0]}</Avatar>  {name}
              </TableCell>
              <TableCell align="left">{type}</TableCell>
              <TableCell align="center">{conversation_rating}</TableCell>
              <TableCell align="left">{moment(first_seen).fromNow()}</TableCell>
              <TableCell align="left">{sign_up}</TableCell>
              <TableCell align="left">{moment(last_seen).fromNow()}</TableCell>
              <TableCell align="left">{web_sessions}</TableCell>
              <TableCell align="left">{city}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  
  </>)
}

export default AudianceTable
