import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MessageTypingBoxIcon from "../../../../assets/icons/MessageTypingBoxIcon";
import style from "./sendingDropdown.module.scss";
import BookIcon from "../../../../assets/icons/BookIcon";

const SendingDropDown = ({ setMessageType, messageType }) => {
  const handleChange = (event) => {
    setMessageType(event.target.value);
  };
  return (
    <>
      <FormControl
        sx={{ minWidth: 100, height: "auto", border: "none", outline: "none" }}
        size="small"
        className={style.sendingDropDownMainContainer}
      >
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          className="selectBoxOfSendingDropdown"
          value={messageType}
          onChange={handleChange}
          sx={{
            border: "none",
            outline: "none",
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <MenuItem className="menuItems" value={"REPLY"}>
            <MessageTypingBoxIcon height={15} width={15} />
            <span
              className="dropdownMessage"
              style={{ fontSize: "13px", marginLeft: "4px" }}
            >
              Reply
            </span>
          </MenuItem>

          <MenuItem className="menuItems" value={"NOTE"}>
            <BookIcon color="#edbc78" />
            <span
              className="dropdownMessage"
              style={{ fontSize: "13px", marginLeft: "4px" }}
            >
              Note
            </span>
          </MenuItem>
        </Select>
      </FormControl>
    </>
  );
};

export default SendingDropDown;
