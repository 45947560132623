import { Box, Typography } from "@mui/material";
import React from "react";
import style from "./email-auto-reply.module.scss";
const EmailAutoReplyToolbar = ({}) => {


  
  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", width:"100%" }}>
            Email auto reply
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default EmailAutoReplyToolbar;
