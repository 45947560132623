import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Divider,
} from "@mui/material";
import style from "./ticketTypeComponent.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import {
  getTicketType,
} from "../../../services/Apis";
import { get } from "lodash";
import CommonContentCard from "./displayTicketTypes/commponContentSouceCard/CommonContentCard";
import CustomerTicketTypeIcon from "../../../assets/icons/CustomerTicketTypeIcon";
import DisplayTicketTypesTable from "./displayTicketTypes/contentSources/DisplayTicketTypesTable";
import CreateNewTicketTypes from "./createNew/CreateNewTicketTypes";

const TicketTypeComponent = ({ ToolbarComponent }) => {
  const { handleOpenModal } = useContext(MainContext)
  const [ticketTypesData, setTicketTypesData] = useState({
    CUSTOMER:[],
    BACK_OFFICE:[],
    TRACKER:[]
  });
  
const handleGetTicketTypes = () => {
  getTicketType().then(res=>{
    let response = get(res,'data.data.docs',[]);
    const filterList  = {
      CUSTOMER:[],
      BACK_OFFICE:[],
      TRACKER:[]
    }
    response.forEach(ele=>{
      let type = get(ele,'type','customer')
      if(type === "tracker"){
        filterList.TRACKER.push(ele)
      }else if(type === "back-office"){
        filterList.BACK_OFFICE.push(ele)
      }else{
        filterList.CUSTOMER.push(ele)
      }
    })
    setTicketTypesData(filterList);
  })
}
  useEffect(()=>{
    handleGetTicketTypes();
  },[])

  const handleCreateTicketTypes = ({componentType="create",updatedData={}}) => {
    handleOpenModal({
      Component:<CreateNewTicketTypes componentType={componentType} updatedData={updatedData} handleGetTickettypes={handleGetTicketTypes} />
    })

  }


  return (
    <>
      <MainComponent className={style['ticket-type-main-component']}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent handleCreateTicketTypes={handleCreateTicketTypes} heading={'Ticket Types'}  />}</SideToolbar>
          <Divider />
   
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "87vh",
              padding:"10px 0px 0px 0px"
            }}
          >
            <CommonContentCard
              defaultOpen={true}
              heading={`Customer ticket types (${ticketTypesData.CUSTOMER.length})`}
              content={ "To capture and track long-running queries. Useful for when the teammate who owns the ticket is also responsible for communicating with the customer." }
              Icon={()=><Box  sx={{display:"flex", justifyContent:'center', alignItems:"center", padding:"10px", borderRadius:"50%", background:"#a84acb", zIndex:'10'}} >
                <CustomerTicketTypeIcon color="#fff" />
              </Box>}
              Component={ ()=><DisplayTicketTypesTable dataList={ticketTypesData.CUSTOMER} />}
            />

            <CommonContentCard
              defaultOpen={true}
              heading={`Back-office ticket types (${ticketTypesData.BACK_OFFICE.length})`}
              content={ "To assign back-office work to separate teams, distinct from the team responsible for customer communication handling" }
              Icon={()=><Box  sx={{display:"flex", justifyContent:'center', alignItems:"center", padding:"10px", borderRadius:"50%", background:"#a84acb", zIndex:'10'}} >
                <CustomerTicketTypeIcon color="#fff" />
              </Box>}
              Component={ ()=><DisplayTicketTypesTable dataList={ticketTypesData.BACK_OFFICE} />}
            />

              <CommonContentCard
              defaultOpen={true}
              heading={`Tracker ticket types (${ticketTypesData.TRACKER.length})`}
              content={ "For widespread problems that affect multiple customers" }
              Icon={()=><Box  sx={{display:"flex", justifyContent:'center', alignItems:"center", padding:"10px", borderRadius:"50%", background:"#a84acb", zIndex:'10'}} >
                <CustomerTicketTypeIcon color="#fff" />
              </Box>}
              Component={ ()=><DisplayTicketTypesTable dataList={ticketTypesData.TRACKER}  />}
            />
        </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default TicketTypeComponent;
