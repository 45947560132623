import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
} from "@mui/material";
import style from "./company-data-modelling.module.scss";
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../../context/MainProvider";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CreateDataModelling from "../data-modelling-modal/CreateDataModelling";
import ListComponent from "../listComponent/ListComponent";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import {
  createCustomFields,
  deleteCustomFields,
  getCustomeFields,
} from "../../../../services/Apis";
import { get } from "lodash";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { Typography } from "@mui/material";
import PropTypes from "prop-types";
// customer, company, ticket
const totalTabs = ['customer','company','ticket'];
const CompanyDataModellingComponent = ({ ToolbarComponent }) => {
  const { handleOpenModal } = useContext(MainContext);
  const [customFieldsData, setCustomFieldsData] = useState([]);
  const [currentTab, setCurrentTab] = React.useState("customer");
  

  const handleChange = (event, newValue) => {
    // setValue(newValue);
    setCurrentTab(newValue)
    
  };

  useEffect(() => {
    handleGetCustomerFields({type:"entity_type",data:currentTab});
  }, [currentTab]);

  const handleCreateDataModeling = (type="new",id="") => {
    handleOpenModal({
      state: true,
      Component: (
        <CreateDataModelling
          data={{
            type: type,
            entityType:currentTab,
            handleGetCustomerFields,
            id:id
          }}
        />
      ),
    });
  };

  const handleGetCustomerFields = (filter) => {
    getCustomeFields(filter).then((res) => {
      let data = get(res, "data.data.docs", []);
      setCustomFieldsData(data);
    });
  };

  const handleDeleteCustomField = (id) => {
    deleteCustomFields(id).then(res=>{
      handleGetCustomerFields({type:"entity_type",data:currentTab});
    })
  }

  return (
    <>
      <MainComponent className={style["company-data-modelling-component"]}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
            }}
          >
            <Box className="data-modelling-dropdown">
              <Button
                varient="text"
                sx={{
                  textTransform: "capitalize",
                  background: `linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)`,
                  color: "white",
                }}
                onClick={()=>handleCreateDataModeling('new')}
                className="create-data-modelling-btn"
              >
                {" "}
                + Create attributes
              </Button>
            </Box>



            <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "80vh",
            }}
          >
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={currentTab}
                onChange={handleChange}
                aria-label="basic tabs example"
                textColor="black"
                indicatorColor="secondary"
              >
                <Tab
                  value={'customer'}
                  label="Customer"
                  {...a11yProps(0)}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "inherit",
                    color: "black",
                  }}
                />
                <Tab
                 value={'company'}
                 label="Company"
                  {...a11yProps(1)}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "inherit",
                    color: "black",
                  }}
                />
                {/* customer, company, ticket */}
                 <Tab
                  value={'ticket'}
                  label="Ticket"
                  {...a11yProps(2)}
                  sx={{
                    fontWeight: "bold",
                    textTransform: "inherit",
                    color: "black",
                  }}
                />
              </Tabs>
            </Box>
            {
              totalTabs.map(item=>(
                <>
                <CustomTabPanel style={{ maxHeight: "80vh", overflowY: "scroll" }} value={currentTab} index={item}>
                {customFieldsData.length ? (
                <ListComponent
                  handleCreateDataModeling={handleCreateDataModeling}
                  data={customFieldsData}
                  handleDeleteCustomField={handleDeleteCustomField}

                  handleUpdate={() => {}}
                  handleDeleteTag={() => {}}
                />
              ) : (
                <Box
                  sx={{
                    maxHeight: "80vh",
                    minHeight:"50vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <DoNotDisturbIcon sx={{ fontSize: "100px", color: "gray" }} />
                </Box>
              )}
            </CustomTabPanel>
                </>
              ))
            }
          </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );

};



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


export default CompanyDataModellingComponent;
