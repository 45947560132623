import React, { useContext, useEffect } from "react";
import { MainContext } from "../../../context/MainProvider";
import { get } from "lodash";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import MessageBarComponent from "./messageBarComponent/MessageBarComponent";
import { useSocket } from "../../../context/SocketProvider";
import moment from "moment";
import DoDisturbIcon from "@mui/icons-material/DoDisturb";
import { Box } from "@mui/material";
import UserCard from "../../sidebar/Card";

const InboxCardComponent = ({
  userTickets,
  currentTicket,
  setCurrentTicket,
  cardMessagesState,
}) => {
    const { handleFetchTickets } = useContext(MainContext)
  const navigate = useNavigate();
  const location = useLocation();
  const getUnreadMessages = (messages) => {
    let allUnreadsMessages = messages.filter((ele) => ele.read == false);
    let time = get(allUnreadsMessages.slice(-1), `[0].createdAt`, "");
    let response = {
      unreadMessages: allUnreadsMessages.length,
      lastMessageTime: time,
    };
    return response;
  };

  const handleSetCurrentView = (cardDetails, i) => {
    navigate(`/inbox/your-inbox/conversation/${cardDetails.sno}`, {
      state: {
        from_user: i,
      },
    });
    setCurrentTicket(cardDetails);
  };

  // console.log(get(
  //   cardMessagesState,
  //   `[${1}][0].message`,
  //  false
  // ),"cardMessagesState")


  return (
    <>
      {!userTickets.length ? (
        <>
          <Box
            sx={{
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DoDisturbIcon sx={{ color: "gray", fontSize: "100px" }} />
          </Box>
        </>
      ) : (
        <>
          {userTickets.map((ele, i) => {
            return (
            <UserCard
              key={ele.sno}
              active={get(currentTicket, "sno", null) === get(ele, "sno", null)}
              handleOpenChatView={handleSetCurrentView}
              // cardLastMessage={get(
              //   cardMessagesState,
              //   `[${ele.sno}][0].message`,
              //   get(ele,'lastMessage',"")
              // )}

              cardLastMessage={get(ele,'lastMessage',<></>)}
              cardDetails={ele}
              unreadMessages={getUnreadMessages(
                get(cardMessagesState, `[${ele.sno}]`, [])
              )}
            />
          )})}
        </>
      )}
    </>
  );
};

export default InboxCardComponent;
