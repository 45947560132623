import React from "react";
import CardBoxComponentWrapper from "./CardBoxComponentWrapper";
import { Box, Button, InputBase, Paper, Typography } from "@mui/material";
import CopyPlusIcon from "../../../../assets/icons/CopyPlusIcon";

const BulletCircleComponent = ({ content }) => {
  return (
    <Box
      sx={{
        height: "20px",
        width: "20px",
        borderRadius: "50%",
        background: "#e8e8e8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography sx={{ fontWeight: "100", color: "gray" }} variant="caption">
        {content}
      </Typography>
    </Box>
  );
};

const ForwardingEmailBody = ({forwardingEmailData, handleCollectForwardingEmailData, handleCopyData}) => {
  return (
    <CardBoxComponentWrapper>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
        <Box>
          <Typography sx={{ fontSize: "14px" }} variant="h1">
            Follow these instructions to set up email forwarding:
          </Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ padding: "5px" }}>
            <BulletCircleComponent content="1" />
          </Box>
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <Box>
              {" "}
              <Typography sx={{ fontSize: "14px" }} variant="span">
                Enter your existing email account
              </Typography>{" "}
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ width: "300px" }}>
                <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 30,
                    padding: "10px",
                    boxShadow: "none",
                    border: `1px solid rgba(0,0,0,0.1) `,
                  }}
                >
                  <InputBase
                    onChange={handleCollectForwardingEmailData}
                    value={forwardingEmailData.existEmail}
                    className="input-box"
                    type="email"
                    name="existEmail"
                    sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                    placeholder="help@myapp.com"
                  />
                </Paper>
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                  }}
                  //   onClick={handleAddNewDomain}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ padding: "5px" }}>
            <BulletCircleComponent content="2" />
          </Box>
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <Box>
              {" "}
              <Typography sx={{ fontSize: "14px" }} variant="span">
                Copy your forwarding address below:
              </Typography>{" "}
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ width: "300px" }}>
                <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 30,
                    padding: "10px",
                    boxShadow: "none",
                    border: `1px solid rgba(0,0,0,0.1) `,
                  }}
                >
                  <InputBase
                    onChange={handleCollectForwardingEmailData}
                    value={forwardingEmailData.forwardingEmail}
                    className="input-box"
                    type="email"
                    name="forwardingEmail"
                    sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                    placeholder="help@myapp.com"
                  />
                </Paper>
              </Box>
              <Box>
                <Button
                    onClick={()=>handleCopyData(forwardingEmailData.forwardingEmail)}
                  startIcon={<CopyPlusIcon color="#a84acb" />}
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                  }}
                  //   onClick={handleAddNewDomain}
                >
                  Copy
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ padding: "5px" }}>
            <BulletCircleComponent content="3" />
          </Box>
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <Box sx={{ maxWidth: "400px" }}>
              {" "}
              <Typography sx={{ fontSize: "14px" }} variant="span">
                Sign into your email provider and find the settings for email
                forwarding. Then, save the address above as your forwarding
                address.
              </Typography>{" "}
            </Box>
          </Box>
        </Box>

        <Box sx={{ display: "flex", gap: "10px" }}>
          <Box sx={{ padding: "5px" }}>
            <BulletCircleComponent content="4" />
          </Box>
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <Box sx={{ maxWidth: "400px", display:'flex', flexDirection:"column", gap:'2px' }}>
              {" "}
              <Typography
                sx={{ fontSize: "14px", fontWeight: "600" }}
                variant="span"
              >
                For domain providers requesting a confirmation code. .
              </Typography>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "400" }}
                variant="span"
              >
                The code will appear below once the verification process is initiated. If code fails to show, please find it in your
              </Typography>{" "}
            </Box>
         


            <Box sx={{ display: "flex", gap: "10px" }}>
              <Box sx={{ width: "300px" }}>
                <Paper
                  component="box"
                  sx={{
                    p: "1px 2px",
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    height: 30,
                    padding: "10px",
                    boxShadow: "none",
                    border: `1px solid rgba(0,0,0,0.1) `,
                  }}
                >
                  <InputBase
                    onChange={handleCollectForwardingEmailData}
                    value={forwardingEmailData.domainEmailAddress}
                    className="input-box"
                    type="email"
                    name="domainEmailAddress"
                    sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                    placeholder=""
                  />
                </Paper>
              </Box>
              <Box>
                <Button
                 onClick={()=>handleCopyData(forwardingEmailData.domainEmailAddress)}
                  startIcon={<CopyPlusIcon color="#a84acb" />}
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                  }}
                  //   onClick={handleAddNewDomain}
                >
                  Copy
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>





        <Box sx={{ display: "flex", gap: "10px", width:"400px" }}>
          <Box sx={{ padding: "5px" }}>
            <BulletCircleComponent content="5" />
          </Box>
          <Box sx={{ display: "flex", gap: "10px", flexDirection: "column" }}>
            <Box>
              {" "}
              <Typography sx={{ fontSize: "14px" }} variant="span">
              Check that Intercom is receiving your incoming email by sending a test message.
              </Typography>{" "}
            </Box>
            <Box sx={{ display: "flex", gap: "10px" }}>
                <Button
                  variant="outlined"
                  sx={{
                    background: "#f1f1fb",
                    color: "#707c97",
                    height: "26px",
                    boxShadow: "none",
                    fontSize: "12px",
                    textTransform: "capitalize",
                    padding: "3px 10px",
                    borderColor: "#a84acb",
                    color: "#a84acb",
                    ":hover": { borderColor: "#a84acb" },
                    textTransform: "inherit",
                  }}
                  //   onClick={handleAddNewDomain}
                >
                  Validate email forwarding
                </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </CardBoxComponentWrapper>
  );
};

export default ForwardingEmailBody;
