const arr = 
    {
       profilePicture:{
        url:""

       },
       profileName:{
        name:""
       }
    }


    export const skeletonLoadingData = [{
      "id": "920e30aa-d139-4fc1-9845-de4d237b0d4b",
      "child": [
        "79238f34-90be-4bce-9060-9bd627b41e53",
        "015597a5-e3d6-4ce6-b374-650555d2cf67",
        "13e4d54d-bb11-4d39-b637-955bf2dc59e8",
        "8cfb21e6-781f-45aa-bc81-f2deaef91b0c",
        "e5d182aa-1f04-41be-ac72-d84de11e7abc",
        "3b4fa852-a419-4b4c-9b83-f5fc6a90fb02",
        "b7b2140a-6675-45dc-8b6b-b4ec4f90f375"
      ]
    }, {
      "id": "2c2d8890-e880-448d-b115-b9601fa60486",
      "child": [
        "3c0eae58-1313-43a3-a479-0b692d6d2753",
        "3b70b02a-16a9-4b59-93cf-29b590072ef1",
        "a06fb974-b855-46d6-97c2-d28a29b1bd4f",
        "9eba15a9-27e4-48f5-9305-eb36eb8232b0",
        "40f6f549-413f-4b7d-beaa-e79853a35996",
        "cd5c9fa1-c720-4b86-b62f-a371f35d9ac6",
        "c699b0b5-a018-498c-bd1d-31482be13658"
      ]
    }, {
      "id": "bf6d2cd0-4eea-4a7b-b465-2dfcf025b569",
      "child": [
        "9d067b04-29c8-4c5f-89fc-a56668e899c7",
        "55436ca0-a435-49b2-80da-7c3bb18fa4fb",
        "0dba01c7-42d5-4dde-8051-0a6d8316f07e",
        "9292d17b-83e2-43a4-9953-0d18131b3b35",
        "50b2f397-7413-46f2-8846-9cd88e046071",
        "147b8227-8f5b-4102-ae9d-5cc76bb3c357",
        "8557a782-e7d1-4405-bc62-3ec601681d39"
      ]
    }, {
      "id": "49b5376e-463c-4828-8999-0c760c963e41",
      "child": [
        "c9be6889-db20-4c8b-921b-9bb0b76b3697",
        "be34bcf2-ede1-4d4d-8dab-2a22debf0eb9",
        "5c1a9da8-5d7b-46eb-a2a3-1837b5d80912",
        "95291989-1f04-447d-912b-0e063a3fb78f",
        "eda30553-6dfa-428c-b5ad-767ecbf08766",
        "eb1c8bca-3c28-483d-8531-2923cb4a26ca",
        "840c436e-7d17-4e34-b0a5-4036b8dfb8d5"
      ]
    }, {
      "id": "f1a6c617-1219-4d54-90e3-4dfe96edcce1",
      "child": [
        "963e42ed-8da2-4145-9346-45d4fcfabc97",
        "e78d222e-bded-4880-b3df-df685c02164b",
        "3f872c8d-b4e0-4522-ba9b-d843c68cf208",
        "fd377d76-5713-40a7-88c9-dcecdb76103f",
        "8bc1118a-11ad-4878-9c23-c14c579053ac",
        "74153cdf-1967-42e9-85b0-e6f1a8778c36",
        "071f35e9-4467-4ef7-8a13-27357309d001"
      ]
    }, {
      "id": "dfd61b37-1e72-43a3-8edd-45de0d7c08f2",
      "child": [
        "12ff1990-49d2-4c98-a7db-58fb03a9c4cc",
        "e7cda307-5eb1-4413-b5e3-aeda5f9f2a26",
        "b814f647-d838-4dd6-990e-083fbf19be33",
        "ef08049d-74b3-4767-ac25-22408ca1a639",
        "591ddbb1-6374-442f-874a-9df706c426b6",
        "bc844664-2835-456c-810c-d57859cc3d05",
        "cf0fc5f3-c7f8-406b-a4a7-871122f61f93"
      ]
    }, {
      "id": "aa922236-c238-4641-9873-15d83f72dbfc",
      "child": [
        "126cde6e-8406-4f5a-a645-1d0f5368205c",
        "4d95e430-ecdb-4449-bcc6-be8042c3e0ff",
        "e0f31613-b717-4431-a016-f2eee6134e01",
        "e515f042-2f9b-49b9-94de-ed0b606a4be1",
        "be9d65d2-0c8e-4b09-a4bb-1e1eeab525fb",
        "318d65e7-859c-4890-b370-c58fd51c3e25",
        "e6c2fb0e-bad2-4468-9b53-645517ff54c2"
      ]
    }, {
      "id": "988d5611-f17b-4db9-a818-457af3e3cf8f",
      "child": [
        "3fbb58d0-8f25-4eeb-8983-cc9fc75d53ff",
        "c7985703-f0f8-45af-9347-63c577fcc138",
        "2ff1614e-61b6-473d-8efc-d43ac9cbad83",
        "faa8c24b-4c0c-4e31-b7d2-6e38e068cc9d",
        "f6e6d188-cf04-4152-bb78-dea326375a82",
        "84c142ad-d3d8-4848-a7fd-b210179b8f4d",
        "fe4391b8-6770-4498-9893-1ce7ea211738"
      ]
    }, {
      "id": "e240ef5b-33cf-479d-9c64-36a72f608697",
      "child": [
        "60c48e28-8041-45f3-a5e3-647d5410fd9e",
        "9c6ff846-20b3-4152-8cec-f36cf22f8e20",
        "3400a2ef-fa15-47df-af42-019310e30448",
        "aaeb49bf-9f19-4f80-aa46-f1f2441c1db0",
        "f3316e24-c3f1-44d3-8aaf-1612fd8bed7e",
        "a1980bf1-9cec-4118-a984-afb8375cd433",
        "7a6929b9-adb7-451b-9410-901a07e7946e"
      ]
    }]