import React from "react";

const OpenEmailBoxIcon = ({color='#a84acb', height=40, width=40}) => {
  return (
    <>
      <svg   height={height}  width={width} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 40">
        <path fill={color} d="M38.42 13.09h-.09L33 8.8V1a1 1 0 0 0-1-1H8a1 1 0 0 0-1 1v7.67l-5.59 4.47A1 1 0 0 0 1 14v25a1 1 0 0 0 1 1h36a1 1 0 0 0 1-1V14a1 1 0 0 0-.58-.91zM31 2v13h2v-3.64l3.37 2.7L20 27.7 9 18.53V2zM7 16.86L3.55 14 7 11.24zM37 38H3V16.14l16.36 13.63a1 1 0 0 0 1.28 0L37 16.14z"></path>
        <path fill={color} d="M13 7h14v2H13zm0 5h14v2H13zm0 5h14v2H13z"></path>
      </svg>
    </>
  );
};

export default OpenEmailBoxIcon;
