import React from "react";

const CrossIcon = ({color="#4E5072", height=24, width=24,...props}) => {
  return (
    <>
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M5.29283 5.29289C5.68336 4.90237 6.31652 4.90237 6.70705 5.29289L11.9999 10.5858L17.2928 5.29289C17.6834 4.90237 18.3165 4.90237 18.707 5.29289C19.0976 5.68342 19.0976 6.31658 18.707 6.70711L13.4142 12L18.707 17.2929C19.0976 17.6834 19.0976 18.3166 18.707 18.7071C18.3165 19.0976 17.6834 19.0976 17.2928 18.7071L11.9999 13.4142L6.70705 18.7071C6.31652 19.0976 5.68336 19.0976 5.29283 18.7071C4.90231 18.3166 4.90231 17.6834 5.29283 17.2929L10.5857 12L5.29283 6.70711C4.90231 6.31658 4.90231 5.68342 5.29283 5.29289Z"
          fill={color}
        />
      </svg>
    </>
  );
};

export default CrossIcon;
