import React from "react";

const PiechatSidebarIcon = ({ color }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 29 29"
        fill="none"
      >
        <g opacity="1">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M24.4814 16.4472C23.8665 16.2296 23.1963 16.549 22.9811 17.1598C21.6548 20.8921 18.0887 23.3994 14.1098 23.3994C8.92341 23.3994 4.70351 19.2014 4.70351 14.0394C4.70351 10.0801 7.22322 6.53149 10.974 5.21173C11.5866 4.99762 11.9076 4.32955 11.6912 3.71998C11.4749 3.11158 10.8035 2.791 10.1909 3.00745C5.50187 4.65598 2.35193 9.08911 2.35193 14.0394C2.35193 20.4908 7.62652 25.7394 14.1098 25.7394C19.0846 25.7394 23.5396 22.6061 25.1975 17.939C25.4138 17.3306 25.094 16.6625 24.4814 16.4472ZM16.461 11.6998V4.76291C20.0601 5.27771 22.9149 8.1173 23.4323 11.6998H16.461ZM15.2852 2.33984C14.6362 2.33984 14.1095 2.86283 14.1095 3.50984V12.8698C14.1095 13.5169 14.6362 14.0398 15.2852 14.0398H24.6916C25.3406 14.0398 25.8673 13.5169 25.8673 12.8698C25.8673 7.06313 21.1195 2.33984 15.2852 2.33984Z"
            fill={color}
          />
        </g>
      </svg>
    </>
  );
};

export default PiechatSidebarIcon;
