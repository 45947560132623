import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./domain-component.module.scss";
import { MainContext } from "../../../context/MainProvider";
import AddDomainComponent from "./add-domain-component/AddDomainComponent";
const DomainComponentToolbar = ({handleCreateEmailDomain}) => {
  const { handleOpenModal } = useContext(MainContext)

  const handleAddNewDomain = () => {
    handleOpenModal({
      Component:<AddDomainComponent handleCreateEmailDomain={handleCreateEmailDomain} />,
      state:true,
    })
  }


  
  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {" "}
            Domain
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
          >
            <Button
              variant="outlined"
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
              onClick={handleAddNewDomain}
            >
              Add and Verfiy Domain
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default DomainComponentToolbar;
