import React from "react";

const DecimalIcon = ({height=16, width=16,color='black',...props}) => {
  return (
    <>
      <svg
        {...props}
        width={width}
        height={height}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
         fill={color}
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2 2C0.895431 2 0 2.89543 0 4V12C0 13.1046 0.895431 14 2 14H14C15.1046 14 16 13.1046 16 12V4C16 2.89543 15.1046 2 14 2H2ZM13.488 10.5H9.42158V9.70703L11.238 7.91406C11.5036 7.65885 11.7093 7.44922 11.8552 7.28516C12.001 7.11849 12.1026 6.97396 12.1599 6.85156C12.2172 6.72656 12.2458 6.59766 12.2458 6.46484V6.45312C12.2458 6.29167 12.2106 6.14974 12.1403 6.02734C12.0726 5.90495 11.9737 5.8099 11.8435 5.74219C11.7132 5.67188 11.5583 5.63672 11.3786 5.63672C11.1963 5.63672 11.0349 5.67578 10.8942 5.75391C10.7536 5.82943 10.6442 5.9349 10.5661 6.07031C10.488 6.20312 10.4489 6.35938 10.4489 6.53906V6.55859H9.35908L9.35518 6.53906C9.35518 6.17969 9.44242 5.86328 9.61689 5.58984C9.79398 5.31641 10.0375 5.10286 10.3474 4.94922C10.6599 4.79557 11.0218 4.71875 11.4333 4.71875C11.8213 4.71875 12.1625 4.78776 12.4567 4.92578C12.7536 5.0638 12.9854 5.25521 13.1521 5.5C13.3213 5.74479 13.406 6.02995 13.406 6.35547V6.36719C13.406 6.58073 13.3643 6.79036 13.281 6.99609C13.1976 7.19922 13.057 7.41927 12.8591 7.65625C12.6612 7.89323 12.3903 8.17057 12.0466 8.48828L11.0036 9.46232V9.55078H13.488V10.5ZM7.23486 10.4023C7.10726 10.5326 6.94189 10.5977 6.73877 10.5977C6.54085 10.5977 6.37679 10.5326 6.24658 10.4023C6.11637 10.2721 6.05127 10.1107 6.05127 9.91797C6.05127 9.72526 6.11637 9.5651 6.24658 9.4375C6.37679 9.30729 6.54085 9.24219 6.73877 9.24219C6.94189 9.24219 7.10726 9.30729 7.23486 9.4375C7.36247 9.5651 7.42627 9.72526 7.42627 9.91797C7.42627 10.1107 7.36247 10.2721 7.23486 10.4023Z"
        ></path>
      </svg>
    </>
  );
};

export default DecimalIcon;
