import React, { useEffect, useState } from "react";
import SetupPageCards from "./dashboardCard/SetupPageCards";
import { Box } from "@mui/material";
import CommonContentCard from "../commponContentSouceCard/CommonContentCard";
import ChatBot from "../chatboat/ChatBot";
import ImportExternalSources from "../contentSources/ImportExternalSources";
import PullseHelpCenterSource from "../contentSources/PullseHelpCenterSource";
import UseSnippetSource from "../contentSources/UseSnippetSource";
import ImportContentFilesSources from "../contentSources/ImportContentFilesSources";
import ChatbotTableContents from "./bot-contents/ChatbotTableContents";
import { getChatBotDocuments } from "../../../../services/Apis";
import { get } from "lodash";
import ContantAddPage from "./dashboardCard/ContantAddPage";

const setupPageContensts =  [
  {
    heading:'Import external content',
    content:'Import content from public URLs, like knowledge bases or websites',
    Component:ImportExternalSources,
    key:'1'
  },
  {
    heading:'Use your Pullse Help Center',
    content:'Let AI Chatbot learn from the support content in your Help Center',
    Component:PullseHelpCenterSource,
    key:'2'
  },
  {
    heading:'Use Snippets',
    content:'Create plain text content specific for AI Chatbot, not publicly available',
    Component:UseSnippetSource,
    key:'3'
  },
  {
    heading:'Import content from files',
    content:`Upload PDF files and we'll fetch all the text data inside`,
    Component:ImportContentFilesSources,
    key:'4'
  }

]

const SetupPage = ({params}) => {
  const [chatbotDocuments, setChatbotDocuments] = useState([]);
  const handleGetBotDocuments = () => {
    getChatBotDocuments({type:'chatbot_id',data:params.id}).then((res) => {
      let response = get(res, "data.data.docs", []);
      setChatbotDocuments(response);
    });
  }
  useEffect(() => {
    handleGetBotDocuments()
   
  }, []);



  const [currentCard,setCurrentCard] = useState('')
  return (
    <>
      <Box>
        {/* <SetupPageCards /> */}
        <ContantAddPage  handleGetBotDocuments={handleGetBotDocuments}/>
        <Box
          sx={{
            display: "flex",
            padding: "10px 0px 10px 0px",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              width: "60%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
              flexWrap: "wrap",
              minWidth: "300px",
            }}
          >
            <ChatbotTableContents chatbotDocuments={chatbotDocuments}   />
          </Box>

          <Box
            sx={{
              width: "40%",
              padding: "0px 0px 0px 20px",
              minWidth: "400px",
              height:'600px',
              display:'flex',
              justifyContent:'center'
            }}
          >
            <ChatBot  />
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default SetupPage;
