import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  StepContent,
  Typography,
} from "@mui/material";
import style from "./email-setting.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Chip from '@mui/material/Chip';
import CannedResponseEditor from "../../editors/cannedResponseEditor/CannedResponseEditor";
import DisplayEmailSettingResponse from "./display-email-response/DisplayEmailSettingResponse";
import { Navigate, Outlet, Route, Routes, useNavigate, useParams, useSearchParams } from "react-router-dom";
import CreateEmailResponse from "./create-email-response/CreateEmailResponse";
import { MainContext } from "../../../context/MainProvider";
import { createCustomSupportEmail, customSupportEmailList } from "../../../services/Apis";
import { get } from "lodash";
import { SidebarContext } from "../../../context/SidebarProvider";
const EmailSettingComponent = ({ ToolbarComponent }) => {
  const [customEmailSettingListData, setCustomEmailSettingListData] = useState([])
  const { handleShowSnackPopup } = useContext(SidebarContext)



  const handleCreateCustomSupportEmail = (payload) => {
    return new Promise((resolve, reject)=>{
      createCustomSupportEmail(payload).then(res=>{
        handleGetCustomSupportEmail()
        handleShowSnackPopup({message:"custom email setting created successfully", error:false})
        resolve(res)
      }).catch(err=>{
        let error = get(err,'response.data.message',"")
        handleShowSnackPopup({message:error, error:true})
        reject(err)
      })
    })
  }


  useEffect(()=>{
    handleGetCustomSupportEmail()
  },[])

  const handleGetCustomSupportEmail = () => {
    customSupportEmailList().then(res=>{
      let response = get(res,'data.data.docs',[])
      setCustomEmailSettingListData(response)
    })

  }

  


  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />

          <Routes>
        <Route path="/" element={<DisplayEmailSettingResponse customEmailSettingListData={customEmailSettingListData} />} />
          <Route  path="create" element={<>
          <CreateEmailResponse componentType={'create'} handleAddData={handleCreateCustomSupportEmail} />
          </>} />

          <Route  path=":id" element={<>
          <CreateEmailResponse  componentType={'update'} />
          </>} />
        
          <Route  path="*" element={<><Navigate to={'/settings/email-setting'} /></>} />
        </Routes>

          
         
        </Box>
      </MainComponent>
    </>
  );
};

export default EmailSettingComponent;
