import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import brandLogo from "../../assets/Logo.png";
import SearchIcon from "@mui/icons-material/Search";
import VerticalTabs from "./SideTabPanel";
import { Link, Route, Routes } from "react-router-dom";
import { Outlet } from "react-router-dom";
import style from "./sidebar.module.scss";
import settingIcon from "../../assets/icons/setting.svg";
import { sideBarItems } from "./constant/sidebarConstant";
import FilterComponent from "../inbox/searchComponent/FilterComponent";

import InputAdornment from "@mui/material/InputAdornment";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Tooltip from "@mui/material/Tooltip";
import { useLocation } from "react-router-dom";
import { MainContext } from "../../context/MainProvider";
import { nestedRoute } from "../../routers/nestedRoutes";
import { SidebarContext } from "../../context/SidebarProvider";
import { get } from "lodash";
import LogoutIcon from "@mui/icons-material/Logout";
import { handleLogout } from "../../utils/helpers/helpers";
import { PullseChatWidget } from "chat-widget";
import { logoutApi } from "../../services/Apis";

const drawerWidth = 70;
export default function Dashboard({ childRoutes, setPullseChatData }) {
  const {
    sideLeftPane,
    setSideLeftPanel,
    setSecondSidebarConten,
    currentUser,
  } = React.useContext(MainContext);
  const { sidebarState } = React.useContext(SidebarContext);

  const { sidebarContents } = React.useContext(SidebarContext);
  const [sidebarHeader, setSidebarHeader] = React.useState("Inbox");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //   <PullseChatWidget data={{
  //     token: "bshapiraf@discovery.com",
  //     userType: 'customer',
  //  }} style={{
  //    bottom:"-20px !important"

  //  }} />
  // const modifiedAppBar = drawerWidth + sideLeftPane;
  // const totalWidth = drawerWidth + sideLeftPane;

  React.useEffect(()=>{
    setPullseChatData({
      token:get(currentUser, "email", ""),
      userType:"customer"
    })
  },[get(currentUser, "email", "")])
  
  return (
    <>
      <Box sx={{ display: "flex" }} className={style.sidebarMainClass}>
        <CssBaseline />
        <Drawer
          className="mainDrawer"
          sx={{
            gap: "2px",
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="permanent"
          anchor="left"
        >
          <Toolbar className="toolbarContant">
            <img src={brandLogo} style={{ height: "35px" }} />
          </Toolbar>
          <Divider />
          <Box className="iconMaonBoxContainer">
            <List className="sidebarIconContainer">
              {sideBarItems.map(({ key, Icon, header, link }) => (
                <Link to={link} >
                  <ListItem
                    disablePadding
                    key={key}
                    className="sideIconListItem"
                  >
                    <Tooltip
                      title={header}
                      placement="right"
                      sx={{ background: "white !important" }}
                    >
                      <ListItemButton className="sideIconbutton">
                        <ListItemIcon className="sideIcon">
                          <Icon
                            color={
                              get(sidebarState, "parent", "inbox") === key
                                ? "#a84acb"
                                : "#CCCCCC"
                            }
                          />
                        </ListItemIcon>
                      </ListItemButton>
                    </Tooltip>
                  </ListItem>
                </Link>
              ))}
            </List>

            <List className="sidebarIconContainer bottomIcons">
              <ListItem disablePadding className="sideIconListItem">
                <ListItemButton
                  className="sideIconbutton"
                  onClick={()=>{
                    logoutApi().then(res=>{
                      handleLogout()
                    })
                  }}
                >
                  <ListItemIcon className="sideIcon">
                    <LogoutIcon sx={{ fontSize: "18px", color: "gray" }} />
                  </ListItemIcon>
                </ListItemButton>
              </ListItem>
            </List>
          </Box>
        </Drawer>

        <Drawer
          sx={{
            width: 200,
            display: sideLeftPane == 0 ? "none" : "visible",
          }}
          variant="permanent"
          anchor="center"
          className="secondDrawer"
        >
          <VerticalTabs
            sidebarHeader={get(sidebarState,'parent','inbox')}
            sideLeftPane={sideLeftPane}
            value={value}
            handleChange={handleChange}
            setSideLeftPanel={setSideLeftPanel}
          />
        </Drawer>
        <Routes>
          <Route path="/" element={<Outlet />} />
          {childRoutes.map((ele) => {
            let { Component, path, ToobarComponent, filterValue = "" } = ele;
            // co:143nsole.log(path,"paths")
            return (
              <>
                <Route
                  path={path}
                  element={
                    <>
                      <Component
                        ToolbarComponent={ToobarComponent}
                        filterValue={filterValue}
                      />
                    </>
                  }
                />
              </>
            );
          })}
        </Routes>

        {/* {get(currentUser, "email", "").length > 4 ? (
          <>
            <PullseChatWidget
              data={{
                token: get(currentUser, "email", ""),
                userType: "customer",
              }}
              style={{
                bottom: "-20px !important",
              }}
            />
          </>
        ) : null} */}
      </Box>
    </>
  );
}
