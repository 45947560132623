import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./email-forwarding-component.module.scss";
import { MainContext } from "../../../context/MainProvider";
const EmailForwardingToolbar = ({}) => {
  const { handleOpenModal } = useContext(MainContext)


  
  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold", width:"100%" }}>
            {" "}
            Email forwarding
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default EmailForwardingToolbar;
