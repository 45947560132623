import React, { useContext, useEffect, useState } from "react";
import MainComponent from "../../../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../../../fixedComponent/sideToolbar/SideToolbar";
import {
  Box,
  Divider,
  Typography,
  Button,
  Avatar,
  Paper,
  InputBase,
  TextareaAutosize,
  Select,
  MenuItem,
} from "@mui/material";
import style from "./companyProfile.module.scss";
import { MainContext } from "../../../../../context/MainProvider";
import CaretDownIcon from "../../../../../assets/icons/CaretDownIcon";
import {
  PullseTickets,
  allCustomers,
  deleteCompany,
  getAllComapany,
  getCompanyDetails,
  updateCompanyData,
} from "../../../../../services/Apis";
import { get } from "lodash";
import Grid from "@mui/material/Grid";
import UserIcon from "../../../../../assets/searchIcons/UserIcon";
import LocationIcon from "../../../../../assets/icons/LocationIcon";
import EmailBoxIcon from "../../../../../assets/icons/EmailBoxIcon";
import SwitchIcon from "../../../../../assets/icons/SwitchIcon";
import CheckIcon from "../../../../../assets/icons/CheckIcon";
import CompanyTabs from "./companyTabs/CompanyTabs";
import EditIcon from "../../../../../assets/icons/EditIcon";
import SendingButton from "../../../../../assets/icons/SendingButton";
import SearchIcon from "../../../../../assets/icons/SearchIcon";
import { useNavigate, useParams } from "react-router-dom";
import useStateCallback from "../../../../../utils/hooks/useStateCallback";
import UpdateCompany from "./update-company/UpdateCompany";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { SidebarContext } from "../../../../../context/SidebarProvider";
import { customFieldIcons } from "../../../../../utils/helpers/helpers";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { setValueUsingCustomFields } from "../../../../../services/Apis";

const CompanyProfile = ({ ToolbarComponent }) => {
  const { handleOpenModal, customeFieldsData } = useContext(MainContext);
  const {  handleShowSnackPopup } = useContext(SidebarContext)
  const [companyCustomFields, setCompanyCustomFields] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [showMore, setShowMore] = useState(true);
  const [companyDetails, setCompanyDetails] = useState({});
  const [companyPeoples, setCompanyPeoples] = useState([]);
  const [editableNote, setEditableNote] = useState(false);
  const [dialogToggle, setDialogToggle] = useState(false);
  const [comapnyTickets, setCompanyTickets] = useState([])
  const [comapnyDetailEditData, setCompanyDetailEditData] = useStateCallback({
    state: false,
    data: {},
  });
  const { id: companyId } = useParams();
  const navigate = useNavigate();
  const handleGetCompanyTickets = () => {
    PullseTickets({
      type:'company_id',
      data:companyId
    },{
      type:'limit',
      data:5

    }).then(res=>{
      let response = get(res,'data.data.docs',[]);
      setCompanyTickets(response)
    })
  }

  useEffect(() => {
    handleGetCompanyDetails();
    handleGetCompanypeoples();
    handleGetCompanyTickets()
  }, []);

  const handleGetCompanyDetails = () => {
    getCompanyDetails(companyId).then((res) => {
      let data = get(res, "data.data", {});
      setCompanyDetails(data);
      setEditableNote(false);
      setCompanyDetailEditData({
        state: false,
        data: {},
      });
    }).catch(err=>{
      handleShowSnackPopup({message:"invalid company id", error:true});
      navigate('/contacts/comapny/all');

    })
  };

  const companyCardDetails = [
    {
      key: "Company name",
      value: get(companyDetails, "name", "N/A"),
      mainValue: get(companyDetails, "name", "N/A"),
    },
    {
      key: "Company id",
      value: `${get(companyDetails, "id", "N/A").substring(0, 15)}${
        get(companyDetails, "id", "").length > 15 && "..."
      } `,
      mainValue: get(companyDetails, "id", ""),
    },
    {
      key: "Company last seen",
      value: get(companyDetails, "lastSeen", "N/A"),
      mainValue: get(companyDetails, "lastSeen", ""),
    },
    {
      key: "Company created at",
      value: get(companyDetails, "lastSeen", "Unknown"),
      mainValue: get(companyDetails, "lastSeen", ""),
    },
    {
      key: "People",
      value: get(companyDetails, "numberOfEmployees", "N/A"),
      mainValue: get(companyDetails, "numberOfEmployees", ""),
    },
    {
      key: "Company web sessi...",
      value: get(companyDetails, "webSessions", "N/A"),
      mainValue: get(companyDetails, "webSessions", ""),
    },
    {
      key: "Plan",
      value: get(companyDetails, "numberOfEmployees", "N/A"),
      mainValue: get(companyDetails, "numberOfEmployees", ""),
    },
    {
      key: "Monthly spend",
      value: get(companyDetails, "montheySpend", "N/A"),
      mainValue: get(companyDetails, "montheySpend", ""),
    },
    {
      key: "Company size",
      value: get(companyDetails, "numberOfEmployees", "N/A"),
      mainValue: get(companyDetails, "numberOfEmployees", ""),
    },
    {
      key: "Company industry",
      value: get(companyDetails, "industry", "N/A"),
      mainValue: get(companyDetails, "industry", ""),
    },
    {
      key: "Company website",
      value: get(companyDetails, "websites[0]", "N/A"),
      mainValue: get(companyDetails, "websites", []),
    },
  ];

  const handleGetCompanypeoples = () => {
    allCustomers({ type: "company_id", data: companyId }).then((res) => {
      let responseArr = get(res, "data.data.docs", []);
      setCompanyPeoples(responseArr);
    });
  };

  const handeUpdateNote = () => {
    setCompanyDetailEditData(
      {
        state: false,
        data: {
          notes: get(companyDetails, "notes", ""),
        },
      },
      () => {
        setEditableNote(true);
      }
    );
  };
  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setCompanyDetailEditData((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    }));
  };

  useEffect(() => {}, [comapnyDetailEditData]);

  const handleUpdateCompanyProfile = () => {
    updateCompanyData(companyId, {
      ...get(comapnyDetailEditData, "data", {}),
    }).then((res) => {
      handleGetCompanyDetails();
    });
  };

  const handleUpdateCompany = () => {
    handleOpenModal({
      state: true,
      Component: (
        <UpdateCompany
          data={{
            companyDetails: companyDetails,
            handleGetCompanyDetails,
          }}
        />
      ),
    });
  };

  const handleDeleteCompany = () => {
    deleteCompany(companyId).then(res=>{
      handleShowSnackPopup({message:"company deleted successfully"})
      navigate("/contacts/comapny")
    }).catch(err=>{
      handleShowSnackPopup({message:"error while deleting company",error:true})
    })
  

  }


  const [newAttributeValue, setNewAttributeValue] = useState({
    state: false,
    data: {
      currentAttributeType:{},
      attrType: "",
      attrValue:"",

    },
  })
  const [viewCustomFieldValues,setViewCustomFieldValues] = useState([])




  const handleSetCustomeFieldsValue = () => {
    let tempCustomeFields = {...get(companyDetails,'customFields',{})};
    let tempViewCustomFieldValues = [];
    Object.keys(tempCustomeFields).map((eleKey)=>{
    let customFieldData  = companyCustomFields.find(ele=>ele.id === eleKey);
    if(customFieldData){
      tempViewCustomFieldValues.push({
        customFieldData,
        value:tempCustomeFields[eleKey],
        id:eleKey,
        editable:false
      })
    }      
    })
    setViewCustomFieldValues(tempViewCustomFieldValues)

  }
  useEffect(()=>{
    handleSetCustomeFieldsValue()
  },[companyDetails, companyCustomFields])
  const handleAddAttriuteValue = () => {
    setNewAttributeValue((state)=>({ 
      data: {
        currentAttributeType:{},
        attrType: "",
        attrValue:""
      },
      state:true
    }))
  }


  useEffect(()=>{
    let tempCustomFields = [...get(customeFieldsData,'company',[])]
    // let tempCustomeFields = {};
    let clearObj = {
      id:"cancel",
      name:"Cancel"
    };
    tempCustomFields.unshift(clearObj)
    setCompanyCustomFields(tempCustomFields)
  },[get(customeFieldsData,'company',[])])


  const handleCollectCustomeFieldValue = (event,element,name) => {
    if(element.id === "cancel"){
      setNewAttributeValue({
        state:false,
        data:{
          currentAttributeType:{},
          attrType: "",
          attrValue:""
        }
      })
      return
    }
    if(name=== "attrValue"){
      setNewAttributeValue((state)=>({
        ...state,
        data:{
          ...state.data,
          attrValue:event.target.value
        }
      }))
    }else{
      setNewAttributeValue((state)=>({
        ...state,
        data:{
          attrType:element.id,
          attrValue:"",
          currentAttributeType:element
        }
      }))
    }
  }

  const handleSetCustomFieldValue = () => {
    let customFiledId = get(newAttributeValue,"data.currentAttributeType.id")
    let customFieldPayload = {
      fieldValue:get(newAttributeValue,"data.attrValue"),
      entityId:companyId,
    }
    setValueUsingCustomFields(customFiledId,customFieldPayload).then((res)=>{
      handleGetCompanyDetails()
      setNewAttributeValue({
        state:false,
        data:{
          currentAttributeType:{},
          attrType: "",
          attrValue:""
        }
      })
    })
  }

  const handleSetCustomFieldEditable = (index, editable=false) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues]
    tempViewCustomFieldValues[index]['editable'] = editable;
    setViewCustomFieldValues(tempViewCustomFieldValues);
  }


  const handleSetViewCustomFieldValues = (event, index) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues]
    tempViewCustomFieldValues[index]['value'] = event.target.value;
    setViewCustomFieldValues(tempViewCustomFieldValues);
  }

  const handleUpdateCustomFieldData = (index) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues]
    let tempCustomFieldValue = tempViewCustomFieldValues[index];
    let customFiledId = get(tempCustomFieldValue,"id","")
    let customFieldPayload = {
      fieldValue:get(tempCustomFieldValue,"value"),
      entityId:companyId,
    }
    setValueUsingCustomFields(customFiledId,customFieldPayload).then((res)=>{
      handleGetCompanyDetails()
    })
  }

  const handleOnBlurViewCustomField = (event,index) => {
    // handleSetCustomFieldEditable(index)


  }


  return (
    <>
      <MainComponent className={style.companyprofile}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
            }}
          >
            <Box sx={{ flexGrow: 1, padding: "20px 5px" }}>
              <Grid container spacing={1}>
                <Grid item xs={3} className="comapny-details-container">
                  <Box className="comapny-detail-card comapny-card">
                    {/* <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          height: "25px",
                          width: "25px",
                          background: "#E87373",
                        }}
                      >
                        S
                      </Avatar>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        Suhail joo
                      </Typography>
                    </Box> */}

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                      }}
                    >
                      <Box
                        className="comapny-sub-cards"
                        sx={{ border: "none !important", marginBottom: "10px" }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              sx={{
                                height: "25px",
                                width: "25px",
                                background: "#E87373",
                              }}
                            >
                              S
                            </Avatar>
                            <Typography
                              sx={{ color: "gray", fontSize: "14px" }}
                            >
                              Suhail joo
                            </Typography>
                          </Box>
                          {/* <Box>
                            <EditIcon
                              height={18}
                              width={18}
                              style={{ cursor: "pointer" }}
                            />
                          </Box> */}
                        </Box>
                        {/* <Box
                          sx={{ display: "flex", flexGrow: 1, width: "100%" }}
                        >
                          <Button
                            fullWidth
                            startIcon={<SendingButton height={13} width={13} />}
                            sx={{
                              background: `linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)`,
                              color: "white",
                              borderRadius: "5px",
                              textTransform: "capitalize",
                              height: "30px",
                            }}
                            variant="text"
                          >
                            Message company
                          </Button>
                        </Box> */}
                      </Box>
                      <Box className="comapny-sub-cards">
                        <Box>
                          <Typography sx={{ fontSize: "13px", color: "gray" }}>
                            No visible details
                          </Typography>
                        </Box>

                        {showMore ? (
                          <>
                            <Box>
                              <Paper
                                component="box"
                                sx={{
                                  p: "1px 2px",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "100%",
                                  height: 30,
                                  padding: "10px",
                                  boxShadow: "none",
                                  border: `1px solid rgba(0,0,0,0.2)`,
                                }}
                              >
                                <InputBase
                                disabled
                                  name="username"
                                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                                  placeholder="Search details..."
                                  // onChange={handleCollectValue}
                                  // value={loginData.username}
                                  // onFocus={()=>setOnFormFocus(true)}
                                  // onBlur={()=>setOnFormFocus(false)}
                                  // onKeyUp={onHandleSubmit}
                                />
                              </Paper>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "2px",
                              }}
                            >
                              {companyCardDetails.map(({ key, value }) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "gray",
                                      fontWeight: "400",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {key}
                                  </Typography>

                                  {/* <Box  sx={{display:"flex", justifyContent:"flex-end"}} >
                              <Paper
                                component="box"
                                sx={{
                                  p: "1px 2px",
                                  display: "flex",
                                  alignItems: "center",
                                  width: "80%",
                                  height: 20,
                                  padding: "10px",
                                  boxShadow: "none",
                                  border: `1px solid rgba(0,0,0,0.2)`,
                                }}
                              >
                                <InputBase
                                value={value}
                                  name="username"
                                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                                  placeholder="Search details..."
                                />
                              </Paper>
                            </Box> */}
                                  <Typography
                                    sx={{
                                      color: "#222",
                                      fontWeight: "400",
                                      fontSize: "13px",
                                    }}
                                  >
                                    {value}
                                  </Typography>
                                </Box>
                              ))}

              <Divider textAlign="left" ></Divider>


                            {
                              <>
                                 {
                          viewCustomFieldValues.map(({id,value,editable, customFieldData},index)=>{

                           return ( <Box
                            sx={{
                              display: "flex",
                              gap: "2px",
                              alignItems: "center",
                              
                              // border:"1px solid green",
                              justifyContent:"space-between",
                            }}
                          >
                                                      
                            <Typography sx={{ color: "gray", fontSize: "14px",  }}>
                             {customFieldData.name} 
                            </Typography>
                            {
                              !editable ? (
                                <Typography
                                onClick={()=>handleSetCustomFieldEditable(index, true)}
                                  sx={{
                                    color: "#222",
                                    fontSize: "14px",
                                    textOverflow: " ",
                                    // border:"1px solid red"
                                  }}
                                >
                                  {`${value.substring(0, 20)}  ${
                                    value.length > 20 ? "..." : ""
                                  }`}
                                </Typography>
                              )
                              :
                              (
                                <>
                                <Box sx={{display:"flex", gap:"2px", width:"100%", justifyContent:"flex-end", alignItems:"center"}} >
                                
                              {["text", "number"].includes(
                                get(
                                  customFieldData,
                                  "fieldType",
                                  ""
                                )
                              ) ? (
                                <>
                                  <Paper
                                    component="box"
                                    sx={{
                                      p: "1px 1px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: "80%",
                                      height: 20,
                                      padding: "5px",
                                      boxShadow: "none",
                                      border: `1px solid rgba(0,0,0,0.2)`,
                                    }}
                                  >
                                    <InputBase
                                      type={get(
                                        customFieldData,
                                        "fieldType",
                                        ""
                                      )}
                                      autoFocus={true}
                                      onBlur={(event)=>{
                                        handleOnBlurViewCustomField(event,index)
                                      }}

                                      value={value}
                                      name={get(
                                        customFieldData,
                                        "name",
                                        ""
                                      )}
                                      onChange={(event)=>{
                                        handleSetViewCustomFieldValues(event,index)
                                      }}
                                      sx={{
                                        ml: 1,
                                        flex: 1,
                                        fontSize: "13px",
                                        "::placeholder": { fontSize: "10px" },
                                        WebkitAppearance: "none",
                                        "::-webkit-outer-spin-button": {
                                          WebkitAppearance: "none", // Also use camelCase here
                                          margin: 0,
                                        },
                                      }}
                                      placeholder={get(
                                        customFieldData,
                                        "placeholder",
                                        ""
                                      )}
                                    />
                                  </Paper>
                                </>
                              ) : ["select"].includes(
                                get(
                                  customFieldData,
                                  "fieldType",
                                  ""
                                )
                                ) ? (
                                <>
                                  <Select
                                  autoFocus={true}
                                    sx={{
                                      width: "45%",
                                      border: "1px solid rgba(0,0,0,0.1)",
                                      fontSize: "13px",
                                      paddingLeft: "5px",
                                      height: "23px",
                                      borderRadius: "5px",
                                      // width: "50%",
                                      width: "80%",
                                    }}
                                    onBlur={(event)=>{
                                      handleOnBlurViewCustomField(event,index)
                                    }}
                                    value={value}
                                    onChange={(event)=>{
                                      handleSetViewCustomFieldValues(event,index)
                                    }}
                                    placeholder={get(
                                      customFieldData,
                                      "placeholder",
                                      ""
                                    )}
                                    name={get(
                                      customFieldData,
                                      "name",
                                      ""
                                    )}
                                    input={
                                      <InputBase
                                        variant="standard"
                                        autoFocus={true}
                                        sx={{
                                          ml: 1,
                                          flex: 1,
                                          fontSize: "13px",
                                        }}
                                      ></InputBase>
                                    }
                                  >
                                    {get(
                                      customFieldData,
                                      "options",
                                      []
                                    ).map((ele) => (
                                      <MenuItem
                                        value={ele}
                                        sx={{ fontSize: "13px" }}
                                      >
                                        {ele}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              ) : null}
                             {

                              value ?
                               <>
                               <CheckCircleOutlineRoundedIcon
                                onClick={()=>{
                                  handleUpdateCustomFieldData(index)
                                }}
                                sx={{
                                  fontSize: "17px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              /> </>
                               :
                               null                              
                              } 
                          </Box>


                            </>                
                              )
                            }                                      
                          </Box>)
                          })
                        }
                              </>
                            }

                            </Box>
                          </>
                        ) : null}

                       



                     

                 





















                    {newAttributeValue.state ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Select
                            defaultOpen={true}
                            sx={{
                              width: "45%",
                              border: "1px solid rgba(0,0,0,0.1)",
                              fontSize: "13px",
                              paddingLeft: "5px",
                              height: "23px",
                              borderRadius: "5px",
                            }}
                            name="attrType"
                            input={
                              <InputBase
                                variant="standard"
                                autoFocus={true}
                                sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                              ></InputBase>
                            }
                          >
                            {companyCustomFields.map((ele) => {
                              const Icon = customFieldIcons[get(ele,'fieldType',"")];

                              return (
                                <MenuItem
                                  name="attrType"
                                  onClick={(event) =>
                                    handleCollectCustomeFieldValue(
                                      event,
                                      ele,
                                      "attrType"
                                    )
                                  }
                                  value={ele.id}
                                  sx={{
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  {Icon && <Icon />} {ele.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {newAttributeValue.data.attrType ? (
                            <>
                              {["text", "number"].includes(
                                get(
                                  newAttributeValue,
                                  "data.currentAttributeType.fieldType",
                                  ""
                                )
                              ) ? (
                                <>
                                  <Paper
                                    component="box"
                                    sx={{
                                      p: "1px 2px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                      height: 20,
                                      padding: "10px",
                                      boxShadow: "none",
                                      border: `1px solid rgba(0,0,0,0.2)`,
                                    }}
                                  >
                                    <InputBase
                                      type={get(
                                        newAttributeValue,
                                        "data.currentAttributeType.fieldType",
                                        ""
                                      )}

                                      value={get(
                                        newAttributeValue,
                                        "data.attrValue",
                                        ""
                                      )}
                                      name={get(
                                        newAttributeValue,
                                        "data.currentAttributeType.name",
                                        ""
                                      )}
                                      onChange={(event)=>{
                                        handleCollectCustomeFieldValue(
                                          event,
                                          {},
                                          "attrValue"
                                        )
                                      }}
                                      sx={{
                                        ml: 1,
                                        flex: 1,
                                        fontSize: "13px",
                                        "::placeholder": { fontSize: "10px" },
                                        WebkitAppearance: "none",
                                        "::-webkit-outer-spin-button": {
                                          WebkitAppearance: "none", // Also use camelCase here
                                          margin: 0,
                                        },
                                      }}
                                      placeholder={get(
                                        newAttributeValue,
                                        "data.currentAttributeType.placeholder",
                                        ""
                                      )}
                                    />
                                  </Paper>
                                </>
                              ) : ["select"].includes(
                                  get(
                                    newAttributeValue,
                                    "data.currentAttributeType.fieldType",
                                    ""
                                  )
                                ) ? (
                                <>
                                  <Select
                                    sx={{
                                      width: "45%",
                                      border: "1px solid rgba(0,0,0,0.1)",
                                      fontSize: "13px",
                                      paddingLeft: "5px",
                                      height: "23px",
                                      borderRadius: "5px",
                                    }}
                                    value={get(
                                      newAttributeValue,
                                      "data.attrValue",
                                      ""
                                    )}
                                    onChange={(event)=>{
                                      handleCollectCustomeFieldValue(
                                        event,
                                        event.target.value,
                                        "attrValue"
                                      )
                                    }}
                                    name={get(
                                      newAttributeValue,
                                      "data.currentAttributeType.name",
                                      ""
                                    )}
                                    input={
                                      <InputBase
                                        variant="standard"
                                        autoFocus={true}
                                        sx={{
                                          ml: 1,
                                          flex: 1,
                                          fontSize: "13px",
                                        }}
                                      ></InputBase>
                                    }
                                  >
                                    {get(
                                      newAttributeValue,
                                      "data.currentAttributeType.options",
                                      []
                                    ).map((ele) => (
                                      <MenuItem
                                        value={ele}
                                        sx={{ fontSize: "13px" }}
                                      >
                                        {ele}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              ) : null}
                            </>
                          ) : null}

                          {newAttributeValue.data.attrValue ? (
                            <>
                              <CheckCircleOutlineRoundedIcon
                                onClick={handleSetCustomFieldValue}
                                sx={{
                                  fontSize: "17px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          ) : null}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "2px",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "gray",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            Attributes
                          </Typography>
                          <Typography
                            onClick={handleAddAttriuteValue}
                            sx={{
                              fontSize: "14px",
                              color: "#a84acb",
                              cursor: "pointer",
                            }}
                          >
                            +Add
                          </Typography>
                        </Box>
                      </>
                    )}





























                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#A84ACB",
                              fontWeight: "bold",
                              fontSize: "13px",
                              cursor: "pointer",
                            }}
                            onClick={() => setShowMore(!showMore)}
                          >
                            {showMore ? "Less" : "See 11 more"}
                          </Typography>
                          <Typography
                            onClick={handleUpdateCompany}
                            sx={{
                              color: "#A84ACB",
                              fontWeight: "bold",
                              fontSize: "13px",
                              cursor: "pointer",
                            }}
                          >
                            {showMore ? "Update" : null}
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="comapny-sub-cards">
                        <Box>
                          <Typography sx={{ fontSize: "13px", color: "#222" }}>
                            Company segments
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              gap: "5px",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                display: "inline-block",
                                height: "15px",
                                width: "15px",
                                background: "#1BB157 ",
                                borderRadius: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              {" "}
                              <CheckIcon />{" "}
                            </span>
                            <Typography
                              sx={{
                                color: "#222",
                                fontWeight: "400",
                                fontSize: "13px",
                              }}
                            >
                              Active
                            </Typography>
                          </Box>
                        </Box>
                        <Box
                          sx={{ display: "flex", justifyContent: "flex-end" }}
                        >
                          <Typography
                            sx={{
                              color: "#A84ACB",
                              fontWeight: "bold",
                              fontSize: "13px",
                            }}
                          >
                            Edit
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="comapny-sub-cards">
                        <Box>
                          <Typography sx={{ fontSize: "13px", color: "#222" }}>
                            Company tag
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#A84ACB",
                              fontWeight: "bold",
                              fontSize: "13px",
                              cursor: "pointer",
                            }}
                          >
                            + Add
                          </Typography>
                        </Box>
                      </Box>

                      <Box className="comapny-sub-cards">
                        <Box>
                          <Typography sx={{ fontSize: "13px", color: "#222" }}>
                            Company notes
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {editableNote ? (
                            <>
                              <Box
                                sx={{
                                  height: "100%",
                                  width: "100%",
                                  overflow: "hidden",
                                }}
                              >
                                <TextareaAutosize
                                  autoFocus
                                  name="notes"
                                  value={get(
                                    comapnyDetailEditData,
                                    "data.notes",
                                    ""
                                  )}
                                  onChange={handleCollectData}
                                  onKeyDown={(event) => {
                                    if (event.key === "Enter") {
                                      event.preventDefault()
                                      if (
                                        get(
                                          comapnyDetailEditData,
                                          "data.notes",
                                          ""
                                        ).length >= 3
                                      ) {
                                        handleUpdateCompanyProfile();
                                      }
                                    }
                                  }}
                                  maxRows={15}
                                  placeholder="add your note here"
                                  minRows={8}
                                  style={{
                                    outerHeight: "100%",
                                    width: "100%",
                                    fontSize: "12px",
                                  }}
                                />
                              </Box>
                            </>
                          ) : (
                            <Typography
                              sx={{
                                fontSize: "12px",
                                color: "#222",
                                wordBreak: "break-all",
                              }}
                              onDoubleClick={handeUpdateNote}
                            >
                              {get(
                                companyDetails,
                                "notes",
                                "double click to add your note here..."
                              )}
                            </Typography>
                          )}
                        </Box>
                      </Box>

                      <Box
                        className="comapny-sub-cards"
                        sx={{ border: "none !important" }}
                      >
                        <Button
                        onClick={()=>setDialogToggle(true)}
                          variant="text"
                          sx={{
                            textTransform: "capitalize",
                            color: "#DB0629",
                            background: "#FFF0F4",
                            ":hover": { background: "#FFF0F4" },
                          }}
                        >
                          Archive company
                        </Button>
                        <Dialog
                          open={dialogToggle}
                          onClose={()=>setDialogToggle(false)}
                          aria-labelledby="alert-dialog-title"
                          aria-describedby="alert-dialog-description"
                        >
                          {/* <DialogTitle id="alert-dialog-title">
                            {"Use Google's location service?"}
                          </DialogTitle> */}
                          <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                            Are you sure want to archive the company
                            </DialogContentText>
                          </DialogContent>
                          <DialogActions>
                            <Button onClick={()=>setDialogToggle(false)}  sx={{
                                background: "#F1F1F1",
                                color: "black",
                                height: "30px",
                                textTransform: "inherit",
                                fontSize: "13px",

                            }}  >Disagree</Button>
                            <Button sx={{
                               background:
                               "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                             color: "white",
                             height: "30px",
                             textTransform: "inherit",
                             fontSize: "13px",

                            }}  onClick={handleDeleteCompany} autoFocus>
                              Agree
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={9} className="user-conversations">
                  <CompanyTabs companyPeoples={companyPeoples} comapnyTickets={comapnyTickets}/>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default CompanyProfile;
