import React from "react";
import { Box } from "@mui/material";

const MiniCircle = ({ children, style,...props }) => {
  return (
    <>
      <Box
        
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems:'center',
          height: "30px",
          width: "30px",
          filter:
            "drop-shadow(0px 5px 25px rgba(42, 139, 242, 0.07)) drop-shadow(0px 7px 25px rgba(42, 139, 242, 0.03)) drop-shadow(0px 10px 15px rgba(0, 0, 0, 0.03))",
          background: "white",
          borderRadius: "50%",
          cursor: "pointer",
          ...style,
        }}
        {...props}
      >
        {children}
      </Box>
    </>
  );
};

export default MiniCircle;
