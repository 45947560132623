import { Box } from "@mui/system";
import React from "react";
import CheckIcon from "../../../../assets/icons/CheckIcon";
import OpenEmailBoxIcon from "../../../../assets/icons/OpenEmailBoxIcon";
import { Typography } from "@mui/material";
import AnalogClockIcon from "../../../../assets/icons/AnalogClockIcon";
const TempEmailAddressComponent = ({active}) => {
  return (
    <Box sx={{ width: "100%", height: "200px", position: "relative" }}>
     {active && <CheckIcon
        color="#a84acb"
        height={20}
        width={20}
        style={{ position: "absolute", top: "10px", right: "10px" }}
      />}
      <Box sx={{display:"flex", flexDirection:"column", gap:"5px", padding:"10px"}} >
        <Box  sx={{display:"flex", justifyContent:"center", alignItems:'center'}} >
            <AnalogClockIcon color={ active ? "#a84acb" : "gray" }/>
        </Box>

        <Box  sx={{display:"flex", justifyContent:"center", alignItems:'center', marginTop:"5px"}} >
           <Typography  sx={{fontWeight:"bold", fontSize:"15px", textTransform:"initial"}} >
           Use temporary Pullseai email address
           </Typography>
        </Box>
         <Box  sx={{display:"flex", justifyContent:"center", alignItems:'center', marginTop:"5px", padding:"0px 20px"}} >
           <Typography  sx={{fontSize:"14px", textTransform:"initial"}} >
           Emails and notifications will send from the address each teammate signed up with, and appear as if they're coming from your domain e.g. teammate@pullseai.com
           </Typography>
        </Box>


      </Box>
    </Box>
  );
};

export default TempEmailAddressComponent;
