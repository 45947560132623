import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import style from "./chatboAnswerType.module.scss"
// import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
let name = process.env.REACT_APP_AUTO_NAME
const ChatbotAnswerTypeHeader = ({open}) => {
    // let name = 'ankit';
  return (
   <>
        <AccordionSummary
          className={style['chatboat-answer-type-header-main-container']}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography    className='accordianHeading' >{`How ${name } should answer`} {open ? <ExpandMoreIcon /> : <NavigateNextIcon />} </Typography>

        </AccordionSummary>
   
   </>
  )
}

export default ChatbotAnswerTypeHeader
