import React, { useContext, useState } from "react";
import { Box, Button, Chip, InputBase, MenuItem, Paper, Select, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import FileIcon from "../../../../../assets/icons/FileIcon";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { MainContext } from "../../../../../context/MainProvider";
import { uploadChatbotDocsFile } from "../../../../../services/Apis";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 35,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(18px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: "#8F1EB3",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const UseSnippetSourceModal = () => {
  const [open, setOpen] = React.useState(true);
  const { handleCloseModal } = useContext(MainContext)
  const [uploadLoading,setUploadLoading] = useState(false)
  const [globalError, setGlobalError] = useState(false)
  const [snippetContent,setSnippetContent] = useState({
    title:"",
    content:"",
  })

  const handleCollectData = (event) => {
    let {name, value} = event.target
    setSnippetContent((state)=>({
      ...state,
      [name]:value
    }))
  }

  


  const handleSubmitData = () => {
    if(!(snippetContent.title.length > 3 && snippetContent.content.length > 5))
    {
      setGlobalError(true)
      return 
    }
    let data = {
      'title':snippetContent.title,
      'content':snippetContent.content,
      'doc_type':'content'
      }
      setUploadLoading(true)
      uploadChatbotDocsFile(data,'json').then(res=>{
        setUploadLoading(false)
        handleCloseModal()
      })

  }

  const titleError = globalError  && !(snippetContent.title.length > 3)
  const contentError = globalError  && !(snippetContent.content.length > 5)
  return (
    <>
      <Box  sx={{padding:"20px 40px"}} >
      <Box sx={{ width: "460px", display: "inline-block", height: "410px" }}>
        <Box>
          <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
            {" "}
            Use Snippets
          </Typography>
        </Box>

        <Box sx={{ marginTop: "50px", width: "100%" }}>
          <Paper
            component="div"
            sx={{
              p: "1px 2px",
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: 35,
              padding: "10px",
              boxShadow: "none",
              border: `1px solid ${titleError ? "red" : "rgba(0,0,0,0.2)"}`,
            }}
          >
            <InputBase
              value={snippetContent.title}
              name='title'
              placeholder="Enter a title"
              sx={{ ml: 1, flex: 1, fontSize: "14px" }}
              onChange={handleCollectData}
            />
          </Paper>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
          <Box  sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "flex-start",
            marginTop: "20px",
            alignItems: "center",
          }}>
          <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
            State
          </Typography>
          <AntSwitch
            defaultChecked
            inputProps={{ "aria-label": "ant design" }}
          />
          <Chip
            sx={{
              background: "#D7EFDC",
              color: "#0F7134",
              borderRadius: "5px",
              height: "22px",
            }}
            label="Used by AI Chatbot"
          />
          </Box>
          <Box>
          <Select
              defaultValue=""
              placeholder="text"
              sx={{
                
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                borderRadius:"5px",
                width: "100%",
                height: 40,
                padding: "10px",
                boxShadow: "none",
                border: "1px solid rgba(0,0,0,0.2)",
              }}
              name="companyId"
              value="123"
              input={
                <InputBase
                  placeholder="company"
                  variant="standard"
                  autoFocus={true}
                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                />
              }
            >
              <MenuItem value="124" disabled>
                <em>Company</em>
              </MenuItem>
            </Select>
          </Box>
          
        </Box>
        <Box
          sx={{
            display: "flex",
            marginTop: "20px",
            alignItems: "center",
            maxHeight: "300px",
          }}
        >
          <TextareaAutosize
            aria-label="empty textarea"
            maxRows={10}
            name='content'
            value={snippetContent.content}
            minRows={15}
            placeholder="Start writing your pullse bot content..."
            style={{
              border: "none",
              outline: "none",
              width: "100%",
              resize: "none",
              fontFamily:'sans-serif',
              border:`1px solid ${contentError ? "red" : "transparent"}` 
            }}
            onChange={handleCollectData}
          />
        </Box>

        <Box>
          <Box sx={{ width: "100%" }}>
            <Collapse in={open}>
              <Alert
              icon={<InfoOutlinedIcon sx={{color:'rgba(0,0,0,0.7)'}} />}
              sx={{color:'black', background:'#F5F5F5'}}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                // sx={{ mb: 2 }}
              >
               Tip: Snippets are exclusive to AI Chatbot and not publicly available to your customers. <a href="">Learn more.</a>
              </Alert>
            </Collapse>
            
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "30px",
          }}
        >
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
            <Button
              sx={{
                background: "#DB0629",
                color: "white",
                height: "30px",
                textTransform: "inherit",
                fontSize: "13px",
                ":hover": { background: "#DB0629" },
              }}
            >
              Delete
            </Button>
            <Button
            onClick={handleSubmitData}

              sx={{
                background:
                  "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                color: "white",
                height: "30px",
                textTransform: "inherit",
                fontSize: "13px",
              }}
            >
            {uploadLoading? " Loading..." :  "Save and close"}
            </Button>
          </Box>
        </Box>
      </Box>
      </Box>
    </>
  );
};

export default UseSnippetSourceModal;
