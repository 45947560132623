import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import EmailAddressComponent from './EmailAddressComponent';
import TempEmailAddressComponent from './TempEmailAddressComponent';
import { MainContext } from '../../../../context/MainProvider';
import SwitchTabPopupAlert from '../switch-alert-popup/SwitchTabPopupAlert';
const alignmentArr = [
  'recomanded',
  'temporary'
]

export default function SwitchTabComponent() {
  const { handleOpenModal } = React.useContext(MainContext)
  const [alignment, setAlignment] = React.useState(alignmentArr[0]);

  const handleAlerEmailtSwitchTab = (changeEmailTab=()=>{}) => {
    handleOpenModal({
      Component:<SwitchTabPopupAlert handleChangeTab={changeEmailTab} />,
      state:true,
    })
  }

  const handleChange = (
    event,
    newAlignment,
  ) => {
    handleAlerEmailtSwitchTab(()=>setAlignment(newAlignment))
  };


  
  return (
    <ToggleButtonGroup
    sx={{width:"100%"}}
      color="secondary"
      value={alignment}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
    >
      <ToggleButton value={alignmentArr[0]}   sx={{width:"50%", padding:"0"}}>
        <EmailAddressComponent active={alignment == alignmentArr[0] } />
      </ToggleButton>
      <ToggleButton value={alignmentArr[1]} sx={{width:"50%", padding:"0"}}>
        
      <TempEmailAddressComponent active={alignment == alignmentArr[1] } />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}