import * as React from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function FormTabs({ handleCurrentTab=()=> {}, onClick=()=>{}, currentValue="authenticated"}) {
  const handleChange = (event, newAlignment) => {
    if(!newAlignment) return;
    handleCurrentTab(newAlignment)
    onClick()
  };

  return (
    <ToggleButtonGroup
      color="secondary"
      value={currentValue}
      exclusive
      onChange={handleChange}
      aria-label="Platform"
      sx={{
        height:"25px",
        border:"none"
      }}
    >
      <ToggleButton value="authenticated"  sx={{ textTransform:"inherit"}}>Authenticated  user</ToggleButton>
      <ToggleButton value="unAuthenticated"  sx={{ textTransform:"inherit"}}> Unauthenticated user</ToggleButton>
    </ToggleButtonGroup>
  );
}