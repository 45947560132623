import React, { createContext,  useContext,  useEffect,  useState } from 'react'
import CommonModal from './CommonModal'
import useStateCallback from '../utils/hooks/useStateCallback'
import { PullseTickets, agentProfile, agentRoles, allMacrosResponseApi, createChatbotProfile, createMacros, deleteMacro, filterAllTickets, getAllAgents, getAllComapany, getAllTeams, getChatbotProfile, getCustomeFields, getProfile, getTags, getTicketType, getTopics, sentimentSettings, updateChatbotProfile, updateMacro, workspaceDetailApi } from '../services/Apis'
import { get } from 'lodash'
import { SidebarContext } from './SidebarProvider'
import { getWorkspaceId } from '../utils/helpers/helpers'

export const MainContext = createContext({})

const MainProvider = ({children}) => {
  const { sidebarState } = useContext(SidebarContext)
  
  
  
  const [sideLeftPane, setSideLeftPanel] = React.useState(200);
  const [workspaceId, setWorkspaceId] = useState(getWorkspaceId())
  const [tickets, setAllTickets] = useState({})
  const [filterTickets, setFilterTickets] = useState([])
  const [filterLoadingData, setFilterLoadingData] = useState(false)
  const [currentUser, setCurrentUser] = useState({});
  const [ticketTypes,setTicketTypes] = useState([]);
 
  const [modalState,setModalState] = useStateCallback({
    state:false,
    Component:<></>,
  })
  const [allTeams, setAllTeams] = useState([])
  const [allTags, setAllTags] = useState([])
  const [allAgents, setAllAgents]= useState([]);
  const [allTopics, setAllTopics] = useState([]);
  const [ chatbotProfiles, setChatbotProfiles] = useState([]);
  const [profile, setProfile] = useState({})
  const [customeFieldsData, setCustomFieldsData] = useState({
    customer:[],
    company:[],
    ticket:[]
  })

  const [allMacros, setAllMacros] = useState([])
  const [rolePremissions, setRolePermissions] = useState([])
  const [allSentimentSettings, setAllSentimentSettings] = useState([]);
  const [compinesData, setCompinesData] = useState([]);
  const [currentWorkspaceDetail, setCurrentWorkspaceDetail] = useState({})


  const handleGetWorkspaceDetail = (workId) => {
    workspaceDetailApi(workId).then(res=>{
      let response = get(res,'data.data',{});
      setCurrentWorkspaceDetail(response)
    })
  }

  useEffect(()=>{
    
    if(workspaceId.length){
      handleGetWorkspaceDetail(workspaceId)
    }
  },[workspaceId])


  const handleGetAllCompines = (obj={}) => {
    return new Promise((resolve, reject)=>{
      getAllComapany(obj).then(res=>{
        let data = get(res,'data.data.docs',[])
        setCompinesData(data)
        resolve(res)  
    }).catch(err=>{
      reject(err)
    })
    })
  }

  const handleGetSentimentSettings = () => {
    sentimentSettings().then(res=>{
      let response = get(res,'data.data',{});
      setAllSentimentSettings(response)
    })
  }

  const handleGetRolePermissions = () => {
    agentRoles().then(res=>{
      let response = get(res,'data.data.docs',[]);
      setRolePermissions(response)
    })
  }

  const handleGetAllMacros = () => {
    allMacrosResponseApi().then(res=>{
      let response = get(res,'data.data.docs',[]);
      setAllMacros(response)
    })
  }

  const handleCreateMacro = (payload) => {
   return new Promise((resolve, reject)=>{
    createMacros(payload).then(res=>{
      handleGetAllMacros()
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
   }) 
  }

  const handleUpdateMacro = (id,payload) => {
   return new Promise((resolve, reject)=>{
    updateMacro(id,payload).then(res=>{
      handleGetAllMacros()
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
   }) 
  }

  const handleDeleteMacro = (id) => {
   return new Promise((resolve, reject)=>{
    deleteMacro(id).then(res=>{
      handleGetAllMacros()
      resolve(res)
    }).catch(err=>{
      reject(err)
    })
   })
  }

  const handleOpenModal = ({state=true, Component=<></>}) => {
    setModalState({
      state, Component
    })
  }
  const handleCloseModal = () => {
    setModalState({
      state:false,
      Component:<></>,
    })
  }

  const handleGetAgentProfile = () => {
    agentProfile().then(res=>{
      let response = get(res,'data.data',{})
      setWorkspaceId(get(response,`defaultWorkspaceId`,""))

      setProfile(response)
    })
  }
  const handleFetchTicketForInbox = (...keys) => {
    filterAllTickets(...keys).then(res=>{
      let data = get(res,'data.data',{});
      setAllTickets(data)
    })
  }

  const handleFetchFilterTickets = (...keys) => {
    return new Promise((resolve, reject) => {
      filterAllTickets(...keys).then(res=>{
        let data = get(res,'data.data.docs',[]);
        setFilterTickets(data);
        setFilterLoadingData(false)
        resolve(get(data,'[0]',{}))
      })
    })
  }

  const handleGetCustomFields = () => {
    let temoData = {
      ticket:[],
      customer:[],
      company:[]
    }
    Promise.all([
      getCustomeFields({type:'entity_type',data:'ticket'}),
      getCustomeFields({type:'entity_type',data:'customer'}),
      getCustomeFields({type:'entity_type',data:'company'})
    ]).then(res=>{
      let tempResponse = res;
      temoData['ticket'] = get(tempResponse[0],'data.data.docs',[])
      temoData['customer'] = get(tempResponse[1],'data.data.docs',[])
      temoData['company'] = get(tempResponse[2],'data.data.docs',[])
      setCustomFieldsData(temoData)
    })
    // getCustomeFields().then(res=>{
    //   let resp = get(res,'data.data.docs',[]);
    //   setCustomFieldsData(resp);
    // })
  }

  

  const handleToogleLeftSPanel = () => {
    sideLeftPane ? setSideLeftPanel(0) : setSideLeftPanel(200);
  }

  const handleFetchTickets = (...obj) => {
    return new Promise((resolve, reject) => {
      PullseTickets(...obj).then((res)=>{
        // 
        let data = get(res,'data.data',{})
        setAllTickets(data)
        resolve(get(data,'docs[0]',{}))
      })
    })
  }
  const handleGetAllTickets = () => {
    // handleFetchTickets()
  }
    const handleFetchAgents = () => {
      getAllAgents().then(res=>{
        let agentsList = get(res,'data.data.docs',[])
        setAllAgents(agentsList)
      })
    }

    const handleFetchAllTeams = () => {
      getAllTeams().then(res=>{
        let teamData = get(res,'data.data.docs',[])
         setAllTeams(teamData)
      })
    }

    const handleFetchTags = () => {
      getTags().then(res=>{
        let tempTags = get(res,'data.data.docs',[])
        setAllTags(tempTags)

      })
    }

    const handleFetchTopics = () => {
      getTopics().then(res=>{
        let tempTopics = get(res,'data.data.docs',[])
        setAllTopics(tempTopics)
      })
    }

    useEffect(()=>{
      // navigate({state:null})
    },[]);

  useEffect(()=>{
    if(Object.keys(profile).length){
      handleFetchAgents();
      handleFetchAllTeams();
      handleFetchTags();
      handleFetchTopics();
      handleFetchUserProfile();
      handleGetTicketTypes();
      handleGetCustomFields();
   
      handleGetAllMacros();
      handleGetRolePermissions();
      handleGetSentimentSettings();
      handleGetAllCompines();
      handleGetAllTickets();
    }
    },[profile])

    useEffect(()=>{
      handleGetAgentProfile();
    },[])

  const handleFetchUserProfile = () => {
    getProfile().then(res=>{
      let data = get(res,'data.data',{})
      setCurrentUser(data);

    })
  }

  const handleGetTicketTypes = () => {
    getTicketType().then(res=>{
      let tempTicketTypes = get(res,'data.data.docs',[])
      setTicketTypes(tempTicketTypes)
    })
  }

  const handleGetChatbotProfile = () => {
    getChatbotProfile().then(res=>{
      let chatBotProfiles = get(res,'data.data.docs',[])
      setChatbotProfiles(chatBotProfiles)
    })
  }
  const handleCreateChatbotProfile = (data) => {
        return new Promise((resolve,reject)=>{
          createChatbotProfile(data).then(res=>{
            resolve(true);
            handleGetChatbotProfile()
          }).catch(()=>{
            reject(true)
          })
        }) 
  }

  const handleUpdateChatbotProfile = (id,data) => {
    return new Promise((resolve,reject)=>{
      updateChatbotProfile(id,data).then(res=>{
        resolve(true);
        handleGetChatbotProfile()
      }).catch(()=>{
        reject(true)
      })
    }) 
}



  useEffect(()=>{
    if(get(sidebarState,'parent','')=='automation'){
      handleGetChatbotProfile()
    }
  },[sidebarState])
  return (
    <MainContext.Provider
    value={{
      sideLeftPane,
      setSideLeftPanel,
      handleToogleLeftSPanel,
      handleOpenModal,
      handleCloseModal,
      tickets, 
      setAllTickets,
      handleFetchTickets,
      workspaceId, setWorkspaceId,
      handleFetchFilterTickets,
      filterTickets,
      filterLoadingData,
      handleFetchTicketForInbox,
      allAgents,
      allTeams,
      allTags,
      allTopics,
      handleFetchUserProfile,
      currentUser,
      ticketTypes,
      handleCreateChatbotProfile,
      handleUpdateChatbotProfile,
      chatbotProfiles,
      customeFieldsData,
      profile, 
      handleGetAgentProfile,
      handleCreateMacro,
      handleUpdateMacro,
      handleDeleteMacro,
      allMacros,
      rolePremissions,
      handleFetchAgents,
      handleGetSentimentSettings,
      allSentimentSettings,
      compinesData,
      handleGetAllCompines,
      currentWorkspaceDetail
        }}
    >
      <CommonModal  {...modalState} handleCloseModal={handleCloseModal} />
     
      {children}
    </MainContext.Provider>
  )
}

export default MainProvider
