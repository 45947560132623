import {
  Box,
  InputBase,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import AntSwitch from "../commonComponent/switch/AntiSwitch";
import ListIcon from "../../assets/icons/ListIcon";
import AlphabetIcon from "../../assets/icons/AlphabetIcon";
import NumberIcon from "../../assets/icons/NumberIcon";
import TrueFalseIcon from "../../assets/icons/TrueFalseIcon";
import CalenderIcon from "../../assets/icons/CalenderIcon";
import DecimalIcon from "../../assets/icons/DecimalIcon";

import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { MainContext } from "../../context/MainProvider";
import SwitchIcon from "../../assets/searchIcons/SwitchIcon";
import useStateCallback from "../../utils/hooks/useStateCallback";
import { setValueUsingCustomFields } from "../../services/Apis";
import PullseAntiSwitch from "../commonComponent/switch/PullseAntiSwitch";
let customFieldIcons = {
  text: AlphabetIcon,
  number: NumberIcon,
  Decimal_Number: DecimalIcon,
  radio: TrueFalseIcon,
  date: CalenderIcon,
  select: ListIcon,
};
const CustomeAttributeComponent = ({
  handleGetComponentDetails = () => {},
  componentType="ticket",
  componentEntityId="",
  componentDetails={},
  displayIcon=false
}) => {
  let { customeFieldsData } = useContext(MainContext);
  const [viewCustomFieldValues, setViewCustomFieldValues] = useState([]);
  const [customeFieldState, setCustomFieldState] = useState([]);
  const [profileDetailEditData, setProfileDetailEditData] = useStateCallback({
    state: false,
    data: {},
  });


  const handleSetCustomeFieldsValue = () => {
    let tempCustomeFields = {...get(componentDetails,'customFields',{})};
    let tempViewCustomFieldValues = [];
    Object.keys(tempCustomeFields).forEach((eleKey)=>{
    let customFieldData  = customeFieldState.find(ele=>ele.id === eleKey);
    if(customFieldData){
      tempViewCustomFieldValues.push({
        customFieldData,
        value:tempCustomeFields[eleKey],
        id:eleKey,
        editable:false
      })
        }      
    })
    setViewCustomFieldValues(tempViewCustomFieldValues)
  }


  useEffect(()=>{
    handleSetCustomeFieldsValue()
  },[customeFieldState.length,componentDetails])

  const handleUpdateCustomFieldData = (entity={},index) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues]
    let tempCustomFieldValue = tempViewCustomFieldValues[index];
    let customFiledId = get(tempCustomFieldValue,"id","")
    let customFieldPayload = {
      fieldValue: entity.hasOwnProperty('value') ? entity.value : get(tempCustomFieldValue,"value"),
      entityId:componentEntityId,
    }
    setValueUsingCustomFields(customFiledId,customFieldPayload).then((res)=>{
        handleGetComponentDetails()
    })
  }


  useEffect(()=>{
    let tempCustomFields = [...get(customeFieldsData,componentType,[])];
    let clearObj = {
      id:"cancel",
      name:"Cancel"
    };  
    tempCustomFields.unshift(clearObj)
    setCustomFieldState([...tempCustomFields])
  },[customeFieldsData])

  const [newAttributeValue, setNewAttributeValue] = useState({
    state: false,
    data: {
      currentAttributeType: {},
      attrType: "",
      attrValue: "",
    },
  });

  const handleCollectCustomeFieldValue = (event, element, name) => {
    if (element.id === "cancel") {
      setNewAttributeValue({
        state: false,
        data: {
          currentAttributeType: {},
          attrType: "",
          attrValue: "",
        },
      });
      return;
    }
    if (name === "attrValue") {
      setNewAttributeValue((state) => ({
        ...state,
        data: {
          ...state.data,
          attrValue: event.target.value,
        },
      }));
    } else {
      setNewAttributeValue((state) => ({
        ...state,
        data: {
          attrType: element.id,
          attrValue: "",
          currentAttributeType: element,
        },
      }));
    }
  };

  const handleSetViewCustomFieldValues = (event, index) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues];
    tempViewCustomFieldValues[index]["value"] = event.target.value;
    setViewCustomFieldValues(tempViewCustomFieldValues);
  };

  const handleOnBlurViewCustomField = (event, index) => {
    // handleSetCustomFieldEditable(index)
  };

  const handleSetCustomFieldEditable = (index, editable = false) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues];
    tempViewCustomFieldValues[index]["editable"] = editable;
    setViewCustomFieldValues(tempViewCustomFieldValues);
  };

  const handleSetCustomFieldValue = (entity={}) => {
    let customFiledId = get(newAttributeValue, "data.currentAttributeType.id","");
    let customFieldPayload = {
      fieldValue: entity.hasOwnProperty('value') ? entity.value : get(newAttributeValue, "data.attrValue",""),
      entityId: componentEntityId,
    };

    setValueUsingCustomFields(customFiledId, customFieldPayload).then((res) => {
        handleGetComponentDetails();
      setNewAttributeValue({
        state: false,
        data: {
          currentAttributeType: {},
          attrType: "",
          attrValue: "",
        },
      });
    });
  };

  const handleAddAttriuteValue = () => {
    setNewAttributeValue((state) => ({
      data: {
        currentAttributeType: {},
        attrType: "",
        attrValue: "",
      },
      state: true,
    }));
  };

  const handleUpdateRadioData = (event) => {
    console.log(event.target.checked,"customFields")
    handleSetCustomFieldValue({value:event.target.checked})
  }



  const handleUpdateRadioField = (event,index) => {
    let value = event.target.checked;
    handleUpdateCustomFieldData({value},index)
  }

  return (
    <Box>
      {viewCustomFieldValues.map(
        ({ id, value, editable, customFieldData }, index) => {
          return (
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                // width:'100%',
                justifyContent:'space-between'
              }}
            >
              <Box sx={{display:"flex", gap:"5px", alignItems:'center', minWidth:"80px"}} >
              {displayIcon && <SwitchIcon height="14" width="14" />}
              <Typography sx={{ color: "gray", fontSize: "15px" }}>
                {customFieldData.name}
              </Typography>
              </Box>
              {!editable ? (
                <>
                {typeof value == "boolean" ? (
                    <>
                        <PullseAntiSwitch  onClick={(event) => handleUpdateRadioField(event,index)} checked={value}  value={value} />
                    </>
                        )
                   
                    : (
                    <>

<Typography
                  onClick={() => handleSetCustomFieldEditable(index, true)}
                  sx={{
                    color: "#222",
                    fontSize: "14px",
                    textOverflow: " ",
                  }}
                >
                  {`${value.substring(0, 20)}  ${
                    value.length > 20 ? "..." : ""
                  }`}

                </Typography>
                    </>
                    )
                }

              
                </>
              ) : (
                <>
                  {["text", "number"].includes(
                    get(customFieldData, "fieldType", "")
                  ) ? (
                    <>
                      <Paper
                        component="box"
                        sx={{
                          p: "1px 2px",
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          height: 20,
                          padding: "10px",
                          boxShadow: "none",
                          border: `1px solid rgba(0,0,0,0.2)`,
                        }}
                      >
                        <InputBase
                          type={get(customFieldData, "fieldType", "")}
                          autoFocus={true}
                          onBlur={(event) => {
                            handleOnBlurViewCustomField(event, index);
                          }}
                          value={value}
                          name={get(customFieldData, "name", "")}
                          onChange={(event) => {
                            handleSetViewCustomFieldValues(event, index);
                          }}
                          sx={{
                            ml: 1,
                            flex: 1,
                            fontSize: "13px",
                            "::placeholder": { fontSize: "10px" },
                            WebkitAppearance: "none",
                            "::-webkit-outer-spin-button": {
                              WebkitAppearance: "none", // Also use camelCase here
                              margin: 0,
                            },
                          }}
                          placeholder={get(customFieldData, "placeholder", "")}
                        />
                      </Paper>
                    </>
                  ) : ["select"].includes(
                      get(customFieldData, "fieldType", "")
                    ) ? (
                    <>
                      <Select
                        autoFocus={true}
                        sx={{
                          width: "45%",
                          border: "1px solid rgba(0,0,0,0.1)",
                          fontSize: "13px",
                          paddingLeft: "5px",
                          height: "23px",
                          borderRadius: "5px",
                        }}
                        onBlur={(event) => {
                          handleOnBlurViewCustomField(event, index);
                        }}
                        value={value}
                        onChange={(event) => {
                          handleSetViewCustomFieldValues(event, index);
                        }}
                        placeholder={get(customFieldData, "placeholder", "")}
                        name={get(customFieldData, "name", "")}
                        input={
                          <InputBase
                            variant="standard"
                            autoFocus={true}
                            sx={{
                              ml: 1,
                              flex: 1,
                              fontSize: "13px",
                            }}
                          ></InputBase>
                        }
                      >
                        {get(customFieldData, "options", []).map((ele) => (
                          <MenuItem value={ele} sx={{ fontSize: "13px" }}>
                            {ele}
                          </MenuItem>
                        ))}
                      </Select>
                    </>
                  ) : null}
                  {value ? (
                    <>
                      <CheckCircleOutlineRoundedIcon
                        onClick={() => {
                          handleUpdateCustomFieldData(index);
                        }}
                        sx={{
                          fontSize: "17px",
                          color: "green",
                          cursor: "pointer",
                        }}
                      />{" "}
                    </>
                  ) : null}
                </>
              )}
            </Box>
          );
        }
      )}

      
{newAttributeValue.state ? (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <Select
              defaultOpen={true}
              sx={{
                width: "45%",
                border: "1px solid rgba(0,0,0,0.1)",
                fontSize: "13px",
                paddingLeft: "5px",
                height: "23px",
                borderRadius: "5px",
                ".MuiSelect-select":{
                    "> span":{
                        display:'none'
                    }
                }

              }}
              className="customeFiledDisplay-Select"
              name="attrType"
              input={
                <InputBase
                className="customFiledisplay"
                  variant="standard"
                  autoFocus={true}
                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                ></InputBase>
              }
            >
              {customeFieldState.map((ele) => {
                const Icon = customFieldIcons[ele.fieldType];

                return (
                  <MenuItem
                    name="attrType"
                    onClick={(event) =>
                      handleCollectCustomeFieldValue(event, ele, "attrType")
                    }
                    value={ele.id}
                    sx={{
                      fontSize: "13px",
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    {ele.id == "cancel"?
                    
                <>
                <em  style={{fontWeight:'bold', color:'red'}} >
                    { ele.name}
                </em>
                
                </>
                :
                ele.name
                }
                    {/* {Icon && <Icon className="custom-component-svg" />}  */}
                    {}
                  </MenuItem>
                );
              })}
            </Select>
            {newAttributeValue.data.attrType ? (
              <>
                {["text", "number"].includes(
                  get(
                    newAttributeValue,
                    "data.currentAttributeType.fieldType",
                    ""
                  )
                ) ? (
                  <>
                    <Paper
                      component="box"
                      sx={{
                        p: "1px 2px",
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        height: 20,
                        padding: "10px",
                        boxShadow: "none",
                        border: `1px solid rgba(0,0,0,0.2)`,
                      }}
                    >
                      <InputBase
                        type={get(
                          newAttributeValue,
                          "data.currentAttributeType.fieldType",
                          ""
                        )}
                        value={get(newAttributeValue, "data.attrValue", "")}
                        name={get(
                          newAttributeValue,
                          "data.currentAttributeType.name",
                          ""
                        )}
                        onChange={(event) => {
                          handleCollectCustomeFieldValue(
                            event,
                            {},
                            "attrValue"
                          );
                        }}
                        sx={{
                          ml: 1,
                          flex: 1,
                          fontSize: "13px",
                          "::placeholder": { fontSize: "10px" },
                          WebkitAppearance: "none",
                          "::-webkit-outer-spin-button": {
                            WebkitAppearance: "none", // Also use camelCase here
                            margin: 0,
                          },
                        }}
                        placeholder={get(
                          newAttributeValue,
                          "data.currentAttributeType.placeholder",
                          ""
                        )}
                      />
                    </Paper>
                  </>
                ) : ["select"].includes(
                    get(
                      newAttributeValue,
                      "data.currentAttributeType.fieldType",
                      ""
                    )
                  ) ? (
                  <>
                    <Select
                      sx={{
                        width: "45%",
                        border: "1px solid rgba(0,0,0,0.1)",
                        fontSize: "13px",
                        paddingLeft: "5px",
                        height: "23px",
                        borderRadius: "5px",
                      }}
                      value={get(newAttributeValue, "data.attrValue", "")}
                      onChange={(event) => {
                        handleCollectCustomeFieldValue(
                          event,
                          event.target.value,
                          "attrValue"
                        );
                      }}
                      name={get(
                        newAttributeValue,
                        "data.currentAttributeType.name",
                        ""
                      )}
                      input={
                        <InputBase
                          variant="standard"
                          autoFocus={true}
                          sx={{
                            ml: 1,
                            flex: 1,
                            fontSize: "13px",
                          }}
                        ></InputBase>
                      }
                    >
                      {get(
                        newAttributeValue,
                        "data.currentAttributeType.options",
                        []
                      ).map((ele) => (
                        <MenuItem value={ele} sx={{ fontSize: "13px" }}>
                          {ele}
                        </MenuItem>
                      ))}
                    </Select>
                  </>
                ) :  ["radio"].includes(get(
                    newAttributeValue,
                    "data.currentAttributeType.fieldType",
                    ""
                  )) ? 
                   <Box sx={{width:"100%", display:'flex', justifyContent:'flex-end'}}>
                     <PullseAntiSwitch  onChange={handleUpdateRadioData} />
                   </Box>
                : null}
              </>
            ) :  null}

            {newAttributeValue.data.attrValue ? (
              <>
                <CheckCircleOutlineRoundedIcon
                  onClick={handleSetCustomFieldValue}
                  sx={{
                    fontSize: "17px",
                    color: "green",
                    cursor: "pointer",
                  }}
                />
              </>
            ) : null}
          </Box>
        </>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              gap: "2px",
              alignItems: "flex-start",
              flexDirection: "column",
            }}
          >
            <Typography
              onClick={handleAddAttriuteValue}
              sx={{
                fontSize: "14px",
                color: "#a84acb",
                cursor: "pointer",
              }}
            >
              +Add
            </Typography>
          </Box>
        </>
      )}
    </Box>
  );
};

export default CustomeAttributeComponent;
