import React, { useContext, useEffect, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Chip,
  Input,
  InputAdornment,
  InputBase,
  ListItemText,
  Paper,
} from "@mui/material";
import FileIcon from "../../../../assets/icons/FileIcon";
// import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import HelpOutlinedIcon from "@mui/icons-material/HelpOutlined";
import { LightTooltip } from "../../../commonComponent/tooltip/LightTooltip";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { Image } from "@mui/icons-material";
import chatbotImagelink from "../../../../assets/chatbot-image-link.png"
import { get } from "lodash";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const SetBrandColorComponent = ({ data, handleUpdateChatbotSetting }) => {
  const [brandSettingsState, setbrandSettinsState] = useState({
    actionColor: "",
    backgroundColor: "",
  });

  useEffect(()=>{
    setbrandSettinsState(data)
    
  },[data])
   
  const handleCollectColor = (event) => {
    let { name, value } = event.target;
    setbrandSettinsState((state)=>({
      ...state,
      [name]:value
    }))
  }

  const handleResetState = () => {
    setbrandSettinsState(data)
  }

  const handleSaveData = () => {
    handleUpdateChatbotSetting({
      brandSettings:{
        actionColor:brandSettingsState.actionColor,
        backgroundColor:brandSettingsState.backgroundColor,
      }
    })
  }
  return (
    <>
      <AccordionDetails>
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            padding: "20px 50px",
          }}
        >
          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                maxWidth: "60%",
              }}
          >
            <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
            Action color
            </Typography>
            <Typography sx={{ fontSize: "12px", }}>
            Used in buttons, links and more to highlight and emphasize.
            </Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "30%",
                height: 30,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${get(brandSettingsState, "actionColor","")} `,
              }}
            >
              <InputBase
                onChange={handleCollectColor}
                name="actionColor"
                value={get(brandSettingsState, "actionColor","")}
                endAdornment={
                  <InputAdornment position="end">
                    <Input name="actionColor" value={get(brandSettingsState, "actionColor","")}   onChange={handleCollectColor} sx={{ width:"30px", padding:"0 !important","input":{
                      
                      padding:"0px",
                      "&::before":{
                        all:"unset !important"
                      },
                      "&::after":{
                        all:"unset !important"
                      }
                    }}}  type="color" >
                    </Input>
                  </InputAdornment>
                }
                className="input-box"
                type="text"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
              />
            </Paper>
        
          </Box>


          <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "7px",
                maxWidth: "60%",
              }}
          >
            <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
            Background color
            </Typography>
            <Typography sx={{ fontSize: "12px", }}>
            Used in the header across all Spaces
            </Typography>
            <Paper
              component="box"
              sx={{
                p: "1px 2px",
                display: "flex",
                alignItems: "center",
                width: "30%",
                height: 30,
                padding: "10px",
                boxShadow: "none",
                border: `1px solid ${get(brandSettingsState,'backgroundColor',"")} `,
              }}
            >
              <InputBase
                name="backgroundColor"
                onChange={handleCollectColor}
                value={get(brandSettingsState,'backgroundColor',"")}
                endAdornment={
                  <InputAdornment position="end">
                    <Input   name="backgroundColor"  value={get(brandSettingsState,'backgroundColor',"")} onChange={handleCollectColor} sx={{ width:"30px", padding:"0 !important","input":{
                      padding:"0px",
                      "&::before":{
                        all:"unset !important"
                      },
                      "&::after":{
                        all:"unset !important"
                      }
                    }}}  type="color" >
                    </Input>
                  </InputAdornment>
                }
                className="input-box"
                type="email"
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
              />
            </Paper>
        
          </Box>




          <Box
            sx={{
              display: "flex",
              gap: "10px",
              maxWidth: "40%",
            }}
          >
            <Button onClick={handleResetState} sx={{ 
              
              
              
              background: "#f1f1fb",
              color: "#707c97",
              height: "26px",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              border:'none',
              ":hover":{borderColor: "none", }}}  variant="outlined" >   Reset</Button>
            <Button
              onClick={handleSaveData}
             sx={{
              background: "#f1f1fb",
              color: "#707c97",
              height: "26px",
              boxShadow: "none",
              fontSize: "12px",
              textTransform: "capitalize",
              padding: "3px 10px",
              borderColor:'#a84acb',
             color: "#a84acb",":hover":{borderColor: "#a84acb", }, textTransform:"inherit" }} variant="outlined">Save</Button>
 

          </Box>
        </Box>
      </AccordionDetails>
    </>
  );
};

export default SetBrandColorComponent;
