import React from "react";

const PlusBoxIcon = ({height=18, width=18, color="#313131",...props}) => {
  return (
    <>
      <svg
         {...props}
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          d="M11.7001 9.00038H9.0001M9.0001 9.00038H6.3001M9.0001 9.00038V11.7004M9.0001 9.00038L9.0001 6.30038M16.1998 4.50078L16.1998 13.5009C16.1998 14.992 14.991 16.2009 13.4998 16.2009H4.4998C3.00864 16.2009 1.7998 14.992 1.7998 13.5009V4.50078C1.7998 3.00961 3.00864 1.80078 4.4998 1.80078H13.4998C14.991 1.80078 16.1998 3.00961 16.1998 4.50078Z"
          stroke={color}
          stroke-width="1.5"
          stroke-linecap="round"
        />
      </svg>
    </>
  );
};

export default PlusBoxIcon;
