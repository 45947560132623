import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Paper,
  Select,
  StepContent,
  Typography,
} from "@mui/material";
import CannedResponseEditor from "../../../editors/cannedResponseEditor/CannedResponseEditor";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { MainContext } from "../../../../context/MainProvider";
import LoadingButton from '@mui/lab/LoadingButton';


const emailSettingFields = [
  {
  name:"Name",
  key:"name",
  fieldType:"text",
  placeholder:"Enter name"
},
{
  name:"Email",
  key:"email",
  fieldType:"text",
  placeholder:"Enter email"
},
{
  name:"Description  (optional)",
  key:"description",
  fieldType:"text",
  placeholder:"Enter description"
}
]
const CreateEmailResponse = ({componentType, handleAddData}) => {

  const navigate = useNavigate();
  const [emailSettingData, setEmailSettingData] = useState({
    name:"",
    email:"",
    description:""
  })

  const [loading, setLoading] = useState(false);

  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setEmailSettingData((state)=>({
      ...state,
      [name]:value
    }))
  }

  const handleSubmitData = () => {
    handleAddData(emailSettingData).then(res=>{
      navigate("/settings/email-setting");
    })
  }

  


  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
          gap: "10px",
          maxHeight: "88vh",
          height: "88vh",
        }}
      >
        <Box
          sx={{
            marginTop: "50px",
            padding: "10px 100px ",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "30px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <ArrowBackIosIcon
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  // handleSetActionView('view')
                  navigate("/settings/email-setting");
                }}
              />
              Go back
            </Typography>
          </Box>

          {
            emailSettingFields.map(({name,key,fieldType,placeholder})=>(
              <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "10px",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: "14px" }}>{name}</Typography>
              <Paper
                component="box"
                sx={{
                  p: "1px 2px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 40,
                  padding: "10px",
                  boxShadow: "none",
                  border: `1px solid rgba(0,0,0,0.1) `,
                }}
              >
                <InputBase
                  name={key}
                  value={emailSettingData[key]}
                  onChange={handleCollectData}
                  className="input-box"
                  type={fieldType}
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  placeholder={placeholder}
                />
              </Paper>
            </Box>
  
            ))
              }
        
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "10px",
              maxWidth: "40%",
              marginTop: "10px",
            }}
          >
            <Button
              onClick={() => {navigate("/settings/macros");}}
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                border: "none",
                ":hover": { borderColor: "none" },
              }}
              variant="outlined"
            >
              {" "}
              Cancel
            </Button>
            <LoadingButton
              onClick={handleSubmitData}
             loading={loading}
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
              variant="outlined"
            >
            {componentType === "update" ? "Update" :"Save"}
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateEmailResponse;
