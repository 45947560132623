import { Box, Menu, Typography } from "@mui/material";
import React, { useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SearchIcon from "@mui/icons-material/Search";
import GridViewIcon from "@mui/icons-material/GridView";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import { Link } from "react-router-dom";
import MenuBarCommon from "../../commonComponent/menuBarComponent/MenuBarCommon";
import FocusIcon from "../../../assets/searchIcons/FocusIcon";
import StatusMenuBar from "./filterMenus/StatusMenuBar";
import PriorityMenuBar from "./filterMenus/PriorityMenuBar";
const YourInboxToolbar = ({handleSetCurrentView, currentView}) => {

  const [filter,setFilter] = useState({
    status:"All",
    priority:0
  })

  const handleSetCurrentFilter = ({type,value}) => {
    setFilter((state)=>({
      ...state,
      [type]:value
    }))
  }
  return (
    <>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", gap: "20px" }}>
          <Box
            id="basic-button"
            sx={{
              color: "gray",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
            }}
          >
            <MenuBarCommon
              style={{background:"transparent !important", fontSize:'14px'}}
               onSelectFilter={handleSetCurrentFilter}
              selected={false}
              filKey={0}
              MenuComponent={StatusMenuBar}
              Icon={ ()=><KeyboardArrowDownIcon  />}
              label={filter.status}
            />
            
          </Box>

          <Box
            id="basic-button"
            sx={{
              color: "gray",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "5px",
              cursor: "pointer",
            }}
          >
             <MenuBarCommon
              style={{background:"transparent !important", fontSize:'14px'}}
              onSelectFilter={handleSetCurrentFilter}
              selected={false}
              filKey={0}
              MenuComponent={PriorityMenuBar}
              Icon={ ()=><KeyboardArrowDownIcon  />}
              label={filter.priority === 0 ? "Newest" : "Priority"}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "20px", alignItems: "center" }}>
          <Link to={'/inbox/search'} >
            <SearchIcon sx={{ color: "#000", cursor: "pointer" }} />
          </Link>
          {/* <GridViewIcon color="primary" sx={{ color:currentView ? "#a84acb" : "#000", cursor: "pointer" }} onClick={()=>handleSetCurrentView()} />
          <FormatListBulletedIcon sx={{ color: "#000", cursor: "pointer" }} /> */}
        </Box>
      </Box>
    </>
  );
};

export default YourInboxToolbar;
