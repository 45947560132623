import {
  Box,
  Button,
  Divider,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState, } from "react";
import { MainContext } from "../../../../context/MainProvider";
import TicketTypeTabs from "./TicketTypeTabs";
import CreateCustomerTicketType from "./ticketTypeComponents/CreateCustomerTicketType";
import DeleteIcon from '@mui/icons-material/Delete';
import { createTicketType, deleteTicketType, updateTicketType } from "../../../../services/Apis";
import { SidebarContext } from "../../../../context/SidebarProvider";
import { get } from "lodash";
import { useNavigate } from "react-router-dom";
const ticketTypeKeys = {
  customer:"customer",
  office:"back-office",
  tracker:"tracker"
}
const CreateNewTicketTypes = ({ componentType="create", updatedData={}, handleGetTickettypes=()=>{} }) => {
  const { handleCloseModal } = useContext(MainContext);
  const { handleShowSnackPopup } =  useContext(SidebarContext);
  const navigate = useNavigate()
  const [currentTicketTypeTab, setCurrentTicketTypeTab] = useState(()=> componentType === "update" ? get(updatedData,'type','customer') :  'customer');
  const [ticketTypeData, setTicketTypeData] = useState({
    name:"",
    description:"",
    type:"customer",
  })

  useEffect(()=>{
    if(componentType==="update"){
      setTicketTypeData(updatedData)
    }
  },[componentType])
  useEffect(()=>{
    setTicketTypeData((state)=>({
      ...state,
      type:ticketTypeKeys[currentTicketTypeTab]
    }))
  },[currentTicketTypeTab])

  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setTicketTypeData((state)=>({
      ...state,
      [name]:value
    }))
  }

  const handleUpdateTicketType = () => {
    updateTicketType(ticketTypeData.id,ticketTypeData).then(res=>{
      handleGetTickettypes(); 
      handleShowSnackPopup({
        message:"Ticket type updated successfully",
      })
      handleCloseModal()
    }).catch(err=>{
      handleShowSnackPopup({
        message:"Error while updating ticket type",
        error:true
      })
    })
  }

  const handleSubmitData = () => {
    if(componentType === "create"){
      handleCreateTicketType()
    }else{
      handleUpdateTicketType()
    }

  }


  const handleCreateTicketType = () => {
    createTicketType(ticketTypeData).then(res=>{
      handleGetTickettypes(); 
      handleShowSnackPopup({
        message:"Ticket type created successfully",
      })
      handleCloseModal()
    }).catch(err=>{
      handleShowSnackPopup({
        message:"Error while creating ticket type",
        error:true
      })
    })
  }

  const handleDeleteTicketType = () => {
    deleteTicketType(get(updatedData,'id',null)).then(res=>{
      handleShowSnackPopup({
        message:"Ticket type archived successfully",
      })
      handleCloseModal()
      navigate("/settings/ticket-types")
    }).catch(err=>{
      handleShowSnackPopup({
        message:"Error while archiving ticket type",
        error:true
      })
    })

  }

  const isUpdatebleComponent = componentType === "update"
  return (
    <>
      <Box sx={{ padding: "20px 40px",maxHeight:"90vh", overflowY:"scroll",  }}>
        <Box
          sx={{
            width: "400px",
            display: "inline-block",
           
            position: "relative",
          }}
        >
          <Typography  sx={{fontSize:"20px", fontWeight:"bold", padding:"0px 0px 10px 0px"}} >{componentType === "create" ? "Create a new ticket type ": "Update ticket type"}
        </Typography>
          <TicketTypeTabs value={currentTicketTypeTab} isUpdatebleComponent={isUpdatebleComponent}  handleValueChange={setCurrentTicketTypeTab}/>
          <CreateCustomerTicketType handleCollectData={handleCollectData} ticketTypeData={ticketTypeData} currentTicketTypeTab={currentTicketTypeTab} />
          <Divider sx={{margin:"10px 0px"}} />
          <Box sx={{ display: "flex", gap: "10px", alignItems: "center", justifyContent:'space-between' }}>
            <Box>
            {componentType === "update" &&  <Button onClick={handleDeleteTicketType}    sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}  startIcon={<DeleteIcon sx={{color:'red'}} />} >
                Archive
              </Button>}
            </Box>
            <Box sx={{display: "flex", gap: "10px",}}>
            <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <Button
              onClick={handleSubmitData}
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
             {componentType === "create" ? "Create" : "Update"}
              </Button>
            </Box>
            
            </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateNewTicketTypes;



