import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Divider,
} from "@mui/material";
import style from "./ticketTypedetail.module.scss";
import SideToolbar from "../../../../fixedComponent/sideToolbar/SideToolbar";
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
import { useParams } from "react-router-dom";
import { getCustomeFields, getTicketTypeDetails } from "../../../../services/Apis";
import { get } from "lodash";
import DisplayTicketTypeAttributes from "./DisplayTicketTypeAttributes";
import { MainContext } from "../../../../context/MainProvider";
import CreateDataModelling from "../../data-modelling/data-modelling-modal/CreateDataModelling";
import CreateNewTicketTypes from "../createNew/CreateNewTicketTypes";





const TicketTypeDetailsComponent = ({ ToolbarComponent }) => {
  const { handleOpenModal } = useContext(MainContext)
  const {id:ticketTypeId} = useParams();
  const [ticketTypeDetails, setTicketTypeDetails] = useState({});
  const [ticketTypeAttributes, setTicketTypeAttributes] = useState([])

  const handeGetTicketTypeDetails = () => {
    getTicketTypeDetails(ticketTypeId).then(res=>{
      let response = get(res,'data.data',{});
      setTicketTypeDetails(response)
    })
  }

  const handleGetTicketTypeAttributes = () => {
    getCustomeFields({type:'entity_type',data:'ticketType'},{type:"entity_id",data:ticketTypeId}).then(res=>{
      let response = get(res,'data.data.docs',[]);
      setTicketTypeAttributes(response)
    })
  }

  useEffect(()=>{
    const getDetails = () => {
      handeGetTicketTypeDetails();
    handleGetTicketTypeAttributes();
    }
    getDetails()
  },[])


  const handleCreateAttributes = ({type='new', id=null}) => {
    handleOpenModal({
      Component:<CreateDataModelling  data={{
        entityType:"ticketType",
        id:id,
        type:type,
        entityId:ticketTypeId,
        creationLocation:"ticket-type",
        handleGetCustomerFields:handleGetTicketTypeAttributes
      }} />
    })
  }

  const handleUpdateTicketTypes = () => {
    handleOpenModal({
      Component:<CreateNewTicketTypes componentType={'update'} updatedData={ticketTypeDetails} handleGetTickettypes={handeGetTicketTypeDetails} />
    })

  }
  return (
    <>
      <MainComponent className={style['ticket-type-main-component']}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent handleUpdateTicketTypes={handleUpdateTicketTypes} handleCreateAttributes={handleCreateAttributes}  heading={get(ticketTypeDetails,'name',"Ticket types")}  />}</SideToolbar>
          <Divider />
          <DisplayTicketTypeAttributes handleCreateAttributes={handleCreateAttributes} ticketTypeAttributes={ticketTypeAttributes} />
        </Box>
      </MainComponent>
    </>
  );
};

export default TicketTypeDetailsComponent;
