import { useState, useEffect, useRef } from 'react';

const useStateCallback = (initialState) => {
  const [state, setState] = useState(initialState);
  const callbackRef = useRef(null);

  useEffect(() => {
    if (callbackRef.current) {
      callbackRef.current(state);
      callbackRef.current = null;
    }
  }, [state]);

  const setStateCallback = (value, callback) => {
    callbackRef.current = callback;
    setState(value);
  };

  return [state, setStateCallback];
};

export default useStateCallback;
