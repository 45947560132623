import React from 'react'
import ContentSouceHocCard from '../contentSouceHocCard/ContentSourceHocCard'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, ListItemText } from '@mui/material';
const CommonContentCard = ({open,setOpen, heading, content, Component, Icon}) => {
    
  return (
  <>
   <Accordion sx={{border:'1px solid rgba(0,0,0,0.2)', boxShadow:'none', borderRadius:'5px'}} expanded={open} onChange={setOpen} >
   <AccordionSummary
        expandIcon={
          <ExpandMoreIcon
            style={{ transform: open ? `rotate(-180deg)` : `rotate(-90deg)` }}
          />
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box sx={{display:"flex", gap:"10px", alignItems:"center"}} >
         {Icon && Icon}
        <ListItemText
          primary={heading}
          secondary={content}
        />
        </Box>
      
      </AccordionSummary>

        {/* <ImportExternalSources /> */}
        {/* <PullseHelpCenterSource /> */}
        {/* <UseSnippetSource />   */}
        {/* <ImportContentFilesSources /> */}
        <Component />
        
      </Accordion>
  
  </>  )
}

export default ContentSouceHocCard(CommonContentCard) 
