import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Divider, InputAdornment, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { MainContext } from "../../../context/MainProvider";
import { allCustomers } from "../../../services/Apis";
import { get } from "lodash";
import { debounceWithImmediate } from "../../../utils/helpers/helpers";
const randColor = () =>  {
  return "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase();
}
const data = [
  {
    name: "Suhail Joo",
    color: "#E89D73",
    email:'suhailjoo@icloud.com'
  },
  {
    name: "AI Chatbot",
    color: "#738DE8",
    email:'fintop@icloud.com'
  },
  {
    name: "Suhail Joo",
    color: "#E87373",
  },
  {
    name: "Jayesh goyal",
    color: "#E873A4",
    email:'jayesh.goyal@gmail.com'
  },
  {
    name: "John snith",
    color: "#E873A4",
    email:'johnsnith@gmail.com'
  },
];
const UserMenu = ({handleClose, onSelectFilter, selectedVal}) => {
  const makeNameSmaller = (name) => {
    let circleContent = name[0];
    return circleContent.toUpperCase();
  };

  const [searchUser, setSearchUser] = useState("")

  const { handleFetchFilterTickets } = useContext(MainContext)
  const [customers,setCustomers] = useState([]);


  const getCustomer = (query="") => {
    allCustomers(query).then(res=>{
      let cus = get(res,'data.data.docs',[])
      cus = cus.map(ele=>({...ele,color: randColor()}))
      setCustomers(cus);
    })
  }

  useEffect(()=>{
    getCustomer()
  },[])

  const handleCollectData = (event) => {
    setSearchUser(event.target.value)
    handleDebounce(event.target.value)
  }

  const handleFetchCustomers = (value) => {
    getCustomer(value)
  }

  // const handleGetTickets = (customerId) => {
  //   handleFetchFilterTickets({type:'customer_id', data:customerId});
    
  // }

  const handleDebounce = useCallback(debounceWithImmediate(handleFetchCustomers,()=>{},700),[])
  const handleSubmit = (event) => {
    event.preventDefault();
    // handleFetchFilterTickets({type:'customer_id', data:""}); 
    // handleClose()
  };
  return (
    <Box sx={{ width: "360px", padding: "0px" }} className={"box"}>
      <Paper
        component="box"
        className="padding"
        onSubmit={handleSubmit}
        sx={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: 40,
          padding: "10px",
          boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
        }}
      >
        <InputBase
          className=""
          placeholder="Search"
          sx={{ flex: 1, padding: 0 }}
          value={searchUser}
          onChange={handleCollectData}
          onSubmit={handleSubmit}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "gray" }}    />
            </InputAdornment>
          }
        />
      </Paper>
      <Divider />

      <List
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", maxHeight:'500px', overflowY:'scroll', overflowX:'hidden', padding:'10px 0px 0px 0px' }}
      >
        {customers.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          let selected = selectedVal === value.id

          return (
            <ListItem key={value} disablePadding  sx={{ background : selected && '#c166e2', color:selected && 'white'}} onClick={()=>{
              onSelectFilter(value)
              handleClose()
            }} >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{ width: 27, height: 27, background: value.color }}
                  >
                    {makeNameSmaller(value.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={labelId} primary={value.name}  secondary={value.email} sx={{display:'flex', gap:'10px','.MuiListItemText-secondary':{color:selected && 'white'}}} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default UserMenu;
