import { Box, Button } from "@mui/material";
import React from "react";
import style from "./profileSetup.module.scss";
import CursorDownIcon from "../../../assets/icons/CursorDownIcon";
import ProfileBookIcon from "../../../assets/icons/ProfileBookIcon";
import { Link } from "react-router-dom";
const ProfileSetupToolbar = ({handleUpdateProfile}) => {
  return (
    <>
      <Box className={style.profileToolbarMainClass}>
        <Box className="toolbarContent">
          <Button
          onClick={handleUpdateProfile}
          className="add-profile"
            variant="contained"
            sx={{
              textTransform: "inherit",
              height:"30px",
            }}
          >
            Update profile
          </Button>        
        </Box>
      </Box>
    </>
  );
};

export default ProfileSetupToolbar;
