import React from "react";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import style from "./answerBehave.module.scss";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
let name = process.env.REACT_APP_AUTO_NAME;

const AnswerBehaveHeader = ({open}) => {
  return (
    <>
      <AccordionSummary
        className={style["answer-behave-header-main-container"]}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography className="accordianHeading">
          {`How ${name} should behave`} {open ? <ExpandMoreIcon /> : <NavigateNextIcon />}{" "}
        </Typography>
      </AccordionSummary>
    </>
  );
};
// How name should behave

export default AnswerBehaveHeader;
