import React, { useCallback, useEffect, useState } from "react";
import { Box, Chip, Typography } from "@mui/material";
import ChatBoatStaticView from "./ChatBoatStaticView";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import style from "./chatboat.module.scss";
import ChatBoatContainer from "./ChatBoatContainer";
import chatbotLogo from "../../../../assets/brandChatbotImage.jpeg";
import chatboatLogo from "../../../../assets/icons/chatbotlogo.png";
import { askQueryApi } from "../../../../services/llmService";
import { get } from "lodash";
import useStateCallback from "../../../../utils/hooks/useStateCallback";

const chatBoatData = [
  {
    message: `Hi I'm AI Chatbot, an AI-powered bot that automatically answers questions based on your support content.`,
    type: "reciver",
    icon: false,
    time: "",
  },
  {
    message: `I have ingested your content, you can give me a spin! Just type any question your customers might have. Don't be shy, I love a good chat!`,
    type: "reciver",
    icon: true,
    time: "",
  },
];
const ChatBot = ({handleGetBotDocuments}) => {
  const [toggleShowDetail, setToggleShowDetail] = useState(false);
  const [messages, setMessages] = useStateCallback([]);
  const [textAreaValue, setTextAreaValue] = useState("");
  const [loading, setLoading] = useState(false);

  const handleAskQuery = (oldMessages) => {
    setLoading(true);
    setTextAreaValue("");
    askQueryApi({
      query: textAreaValue,
    })
      .then((res) => {
        let response = get(res, "data.data", "");
        let tempMessages = [...oldMessages]
        tempMessages.push({
          message: response,
          type: "reciver",
          icon: true,
          time: "",
        })
        setMessages(tempMessages)
        setLoading(false);        
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleSetNewMessages =    (messageArr) => {
    let tempMessages = [...messages];
   
    tempMessages.push(...messageArr);
   
    setMessages(tempMessages,(oldMessages)=>{
      handleAskQuery(oldMessages)
    });
   
  }

  const handleToggleDetails = () => {
    setToggleShowDetail(!toggleShowDetail);
  };

  const handleSubmitMessage =  (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      let msg = textAreaValue;
      let newMessage = [];
      let tempMessage = [...chatBoatData];
      if (!messages.length) {
        newMessage.push(...tempMessage);
      }
      newMessage.push({
        message: msg,
        type: "sender",
        icon: false,
        time: "",
      });
      
      handleSetNewMessages(newMessage);
      
      // setTextAreaValue("")
      // handleAskQuery(msg)
    }
  }

  useEffect(() => {
  }, [loading]);
  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "80%",
          boxShadow: "0px 2px 10px rgba(0,0,0,0.1)",
          borderRadius: "10px",
          overflow: "hidden",
        }}
      >
        {messages.length ? (
          <Box
            sx={{
              height: toggleShowDetail ? "30%" : "10%",
              borderBottom: "1px solid rgba(0,0,0,0.1)",
              display: "flex",
              justifyContent: "space-between",
              padding: "5px 10px",
            }}
            className={toggleShowDetail ? style.showHeaderDetailsClass : ""}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                justifyContent: "flex-start",
                alignItems: "center",
                width: "60%",
                borderRadius: "20px",
                padding: "10px",
              }}
              className={`${style.hoverClassForChatboatHeader} headerContent`}
              onClick={handleToggleDetails}
            >
              <Box>
                <img
                  src={chatboatLogo}
                  style={{ height: "35px", width: "35px" }}
                  alt=""
                  srcset=""
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ marginBottom: "10px" }}>Pullse AI</Typography>
              </Box>
            </Box>
            <Box></Box>
          </Box>
        ) : null}
        {messages.length ? (
          <>
            <Box sx={{ height: toggleShowDetail ? " 60%" : "80%" }}>
              <ChatBoatContainer
                messages={messages}
                handleSetNewMessages={handleSetNewMessages}
                loading={loading}
              />
            </Box>
          </>
        ) : (
          <Box sx={{ height: "90%" }}>
            <ChatBoatStaticView
              handleSetNewMessages={handleSetNewMessages}
              chatBoatData={chatBoatData}
            />
          </Box>
        )}

        <Box
          sx={{
            height: "10%",
            padding: "2px",
            background: "#f6f6f6",
            borderTop: "1px solid rgba(0,0,0,0.1)",
          }}
        >
          <Box sx={{ height: "100%", width: "80%", overflow: "hidden" }}>
            <TextareaAutosize
              disabled={loading}
              maxRows={3}
              placeholder="Ask a question...."
              minRows={3}
              value={textAreaValue}
              onChange={(e) => setTextAreaValue(e.target.value)}
              className={style.messageTextAreaClass}
              sx={{ outerHeight: "100%", width: "320px" }}
              onKeyDown={(e) => handleSubmitMessage(e)}
            />
          </Box>
          <Box></Box>
        </Box>
      </Box>
    </>
  );
};

export default ChatBot;
