import React, { useContext, useEffect, useState } from 'react'
import { Box, Button, Divider} from '@mui/material'
import style from "./reports.module.scss"
import MainComponent from '../../../fixedComponent/sideDashboard/MainComponent'
import SideToolbar from '../../../fixedComponent/sideToolbar/SideToolbar'
import { MainContext } from '../../../context/MainProvider'
import  PieChartComponent  from './chartComponents/pie-chart-component/PieChartComponent'
import LineChartComponent from './chartComponents/line-chart-component/LineChartComponent'
import AreaChartComponent from './chartComponents/area-chat-component/AreaChartComponent'
import ScatterChartComponent from './chartComponents/scatter-chart-compoment/ScatterChartComponent'
import BarChartComponent from './chartComponents/bar-chart-component/BarChartComponent'
import MatrixChartComponent from './chartComponents/matrix-chart-component/MatrixChartComponent'
import { CHART_TYPES, chartMockData } from './report-json/report'
import { renderReports, reportList } from '../../../services/Apis'
import { get } from 'lodash'
import { MainChartComponent } from './chartComponents/chartComponent/MainChartComponent'
import Grid from '@mui/material/Grid';
import { dynamicChartsData } from './chartComponents/chartComponent/chart'
import DashboardSkelton from './dashboard-skleton/DashboardSkelton';
import { useSearchParams } from "react-router-dom";


const ReportsComponent = ({ToolbarComponent}) => {
  const [ reportsData, setReportsData ] = useState([]);
  const [reportListData, setReportListData] = useState({docs:[]});
  const [dashboardLoading, setDashboardLoading] = useState(false);
  const [currentDashboardId, setCurrentDashboardId] = useState("");

  useEffect(()=>{
    handleGetReportList()
  },[])


  const handleGetDashboard = (reportId) => {
    setDashboardLoading(true)
    renderReports(reportId).then(res=>{
      let response = get(res,'data.data',[]);
      setReportsData(response)
      setDashboardLoading(false)
    }).catch(error=>{
      setDashboardLoading(false)
    })
  }

  const handleSetDashboardId = (dashboardId) => {
    setCurrentDashboardId(dashboardId)
    handleGetDashboard(dashboardId)
  }

  const handleGetReportList = ()=>{
    setDashboardLoading(true)
    reportList().then(res=>{
      let reaponse = get(res,'data.data',{docs:[]})
      setReportListData(reaponse)
      let reportId = get(reaponse,'docs[0].id','');
      handleSetDashboardId(reportId);
    }).catch(err=>{
      setDashboardLoading(false)
    })
  }

  // const CHARTS = {
  //   [CHART_TYPES.PIE_CHART]:PieChartComponent,
  //   [CHART_TYPES.AREA_CHART]:AreaChartComponent,
  //   [CHART_TYPES.BAR_CHART]:BarChartComponent,
  //   [CHART_TYPES.LINE_CHART]:LineChartComponent,  
  //   [CHART_TYPES.SCATTER_CHART]:ScatterChartComponent,
  //   [CHART_TYPES.MATRIX_CHART]:MatrixChartComponent
  // } 



  return (
   <>
     <MainComponent className={style.allUsersBodyMainComponent} >
       <Box  sx={{padding:'0px 10px',  height:'95vh', background:'white !important'}} >
       <SideToolbar>
          {ToolbarComponent && (
            <ToolbarComponent reportListData={reportListData} handleGetDashboard={handleGetDashboard} currentDashboardId={currentDashboardId} setCurrentDashboardId={handleSetDashboardId}    />
          )}
        </SideToolbar>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflowY: "scroll",
            gap: "10px",
            maxHeight:'88vh',

          }}
        >
          <Box sx={{ marginTop:"10px",flexGrow: 1, maxWidth:"100%" }} > 
          {
            dashboardLoading ? 
            <DashboardSkelton /> :
            <>
            <Grid container spacing={2}>
            {
              reportsData.map((ele,index)=>{  
                let {columns, type} = ele     
                if(type=='matrix_card'){
                  return (
                    <Grid item  sm={columns}   >
                    <MatrixChartComponent data={ele} />
                      </Grid>                
                      )
                } else{
                  return (
                    <Grid item sm={columns}  >
                    <MainChartComponent data={ele} />
                    </Grid>
                  )
                }        
              
            })
            }
          </Grid>
            </>

          }
          

          
          </Box>
         
        </Box>
       </Box>
      </MainComponent>
   
   </>
  )
}

export default ReportsComponent
