import React from "react";
import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import SwitchIcon from "../../../assets/searchIcons/SwitchIcon";
const data = [
  {
    name: "Newest",
    color: "#E89D73",
    value:{
        sort_by:'createdAt',
        sort_order:'desc'
    },
    key:'newest'
  },
  {
    name: "Oldest",
    color: "#E89D73",
    value:{
        sort_by:'createdAt',
        sort_order:'asc'
    },
  },
  {
    name: "Priority",
    color: "#E89D73",
    value:{
        sort_by:'priority',
        sort_order:'desc'
    }
  },
];

const OrderMenu = ({ handleClose, onSelectFilter, selectedVal={} }) => {
  return (
    <Box sx={{ width: "100px", padding: "0px" }} className={"box"}>
      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper", padding:'0px' }}
      >
        {data.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          const selected = JSON.stringify(selectedVal) === JSON.stringify(value.value)

          return (
            <ListItem key={value} disablePadding  sx={{background: selected && '#c166e2', color: selected && 'white'}} onClick={()=>{
                onSelectFilter(value.value);
                handleClose();
            }}>
              <ListItemButton>
                <ListItemText id={labelId} primary={value.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default OrderMenu;
