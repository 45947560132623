import React from "react";

const OutBoundSidebarIcon = ({ color ,...props }) => {
  return (
    <>
      <svg
        {...props}
        width="20"
        height="20"
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Outbound">
          <path
            id="Vector"
            d="M24.5822 3.9179L12.1409 16.3592M3.81633 10.1079L23.1899 3.38642C24.3829 2.9725 25.5276 4.11715 25.1137 5.31022L18.3922 24.6838C17.9318 26.011 16.068 26.0474 15.5561 24.7391L12.4797 16.8773C12.3261 16.4846 12.0155 16.174 11.6228 16.0203L3.76095 12.944C2.45272 12.4321 2.48911 10.5683 3.81633 10.1079Z"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
          />
        </g>
      </svg>
    </>
  );
};

export default OutBoundSidebarIcon;
