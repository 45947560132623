import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip, Button } from '@mui/material';
import { Box, } from '@mui/system';

let mockTableData = [
    {
        name:"Support",
        email:"hello@pullse.ai",
        status:"Unverified",
        domain:"pullse.ai"
    },
    {
      name:"Hello",
      email:"hello@pullseai.com",
      status:"Unverified",
      domain:"pullseai.com"
  },
]
const DomainContentDisplayTable = () => {
  return (
    <Box  sx={{padding:"10px 5px"}} >
   <TableContainer component={Paper} sx={{boxShadow:"none"}} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
        <TableRow>
            <TableCell sx={{fontWeight:"bold"}}>Name</TableCell>
            <TableCell sx={{fontWeight:"bold"}}>	Email 	</TableCell>
            <TableCell sx={{fontWeight:"bold"}}>	Status	</TableCell>
            <TableCell sx={{fontWeight:"bold"}} >Domain</TableCell>

          </TableRow>
        </TableHead>
        <TableBody>
          {mockTableData.map(({name, email, status, domain}) => (
            <TableRow
              key={email}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {name}
              </TableCell>
              <TableCell >{email}</TableCell>
              <TableCell >
              <Chip
                      sx={{
                        borderRadius: "5px",
                        height: "24px",
                        color: "rgba(66,50,0,1)",
                        background: "rgba(254,237,175,1)",
                        fontWeight: "bold",
                      }}
                      label={status}
                    />
                </TableCell>
              <TableCell >{domain}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   </Box>
  )
}

export default DomainContentDisplayTable
