import React from "react";
import { Box, Button, Typography } from '@mui/material'
import BarGroupIcon from '../../../../../assets/icons/BarGroupIcon'
import AutoSettingIcon from '../../../../../assets/icons/AutoSettingIcon'
import MessageTextIcon from '../../../../../assets/icons/MessageTextIcon'
import SendAlertIcon from "../../../../../assets/icons/SendAlertIcon";
import HorizontalIcon from "../../../../../assets/icons/HorizontalIcon";
const botName = process.env.REACT_APP_AUTO_NAME
const ContentIcon = () => (
    <>
    <Typography sx={{fontWeight:'bold', fontSize:'40px'}} >
        0
    </Typography>
    </>
)
const contentData = [
    {
        Icon:SendAlertIcon,
        content:'Customize how AI Chatbot reaches your customers with Profiles',
        buttonName:`Manage ${botName} profile `
    },
    {
        Icon:  HorizontalIcon,
        content:'Resolution rate',
        buttonName:'AI Chatbot report'
    },,
    {
        Icon: ContentIcon,
        content:'Conversations involved',
        buttonName:'AI Chatbot inbox'
    },
    {
        Icon:  ContentIcon,
        content:'Resolutions this billing period',
        buttonName:'Biling'
    },
]

const SetupPageCards = () => {
  return (
    <>
    <Box  sx={{width:'100%', borderRadius:'5px',}} >
    <Box sx={{display:'flex', justifyContent:'center', alignItems:'center', gap:'15px', borderRadius:'16px', marginTop:'20px', }} >
        {contentData.map(ele=>(
            <ReportDetailCard  {...ele}/>
        ))}

    </Box>
    </Box>
    
    </>
  )
}

const ReportDetailCard = ({ Icon, content, buttonName }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "flex-start",
          gap: "20px",
          height: "231px",
          flexDirection: "column",
          background: "white",
          width: "400px",
          borderRadius: "16px",
          padding: "20px 20px",
          boxShadow: '0px 2px 30px 0px rgba(0, 0, 0, 0.06)',
        }}
      >
        <Icon />
        <Typography
          sx={{  color: "#707C97", fontSize: "15px" }}
        >
          {content}
        </Typography>

        <Button
          variant="contained"
          sx={{
            background:
              "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
            textTransform: "inherit",
          }}
        >
          {buttonName}
        </Button>
      </Box>
    </>
  );
};
export default SetupPageCards;
