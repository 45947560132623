import React, { useContext, useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import style from "./domain-component.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import CommonContentCard from "./commponContentSouceCard/CommonContentCard";
import BotSettingIcon from "../../../assets/icons/BotSettingIcon";
import BotInboxSettingIcon from "../../../assets/icons/BotInboxSettingIcon";
import BotWorkflowSettingIcon from "../../../assets/icons/BotWorkflowSettingIcon";
import {
  allEmailDomains,
  chatbotSettingApi,
  emailDomainApi,
  updateChatbotSetting,
} from "../../../services/Apis";
import { get } from "lodash";
import OpenEmailBoxIcon from "../../../assets/icons/OpenEmailBoxIcon";
import Chip from "@mui/material/Chip";
import CardDataComponent from "./content-source/CardDataComponent";
import UnauthenticatedCard from "./content-source/UnauthenticatedCard";
import DomainContentDisplayTable from "./domain-content-display/DomainContentDisplayTable";

const DomainComponent = ({ ToolbarComponent }) => {
  const [emailDomainsData, setEmailDomainData] = useState([])

  const handleGetEmailDomains = () => {
    allEmailDomains().then(res=>{
      let response = get(res,'data.data.docs',[])
      setEmailDomainData(response)
    })
  }


  useEffect(()=>{
    handleGetEmailDomains()
  },[])




  const handleCreateEmailDomain = (data) => {
    return new Promise((resolve, reject)=>{
      emailDomainApi(data).then(res=>{
        resolve(res)
        handleGetEmailDomains()
      }).catch(err=>{
        reject(err)
      })
    })
  }


  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent handleCreateEmailDomain={handleCreateEmailDomain} />}</SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
              marginBottom: "10px",
              marginTop: "10px",
              padding: "20px",
            }}
          >
            {
              emailDomainsData.map(ele=>(
                <>

                
                 <CommonContentCard
              heading={
                <>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        ":hover": { color: "#a84acb" },
                      }}
                    >
                      {ele.domain}
                    </Typography>{" "}
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        height: "24px",
                        color: "rgba(66,50,0,1)",
                        background: "rgba(254,237,175,1)",
                        fontWeight: "bold",
                      }}
                      label="Unauthenticated"
                    />
                  </Box>
                </>
              }
              Icon={<OpenEmailBoxIcon height={20} width={20} />}
              Component={() => (
                <UnauthenticatedCard />
              )}
            />

                
                </>
              ))
            }
            {/* <CommonContentCard
              heading={
                <>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        ":hover": { color: "#a84acb" },
                      }}
                    >
                      pullseai.com
                    </Typography>{" "}
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        height: "24px",
                        color: "rgba(15,113,52,1)",
                        background: "rgba(215,239,220,1)",
                        fontWeight: "bold",
                      }}
                      label="Authenticated"
                    />
                  </Box>
                </>
              }
              Icon={<OpenEmailBoxIcon height={20} width={20} />}
              Component={() => (
              <CardDataComponent />
              )}
            /> */}



            {/* <CommonContentCard
              heading={
                <>
                  <Box sx={{ display: "flex", gap: "10px" }}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        fontWeight: "600",
                        ":hover": { color: "#a84acb" },
                      }}
                    >
                      pullse.ai
                    </Typography>{" "}
                    <Chip
                      sx={{
                        borderRadius: "5px",
                        height: "24px",
                        color: "rgba(66,50,0,1)",
                        background: "rgba(254,237,175,1)",
                        fontWeight: "bold",
                      }}
                      label="Unauthenticated"
                    />
                  </Box>
                </>
              }
              Icon={<OpenEmailBoxIcon height={20} width={20} />}
              Component={() => (
                <UnauthenticatedCard />
              )}
            /> */}

            <Box  sx={{}} >
              <Typography  sx={{fontWeight:"bold",fontSize:"15px"}} >Email deliverability assigned teammates</Typography>
              <Typography sx={{fontWeight:"normal",fontSize:"13px"}} >Setup who should be notified if there is any email deliverability issue with this workspace. The workspace creator and the billing admin will always be notified by default.</Typography>
            </Box>

            <Box sx={{marginTop:"10px"}}>
              <DomainContentDisplayTable />


            </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default DomainComponent;
