import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  InputBase,
  MenuItem,
  Select,
} from "@mui/material";
import style from "./aiChatbotContent.module.scss";
import MainComponent from "../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../fixedComponent/sideToolbar/SideToolbar";
import { MainContext } from "../../../context/MainProvider";
import { getChatBotDocuments } from "../../../services/Apis";
import { get } from "lodash";
import AiChatbotContentTable from "./table-component/AiChatbotContentTable";
import ChatbotContents from "./chatbot-contents/ChatbotContents";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AddChatbotContents from "./add-documentions/AddChatbotContents";
const AiChatBotContentComponent = ({ ToolbarComponent }) => {



  

  return (
    <>
      <MainComponent className={style.allUsersBodyMainComponent}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>{ToolbarComponent && <ToolbarComponent />}</SideToolbar>
          <Divider />
     


         <Routes>
        <Route path="/" element={    <ChatbotContents   />} />
          <Route  path="add-contents" element={<>
          <AddChatbotContents />
          </>} />
        
          <Route  path="*" element={<><Navigate to={'/automation/pullse-contents'} /></>} />
        </Routes>
        </Box>
      </MainComponent>
    </>
  );
};

export default AiChatBotContentComponent;
