import React, {useContext, useEffect, useState} from 'react'
import {
    Box,
    Button,
    Divider,
    InputBase,
    MenuItem,
    Paper,
    Select,
    Typography,
    colors,
  } from "@mui/material";
import ConditionCard from '../condition-card/ConditionCard';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { MainContext } from '../../../../context/MainProvider';
import { createWorkflow, updateWorkflowDetails, workflowDetails, workflowEntityFields } from '../../../../services/Apis';
import { get, set } from 'lodash';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { SidebarContext } from '../../../../context/SidebarProvider';
import ActionCard from '../action-card/ActionCard';

let initialConditionData = {
    "resource": "",
    isNew:true
    // "field": "status",
    // "operator": "not_in",
    // "value": [4, 5]
}
const initialActionCardState = {
  type:"",
}

const CreateTrigger = ({handleSetActionView, componentType, handleSetTabWhileCreated}) => {
    const navigate = useNavigate()
    let params = useParams()
    const [searchParams, setSearchParams] = useSearchParams();
    const { handleShowSnackPopup, setBackdropLoading } = useContext(SidebarContext)
    const [workflowEntityFieldsData,setWorkflowEntityFieldsData] = useState({})
    const [editable, setIsEditable] = useState(false);
    const [updatedData, setUpdatedData] = useState({})
    const [triggers, setTriggers] = useState({
        name:"",
        description:"",
        conditionAll:[],
        conditionOr:[],
        actions:[]
      })

      useEffect(()=>{
        
        if(componentType === 'update'){
            
            setSearchParams({
                mode: searchParams.get('mode') ? searchParams.get('mode')  : "view"
            })
        }
      },[])
      useEffect(()=>{
        let updateMode = searchParams.get('mode') === "edit"
        setIsEditable(updateMode)    
      },[searchParams])
      const handleAddNewAction = (type) => {
        let tempTrigers = {...triggers}
        if(type === 'actions'){
          tempTrigers[type].push({...initialActionCardState});
        }else{
          tempTrigers[type].push({...initialConditionData});
        }
        setTriggers(tempTrigers)
      }
    
      const handleRemoveAction = (type,index) => {
        let tempTrigers = {...triggers}
        tempTrigers[type].splice(index,1)
        setTriggers(tempTrigers)
      }
    
      const handleCollectData = (event) => {
        const { name, value } = event.target;
        setTriggers((state)=>({
          ...state,
          [name]:value
        }))
      }
      useEffect(()=>{
        getWorkflowEntitryFields()
      },[])

      const handleCollectConditionalData = (eventData, type,index) => {
        let tempTriggers = {...triggers}
        triggers[type][index]  = eventData;
        setTriggers(tempTriggers)
      }

     const  getWorkflowEntitryFields = () => {
        workflowEntityFields().then(res=>{
            let response = get(res,'data.data',{});
            setWorkflowEntityFieldsData(response)
        })
      }
      useEffect(()=>{
        
      },[workflowEntityFieldsData])

      useEffect(()=>{
        // if()
      },[updatedData])


// attribute: "assignee"
// condition: "equals"
// type: "ticket"
// value: "cf2ee486-e69d-4d65-ac90-4d97954e3f52"

// "resource": "ticket",
// "field": "status",
// "operator": "not_in",
// "value": [4, 5]

      
      const conditionPayload = (conditionalArr) => {
        return conditionalArr.map(ele=>{
          let {isNew, ...rest} = ele;
          return rest
        })
        return conditionalArr.map(ele=>({
            "resource": get(ele,'type',""),
            "field": get(ele,'attribute',""),
            "operator": get(ele,'condition',""),
            "value":get(ele,'value',"")
        }))
      }
      const handleSubmitTriggers = () => {
        let matchAllTriggers  = conditionPayload([...triggers.conditionAll]);
        let matchOrTriggers  = conditionPayload([...triggers.conditionOr]);
        let checkIsCondition = {}
        if(triggers.actions.length){
          checkIsCondition['actions'] = triggers.actions
        }
        let rules = []
        if(matchAllTriggers.length){
            rules.push({
                matchType:'all',
                properties:matchAllTriggers
            })
        }
        if(matchOrTriggers.length){
            rules.push({
                matchType:'any',
                properties:matchOrTriggers
            })
        }
        let payload = {
            "name": triggers.name,
            "summary": "to do",
            "description": triggers.description,
            rules,
            position:0,
            status:"draft",
            // "actions": [{
            //     "type": "updateTicket",
            //     "attributes": {
            //         "status": "closed",
            //         "priotity": 2
            //     },
            //     "customAttributes": {
            //         "7d707a3b-e126-45ea-a23e-a597c0887dc3": "Chat Bot"
            //     }
            //   }
            // ],
             ...checkIsCondition
            // "position": 0,
        }

        createWorkflow(payload).then(res=>{
            // handleSetActionView('view')
            let response = "workflow created successfully"
            handleSetTabWhileCreated()
            handleShowSnackPopup({message:response,error:false, time:3000})
        }).catch(err=>{
          let error = get(err,'response.data.message',"internal server error");
          
          handleShowSnackPopup({message:error,error:true, time:3000})
        })
      }

      const isUpdateComponent = componentType === 'update';
      const isCreateNewComponent = componentType === "create";


      useEffect(()=>{
        if(isUpdateComponent){
            
        }

      },[updatedData])

      const handleFetchWorkflowDetails = () => {
        let workflowId = params.id
        if(isUpdateComponent){
            workflowDetails(workflowId).then(res=>{
                
                let response = get(res,'data.data',{})
                setUpdatedData({...response});
                setBackdropLoading(false)
            }).catch(err=>{
                let errorReponse = get(err,'response.data.code',"")
                if(errorReponse === "NOT_FOUND"){
                    setBackdropLoading(false)
                    navigate('/automation/actions')
                    handleShowSnackPopup({message:"Invalid workflow id",error:true})
                    
                }
            })
        }

      }

      useEffect(()=>{
        handleFetchWorkflowDetails()
      },[])

      useEffect(()=>{
        // const [triggers, setTriggers] = useState({
        //     name:"",
        //     description:"",
        //     conditionAll:[],
        //     conditionOr:[],
        //     actions:[]
        //   })
        if(isUpdateComponent && Object.keys(updatedData).length){
            let tempUpdatedData = JSON.parse(JSON.stringify({...updatedData}))
            let rules = get(tempUpdatedData,'rules',[]);
            // setTriggers
            let matchAll = rules.find(ele=>ele.matchType === "all");
            let matchAny = rules.find(ele=>ele.matchType === "any");
            let actions = get(tempUpdatedData,'actions',[]).map(ele=>({
              // name:get(ele,'name',""),
              position:get(ele,'position',0),
              type:get(ele,'type',""),
              attributes:get(ele,'attributes',{})
            }))
            setTriggers((state)=>({
                conditionAll:get(matchAll,'properties',[]),
                conditionOr:get(matchAny,'properties',[]),
                description:get(tempUpdatedData,'description',""),
                name:get(tempUpdatedData,'name',""),
                actions:actions
            }))
        }
      },[updatedData])
      

      const handleUpdateTrigger = () => {
        let tempTriggers = {...triggers}
        let tempUpdatedData = {...updatedData};
        let matchAllTriggers  = conditionPayload([...tempTriggers.conditionAll]);
        let matchOrTriggers  = conditionPayload([...tempTriggers.conditionOr]);
        let updateTempRules = [...get(tempUpdatedData,'rules',[])];
        tempUpdatedData.name = tempTriggers.name
        tempUpdatedData.description = tempTriggers.description;
        tempUpdatedData.actions = triggers.actions;
          if(get(updateTempRules,'[0]',{}).hasOwnProperty('matchType')){
            if(get(get(updateTempRules,'[0]',{}),'matchType',"")==="all"){
              set(updateTempRules,`[0]['properties']`,matchAllTriggers);
            }else{
              set(updateTempRules,`[0]['properties']`,matchOrTriggers);
            }
          }else{
            if(matchAllTriggers.length){
              set(
                updateTempRules,`[0]`,{
                  "matchType":"all",
                  'properties':matchAllTriggers
                }
              )
            }
          }
          if(get(updateTempRules,`[1]`,{}).hasOwnProperty('matchType')){
            if(get(updateTempRules,'[1].matchType',"")==="all"){
              set(updateTempRules,`[1]['properties']`,matchAllTriggers);
            }else{
              set(updateTempRules,`[1]['properties']`,matchOrTriggers)
            }
          }else{
            if(matchOrTriggers.length){
              set(updateTempRules,`[1]`,{
                "matchType":"any",
                'properties':matchOrTriggers
              })
            }
           
          }
      //   if(updateTempRules.length ==2) {
      //       if(get(updateTempRules,'[0].matchType',"") === "all"){
      //           updateTempRules[0]['properties'] = matchAllTriggers
      //       }else{    
      //         updateTempRules[0]['properties'] = matchOrTriggers
      //       }
      //       if(get(updateTempRules,'[1].matchType',"") === "any"){
      //           updateTempRules[1]['properties'] = matchOrTriggers
      //       }else{
      //         updateTempRules[1]['properties'] = matchAllTriggers
      //       }
      // }
        tempUpdatedData['rules'] =  updateTempRules;
        let workflowId = params.id
        setBackdropLoading(true)

        updateWorkflowDetails(workflowId, tempUpdatedData).then(res=>{
            handleFetchWorkflowDetails()
        })


      }



      const disabledButtons = editable||componentType==="create";



      // 


      // 
  return (
   <>
    <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
              padding: "30px 30px 0px 30px",
            }}
          >
           <Box sx={{ display: "flex",
           alignItems:"center",
              justifyContent:"space-between"}} >
           <Typography sx={{ fontWeight: "400", fontSize: "30px", display:"flex", alignItems:"center"}}>
            <ArrowBackIosIcon  sx={{cursor:"pointer"}} onClick={()=>{
                // handleSetActionView('view')
                navigate("/automation/actions");

            }} />{triggers.name.length ? triggers.name : " Add new trigger"}
            </Typography>
      {
        componentType === "update" ?
        <>
              <Button
            onClick={()=>{
                let currentmode = searchParams.get('mode') === 'view'
                if(currentmode){
                    setSearchParams({
                        mode:"edit"
                    })
                }else{
                    setSearchParams({
                        mode:"view"
                    })
                }

            }}
              variant='outlined' sx={{
                textTransform:"inherit",
                display:"inline-block",
                width:"100px",
                marginTop:"10px",
                color:editable ? "red" :"#a84acb",
                borderColor:editable ? "red" :"#a84acb",
                padding: '0',
                width: '81px',
                ":hover":{
                    // color:"white",
                    borderColor:editable ? "red" :"#a84acb",
                    // borderColor:"#a84acb",
                    // background:"#a84acb"
                }
            }} >{editable ? "Cancel"  : "Edit"}</Button>
        
         </>
          :

         null
      }
           </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                marginTop: "20px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Trigger name</Typography>
              <Paper
                component="box"
                sx={{
                  p: "1px 2px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 40,
                  padding: "10px",
                  boxShadow: "none",
                  border: `1px solid rgba(0,0,0,0.2)`,
                }}
              >
                <InputBase
                 disabled={isUpdateComponent}
                  value={triggers.name}
                  name="name"
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  placeholder="Enter a trigger name"
                  onChange={handleCollectData}
                />
              </Paper>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                marginTop: "20px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Description</Typography>
              <Paper
                component="box"
                sx={{
                  p: "1px 2px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 40,
                  padding: "10px",
                  boxShadow: "none",
                  border: `1px solid rgba(0,0,0,0.2)`,
                }}
              >
                <InputBase
                  disabled={componentType==="update" && !editable}
                  value={triggers.description}
                  onChange={handleCollectData}
                  name="description"
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  placeholder="Enter an optional description"
                  // onChange={handleCollectValue}
                  // value={loginData.username}
                  // onFocus={()=>setOnFormFocus(true)}
                  // onBlur={()=>setOnFormFocus(false)}
                  // onKeyUp={onHandleSubmit}
                />
              </Paper>
            </Box>

            {/* <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                marginTop: "20px",
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>Category</Typography>

              <Select
                value={triggers.category}
                autoFocus={true}
                onChange={handleCollectData}
                name="category"
                sx={{ width: "60%", border: "1px solid rgba(0,0,0,0.2)" ,  height: 40,borderRadius:"5px", paddingLeft:"10px"}}
                input={
                  <InputBase
                    variant="standard"
                    autoFocus={true}
                    sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                  ></InputBase>
                }
              >
                <MenuItem value="none">select </MenuItem>
                <MenuItem
                  value={"ele"}
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  {" "}
                  {"ele"}
                </MenuItem>
              </Select>
            </Box> */}


            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
            <Divider />
            </Box>


            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                // marginTop: "20px",
                marginBottom: "20px",
              }}
            >
           <Typography sx={{fontSize:"20px"}} >Conditions</Typography>
           <Typography  sx={{color:"gray", fontSize:"14px"}} >Conditions that must be met for the trigger to run</Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                // marginTop: "20px",
                marginBottom: "20px",
              }}
            >
           <Typography sx={{fontSize:"14px", fontWeight:"600"}} >Meet ALL of the following conditions</Typography>
           {
            triggers.conditionAll.map((ele, index)=>(
              <ConditionCard componentType={componentType} cardData={ele} handleCollectConditionalData={handleCollectConditionalData} workflowEntityFieldsData={workflowEntityFieldsData} handleRemoveAction={handleRemoveAction} index={index} type="conditionAll" editable={editable} />
            ))
           }

           <Button  variant="outlined"  sx={{
            textTransform:"inherit",
            display:"inline-block",
            width:"200px",
            marginTop:"10px"
           }}
           disabled={!disabledButtons}
           onClick={()=>{
            handleAddNewAction('conditionAll')
           }}
           >Add condition</Button>
            </Box>





            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                // marginTop: "20px",
                marginBottom: "20px",
              }}
            >
           <Typography sx={{fontSize:"14px", fontWeight:"600"}} >Meet ANY of the following conditions</Typography>
         
           {
            triggers.conditionOr.map((ele,index)=>(
              <ConditionCard componentType={componentType} cardData={ele} handleCollectConditionalData={handleCollectConditionalData} workflowEntityFieldsData={workflowEntityFieldsData}  handleRemoveAction={handleRemoveAction} index={index} type="conditionOr" editable={editable} />
            ))
           }


           <Button  variant="outlined"  sx={{
            textTransform:"inherit",
            display:"inline-block",
            width:"200px",
            marginTop:"10px"
           }}
           disabled={!disabledButtons}
           onClick={()=>{
            handleAddNewAction('conditionOr')
           }} >Add condition</Button>
            </Box>

            <Divider />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                // marginTop: "20px",
                marginBottom: "20px",
              }}
            >
           <Typography sx={{fontSize:"20px"}} >Actions</Typography>
           <Typography  sx={{color:"gray", fontSize:"14px"}} >Actions that will occur if global conditions are satisfied</Typography>
            </Box>


            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "5px",
                maxWidth: "80%",
                // marginTop: "20px",
                marginBottom: "20px",
              }}
            >
                 {
            triggers.actions.map((ele,index)=>(
              <ActionCard workflowEntityFieldsData={workflowEntityFieldsData} cardData={ele} handleCollectConditionalData={handleCollectConditionalData} componentType={componentType} handleRemoveAction={handleRemoveAction} index={index} type="actions" editable={editable} />
              // <ConditionCard handleCollectConditionalData={handleCollectConditionalData} workflowEntityFieldsData={workflowEntityFieldsData} handleRemoveAction={handleRemoveAction} index={index} type="actions" />
            ))
           }
         

           <Button  variant="outlined"  sx={{
            textTransform:"inherit",
            display:"inline-block",
            width:"200px",
            marginTop:"10px"
           }}
           disabled={!disabledButtons}
           onClick={()=>{
            handleAddNewAction('actions')
           }}  >Add action</Button>
            </Box>

            <Divider />


            <Box
              sx={{
                display: "flex",
                gap: "10px",
                maxWidth: "80%",
                marginTop: "20px",
                marginBottom: "100px",
                justifyContent:"flex-end"
              }}
            >

           <Button  variant="text"  sx={{
            textTransform:"inherit",
            display:"inline-block",
            width:"100px",
            marginTop:"10px",
            color:"#707c97",
            background:"#f1f1fb",
            // color:"white"
           }} onClick={()=>{
            navigate("/automation/actions")
           }} >Cancel</Button>


          <Button  variant="outlined" onClick={ () => {componentType === "create" ? handleSubmitTriggers() : handleUpdateTrigger()}}   sx={{
            
            textTransform:"inherit",
            display:"inline-block",
            width:"100px",
            marginTop:"10px"
          }} disabled={!disabledButtons} >{componentType === "create" ?  "Create" : "Update"}</Button>
            </Box>

          </Box>


   </>
  )
}

export default CreateTrigger
