import { Box, Button, Typography } from "@mui/material";
import React  from "react";
import style from "./ticketTypeComponent.module.scss";
import PluseIcon from "../../../assets/searchIcons/PluseIcon";
const TicketTypeComponentToolbar = ({ heading, handleCreateTicketTypes}) => {

  

  return (
    <>
      <Box className={style['ticket-type-toolbar-component']}>
      <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Typography sx={{ fontSize: "20px", fontWeight: "bold" }}>
            {" "}
            Ticket types
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "flex-end", width: "50%" }}
          >
            <Button
            onClick={handleCreateTicketTypes}
            startIcon={
              <PluseIcon color="#a84acb" />

            }
              variant="outlined"
              sx={{
                background: "#f1f1fb",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
            >
           Create ticket types
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TicketTypeComponentToolbar;
