import { HttpClient } from "./HttpClient";

const summarizeTextApi = (data) => {
    const apiData = {
        method:'POST',
        url:`/summarize`,
        data:data
    }
    return HttpClient.llmCustom(apiData)
}


const askQueryApi = (data) => {
    const apiData = {
        method:'POST',
        url:`/query`,
        data:data
    }
    return HttpClient.llmCustom(apiData)

}

export {
    summarizeTextApi,
    askQueryApi
}
