import React from "react";

const AutoSettingIcon = ({height=40, width=40, color='#313131'}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 40 40"
        fill="none"
      >
        <path
          d="M10.2102 16.5219L29.531 16.5217C32.9608 16.5217 35.7412 13.7187 35.7412 10.2609C35.7412 6.80309 32.9608 4 29.531 4L10.2102 4.00013M10.2102 16.5219C6.78042 16.5219 4 13.7188 4 10.261C4 6.80321 6.78042 4.00013 10.2102 4.00013M10.2102 16.5219C13.6401 16.5219 16.4205 13.7188 16.4205 10.261C16.4205 6.80321 13.6401 4.00013 10.2102 4.00013M29.7898 23.4783H10.2102C6.78042 23.4783 4 26.2813 4 29.7391C4 33.1969 6.78042 36 10.2102 36H29.7898M29.7898 23.4783C33.2196 23.4783 36 26.2813 36 29.7391C36 33.1969 33.2196 36 29.7898 36M29.7898 23.4783C26.3599 23.4783 23.5795 26.2813 23.5795 29.7391C23.5795 33.1969 26.3599 36 29.7898 36"
          stroke={color}
          strokeWidth="2"
        />
      </svg>
    </>
  );
};

export default AutoSettingIcon;
