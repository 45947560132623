import { Box, Typography } from "@mui/material";
import React from "react";
import style from "./performace.module.scss"
const PerformaceToolbar = ({}) => {

  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
      <Box className="toolbarContent">
          <Typography  sx={{fontSize:"20px", fontWeight:"bold"}} > Performance</Typography>

        </Box>
      </Box>
    </>
  );
};

export default PerformaceToolbar;
