import React from "react";
import { Box } from "@mui/material";

const MainComponent = ({ children, className="", style }) => {
  return (
    <>
      <Box
        component="main"
        className={className}
        sx={{
          flexGrow: 1,
          background: "linear-gradient(180deg, #F3F3FB 0%, #FDFBFD 100%)",
          maxHeight: "100vh !important",
          height: "100vh",
          overflow: "scroll",
          paddingLeft: "10px",
          ...style
        //   border: "1px solid #e5e5e5",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default MainComponent;
