import React from "react";

const TagIcon = ({ color = "#707C97", height = 20, width = 20 }) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M7.08332 2.5H9.59314C10.2046 2.5 10.5104 2.5 10.7981 2.56908C11.0532 2.63032 11.297 2.73133 11.5207 2.8684C11.773 3.023 11.9892 3.23919 12.4216 3.67157L17.0833 8.33333M6.29149 8.37484H6.29983M7.92647 5H6.91666C5.51653 5 4.81646 5 4.28168 5.27248C3.81128 5.51217 3.42882 5.89462 3.18914 6.36502C2.91666 6.8998 2.91666 7.59987 2.91666 9V10.0098C2.91666 10.6213 2.91666 10.927 2.98573 11.2147C3.04697 11.4698 3.14798 11.7137 3.28506 11.9374C3.43966 12.1897 3.65585 12.4059 4.08823 12.8382L6.7549 15.5049C7.74494 16.4949 8.23996 16.99 8.81078 17.1754C9.31289 17.3386 9.85376 17.3386 10.3559 17.1754C10.9267 16.99 11.4217 16.4949 12.4118 15.5049L13.4216 14.4951C14.4116 13.5051 14.9066 13.01 15.0921 12.4392C15.2552 11.9371 15.2552 11.3962 15.0921 10.8941C14.9066 10.3233 14.4116 9.82828 13.4216 8.83824L10.7549 6.17157C10.3225 5.73919 10.1063 5.523 9.85404 5.3684C9.63036 5.23133 9.38649 5.13032 9.1314 5.06908C8.84368 5 8.53795 5 7.92647 5ZM6.70816 8.37484C6.70816 8.60496 6.52161 8.7915 6.29149 8.7915C6.06137 8.7915 5.87483 8.60496 5.87483 8.37484C5.87483 8.14472 6.06137 7.95817 6.29149 7.95817C6.52161 7.95817 6.70816 8.14472 6.70816 8.37484Z"
          stroke={color}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default TagIcon;
