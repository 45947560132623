import * as React from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import CustomerTicketTypeIcon from "../../../../assets/icons/CustomerTicketTypeIcon";
import { Typography } from "@mui/material";
import BackOfficeTicketTypeIcon from "../../../../assets/icons/BackOfficeTicketTypeIcon";
import TrackerTicketTypeIcon from "../../../../assets/icons/TrackerTicketTypeIcon";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
    width: "100%",
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]:
    {
      marginLeft: -1,
      borderLeft: "1px solid transparent",
    },
}));

export default function TicketTypeTabs({value, handleValueChange, isUpdatebleComponent}) {

  const handleAlignment = (event, newAlignment) => {
    handleValueChange(newAlignment)
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          display: "flex",
          border: (theme) => `1px solid ${theme.palette.divider}`,
          flexWrap: "wrap",
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          color="secondary"
          value={value}
          exclusive
          onChange={handleAlignment}
          aria-label="text alignment"
          style={{ width: "100%", height: "30px" }}
        >
          <ToggleButton
            disabled={isUpdatebleComponent}
            sx={{ width: "100%", gap: "8px", borderRadius: "10px" }}
            value="customer"
            aria-label="left aligned"
          >
            <CustomerTicketTypeIcon
              color={value === "customer" ? "#a84acb" : "black"}
            />
            <Typography sx={{ fontSize: "13px", textTransform: "initial" }}>
              {" "}
              Customer
            </Typography>
          </ToggleButton>
          <ToggleButton
            disabled={isUpdatebleComponent}
            sx={{ width: "100%", gap: "8px" }}
            value="office"
            aria-label="centered"
          >
            <BackOfficeTicketTypeIcon
              color={value === "office" ? "#a84acb" : "black"}
            />
            <Typography sx={{ fontSize: "13px", textTransform: "initial" }}>
              {" "}
              Back-office
            </Typography>
          </ToggleButton>
          <ToggleButton
            disabled={isUpdatebleComponent}
            sx={{ width: "100%", gap: "8px" }}
            value="tracker"
            aria-label="right aligned"
          >
            <TrackerTicketTypeIcon
              color={value === "tracker" ? "#a84acb" : "black"}
            />
            <Typography sx={{ fontSize: "13px", textTransform: "initial" }}>
              {" "}
              Tracker
            </Typography>
          </ToggleButton>
        </StyledToggleButtonGroup>
      </Paper>
    </div>
  );
}
