/* eslint-disable react-hooks/exhaustive-deps */
import ReactQuill, { Quill } from "react-quill";
import React, { useEffect, useMemo, useRef, useState } from "react";
import "quill-mention";
import "./mention.css"
import "react-quill/dist/quill.snow.css";
import editorCss from "./editor.module.scss"
import quillEmoji from 'quill-emoji';
import 'react-quill/dist/quill.snow.css';
import "quill-emoji/dist/quill-emoji.css";
import { Button } from "@mui/material";

const { EmojiBlot, ShortNameEmoji, ToolbarEmoji, TextAreaEmoji } = quillEmoji;

Quill.register({
  'formats/emoji': EmojiBlot,
  'modules/emoji-shortname': ShortNameEmoji,
  'modules/emoji-toolbar': ToolbarEmoji,
  'modules/emoji-textarea': TextAreaEmoji
}, true);

export default function QuillEditor({
  onChange,
  defaultValue,
  placeholder,
  theme,
  value:editorValue,
  tags,
  mentions
}) {

  const [mentionData,setMentionData] = useState([])
  const [tagData,setTagData] = useState([])
  const editor = useRef();

  useEffect(()=>{
    let mention = mentions.map(ele=>({
        id:ele.id,
        value:ele.name
      }))
      let tag = tags.map(ele=>({
        id:ele.id,
        value:ele.name
      }))
      setTagData(tag)
      setMentionData(mention)

  },[tags, mentions])
  const modules = useMemo(()=>({
    toolbar: [
      ["bold"],
      ["italic"],
      ['underline'],
      ["image"],
      ["link"],
      [{ size: ["small", false, "large", "huge"] }]["clean"],
      [{ color: [] }],
      [{ background: [] }],
      [{ list: "ordered" }],
      [ { list: "bullet" }],
      [{ script: "sub" }],
      [ { script: "super" }],
      ['emoji']
    ],
    'emoji-toolbar': true,
    "emoji-shortname": true,
    clipboard: {
      matchVisual: true,
    },
    mention: {
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ["@", "#"],
      source: function (searchTerm, renderList, mentionChar) {
        let values;

        if (mentionChar === "@") {
          values = mentionData;
        } else {
          values = tagData;
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm);
        } else {
          const matches = [];
          for (let i = 0; i < values.length; i++)
            if (
              ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
            )
              matches.push(values[i]);
          renderList(matches, searchTerm);
        }
      },
    },
  }),[mentionData,tagData])




  // useEffect(() => {
  //   if (defaultValue) {
  //     const delta = editor.current.editor.clipboard.convert(defaultValue);
  //     editor.current.editor.setContents(delta, "silent");
  //   }
  // }, [defaultValue]);
  // const formats = ['font', 'header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'code-block', 'color', 'background', 'list', 'indent', 'align', 'link', 'image', 'clean', 'emoji']



  return (
    <div className={`w-400 h-full ${editorCss.mentionCssClass}`}>
      <ReactQuill   
        value={editorValue.content}
        ref={editor}
        theme={'snow'}
        modules={{ ...modules }}
        onChange={onChange}
        
        placeholder={placeholder}
      />
    </div>
  );
}
