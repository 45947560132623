import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Box } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
    //   display: true,
    //   text: 'Chart.js Line Chart',
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: labels.map(
        () => Math.floor(Math.random() * (1000 - -1000 + 1)) + 1000
      ),
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: labels.map(
        () => Math.floor(Math.random() * (1000 - -1000 + 1)) + 1000
      ),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const LineChartComponent = ({chartData}) => {
    let {data , labels} = chartData
    const newChartData = {
       labels,
       datasets: data,
     };
  return (
    <Box sx={{   width:"100%" }}>
      <Line options={options} data={newChartData} />
    </Box>
  );
};

export default LineChartComponent;
