import React, { useContext, useEffect, useState } from "react";
import MainComponent from "../../../../../fixedComponent/sideDashboard/MainComponent";
import SideToolbar from "../../../../../fixedComponent/sideToolbar/SideToolbar";
import {
  Box,
  Divider,
  Typography,
  Button,
  Avatar,
  Paper,
  InputBase,
} from "@mui/material";
import style from "./userProfile.module.scss";
import { MainContext } from "../../../../../context/MainProvider";
import CaretDownIcon from "../../../../../assets/icons/CaretDownIcon";
import {
  PullseTickets,
  allCustomers,
  getCustomerDetails,
  updateCustomerDetails,
} from "../../../../../services/Apis";
import { get } from "lodash";
import Grid from "@mui/material/Grid";
import UserIcon from "../../../../../assets/searchIcons/UserIcon";
import LocationIcon from "../../../../../assets/icons/LocationIcon";
import EmailBoxIcon from "../../../../../assets/icons/EmailBoxIcon";
import SwitchIcon from "../../../../../assets/icons/SwitchIcon";
import CheckIcon from "../../../../../assets/icons/CheckIcon";
import { useNavigate, useParams } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import useStateCallback from "../../../../../utils/hooks/useStateCallback";
import { customFieldIcons } from "../../../../../utils/helpers/helpers";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import { setValueUsingCustomFields } from "../../../../../services/Apis";
import BuildingIcon from "../../../../../assets/icons/BuildingIcon";
import moment from "moment";
import { SidebarContext } from "../../../../../context/SidebarProvider";
import CustomeAttributeComponent from "../../../../customeAttribute/CustomeAttributeComponent";

// const currencies = [
//   {
//     value: "USD",
//     label: "$",
//   },
//   {
//     value: "EUR",
//     label: "€",
//   },
//   {
//     value: "BTC",
//     label: "฿",
//   },
//   {
//     value: "JPY",
//     label: "¥",
//   },
// ];

const UserProfile = ({ ToolbarComponent, filterValue }) => {
  const { chatbotProfiles, compinesData, customeFieldsData } = useContext(MainContext);
  const [usersLoading, setUsersLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [editableNote, setEditableNote] = useState(false);
  const [customerCustomFields, setCustomerCustomFields] = useState([]);
  const [customerTickets , setCustomerTickets] = useState([])
  const [profileDetailEditData, setProfileDetailEditData] = useStateCallback({
    state: false,
    data: {},
  });
  const [newAttributeValue, setNewAttributeValue] = useState({
    state: false,
    data: {
      currentAttributeType:{},
      attrType: "",
      attrValue:"",

    },
  })
  const [viewCustomFieldValues,setViewCustomFieldValues] = useState([])
  const { handleShowSnackPopup } = useContext(SidebarContext)
  const navigate = useNavigate()
  const { id:profileId } = useParams();
  const handleGetUserTickets = () => {
    PullseTickets({type:'customer_id', data:profileId},{type:'limit',data:4}).then(res=>{
      let response = get(res,'data.data.docs',[]);
      setCustomerTickets(response)

    })
  }

  useEffect(()=>{
    handleGetUserTickets()

  },[])




  const handleSetCustomeFieldsValue = () => {
    let tempCustomeFields = {...get(userDetails,'customFields',{})};
    let tempViewCustomFieldValues = [];
    Object.keys(tempCustomeFields).forEach((eleKey)=>{
    let customFieldData  = customerCustomFields.find(ele=>ele.id === eleKey);
    if(customFieldData){
      tempViewCustomFieldValues.push({
        customFieldData,
        value:tempCustomeFields[eleKey],
        id:eleKey,
        editable:false
      })
    }      
    })
    setViewCustomFieldValues(tempViewCustomFieldValues)
  }
  useEffect(()=>{
    handleSetCustomeFieldsValue()
  },[userDetails, customerCustomFields])
  const handleAddAttriuteValue = () => {
    setNewAttributeValue((state)=>({ 
      data: {
        currentAttributeType:{},
        attrType: "",
        attrValue:""
      },
      state:true
    }))
  }

  const handleGetUserDetails = () => {
    getCustomerDetails(profileId).then((res) => {
      let data = get(res, "data.data", {});
      setUserDetails(data);
      setProfileDetailEditData({
        state: false,
        data: {},
      });
      setEditableNote(false)
    }).catch(err=>{
      navigate(`/contacts/people/${filterValue}`);
      handleShowSnackPopup({message:"invalid user id", error:true})
    })
  };

  useEffect(() => {
    handleGetUserDetails();
  }, []);


  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setProfileDetailEditData((state) => ({
      ...state,
      data: {
        ...state.data,
        [name]: value,
      },
    }));
  };

  const handleUpdateUserProfile = () => {
    updateCustomerDetails(profileId, {
      ...get(profileDetailEditData, "data", {}),
    }).then((res) => {
      handleGetUserDetails();
    });
  };

  const handeUpdateNote = () => {
    setProfileDetailEditData({
      state:false,
      data:{
        notes:get(userDetails,"notes","")
      }
    },()=>{
      setEditableNote(true)
    })

  }

  useEffect(()=>{
    let tempCustomFields = [...get(customeFieldsData,'customer',[])];
    let clearObj = {
      id:"cancel",
      name:"Cancel"
    };  
    tempCustomFields.unshift(clearObj)
    setCustomerCustomFields([...tempCustomFields])
  },[get(customeFieldsData,'customer',[])])


  const handleCollectCustomeFieldValue = (event,element,name) => {

    if(element.id === "cancel"){
      setNewAttributeValue({
        state:false,
        data:{
          currentAttributeType:{},
          attrType: "",
          attrValue:""
        }
      })
      return
    }
    if(name=== "attrValue"){
      setNewAttributeValue((state)=>({
        ...state,
        data:{
          ...state.data,
          attrValue:event.target.value
        }
      }))
    }else{
      setNewAttributeValue((state)=>({
        ...state,
        data:{
          attrType:element.id,
          attrValue:"",
          currentAttributeType:element
        }
      }))
    }
  }

  const handleSetCustomFieldValue = () => {
    let customFiledId = get(newAttributeValue,"data.currentAttributeType.id")
    let customFieldPayload = {
      fieldValue:get(newAttributeValue,"data.attrValue"),
      entityId:profileId,
    }
    setValueUsingCustomFields(customFiledId,customFieldPayload).then((res)=>{
      handleGetUserDetails()
      setNewAttributeValue({
        state:false,
        data:{
          currentAttributeType:{},
          attrType: "",
          attrValue:""
        }
      })
    })
  }

  const handleSetCustomFieldEditable = (index, editable=false) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues]
    tempViewCustomFieldValues[index]['editable'] = editable;
    setViewCustomFieldValues(tempViewCustomFieldValues);
  }


  const handleSetViewCustomFieldValues = (event, index) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues]
    tempViewCustomFieldValues[index]['value'] = event.target.value;
    setViewCustomFieldValues(tempViewCustomFieldValues);
  }

  const handleUpdateCustomFieldData = (index) => {
    let tempViewCustomFieldValues = [...viewCustomFieldValues]
    let tempCustomFieldValue = tempViewCustomFieldValues[index];
    let customFiledId = get(tempCustomFieldValue,"id","")
    let customFieldPayload = {
      fieldValue:get(tempCustomFieldValue,"value"),
      entityId:profileId,
    }
    setValueUsingCustomFields(customFiledId,customFieldPayload).then((res)=>{
      handleGetUserDetails()
    })
  }

  const handleOnBlurViewCustomField = (event,index) => {
    // handleSetCustomFieldEditable(index)


  }


  // let customeFields
  
  const currentCompany = get(compinesData.find(ele=>ele.id ===userDetails.companyId),'name',"")
  return (
    <>
      <MainComponent className={style.userprofileBody}>
        <Box
          sx={{
            padding: "0px 10px",
            height: "95vh",
            background: "white !important",
          }}
        >
          <SideToolbar>
            {ToolbarComponent && <ToolbarComponent userDetails={userDetails} />}
          </SideToolbar>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              overflowY: "scroll",
              gap: "10px",
              maxHeight: "88vh",
            }}
          >
            <Box sx={{ flexGrow: 1, padding: "20px 5px" }}>
              <Grid container spacing={1}>
                <Grid container item xs={12}>
                  <Grid
                    item
                    xs={3}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      padding: "0px 10px",
                      alignItems: "flex-end",
                    }}
                  >
                    <Typography sx={{ fontWeight: "bold" }}>Details</Typography>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      sx={{
                        background: "#f1f1fb",
                        color: "#707c97",
                        textTransform: "capitalize",
                      }}
                      endIcon={<CaretDownIcon />}
                      variant="text"
                    >
                      Their messages & conversations
                    </Button>
                  </Grid>
                </Grid>
                <Grid item xs={3} className="user-details-container">
                  <Box className="user-display-detail-card user-card">
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Avatar
                        sx={{
                          height: "25px",
                          width: "25px",
                          background: "#E87373",
                        }}
                      >
                        {get(
                          get(userDetails, "name", ""),
                          "[0]",
                          ""
                        ).toUpperCase()}
                      </Avatar>
                      {get(profileDetailEditData, "state", false) ? (
                        <>
                          <Paper
                            component="box"
                            sx={{
                              p: "1px 2px",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: 30,
                              padding: "10px",
                              boxShadow: "none",
                              border: `1px solid rgba(0,0,0,0.2)`,
                            }}
                          >
                            <InputBase
                              value={get(
                                profileDetailEditData,
                                "data.name",
                                ""
                              )}
                              autoFocus={true}
                              onChange={handleCollectData}
                              name="name"
                              sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                            />
                          </Paper>
                        </>
                      ) : (
                        <>
                          <Typography
                            sx={{
                              color: "gray",
                              fontSize: "14px",
                              textTransform: "capitalize",
                            }}
                          >
                            {get(userDetails, "name", "")}
                          </Typography>
                        </>
                      )}
                    </Box>

                    {/* <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <UserIcon height="16" width="16" />
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                      { get(userDetails,"name","") }
                      </Typography>
                    </Box> */}

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <UserIcon height="16" width="16" />
                      {false ? (
                        <>
                          <Paper
                            component="box"
                            sx={{
                              p: "1px 2px",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: 30,
                              padding: "10px",
                              boxShadow: "none",
                              border: `1px solid rgba(0,0,0,0.2)`,
                            }}
                          >
                            <Select
                              onChange={handleCollectData}
                              name="type"
                              value={get(
                                profileDetailEditData,
                                "data.type",
                                ""
                              )}
                              input={
                                <InputBase
                                  variant="standard"
                                  autoFocus={true}
                                  sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                                ></InputBase>
                              }
                            >
                              <MenuItem value="cusomter">Customer</MenuItem>
                              <MenuItem value="lead">visito</MenuItem>
                            </Select>
                          </Paper>
                        </>
                      ) : (
                        <>
                          <Typography sx={{ color: "gray", fontSize: "14px" }}>
                            {get(userDetails, "type", "")}
                          </Typography>
                        </>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <LocationIcon height={14} width={14} />
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        Unknown
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <EmailBoxIcon height="14" width="14" />
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        Email
                      </Typography>
                      {get(profileDetailEditData, "state", false) ? (
                        <>
                          <Paper
                            component="box"
                            sx={{
                              p: "1px 2px",
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: 30,
                              padding: "10px",
                              boxShadow: "none",
                              border: `1px solid rgba(0,0,0,0.2)`,
                            }}
                          >
                            <InputBase
                              onChange={handleCollectData}
                              value={get(
                                profileDetailEditData,
                                "data.email",
                                ""
                              )}
                              autoFocus={true}
                              name="email"
                              sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                            />
                          </Paper>
                        </>
                      ) : (
                        <>
                          <Typography sx={{ color: "#222", fontSize: "14px" }}>
                            {get(userDetails, "email", "")}
                          </Typography>
                        </>
                      )}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <SwitchIcon height="14" width="14" />
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        User id
                      </Typography>
                      <Typography
                        sx={{
                          color: "#222",
                          fontSize: "14px",
                          textOverflow: " ",
                        }}
                      >
                        {`${get(userDetails, "id", "").substring(0, 20)}  ${
                          get(userDetails, "id", "").length > 20 ? "..." : null
                        }`}
                      </Typography>
                    </Box>







                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      {/* <SwitchIcon height="14" width="14" />
                       */}
                       <BuildingIcon  color="black" />
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>
                        Compnay
                      </Typography>
                      {get(profileDetailEditData, "state", false) ? (
                        <>
                           <Select
                            defaultValue=""
                            placeholder="text"

                            sx={{
                              
                              p: "1px 2px",
                              display: "flex",
                              alignItems: "center",
                              borderRadius:"5px",
                              width: "100%",
                              height: 40,
                              padding: "10px",
                              boxShadow: "none",
                              border: "1px solid rgba(0,0,0,0.2)",
                            }}
                            onChange={handleCollectData}
                            value={get(
                              profileDetailEditData,
                              "data.companyId",
                              ""
                            )}
                            name="companyId"
                            input={
                              <InputBase
                                placeholder="company"
                                name="companyId"
                            variant="standard"
                            autoFocus={true}
                            sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                          />
                        }
                      >
                        <MenuItem value="" disabled>
                          <em>Company</em>
                        </MenuItem>
                        {compinesData.map((ele) => (
                          <MenuItem value={ele.id}>{ele.name}</MenuItem>
                        ))}
                       </Select>
                        </>
                      ) : (
                        <>
                         <Typography
                        sx={{
                          color: "#222",
                          fontSize: "14px",
                          textOverflow: " ",
                        }}
                      >

                       {currentCompany}
                      </Typography>
                        </>
                      )}
                      
                    </Box>

                    {/* <Divider sx={{ margin:"0", padding:"0"}} > custom attributes</Divider> */}

                        <CustomeAttributeComponent handleGetComponentDetails={handleGetUserDetails} componentDetails={userDetails} componentEntityId={profileId}   componentType="customer" displayIcon={true}  />






                        {/* {
                          viewCustomFieldValues.map(({id,value,editable, customFieldData},index)=>{

                           return ( <Box
                            sx={{
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                          >
                                                      
                            <SwitchIcon height="14" width="14" />
                            <Typography sx={{ color: "gray", fontSize: "14px" }}>
                             {customFieldData.name} 
                            </Typography>
                            {
                              !editable ? (
                                <Typography
                                onClick={()=>handleSetCustomFieldEditable(index, true)}
                                  sx={{
                                    color: "#222",
                                    fontSize: "14px",
                                    textOverflow: " ",
                                  }}
                                >
                                  {`${value.substring(0, 20)}  ${
                                    value.length > 20 ? "..." : ""
                                  }`}
                                </Typography>
                              )
                              :
                              (
                                <>
                                
                              {["text", "number"].includes(
                                get(
                                  customFieldData,
                                  "fieldType",
                                  ""
                                )
                              ) ? (
                                <>
                                  <Paper
                                    component="box"
                                    sx={{
                                      p: "1px 2px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                      height: 20,
                                      padding: "10px",
                                      boxShadow: "none",
                                      border: `1px solid rgba(0,0,0,0.2)`,
                                    }}
                                  >
                                    <InputBase
                                      type={get(
                                        customFieldData,
                                        "fieldType",
                                        ""
                                      )}
                                      autoFocus={true}
                                      onBlur={(event)=>{
                                        handleOnBlurViewCustomField(event,index)
                                      }}

                                      value={value}
                                      name={get(
                                        customFieldData,
                                        "name",
                                        ""
                                      )}
                                      onChange={(event)=>{
                                        handleSetViewCustomFieldValues(event,index)
                                      }}
                                      sx={{
                                        ml: 1,
                                        flex: 1,
                                        fontSize: "13px",
                                        "::placeholder": { fontSize: "10px" },
                                        WebkitAppearance: "none",
                                        "::-webkit-outer-spin-button": {
                                          WebkitAppearance: "none", // Also use camelCase here
                                          margin: 0,
                                        },
                                      }}
                                      placeholder={get(
                                        customFieldData,
                                        "placeholder",
                                        ""
                                      )}
                                    />
                                  </Paper>
                                </>
                              ) : ["select"].includes(
                                get(
                                  customFieldData,
                                  "fieldType",
                                  ""
                                )
                                ) ? (
                                <>
                                  <Select
                                  autoFocus={true}
                                    sx={{
                                      width: "45%",
                                      border: "1px solid rgba(0,0,0,0.1)",
                                      fontSize: "13px",
                                      paddingLeft: "5px",
                                      height: "23px",
                                      borderRadius: "5px",
                                    }}
                                    onBlur={(event)=>{
                                      handleOnBlurViewCustomField(event,index)
                                    }}
                                    value={value}
                                    onChange={(event)=>{
                                      handleSetViewCustomFieldValues(event,index)
                                    }}
                                    placeholder={get(
                                      customFieldData,
                                      "placeholder",
                                      ""
                                    )}
                                    name={get(
                                      customFieldData,
                                      "name",
                                      ""
                                    )}
                                    input={
                                      <InputBase
                                        variant="standard"
                                        autoFocus={true}
                                        sx={{
                                          ml: 1,
                                          flex: 1,
                                          fontSize: "13px",
                                        }}
                                      ></InputBase>
                                    }
                                  >
                                    {get(
                                      customFieldData,
                                      "options",
                                      []
                                    ).map((ele) => (
                                      <MenuItem
                                        value={ele}
                                        sx={{ fontSize: "13px" }}
                                      >
                                        {ele}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              ) : null}
                             {

                              value ?
                               <>
                               <CheckCircleOutlineRoundedIcon
                                onClick={()=>{
                                  handleUpdateCustomFieldData(index)
                                }}
                                sx={{
                                  fontSize: "17px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              /> </>
                               :
                               null                              
                              }
                            </>                
                              )
                            }                                      
                          </Box>)
                          })
                        }

                    {newAttributeValue.state ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                          }}
                        >
                          <Select
                            defaultOpen={true}
                            sx={{
                              width: "45%",
                              border: "1px solid rgba(0,0,0,0.1)",
                              fontSize: "13px",
                              paddingLeft: "5px",
                              height: "23px",
                              borderRadius: "5px",
                            }}
                            name="attrType"
                            input={
                              <InputBase
                                variant="standard"
                                autoFocus={true}
                                sx={{ ml: 1, flex: 1, fontSize: "13px" }}
                              ></InputBase>
                            }
                          >
                            {customerCustomFields.map((ele) => {
                              const Icon = customFieldIcons[ele.fieldType];

                              return (
                                <MenuItem
                                  name="attrType"
                                  onClick={(event) =>
                                    handleCollectCustomeFieldValue(
                                      event,
                                      ele,
                                      "attrType"
                                    )
                                  }
                                  value={ele.id}
                                  sx={{
                                    fontSize: "13px",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  {Icon && <Icon />} {ele.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                          {newAttributeValue.data.attrType ? (
                            <>
                              {["text", "number"].includes(
                                get(
                                  newAttributeValue,
                                  "data.currentAttributeType.fieldType",
                                  ""
                                )
                              ) ? (
                                <>
                                  <Paper
                                    component="box"
                                    sx={{
                                      p: "1px 2px",
                                      display: "flex",
                                      alignItems: "center",
                                      width: "100%",
                                      height: 20,
                                      padding: "10px",
                                      boxShadow: "none",
                                      border: `1px solid rgba(0,0,0,0.2)`,
                                    }}
                                  >
                                    <InputBase
                                      type={get(
                                        newAttributeValue,
                                        "data.currentAttributeType.fieldType",
                                        ""
                                      )}

                                      value={get(
                                        newAttributeValue,
                                        "data.attrValue",
                                        ""
                                      )}
                                      name={get(
                                        newAttributeValue,
                                        "data.currentAttributeType.name",
                                        ""
                                      )}
                                      onChange={(event)=>{
                                        handleCollectCustomeFieldValue(
                                          event,
                                          {},
                                          "attrValue"
                                        )
                                      }}
                                      sx={{
                                        ml: 1,
                                        flex: 1,
                                        fontSize: "13px",
                                        "::placeholder": { fontSize: "10px" },
                                        WebkitAppearance: "none",
                                        "::-webkit-outer-spin-button": {
                                          WebkitAppearance: "none", // Also use camelCase here
                                          margin: 0,
                                        },
                                      }}
                                      placeholder={get(
                                        newAttributeValue,
                                        "data.currentAttributeType.placeholder",
                                        ""
                                      )}
                                    />
                                  </Paper>
                                </>
                              ) : ["select"].includes(
                                  get(
                                    newAttributeValue,
                                    "data.currentAttributeType.fieldType",
                                    ""
                                  )
                                ) ? (
                                <>
                                  <Select
                                    sx={{
                                      width: "45%",
                                      border: "1px solid rgba(0,0,0,0.1)",
                                      fontSize: "13px",
                                      paddingLeft: "5px",
                                      height: "23px",
                                      borderRadius: "5px",
                                    }}
                                    value={get(
                                      newAttributeValue,
                                      "data.attrValue",
                                      ""
                                    )}
                                    onChange={(event)=>{
                                      handleCollectCustomeFieldValue(
                                        event,
                                        event.target.value,
                                        "attrValue"
                                      )
                                    }}
                                    name={get(
                                      newAttributeValue,
                                      "data.currentAttributeType.name",
                                      ""
                                    )}
                                    input={
                                      <InputBase
                                        variant="standard"
                                        autoFocus={true}
                                        sx={{
                                          ml: 1,
                                          flex: 1,
                                          fontSize: "13px",
                                        }}
                                      ></InputBase>
                                    }
                                  >
                                    {get(
                                      newAttributeValue,
                                      "data.currentAttributeType.options",
                                      []
                                    ).map((ele) => (
                                      <MenuItem
                                        value={ele}
                                        sx={{ fontSize: "13px" }}
                                      >
                                        {ele}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </>
                              ) : null}
                            </>
                          ) : null}

                          {newAttributeValue.data.attrValue ? (
                            <>
                              <CheckCircleOutlineRoundedIcon
                                onClick={handleSetCustomFieldValue}
                                sx={{
                                  fontSize: "17px",
                                  color: "green",
                                  cursor: "pointer",
                                }}
                              />
                            </>
                          ) : null}
                        </Box>
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "2px",
                            alignItems: "flex-start",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "gray",
                              fontSize: "14px",
                              color: "black",
                            }}
                          >
                            Attributes
                          </Typography>
                          <Typography
                            onClick={handleAddAttriuteValue}
                            sx={{
                              fontSize: "14px",
                              color: "#a84acb",
                              cursor: "pointer",
                            }}
                          >
                            +Add
                          </Typography>
                        </Box>
                      </>
                    )} */}

                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      {get(profileDetailEditData, "state", false) && (
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "red",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setProfileDetailEditData({
                              state: false,
                              data: {},
                            });
                          }}
                        >
                          Cancel
                        </Typography>
                      )}

                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#9b55b6",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (get(profileDetailEditData, "state", false)) {
                            handleUpdateUserProfile();
                          } else {
                            setProfileDetailEditData({
                              state: true,
                              data: { ...userDetails },
                            });
                          }
                        }}
                      >
                        {get(profileDetailEditData, "state", false)
                          ? "Update"
                          : "Edit"}
                      </Typography>
                    </Box>


                    
                  </Box>
                  <Box className="user-display-detail-card notes-card">
                    <Typography sx={{ fontSize: "14px", marginLeft: "5px" }}>
                      User notes
                    </Typography>

                    {editableNote ? (
                      <>
                        <Box
                          sx={{
                            height: "100%",
                            width: "100%",
                            overflow: "hidden",
                          }}
                        >
                          <TextareaAutosize
                            autoFocus
                            name="notes"
                            value={get(profileDetailEditData, "data.notes", "")}
                            onChange={handleCollectData}
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                if (
                                  get(profileDetailEditData, "data.notes", "")
                                    .length >= 3
                                ) {
                                  handleUpdateUserProfile();
                                }
                              }
                            }}
                            maxRows={15}
                            placeholder="add your note here"
                            minRows={8}
                            style={{
                              outerHeight: "100%",
                              width: "100%",
                              fontSize: "12px",
                            }}
                          />
                        </Box>
                      </>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "#222",
                          wordBreak: "break-all",
                        }}
                        onDoubleClick={handeUpdateNote}
                      >
                        {get(
                          userDetails,
                          "notes",
                          "double click to add your note here..."
                        )}
                      </Typography>
                    )}
                  </Box>

                  {/* <Box className="user-display-detail-card notes-card">
                  <Typography sx={{fontSize:"14px",marginLeft:"5px"}} >Qualification</Typography>
                  <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                     <span  style={{display:'inline-block', height:"15px", width:"15px", background:"#1BB157 ",borderRadius:"100%", display:"flex",justifyContent:'center', alignItems:"center"}} >  <CheckIcon /> </span>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>  
                        Name
                      </Typography>
                      <Typography sx={{ color: "#222", fontSize: "14px" }}>  
                      Suhail Joo
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                     <span  style={{display:'inline-block', height:"15px", width:"15px", background:"#1BB157 ",borderRadius:"100%", display:"flex",justifyContent:'center', alignItems:"center"}} >  <CheckIcon /> </span>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>  
                      Email
                      </Typography>
                      <Typography sx={{ color: "#222", fontSize: "14px" }}>  
                      Suhail@pullse.com
                      </Typography>
                    </Box>
                 
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                     <span  style={{display:'inline-block', height:"15px", width:"15px", background:"#E8E8E8 ",borderRadius:"100%", display:"flex",justifyContent:'center', alignItems:"center"}} >  <CheckIcon /> </span>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>  
                      Phone
                      </Typography>
                      <Typography sx={{ color: "#222", fontSize: "14px",color:"#8F1EB3",cursor:"pointer" }}>  
                      + Add
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                     <span  style={{display:'inline-block', height:"15px", width:"15px", background:"#E8E8E8 ",borderRadius:"100%", display:"flex",justifyContent:'center', alignItems:"center"}} >  <CheckIcon /> </span>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>  
                      Company name
                      </Typography>
                      <Typography sx={{ color: "#222", fontSize: "14px", color:"#8F1EB3",cursor:"pointer" }}>  
                      + Add
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                     <span  style={{display:'inline-block', height:"15px", width:"15px", background:"#E8E8E8 ",borderRadius:"100%", display:"flex",justifyContent:'center', alignItems:"center"}} >  <CheckIcon /> </span>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>  
                      Company size
                      </Typography>
                      <Typography sx={{ color: "#222", fontSize: "14px",color:"#8F1EB3",cursor:"pointer" }}>  
                      + Add
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                     <span  style={{display:'inline-block', height:"15px", width:"15px", background:"#E8E8E8 ",borderRadius:"100%", display:"flex",justifyContent:'center', alignItems:"center"}} >  <CheckIcon /> </span>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>  
                      Company website
                      </Typography>
                      <Typography sx={{ color: "#222", fontSize: "14px",color:"#8F1EB3",cursor:"pointer" }}>  
                      + Add
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                     <span  style={{display:'inline-block', height:"15px", width:"15px", background:"#E8E8E8 ",borderRadius:"100%", display:"flex",justifyContent:'center', alignItems:"center"}} >  <CheckIcon /> </span>
                      <Typography sx={{ color: "gray", fontSize: "14px" }}>  
                      Company industry
                      </Typography>
                      <Typography sx={{ color: "#222", fontSize: "14px",color:"#8F1EB3",cursor:"pointer" }}>  
                      + Add
                      </Typography>
                    </Box>


                    <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontWeight:"bold",cursor:"pointer", fontSize: "14px", color:"#A84ACB" }}>  
                      Setting
                      </Typography>
                     
                    </Box>

                  </Box> */}
                </Grid>
                <Grid item xs={6} className="user-conversations">
                  {
                    [customerTickets.map((ele)=>{
                      let momentTime = moment(ele.createdAt)
                     let  creationTime =  momentTime.isValid()  ? momentTime.fromNow() : ""

                      
                      
                      
                      return(
                      <Box className="conversaton-card">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                          }}
                        >
                          <Avatar sx={{ height: "32px", width: "32px" }}>
                            H
                          </Avatar>
                          <Typography sx={{ fontSize: "15px" }}>
                            You assigned this{" "}
                            <span style={{ color: "#A84ACB" }}>conversation</span>{" "}
                            with{" "}
                            <span style={{ color: "#A84ACB" }}>suahil joo</span>{" "}
                            to yourself
                          </Typography>
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: "14px", color: "gray" }}>
                            {creationTime}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography sx={{ fontSize: "14px", color: "gray" }}>
                          Hello
                        </Typography>
                      </Box>
  
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "10px",
                        }}
                      >
                        <Avatar sx={{ height: "26px", width: "26px" }}>H</Avatar>
                        <Typography sx={{ fontSize: "14px", color: "gray" }}>
                          hello whats the status of project?
                        </Typography>
                      </Box>
                    </Box>
                    )
                  }) ]
                  }

                </Grid>
                <Grid item xs={3} className="user-envents">
                  {/* <Box className="event-card">
                    <Typography sx={{ fontSize: "15px" }}>
                      Recent events
                    </Typography>
                    <Typography sx={{ fontSize: "14px", color: "gray" }}>
                      No recent events
                    </Typography>
                  </Box> */}

                  {/* <Box className="event-card">
                    <Typography sx={{ fontSize: "15px" }}>
                      Recent content
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", color: "gray" }}>
                        7d ago
                      </Typography>
                    </Box>
                  </Box> */}
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </MainComponent>
    </>
  );
};

export default UserProfile;
