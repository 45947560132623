import React, { useContext } from "react";
import { Box } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import OpenIcon from "../../../../assets/searchIcons/OpenIcon";
import ClosedIcon from "../../../../assets/searchIcons/ClosedIcon";
import SnoozedIcon from "../../../../assets/searchIcons/SnoozedIcon";
import { MainContext } from "../../../../context/MainProvider";
import RefreshIcon from '@mui/icons-material/Refresh';
// "id": "in-progress",
// "name": "In Progress",

const data = [
    {
        name: "All",
        color: "#E89D73",
        Icon: OpenIcon,
        key:'All',
        value:""
    },
    {
      name: "Open",
      color: "#E89D73",
      Icon: OpenIcon,
      key:'Open',
      value:"submitted"
    },
    {
      name: "Closed",
      color: "#E89D73",
      Icon: ClosedIcon,
      key:'Closed',
      value:"closed"
    },
    {
      name: "In Progress",
      color: "#738DE8",
      Icon: RefreshIcon,
      key:'In-Progress',
      value:"in-progress"
    },
  ];

const StatusMenuBar = ({handleClose , onSelectFilter}) => {

    const { handleFetchTicketForInbox } = useContext(MainContext)
  const handleChangeTicketStatus = (key,value) =>{
    onSelectFilter({type:'status', value:key});
    handleFetchTicketForInbox({type:'status',data:value})
    handleClose()
  }
  return (
  <>
   <Box sx={{ width: "180px", padding: "0px" }} className={"box"}>
      <List
        dense
        sx={{ width: "100%", maxWidth: 380, bgcolor: "background.paper" }}
      >
        {data.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          let Icon = value.Icon;

          return (
            <ListItem key={value} disablePadding onClick={()=>handleChangeTicketStatus(value.key, value.value)}>
              <ListItemButton>
                <ListItemAvatar sx={{ display: "flex" }}>
                  <Icon />
                </ListItemAvatar>
                <ListItemText  sx={{textWrap:'no-wrap'}} id={labelId} primary={value.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  
  
  </>)
}

export default StatusMenuBar;