import { Box, Typography } from '@mui/material'
import React from 'react'
const TrackerType = () => {
  return (
  <>
        <Box  sx={{display:'flex', flexDirection:"column", gap:"1px"}} >
      <Typography sx={{fontSize:"14px", color:"black", fontWeight:"bold"}} >
      Customer sharing settings
      </Typography>

      <Typography sx={{fontSize:"13px", color:"gray",}} >
      Tracker tickets are never shared with the customer
      </Typography>
     
        </Box></>
  )
}

export default TrackerType

