import React, { useCallback } from "react";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import moment from "moment";
import messageStyle from "./messageBoxStyle.module.scss";
import { get } from "lodash";
const currentColor = {
  "QA":'orange',
  "note":"yellow",
  "summary":"#87CEEB"

}

const MessageBox = ({message, type='sender', createdAt="", color='',messageType=""}) => {
  const getTimeFunction =   useCallback(() => {
    let momentTime = moment(createdAt)
    return momentTime.isValid()  ? momentTime.fromNow() : ""
  },[createdAt])
  let exactPosition = type === 'sender';
  let messageTime  = getTimeFunction()
  let obj = {left:'0px'}
  if(exactPosition){
    obj = {right:'0px'}

  }
  console.log(messageType,"messageType")


  return (
    <>
      <Box className={messageStyle.mainMessageBox} component={'span'} sx={{ maxWidth:'90%',alignSelf:exactPosition ? 'flex-end':'flex-start' , display:'flex', alignItems:'center', }} >
      <Box sx={{color:get(currentColor,`${messageType}`,'').length ? get(currentColor,`${messageType}`,'') === "yellow" ? "black": "white" :'#707C97',background:exactPosition ?  get(currentColor,`${messageType}`,'transparent') :'#f9f4fc', display:"inline-block", padding:'10px 10px',borderRadius:exactPosition ? '10px 10px 0px 10px':' 0px 10px 10px 10px ', position:'relative', border:exactPosition ?'1px solid rgba(112, 124, 151, 0.25)' :'none', "overflowWrap": 'break-word'}}   >
       <span dangerouslySetInnerHTML={{__html:message}} className="messageCom"/> 
      <span style={{position:'absolute', bottom:'-20px', ...obj, color:'gray', fontSize:'12px',textWrap:'nowrap'}} > {messageTime}</span>
      </Box>
     {exactPosition && <DoneAllIcon  sx={{marginLeft:'12px', alignItems:'center', fontSize:'15px', color:'gray'}} />}
      </Box>
    </>
  );
};

export default MessageBox;
