import React from "react";
import HocCommonButton from "./HocCommonButton";
import { Button, Menu, MenuItem , Box} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CheckIcon from "@mui/icons-material/Check";
const CommonButton = ({open, handleClick, handleClose,anchorEl, buttonValue,menuListItems=[], handleCollectData=()=>{} }) => {
  return (
    <>
      <Box className="audience-button">
        <Button
          endIcon={<ArrowDropDownIcon />}
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          className="audience"
          sx={{
            background: `rgba(241,241,241)`,
            fontSize: `13px`,
            textTransform: `capitalize`,
            padding:` 5px 15px`,
            color: `#222`,
            fontWeight:` 700`,
            borderRadius:'10px'

          }}
        >
            {buttonValue}
        </Button>
        <Menu
         sx={{".MuiPaper-elevation":{
            borderRadius:'12px'
         }}}
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
            sx: {
              padding: "10px 10px",
              minWidth: "200px",
              borderRadius:"80px"
            },
            className: "audience-menu-container",
          }}
        >
          {menuListItems.map((ele) => (
            <MenuItem
              className="audience-menu-items"
              sx={{
                justifyContent: "space-between",
                color: buttonValue === ele && "#a84acb",
                gap:'10px',
                borderRadius:'10px',
                fontSize:'14px',
                // backgroundColor: buttonValue === ele && 'rgba(0, 0, 0, 0.04)'
              }}
              onClick={() => {
                handleCollectData(ele);
                handleClose()
              }}
            >
              {ele} { buttonValue === ele && <CheckIcon sx={{fontSize:'20px '}} />}
            </MenuItem>
          ))}
        </Menu>
      </Box>
    </>
  );
};

export default HocCommonButton(CommonButton);
