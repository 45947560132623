import { Divider, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const UnauthenticatedCard = () => {
  return (
    <Box sx={{ padding: "20px" }}>
      <Divider />
      <Box sx={{marginTop:"20px"}}>
        <Typography sx={{ fontSize: "13px", fontWeight: "bold" }}>
          We need to verify that you have an email at pullse.ai
        </Typography>
        <Typography sx={{ fontSize: "13px" }}>
          Please click the verification link you received by email or resend to
          an address below:
        </Typography>
      </Box>

      <Box sx={{marginTop:"10px", display:"flex", gap:"10px", alignItems:"center"}} >
        <Typography sx={{ fontSize: "13px", textDecoration:"underline", color:"#a84acb" }}>
            hello@pullse.ai
        </Typography>
      <Button
              variant="outlined"
              sx={{
                background: "#f1f1fb",
                color: "#707c97",
                height: "26px",
                boxShadow: "none",
                fontSize: "12px",
                textTransform: "capitalize",
                padding: "3px 10px",
                borderColor: "#a84acb",
                color: "#a84acb",
                ":hover": { borderColor: "#a84acb" },
                textTransform: "inherit",
              }}
            //   onClick={handleAddNewDomain}
            >
              Resend Verification Email
            </Button>
      </Box>


    </Box>
  );
};

export default UnauthenticatedCard;
