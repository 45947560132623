import { Box,  Typography } from "@mui/material";
import React from "react";
import style from "./notification.module.scss";

const NotificationComponentToolbar = ({heading }) => {

  return (
    <>
      <Box className={style['details-toolbar-component']}>
        <Box className="toolbarContent">
        <Typography sx={{fontWeight:"bold", fontSize:"22px"}} >{heading}</Typography>

        </Box>
      </Box>
    </>
  );
};

export default NotificationComponentToolbar;
