import React from "react";

const AutomationSidebarIcon = ({color}) => {
  return (
    <>
      <svg
        width="20"
        height="20"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="lightning-filled">
          <path
            id="Icon"
            d="M6.08898 16.0316L14.4781 2.84862C14.7467 2.4266 15.4 2.61683 15.4 3.11705V12.5C15.4 12.5553 15.4447 12.6 15.5 12.6H21.4396C21.8442 12.6 22.0812 13.0554 21.8493 13.3868L13.5096 25.3006C13.2293 25.7011 12.6 25.5027 12.6 25.0138V16.9C12.6 16.8448 12.5552 16.8 12.5 16.8H6.51081C6.11627 16.8 5.87716 16.3644 6.08898 16.0316Z"
            stroke={color}
            strokeWidth="2.5"
          />
        </g>
      </svg>
    </>
  );
};

export default AutomationSidebarIcon;
