import React from "react";

const CustomerTicketTypeIcon = ({color='#000', height=16, width=16, ...props}) => {
  return (
    <svg
      {...props}
      class="interface-icon o__standard o__standard__ticket o__by-text"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
      fill={color}
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5 3C1.67157 3 1 3.67157 1 4.5V5.75C1 6.02614 1.22842 6.2428 1.4934 6.32052C2.21966 6.53354 2.75 7.20484 2.75 8C2.75 8.79516 2.21966 9.46646 1.4934 9.67948C1.22842 9.7572 1 9.97386 1 10.25V11.5C1 12.3284 1.67157 13 2.5 13H13.5C14.3284 13 15 12.3284 15 11.5V10.25C15 9.97386 14.7716 9.7572 14.5066 9.67948C13.7803 9.46646 13.25 8.79516 13.25 8C13.25 7.20484 13.7803 6.53354 14.5066 6.32052C14.7716 6.2428 15 6.02614 15 5.75V4.5C15 3.67157 14.3284 3 13.5 3H2.5ZM5 5C4.58579 5 4.25 5.33579 4.25 5.75C4.25 6.16421 4.58579 6.5 5 6.5H11C11.4142 6.5 11.75 6.16421 11.75 5.75C11.75 5.33579 11.4142 5 11 5H5ZM4.25 9.25C4.25 8.83579 4.58579 8.5 5 8.5H8.5C8.91421 8.5 9.25 8.83579 9.25 9.25C9.25 9.66421 8.91421 10 8.5 10H5C4.58579 10 4.25 9.66421 4.25 9.25Z"
      ></path>
    </svg>
  );
};

export default CustomerTicketTypeIcon;
