import React, { useCallback, useContext, useEffect, useState } from "react";
import { Box, Divider, InputAdornment, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Checkbox from "@mui/material/Checkbox";
import Avatar from "@mui/material/Avatar";
import { MainContext } from "../../../context/MainProvider";
import { getAllAgents } from "../../../services/Apis";
import { get } from "lodash";
import { debounceWithImmediate } from "../../../utils/helpers/helpers";
const randColor = () =>  {
  return "#" + Math.floor(Math.random()*16777215).toString(16).padStart(6, '0').toUpperCase();
}

const data = [
  {
    name: "unassigned",
    color: "gray",
    id:null
  },
];
const AssignedToMenu = ({handleClose,onSelectFilter, selectedVal}) => {
  const {allAgents} = useContext(MainContext)
  const [agents, setAgents] = useState([])
  const [inputVal, setInputVal] = useState("")

  useEffect(()=>{
    let tempAgents = [...allAgents]
    tempAgents.forEach(ele=>{
      ele['color'] = randColor()
    })
    tempAgents.unshift(...data)
    setAgents(tempAgents)
  },[allAgents])

  const makeNameSmaller = (name) => {
    return name[0].toUpperCase();
  };

  const getFilterAgentsData = (value) => {
    getAllAgents({type:'name',data:value}).then(res=>{
      let valArr = [...get(res,'data.data.docs',[])]
      valArr.unshift(...data)
      setAgents(valArr);
    })
  }

  const handleCollectData = (event) => {
    setInputVal(event.target.value)
    handleDebounce(event.target.value)
  }

  const handleFetchCustomers = (value) => {
    getFilterAgentsData(value)
  }
  const handleDebounce = useCallback(debounceWithImmediate(handleFetchCustomers,()=>{},700),[])
  return (
    <Box sx={{ width: "300px", padding: "0px" }} className={"box"}>
      <Paper
        component="div"
        className="padding"
        sx={{
          paddingTop: "0px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          height: 40,
          padding: "10px",
          boxShadow: " 0 5px 40px -12px rgba(0,0,0,0.25);",
        }}
      >
        <InputBase
          className=""
          placeholder="Search"
          sx={{ flex: 1, padding: 0 }}
          value={inputVal}
          onChange={handleCollectData}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon sx={{ color: "gray" }} />
            </InputAdornment>
          }
        />
      </Paper>
      <Divider />

      <List
        dense
        sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" , padding:'10px 0px 0px 0px'}}
      >
        {agents.map((value) => {
          const labelId = `checkbox-list-secondary-label-${value.name}`;
          let selected = selectedVal==value.id
          return (
            <ListItem key={value} disablePadding
            style={{background:selected&& '#c166e2', color:selected && 'white'}}
            onClick={()=>{
              onSelectFilter(value);
              handleClose();
            }} >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar
                    sx={{ width: 27, height: 27, background: value.color }}
                  >
                    {makeNameSmaller(value.name)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={labelId} primary={value.name} />
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default AssignedToMenu;
