import { Box, Button, Typography } from "@mui/material";
import React, { useContext } from "react";
import style from "./WebWidgetComponent.module.scss";

const WebWidgetComponentToolbar = ({}) => {

  return (
    <>
      <Box className={style.allUsersToolabrMainComponent}>
        <Box className="toolbarContent">
          <Typography  sx={{fontSize:"20px", fontWeight:"bold"}} > Widget Setting</Typography>

        </Box>
      </Box>
    </>
  );
};

export default WebWidgetComponentToolbar;
