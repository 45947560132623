import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/Image";
import style from "./listComponent.module.scss";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import { Box, IconButton, ListItemIcon } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from '@mui/icons-material/Edit';
import MainComponent from "../../../../fixedComponent/sideDashboard/MainComponent";
const ListComponent = ({ data, handleDelete, handleCreate }) => {
  return (
    <>
      <List
        sx={{ width: "100%", bgcolor: "background.paper" }}
        className={style.listComponentMainContainer}

      >
        {data.map((ele) => (
          <>
            <ListItem

              draggable
              secondaryAction={
                <>
                <Box  sx={{display:"flex", gap:"20px"}} >
                <IconButton edge="end" aria-label="delete">
                    <EditIcon sx={{ color: "green" }}  onClick={()=>handleCreate('update',{
                        id:ele.id,
                        name:ele.name,
                        description:ele.description,
                        workspaceId:ele.workspaceId
                    })} />
                  </IconButton>
                  <IconButton edge="end" aria-label="delete">
                    <DeleteOutlineIcon sx={{ color: "red" }} onClick={()=>handleDelete(ele.id)} />
                  </IconButton>
                </Box>
                </>
              }
              className="listItemContainer"
            >
              <ListItemIcon>
                <FormatAlignJustifyIcon sx={{ cursor: "pointer" }} />
              </ListItemIcon>
              <ListItemText primary={ele.name} secondary={ele.description} />
            </ListItem>
          </>
        ))}
      </List>
    </>
  );
};

export default ListComponent;
