import { Box, Button, Paper, Typography, InputBase } from "@mui/material";
import React, { useContext, useState } from "react";
import { MainContext } from "../../../../../context/MainProvider";
import {
  createNewCompany,
  createNewCustomer,
} from "../../../../../services/Apis";
import { SidebarContext } from "../../../../../context/SidebarProvider";
import { get } from "lodash";
const CreateNewUser = ({ data }) => {
  const { handleCloseModal } = useContext(MainContext);
  const {handleShowSnackPopup} = useContext(SidebarContext)
  const [loading, setLoading] = useState(false);
  let { handleGetAllCompines } = data;

  const [comapnyData, setComapnyData] = useState({
    name: "",
    description: "",
  });

  const handleCollectData = (event) => {
    let { name, value } = event.target;
    setComapnyData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleCreateNewCustomer = () => {
    setLoading(true);
    createNewCompany(comapnyData).then((res) => {
      handleGetAllCompines();
      handleCloseModal();
      setLoading(false);
      handleShowSnackPopup({
        error:false,
        message:"company create successfully"
      })
    }).catch(err=>{
      let msg = get(err,'response.data.message',"")
      setLoading(false);
      handleShowSnackPopup({
        error:true,
        message:msg,
        time:3000
      })
    })
  };
  
  return (
    <>
      <Box sx={{ padding: "20px 40px" }}>
        <Box
          sx={{
            width: "400px",
            display: "inline-block",
            height: "auto",
            position: "relative",
          }}
        >
          <Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "22px" }}>
              Create a new company
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              marginTop: "30px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
              <Typography sx={{ fontSize: "14px" }}>Name</Typography>
              <Paper
                component="box"
                sx={{
                  p: "1px 2px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 35,
                  padding: "10px",
                  boxShadow: "none",
                  ":focus": {
                    boxShadow: " 0px 0px 1px 1px #8F1EB3 !important",
                    // border:"none"
                    background: "red",
                  },
                  border: `1px solid ${"rgba(0,0,0,0.2)"}`,
                }}
              >
                <InputBase
                  name="name"
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  placeholder="Name"
                  value={comapnyData.name}
                  onChange={handleCollectData}
                />
              </Paper>
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: "3px" }}>
              <Typography sx={{ fontSize: "14px" }}>
                Description (optional)
              </Typography>
              <Paper
                component="box"
                sx={{
                  p: "1px 2px",
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  height: 35,
                  padding: "10px",
                  boxShadow: "none",
                  border: `1px solid ${"rgba(0,0,0,0.2)"}`,
                }}
              >
                <InputBase
                  value={comapnyData.description}
                  name="description"
                  sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                  placeholder="description"
                  onChange={handleCollectData}
                />
              </Paper>
            </Box>

            {/* <Box sx={{ display:"flex", flexDirection:"column", gap:"3px"}}>
        <Typography  sx={{fontSize:"14px",fontWeight:"bold"}} >User ID</Typography>
        <Paper
          component="box"
          sx={{
            p: "1px 2px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            height: 35,
            padding: "10px",
            boxShadow: "none",
            border:`1px solid ${'rgba(0,0,0,0.2)'}`
          }}
        >
          <InputBase
          value={userData.email}
           name="email"
            sx={{ ml: 1, flex: 1, fontSize:'14px' }}
            placeholder="Work email"
            // onChange={handleCollectValue}
            // value={loginData.username}
            // onFocus={()=>setOnFormFocus(true)}
            // onBlur={()=>setOnFormFocus(false)}
            // onKeyUp={onHandleSubmit}
            onChange={handleCollectData}
          />
        </Paper>
        </Box> */}
          </Box>
          {/* <Box sx={{marginTop:"2px"}} >
        <Typography sx={{fontSize:"13px", textDecoration:"underline", cursor:"pointer"}} >
        A unique user identifier that won’t change.
        </Typography>
       </Box>
       <Box sx={{margin:"20px 0px"}}>
        <Typography  sx={{fontSize:"13px", color:'gray',textDecoration:"underline",cursor:"pointer"}}>
        To add multiple users use an import tool
        </Typography>
       </Box>
        */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Box sx={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                onClick={handleCloseModal}
                sx={{
                  background: "#F1F1F1",
                  color: "black",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={handleCreateNewCustomer}
                sx={{
                  background:
                    "linear-gradient(137deg, #8F1EB3 22.4%, #3F1599 101.81%)",
                  color: "white",
                  height: "30px",
                  textTransform: "inherit",
                  fontSize: "13px",
                }}
              >
                {loading ? "Loading..." : "Create company"}
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CreateNewUser;
