import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Chip } from '@mui/material';
import { Box } from '@mui/system';

let mockTableData = [
    {
        type:"CNAME",
        name:"intercom._domainkey.pullseai.com",
        value:"c865bfa8-4967-4384-90b5-9b22dc01e75a.dkim.intercom.io",
        authenticated:true
    },
    {
        type:"CNAME",
        name:"outbound.intercom.pullseai.com",
        value:"rp.pullse-2.intercom-mail.com",
        authenticated:true
    }
]
const CardDataComponent = () => {
  return (
    <>
    
   <Box  sx={{padding:"10px 5px"}} >
   <TableContainer component={Paper} sx={{boxShadow:"none"}} >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
        <TableRow>
            <TableCell  sx={{fontWeight:"bold"}}>Type</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Name	</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}} >Value</TableCell>
            <TableCell align="right"  sx={{fontWeight:"bold"}}>	Status	</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {mockTableData.map(({type,name,value,authenticated}) => (
            <TableRow
              key={value}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {type}
              </TableCell>
              <TableCell align="right">{name}</TableCell>
              <TableCell align="right">{value}</TableCell>
              <TableCell align="right">{authenticated ? 
              <>
                <Chip
                      sx={{
                        borderRadius: "5px",
                        height: "24px",
                        color: "rgba(15,113,52,1)",
                        background: "rgba(215,239,220,1)",
                        fontWeight: "bold",
                      }}
                      label="Authenticated"
                    />
              </>
              :
              <>
               <Chip
                      sx={{
                        borderRadius: "5px",
                        height: "24px",
                        color: "rgba(66,50,0,1)",
                        background: "rgba(254,237,175,1)",
                        fontWeight: "bold",
                      }}
                      label="Unauthenticated"
                    />
              </>}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
   </Box>
    </>
  )
}

export default CardDataComponent
