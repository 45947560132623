import { Box, Typography } from '@mui/material'
import React from 'react'
let colors = [
    "#ffd9e1",
    "#b29cd8",
    "#b3ebeb",
    "#ffd9e1",
    "#b29cd8",
    "#b3ebeb",
]


const MaxtrixCard = ({content,index, header="",color="green"}) => {
  return (
    <Box sx={{display:'flex', justifyContent:'center',  alignItems:'center', gap:'10px', height:'200px',  flexDirection:'column', background:'#f7f7f7', width:'100%', borderRadius:'16px', padding:'10px', border:`1px solid ${color}`, position:"relative" ,":hover":{
        background:color,
        color:"white",
        boxShadow:"2px 3px 5px rgba(0,0,0,0.4)"
    }  }} >
        {/* <span style={{
            position:"absolute",
            top:"30%",
            left:"10%",
            height:"4px",
            borderRadius:"4px",
            background:colors[index],

            display:"inline-block",
            // border:"1px solid green",
            width:`${content}%`,

        }} ></span> */}
        <Typography sx={{textAlign:'center', color:'#707C97', fontSize:'30px', textTransform:"capitalize"}}>
            {header.length ? header : ""}
        </Typography>
    <Typography sx={{textAlign:'center', color:'#707C97', fontSize:'30px'}} >
        {content}
    </Typography>
    </Box>
  )
}

export default MaxtrixCard
